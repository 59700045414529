import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";

import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { convertUsersToOptionType } from "utils/userManagement";
import { filterUserGroup, getAvailbleUsers } from "utils/groupManagement";
import { toastError } from "utils/toast.util";
import SMButton from "components/SMButton/SMButton";
import SMCustomSelect from "components/SMCustomSelect/SMCustomSelect";
import SMTextField from "components/SMTextField/SMTextField";

interface Props {
  searchQuery: string | undefined;
  commonCls: any;
  group: Group;
  userGroupType: string;
  deviceUsers?: User[];
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setEditGroup: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editGroup: { [key: string]: boolean };
  onDelete: (group: Group) => void;
  t: any;
  onSaveAction: (group: Group) => void;
  usersEntity: { [key: string]: User };
}
function GroupListRowEdit({
  searchQuery,
  commonCls,
  group,
  deviceUsers,
  groupEntity,
  userGroupType,
  selectedItems,
  setSelectedItems,
  setEditGroup,
  editGroup,
  onDelete,
  t,
  onSaveAction,
  usersEntity
}: Props) {
  const [updatedGroup, setUpdatedGroup] = useState<Group>(_.cloneDeep(group));
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onGroupChange = (key: string, value: any) => {
    const updatedGroupInfo: Group = {
      ...updatedGroup,
      [key]: value
    } as Group;
    setUpdatedGroup(updatedGroupInfo);
  };

  const getSlectedUsers = () => {
    return updatedGroup.users?.map((id: string) => usersEntity[id]);
  };

  const getDefaultGroupValues = () => {
    return updatedGroup.view_user_groups?.map((id: string) => groupEntity[id]);
  };

  const onSave = () => {
    setErrorMessage("");
    if (!updatedGroup) {
      return;
    }
    if (updatedGroup.name) {
      onSaveAction(updatedGroup);
    } else {
      setErrorMessage(t("Please Enter Group Name"));
      // show toast
      toastError(t("Please Enter Group Name"));
    }
  };

  return (
    <div className={`${commonCls.evenChildBackground} ${commonCls.lightBlue}`}>
      <div className={`${commonCls.row}`}>
        <div
          className={`${commonCls.checkboxlistWraper}`}
          data-ref="preventParentClick"
        >
          <FormControlLabel
            value="select"
            control={<Checkbox color="default" />}
            label=""
            labelPlacement="end"
            checked={!!selectedItems[group.id]}
            onChange={(e, value) => {
              setSelectedItems({ ...selectedItems, [group.id]: value });
            }}
          />
        </div>
        <div className={commonCls.col}>
          <SMTextField
            placeholder={
              userGroupType === UserGroupType.viewer
                ? t("Employee Group Name")
                : t("User Group Name")
            }
            onSave={(val: string) => {
              onGroupChange("name", val);
            }}
            value={updatedGroup.name}
            delay={0}
            borderRed={(errorMessage && !updatedGroup.name) || false}
            width="100%"
          />
        </div>
        <div className={commonCls.col}>
          <SMCustomSelect
            optionsList={
              deviceUsers
                ? (convertUsersToOptionType(
                    getAvailbleUsers(Object.values(usersEntity), userGroupType)
                  ) as any[])
                : []
            }
            selectedList={getSlectedUsers() as any[]}
            appendText={
              userGroupType === UserGroupType.viewer
                ? t("employees selected")
                : t("users selected")
            }
            onChange={(options: any[]) => {
              const usersSelected = options.map((usr: User) => usr.id);
              onGroupChange("users", usersSelected);
            }}
            labels={{
              txtShowMore: t("Show All"),
              txtShowLess: t("Show Less"),
              txtSelectClearToggle: t("Select All / Clear All"),
              txtClearAll: t("Clear All"),
              txtSelected: t("Selected"),
              txtNotSelected: t("Not Selected")
            }}
          />
        </div>
        {userGroupType === UserGroupType.viewer ? (
          <div className={commonCls.col}>
            <SMCustomSelect
              optionsList={
                filterUserGroup({
                  type: UserGroupType.device,
                  userGroups: Object.values(groupEntity)
                }) as any[]
              }
              selectedList={getDefaultGroupValues() as any[]}
              appendText={t("groups selected")}
              onChange={(options: any[]) => {
                const userGroups = options.map((grp: Group) => grp.id);
                onGroupChange("view_user_groups", userGroups);
              }}
              labels={{
                txtShowMore: t("Show All"),
                txtShowLess: t("Show Less"),
                txtSelectClearToggle: t("Select All / Clear All"),
                txtSelected: t("Selected"),
                txtNotSelected: t("Not Selected")
              }}
            />
          </div>
        ) : (
          ""
        )}
        <div className={commonCls.col} />
      </div>
      <div className={`${commonCls.row} ${commonCls.alignFlexEnd}`}>
        <div className={commonCls.actionBtnWrapper}>
          <div className={commonCls.actionBtn}>
            <SMButton text={t("Save")} onClick={onSave} />
          </div>
          <div className={commonCls.actionBtn}>
            <SMButton
              outlineBtn
              text={t("Cancel")}
              onClick={() =>
                setEditGroup({ ...editGroup, [updatedGroup.id]: false })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GroupListRowEdit;
