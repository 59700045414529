import i18n from "../i18n/config";

function joinDateStrings(
  t: number | Date | undefined,
  a: any[],
  s: string,
  locale: string
) {
  function format(m: any) {
    const f = new Intl.DateTimeFormat(locale, m);
    return f.format(t);
  }
  return a.map(format).join(s);
}

export const formateDateToDDMMMYYYY = (
  date: string,
  locale: string
): string => {
  const a = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
  return joinDateStrings(new Date(date), a, "-", locale);
};

export const localeMap: { [key: string]: string } = {
  en: "en-gb",
  nb_private_customer: "nb",
  nb_sleep_research: "nb",
  nb_healthwelfare: "nb"
};

export const getLocale = () => {
  const lang: string = i18n.language || "nb_healthwelfare";
  return localeMap[lang];
};
