import * as XLSX from "xlsx";
import moment from "moment";

const getHHMM = (seconds) => {
  return `${Math.floor(moment.duration(seconds, "seconds").asHours())}:${moment
    .duration(seconds, "seconds")
    .minutes()}`;
};

const exportToExcel = (data, userName) => {
  const fileName = userName ? `${userName}.xlsx` : "import.xlsx";
  let sheettitle = userName ? `${userName}` : "Sleep history";
  if (sheettitle.length > 30) {
    sheettitle = sheettitle.substring(0, 30);
  }
  const wb = XLSX.utils.book_new();
  const dataSet = [];
  const wscols = [];

  if (!data && !data.length) {
    return false;
  }

  for (let i = 0; i < data.length; i += 1) {
    const sessionReport = data[i]._embedded.sleep_analysis.report;

    const row = {
      // "Session start": moment
      //   .utc(sessionReport.session_start)
      //   .format("YY-MMMM-D , h:mm"),
      // "Session end": moment
      //   .utc(sessionReport.session_end)
      //   .format("YY-MMMM-D , h:mm"),
      "Intent to sleep": moment
        .utc(sessionReport.time_at_intended_sleep)
        .format("YY-MMMM-D, HH:mm"),
      "Time at sleep": moment
        .utc(sessionReport.time_at_sleep)
        .format("YY-MMMM-D, HH:mm"),
      "Time at wakeup": moment
        .utc(sessionReport.time_at_wakeup)
        .format("YY-MMMM-D, HH:mm"),
      // "Time in bed (hour:minute)": getHHMM(sessionReport.time_in_bed),
      "Time asleep (hour:minute)": getHHMM(sessionReport.time_asleep),
      "Time in light sleep (hour:minute)": getHHMM(
        sessionReport.time_in_light_sleep
      ),
      "Time in deep sleep (hour:minute)": getHHMM(
        sessionReport.time_in_deep_sleep
      ),
      "Time in rem sleep (hour:minute)": getHHMM(
        sessionReport.time_in_rem_sleep
      ),
      "Time in no presence (hour:minute)": getHHMM(
        sessionReport.time_in_no_presence
      ),
      "Sleep onset": getHHMM(sessionReport.sleep_onset),
      "Number of times no presence": sessionReport.number_of_times_no_presence,
      "Number of times awake": sessionReport.number_of_times_awake_long,
      "Sleep efficiency": `${sessionReport.sleep_efficiency}%`,
      "Sleep score": sessionReport.sleep_score_1,
      "Resting respiration rate": sessionReport.non_rem_mean_rpm
    };
    dataSet.push(row);
  }

  // Add Header column length

  const header = Object.keys(dataSet[0]);
  for (let i = 0; i < header.length; i += 1) {
    wscols.push({ wch: header[i].length + 10 });

    // wsrows.push({ hpx: 20 });
  }

  const ws = XLSX.utils.json_to_sheet(dataSet);
  ws["!cols"] = wscols;

  // ws["!rows"] = wsrows;

  XLSX.utils.book_append_sheet(wb, ws, sheettitle);
  XLSX.writeFile(wb, fileName);

  return true;
};

export default exportToExcel;
