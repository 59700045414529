import { AppRouteProps } from "interfaces/router.interface";

export const employeeRouteNames = {
  employeesList: "employeesList",
  viewEmployee: "viewEmployee"
};

export const employeeRoutes: AppRouteProps[] = [
  {
    name: employeeRouteNames.employeesList,
    path: "/app/settings/employees",
    exact: true
  },
  {
    name: employeeRouteNames.viewEmployee,
    path: "/app/settings/employees/:id",
    exact: false
  }
];

export default employeeRoutes;
