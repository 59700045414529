import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import cn from "classnames";

import { OnOffSwitch } from "../OnOffSwitch";
import { getTimeFilter, setFilter } from "../../utils/localStorage";
import Card from "../Cards/Card";
import CardActions from "../Cards/CardActions";
import CardContent from "../Cards/CardContent";
import CardHeader from "../Cards/CardHeader";
import DatePickers from "../Datepicker/DatePicker";
import IconExcel from "../../assets/icons/IconExcel.svg";
import SleepHistoryStages from "../SleepHistoryStages";
import SleepHistoryVitals from "../SleepHistoryVitals";

const styles = (theme) => ({
  divider: {
    marginTop: 16
  },
  right: {
    marginLeft: "auto"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  verticalDivider: {
    margin: "0 10px"
  },
  timeField: {
    marginRight: "10px"
  },
  exportToExcel: {
    marginRight: "10px",
    cursor: "pointer"
  },
  icoExportExcel: {
    objectFit: "contain",
    height: "25px",
    width: "25px",
    float: "left",

    marginRight: "4px"
  },
  txtExportExcel: {
    float: "left"
  }
});

function SleepHistoryComponent({
  classes,
  showVitals,
  onToggleVitals,
  options,
  filterSessionstime,
  exportTpoExcel,
  enableHeartRateMean
}) {
  const timeFilterConfig = getTimeFilter() || {};
  const [fromTime, setFromTime] = useState(timeFilterConfig.from || "");
  const [toTime, setToTime] = useState(timeFilterConfig.to || "");
  const [checked, setChecked] = useState(timeFilterConfig.enabled || false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const updateTimeFilter = ({ from, to, enabled }) => {
    const filter = getTimeFilter() || {};

    // update enable/disable time

    if (enabled !== undefined) {
      filter.enabled = enabled;
    }

    // update from time

    if (from) {
      filter.from = from;
    }

    // update to time

    if (to) {
      filter.to = to;
    }

    // save to local storage

    setFilter(filter);
  };

  const saveCurrentFilter = () => {
    if (!!toTime && !!fromTime) {
      updateTimeFilter({ from: fromTime, to: toTime });
    }
  };

  useEffect(() => {
    if (!!toTime && !!fromTime) {
      saveCurrentFilter();
      filterSessionstime({ from: fromTime, to: toTime, enabled: checked });
    }
    return () => {};
  }, [toTime, fromTime, checked]);

  // options

  if (!options) {
    return null;
  }
  const {
    sleepHistoryStagesOptions,
    sleepHistoryVitalsOptions,
    datePickerOptions
  } = options;

  const handleChange = (event) => {
    updateTimeFilter({ enabled: event.target.checked });
    setChecked(event.target.checked);
  };

  return (
    <Card>
      <CardHeader
        actions={[
          <TextField
            type="time"
            label={t("From")}
            className={classes.timeField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 300 // 5 min
            }}
            value={fromTime}
            disabled={!checked}
            onChange={(e) => setFromTime(e.target.value)}
            key="from"
          />,
          <TextField
            type="time"
            label={t("To")}
            className={classes.timeField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 300 // 5 min
            }}
            value={toTime}
            disabled={!checked}
            onChange={(e) => setToTime(e.target.value)}
            key="to"
          />,
          <FormControlLabel
            control={
              <OnOffSwitch
                checked={checked}
                onChange={handleChange}
                name="save locally"
              />
            }
            label=""
            key="save"
          />,

          <Divider
            orientation="vertical"
            flexItem
            className={classes.verticalDivider}
            key="devider"
          />,
          <Typography key="datePicker">
            {`${datePickerOptions.fromValue
              .lang(lang)
              .format("MMMM DD")} - ${datePickerOptions.toValue
              .lang(lang)
              .format("MMMM DD")}`}
          </Typography>,
          <DatePickers {...datePickerOptions} key="datePickerOptions" />,
          <Typography
            className={classes.exportToExcel}
            onClick={exportTpoExcel}
            key="exportIcon"
          >
            <img src={IconExcel} className={classes.icoExportExcel} alt="" />
            <span className={classes.txtExportExcel}>
              {t("Export to Excel")}
            </span>
          </Typography>
        ]}
        title={sleepHistoryStagesOptions.title || t("Sleep stages")}
      />
      <CardContent>
        <SleepHistoryStages options={sleepHistoryStagesOptions} />
      </CardContent>
      <Divider className={classes.divider} />
      <CardActions>
        <Collapse in={showVitals}>
          <CardHeader
            title={sleepHistoryVitalsOptions.title || t("Sleep History Vitals")}
          />
        </Collapse>
        <Typography className={classes.right}>{`${
          showVitals ? t("Hide") : t("Show")
        } ${t("Vital signs")}`}</Typography>
        <IconButton
          className={cn(classes.expand, {
            [classes.expandOpen]: showVitals
          })}
          onClick={onToggleVitals}
          aria-expanded={showVitals}
          aria-label="Show vitals"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={showVitals} mountOnEnter unmountOnExit>
        <CardContent>
          <SleepHistoryVitals
            options={sleepHistoryVitalsOptions}
            enableHeartRateMean={enableHeartRateMean}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}

SleepHistoryComponent.propTypes = {
  classes: PropTypes.any,
  showVitals: PropTypes.any,
  onToggleVitals: PropTypes.any,
  options: PropTypes.any,
  filterSessionstime: PropTypes.any,
  exportTpoExcel: PropTypes.any,
  enableHeartRateMean: PropTypes.string
};

export default withStyles(styles)(SleepHistoryComponent);
