import { Slider, makeStyles } from "@material-ui/core";
import React from "react";

interface Props {
  value: any;
  onChange: any;
  min?: number;
  max?: number;
  steps: number;
  displayFormater?: (value: number) => string;
  autoWidth?: boolean;
}

// const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
const useStyles = makeStyles((theme: any) => ({
  sliderContainerCls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: ({ autoWidth }: { autoWidth: boolean }) =>
      autoWidth ? "auto" : 240
  },
  sliderCls: {
    width: "90%",
    color: theme.palette.primary.dark
  },
  valueLabel: {
    left: "calc(-50% + -5px)",
    border: "1px solid #F0F0F0",
    "& *": {
      background: "#FFF",
      textAlign: "center",
      zIndex: 99,
      color: "#000",
      fontWeight: "bold"
    }
  },
  thumb: {
    width: 12,
    height: 12
  },
  track: {
    borderRadius: 1
  },
  rail: {}
}));

function DiscreteSlider({
  value,
  onChange,
  min,
  max,
  steps,
  displayFormater,
  autoWidth = false
}: Props): JSX.Element {
  const classes = useStyles({ autoWidth });
  const getDisplayValue = (curValue: number): string => {
    if (displayFormater) {
      return displayFormater(curValue);
    }
    return `${curValue}`;
  };

  return (
    <div className={classes.sliderContainerCls}>
      <Slider
        key={`slider-${value}`}
        className={classes.sliderCls}
        step={steps}
        defaultValue={value}
        onChangeCommitted={onChange}
        min={min || 1}
        max={max || 3}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        getAriaValueText={getDisplayValue}
        valueLabelFormat={getDisplayValue}
        classes={{
          valueLabel: classes.valueLabel,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail
        }}
      />
    </div>
  );
}
export default DiscreteSlider;
