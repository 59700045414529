import { makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";

import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";

export const useStyles = makeStyles((theme: any) => ({
  autoSuggestTextfield: {
    border: `1px solid ${theme.palette.primary.input}`,
    height: 53,
    margin: 0,
    borderRadius: 5,
    boxSizing: "border-box",
    width: ({ width }: { width: number | string }) => width || 254
  },
  inputRoot: {
    padding: 0,
    paddingLeft: 4,
    margin: 0,
    "& input": {
      height: 53,
      padding: "0 !important",
      paddingLeft: "12px !important"
    }
  },
  popupIndicator: {
    width: 12,
    height: 7,
    marginRight: 20,
    "& .MuiIconButton-root": {
      margin: 0,
      padding: 0,
      fontSize: 12
    }
  },
  clearIndicator: {
    display: "none"
  }
}));

interface Props {
  onSelect: (option: any) => void;
  options: any[];
  name?: string;
  defaultValue?: any;
  width?: number | string;
}
export default function AutoSuggestSelect({
  onSelect,
  options,
  name,
  defaultValue,
  width = 254
}: Props) {
  const classes = useStyles({ width });
  if (options.length) {
    return (
      <Autocomplete
        className={classes.autoSuggestTextfield}
        options={options}
        value={defaultValue ?? ""}
        getOptionLabel={(option: any) => `${option}`}
        closeIcon={null}
        popupIcon={
          <div>
            <DownArrowOutline />
          </div>
        }
        classes={{
          endAdornment: classes.popupIndicator,
          clearIndicator: classes.clearIndicator
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            classes={{
              root: classes.inputRoot
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
        onChange={(event, option) => {
          onSelect(option || "");
        }}
      />
    );
  }
  return <div />;
}
