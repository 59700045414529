export interface DeviceRouteType {
  name: string;
  path: string;
  exact?: boolean;
}

export const deviceRouteNames = {
  deviceList: "deviceList",
  deviceView: "deviceView"
};
export const deviceRoutes: DeviceRouteType[] = [
  {
    name: deviceRouteNames.deviceList,
    path: "/app/settings/devices",
    exact: true
  },
  {
    name: deviceRouteNames.deviceView,
    path: "/app/settings/devices/:id",
    exact: false
  }
];
export default deviceRoutes;
