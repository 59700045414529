import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { DEVICE_ACTIONS } from "store/actionTypes/devicesActionTypes";
import { Device, DeviceSettings } from "interfaces/device.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import {
  fetchDeviceSettings,
  resetDeviceSettingsCurrentAction
} from "store/actions/devicesSettingsActions";
import { isEmpty, navObj } from "utils/deviceManagement";
import { resetDeviceCurrentAction } from "store/actions/devicesActions";
import { toastSucess } from "utils/toast.util";

import DeviceInformation from "./DeviceInformation";

/*
 *  DeviceViewTypes Interface
 */
interface DeviceViewTypes {
  id: string;
  deviceEntity: { [key: string]: Device } | undefined;
  usersEntity: any;
  loading: boolean;
  routePath: string;
}

function DeviceView({
  id,
  deviceEntity,
  usersEntity,
  loading,
  routePath
}: DeviceViewTypes): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [user, setCurrentUser] = useState<any>();
  const [device, setCurrentDevice] = useState<Device>();
  const [currentDeviceID, setCurrentDeviceID] = useState<string>(id);
  const [newUser, setNewUser] = useState<User>();

  /**
   * React useSelector hook
   *
   * @return deviceSettings from application state
   */
  const deviceSettings: DeviceSettings = useSelector((state: any) => {
    return state.devicesSettings[id] || {};
  });

  const deviceCurrentAction: any = useSelector((state: any) => {
    return state.deviceReducer.currentAction || {};
  });

  const deviceSettingsCurrentAction: any = useSelector((state: any) => {
    return state.devicesSettings.currentAction || {};
  });

  const usersState = useSelector((state: any) => {
    return state?.users;
  });

  useEffect(() => {
    if (usersState.currentAction === USER_ACTIONS.createSuccess) {
      if (usersState.newUser) {
        setNewUser(_.cloneDeep(usersState.newUser));
      }
    }
  }, [usersState]);

  useEffect(() => {
    if (deviceCurrentAction === DEVICE_ACTIONS.deviceUpdateSuccess) {
      toastSucess(t("Device information updated successfully"));
      dispatch(resetDeviceCurrentAction());
      dispatch(resetDeviceSettingsCurrentAction());
      onBack();
    }
  }, [deviceCurrentAction, deviceSettingsCurrentAction]);

  // dispatch actions.
  useEffect(() => {
    if (isEmpty(deviceSettings)) {
      dispatch(fetchDeviceSettings(id));
    }
  }, []);

  useEffect(() => {
    if (deviceEntity !== undefined && !isEmpty(deviceEntity)) {
      const selectedDevice = deviceEntity[currentDeviceID];
      if (selectedDevice) {
        const userId = selectedDevice.user_id;
        const selectedUser = usersEntity[userId];
        setCurrentDevice(selectedDevice);
        setCurrentUser(selectedUser);
      } else {
        onBack();
      }
    }
  }, [deviceSettings, currentDeviceID, deviceEntity, usersEntity]);

  const onBack = () => {
    const path = `${routePath.replace("/:id", "")}`;
    history.push(path);
  };

  return (
    <div>
      {user && deviceSettings && device && (
        <DeviceInformation
          deviceSettings={deviceSettings}
          user={user}
          device={device}
          newUser={newUser}
        />
      )}
    </div>
  );
}
export default DeviceView;
