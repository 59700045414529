import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import AddEmployeeDrawer from "components/AddEmployeeDrawer/AddEmployeeDrawer";
import AddUserSlidingForm from "components/AddUserSlidingForm/AddUserSlidingForm";
import Highlighted from "components/Highlighted/HighLighted";
import SearchTextField from "components/SearchTextField/SearchTextField";
import ShowMoreComponent from "components/ShowMoreComponent/ShowMoreComponent";
import SwipeableTemporaryDrawer from "components/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    width: "100%",
    float: "left",
    marginBottom: 20
  },
  listWrapper: {
    width: "100%",
    float: "left"
  },
  subUser: {
    float: "left",
    padding: 8,
    fontSize: 16,
    width: ({ inline }: { inline: boolean }) => (inline ? "100%" : "33%")
  },
  title: {
    fontSize: 24,
    width: "100%",
    float: "left",
    margin: "16px 0"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 17
  },
  showMoreWrapper: {
    marginLeft: ({ inline }: { inline: boolean }) =>
      inline ? 15 : "calc(66% + 14px)",
    float: "left"
  },
  drawerWrapper: {
    float: "left",
    marginLeft: 117,
    marginTop: 3
  }
}));

function SMViewUsersList({
  lists,
  onChange,
  label,
  searchPlaceholder,
  defaultValues,
  disabled,
  inline = false,
  userGroupType,
  hideDrawer = false,
  hideLabel = false,
  showItems = 6
}: {
  lists: User[];
  onChange: (value: string[], key: string) => void;
  label: string;
  searchPlaceholder: string;
  defaultValues: string[];
  disabled?: boolean;
  inline?: boolean;
  userGroupType: string;
  hideDrawer?: boolean;
  hideLabel?: boolean;
  showItems?: number;
}) {
  const classes = useStyles({ inline });
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");
  const [total, setTotal] = useState<number>();
  const [showMore, setShowMore] = useState<boolean>(true);
  const [filteredItems, setFilteredItems] = useState<User[]>();

  const [toggleUserAdd, setToggleUserAdd] = useState<boolean>(false);

  useEffect(() => {
    const filteredList = filterArray(lists, query);
    setFilteredItems(
      showMore ? filterArray(lists, query)?.splice(0, showItems) : filteredList
    );
    setTotal(filteredList.length);
  }, [query, lists, showMore]);

  const onCheckBoxChange = (id: string, checked: boolean) => {
    const subList = defaultValues || [];
    if (checked) {
      subList.push(id);
    } else {
      _.remove(subList, (item) => {
        return item === id;
      });
    }
    onChange(subList, "view_users");
  };

  const filterArray = (arr: User[], q: string) => {
    return arr.filter((item: User) => {
      return item.display_name.toLowerCase().includes(q.toLowerCase());
    });
  };
  return (
    <div className={classes.container}>
      {!hideLabel ? <div className={classes.title}>{label}</div> : ""}
      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={(q: string) => setQuery(q)}
          width="320px"
          placeholder={searchPlaceholder}
        />
        {!hideDrawer ? (
          <div className={classes.drawerWrapper}>
            <SwipeableTemporaryDrawer
              label={
                userGroupType === UserGroupType.device
                  ? t("Add User")
                  : t("Add Employee")
              }
              component={
                userGroupType === UserGroupType.device ? (
                  <AddUserSlidingForm onclose={setToggleUserAdd} />
                ) : (
                  <AddEmployeeDrawer onclose={setToggleUserAdd} />
                )
              }
              setToggle={setToggleUserAdd}
              toggle={toggleUserAdd}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classes.listWrapper}>
        {filteredItems
          ? filteredItems.map((user: User) => (
              <div className={classes.subUser} key={user.email}>
                <FormControlLabel
                  value={user.id}
                  disabled={disabled}
                  control={<Checkbox color="default" value={user.id} />}
                  label={
                    <Highlighted text={user.display_name} highlight={query} />
                  }
                  labelPlacement="end"
                  checked={defaultValues?.includes(user.id)}
                  onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                    onCheckBoxChange(user.id, checked)
                  }
                />
              </div>
            ))
          : ""}
      </div>

      {total && total > showItems ? (
        <div className={classes.showMoreWrapper}>
          <ShowMoreComponent
            total={total}
            showMore={showMore}
            showItems={showItems}
            setShowMore={setShowMore}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default SMViewUsersList;
