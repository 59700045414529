import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import moment from "moment";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";

import Section from "../Sections/Section";
import SectionContent from "../Sections/SectionContent";
import SectionHeader from "../Sections/SectionHeader";
import SessionDetails from "../SessionDetails";
import SleepHistory from "../SleepHistory";

const styles = () => ({
  gutterTop: {
    marginTop: 20
  },
  gutterBottom: {
    marginBottom: 20
  },
  hypnogramButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 24
  },
  root: {
    padding: 16
  }
});

const DashboardComponent = (props) => {
  const {
    classes,
    sleepHistoryOptions,
    sessionDetailsOptions,
    exportTpoExcel,
    filterSessionstime,
    showLastNight,
    ...rest
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const dashboardViewMode = getLocalSettings(
    USER_SETTINGS_CONSTANTS.set_history_view_mode
  );
  const [mode, setMode] = React.useState(dashboardViewMode || "last_night");

  let enableHeartRateMean = new URLSearchParams(location.search).get(
    "enable_heart_rate_mean"
  );

  if (enableHeartRateMean) {
    if (enableHeartRateMean === "true") {
      // const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?myNewUrlQuery=1`;
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.enable_heart_rate_mean]: "true"
      });
    } else if (enableHeartRateMean === "false") {
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.enable_heart_rate_mean]: "false"
      });
    }
  } else {
    const lclEnableHeartRateValue = getLocalSettings(
      USER_SETTINGS_CONSTANTS.enable_heart_rate_mean
    );

    if (lclEnableHeartRateValue === "true") {
      // const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?enable_heart_rate_mean=true`;
      // window.history.pushState({ path: newurl }, "", newurl);
      enableHeartRateMean = "true";
    } else {
      enableHeartRateMean = "false";
    }
  }
  useEffect(() => {
    if (mode === "last_night" && sleepHistoryOptions !== null) {
      showLastNight();
    }
  }, [mode, sleepHistoryOptions]);
  return (
    <div className={classes.root}>
      {sleepHistoryOptions && (
        <Section>
          <SectionHeader
            title={sleepHistoryOptions.title || t("Sleep history")}
            actionButtons
            setMode={setMode}
            mode={mode}
          />
          {mode === "history" ? (
            <SleepHistory
              options={sleepHistoryOptions}
              exportTpoExcel={exportTpoExcel}
              filterSessionstime={filterSessionstime}
              enableHeartRateMean={enableHeartRateMean}
            />
          ) : (
            ""
          )}
        </Section>
      )}
      {sessionDetailsOptions && (
        <Section>
          <SectionHeader
            title={sessionDetailsOptions.title || t("Session Details")}
            subheader={moment
              .utc(
                sessionDetailsOptions.sleepStatisticsOptions.plotData.series
                  .timeAtStart
              )
              .format("MMMM DD, YYYY")}
          />

          <SectionContent>
            <SessionDetails
              {...rest}
              options={sessionDetailsOptions}
              enableHeartRateMean={enableHeartRateMean}
            />
          </SectionContent>
        </Section>
      )}
    </div>
  );
};

DashboardComponent.propTypes = {
  classes: PropTypes.any,
  sleepHistoryOptions: PropTypes.any,
  sessionDetailsOptions: PropTypes.any,
  exportTpoExcel: PropTypes.any,
  filterSessionstime: PropTypes.any,
  rest: PropTypes.any,
  showLastNight: PropTypes.func
};

export default withStyles(styles)(DashboardComponent);
