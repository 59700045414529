import { makeStyles } from "@material-ui/core";
import React from "react";

import { ReactComponent as DownArrow } from "../../assets/icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../assets/icons/UpArrow.svg";

export const useStyle = makeStyles((theme: any) => ({
  sortIcon: {
    position: "relative",
    width: 10,
    height: 10,
    color: theme.palette.primary.dark,
    marginLeft: 8,
    float: "left",
    "& > svg": {
      width: 10,
      height: 5,
      float: "left",
      marginTop: 4
    }
  }
}));

function IconSort({
  activeSortOrder
}: {
  activeSortOrder?: "ASC" | "DESC" | null;
}) {
  const classes = useStyle();
  const opacityArrowDropDown = activeSortOrder === "ASC" ? 1 : "1";
  const opacityArrowDropUp = activeSortOrder === "DESC" ? 1 : "1";
  return (
    <div className={classes.sortIcon}>
      <UpArrow style={{ opacity: opacityArrowDropUp }} />
      <DownArrow style={{ opacity: opacityArrowDropDown }} />
    </div>
  );
}
export default IconSort;
