import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

function SingleTextField({ title, text }) {
  return (
    <div>
      <Typography align="center">{title}</Typography>
      <Typography variant="h4" align="center">
        {text || " - "}
      </Typography>
    </div>
  );
}

SingleTextField.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any
};

export default SingleTextField;
