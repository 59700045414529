/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { isAdminUser } from "utils/userManagement";

import EmployeeListRowEdit from "./EmployeeListRowEdit";
import EmployeeListRowView from "./EmployeeListRowView";

interface Props {
  loginedUser: User;
  searchQuery: string | undefined;
  commonCls: any;
  user: User;
  t: any;
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editEmployee: { [key: string]: boolean };
  setEditEmployee: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  viewUserDetails: (event: any, user: User) => void;
  onSaveAction: (updatedUser: User, currentUserGroupsIds: string[]) => void;
  onDelete: (user: User) => void;
}

function EmployeeRow({
  loginedUser,
  searchQuery,
  commonCls,
  user,
  groupEntity,
  t,
  selectedItems,
  setSelectedItems,
  editEmployee,
  setEditEmployee,
  viewUserDetails,
  onSaveAction,
  onDelete
}: Props) {
  return (
    <>
      {editEmployee[user.id] && isAdminUser(loginedUser) ? (
        <EmployeeListRowEdit
          commonCls={commonCls}
          user={user}
          groupEntity={groupEntity}
          t={t}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          editEmployee={editEmployee}
          setEditEmployee={setEditEmployee}
          onSaveAction={onSaveAction}
        />
      ) : (
        <EmployeeListRowView
          searchQuery={searchQuery}
          commonCls={commonCls}
          user={user}
          groupEntity={groupEntity}
          t={t}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          editEmployee={editEmployee}
          setEditEmployee={setEditEmployee}
          onDelete={onDelete}
          viewUserDetails={viewUserDetails}
          isAdmin={isAdminUser(loginedUser)}
        />
      )}
    </>
  );
}

export default EmployeeRow;
