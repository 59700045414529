export enum SortTypes {
  DeviceSort,
  UserSort,
  SerialNumber,
  StatusSort,
  UserNameSort,
  GroupNameSort
}

export enum NightReportSortTypes {
  DeviceSort,
  RespirationRateSort,
  MovementSort,
  TotalSleepTimeSort,
  SleepScoreSort,
  OutOfBedSort,
  SessionInProgressSort,
  BaselineNightsSort,
  UserSort
}

export enum NotificationFilterTypes {
  active_users = "active_users",
  enabled_rules = "enabled_rules"
}
