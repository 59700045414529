import { AppRouteProps } from "interfaces/router.interface";

export const groupsRouteNames = {
  home: "/",
  employees: "Employees groups",
  deviceUsers: "User Groups",
  employeesDetails: "Employees Details",
  deviceUsersGroupDetails: "Device Users Group Details"
};
export const groupsRoutes: AppRouteProps[] = [
  {
    name: groupsRouteNames.home,
    path: "/app/settings/groups",
    exact: true,
    redirectPath: "/app/settings/groups/employees"
  },
  {
    name: groupsRouteNames.employees,
    path: "/app/settings/groups/employees",
    exact: true
  },
  {
    name: groupsRouteNames.deviceUsers,
    path: "/app/settings/groups/deviceUsers",
    exact: true
  },
  {
    name: groupsRouteNames.deviceUsersGroupDetails,
    path: "/app/settings/groups/deviceUsers/:id",
    exact: false
  },
  {
    name: groupsRouteNames.employeesDetails,
    path: "/app/settings/groups/employees/:id",
    exact: false
  }
];
export default groupsRoutes;
