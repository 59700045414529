import { NightReport, NightReportItem } from "interfaces/nightReport.interface";
import { reportServices } from "services/report/reportService";

import * as types from "../actionTypes/reportActionTypes";

/**
 * Redux success action for night report
 *
 * @param data night report (base model)
 * @return data
 */
export const fetchReportActionSuccess = (
  data: NightReport
): types.nightReportActionTypes => {
  return {
    type: types.FETCH_NIGHT_REPORT_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type for to to fetch the night report
 *
 * @param none
 * @return fetch device action type.
 */
export const fetchReportAction = (): types.nightReportActionTypes => {
  return {
    type: types.FETCH_NIGHT_REPORT
  };
};

/**
 * Redux action to fetch night report
 *
 * @param none
 * @return
 */
export function fetchNightReport() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(fetchReportAction());
    reportServices.fetchNightReport().then((data: NightReport) => {
      dispatch(fetchReportActionSuccess(data));
    });
  };
}
