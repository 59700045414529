/* eslint-disable camelcase */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { fetchDeviceSettings } from "store/actions/devicesSettingsActions";
import CommonStyles from "theme/CommonStyles";

import DeviceListRowEdit from "./DeviceListRowEdit";
import DeviceListRowView from "./DeviceListRowView";

interface DeviceListRowInterface {
  device: Device;
  user: any;
  onClick: (event: any, devieObj: Device) => void;
  filterType?: "device" | "user";
  searchQuery?: string;
  t: any;
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editDevice: { [key: string]: boolean };
  setEditDevice: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  onDelete: (user: Device) => void;
  saveDevice: ({
    deviceName,
    userID,
    deviceSerialNumber,
    settings
  }: {
    deviceName: string;
    userID: string;
    deviceSerialNumber: string;
    settings: DeviceSettings;
  }) => void;
}

function DeviceListRow({
  device,
  user,
  onClick,
  filterType,
  searchQuery,
  selectedItems,
  setSelectedItems,
  t,
  setEditDevice,
  editDevice,
  onDelete,
  saveDevice
}: DeviceListRowInterface): JSX.Element {
  const dispatch = useDispatch();
  const common = CommonStyles();

  const deviceSettings: DeviceSettings = useSelector((state: any) => {
    return (
      state.devicesSettings[device.serial_number] || {
        serialNumber: device.serial_number
      }
    );
  });

  useEffect(() => {
    if (
      deviceSettings.serialNumber &&
      Object.keys(deviceSettings).length === 1
    ) {
      dispatch(fetchDeviceSettings(device.serial_number));
    }
  }, [deviceSettings.serialNumber]);

  return (
    <>
      {editDevice[device.serial_number] ? (
        <DeviceListRowEdit
          filterType={filterType}
          common={common}
          onClick={onClick}
          searchQuery={searchQuery}
          device={device}
          t={t}
          user={user}
          deviceSettings={deviceSettings}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditDevice={setEditDevice}
          editDevice={editDevice}
          saveDevice={saveDevice}
        />
      ) : (
        <DeviceListRowView
          filterType={filterType}
          common={common}
          onClick={onClick}
          searchQuery={searchQuery}
          device={device}
          t={t}
          user={user}
          deviceSettings={deviceSettings}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditDevice={setEditDevice}
          editDevice={editDevice}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
export default DeviceListRow;
