import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

export const toastSucess = (message: string) => {
  toast(<Message message={message} type="success" />, {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #457E89",
      background: "#E3F6F3"
    }
  });
};

export const toastError = (message: string) => {
  toast(<Message message={message} type="error" />, {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
      fontSize: 14
    }
  });
};

const Message = ({ message, type }: { message: string; type: string }) => (
  <div>
    <div
      style={{ width: "100%", float: "left", paddingLeft: 4, paddingTop: 4 }}
    >
      {type === "error" && (
        <ErrorOutlineIcon
          style={{ marginRight: 18, float: "left", color: "#F5222D" }}
        />
      )}
      {type === "success" && (
        <CheckCircleOutlineIcon
          style={{ marginRight: 18, float: "left", color: "#457E89" }}
        />
      )}
      <div style={{ float: "left" }}>
        <div style={{ marginBottom: 8 }}>
          {type === "error" ? "Error" : "Success"}
        </div>
        <div>{message}</div>
      </div>
    </div>
  </div>
);
