import { InputAdornment, TextField, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

export const textFeildStyle = makeStyles((theme: any) => ({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  root: {
    width: "100%",
    paddingLeft: 15,
    height: 54,
    border: `1px solid ${theme.palette.secondary.lightGrey}`,
    borderRadius: 5,
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.primary.dark}`
    }
  }
}));

export const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: ({
      width,
      noMargin
    }: {
      width: string | number;
      noMargin: boolean;
    }) => (noMargin ? "0" : 25),
    float: "left",
    width: ({
      width,
      noMargin
    }: {
      width: string | number;
      noMargin: boolean;
    }) => width || 326
  },
  searchfeild: {
    float: "left",
    width: "100%",
    height: 54,
    background: "#FFF"
  },
  searchIco: {
    color: theme.palette.dark,
    cursor: "pointer"
  },

  clearIco: {
    color: theme.palette.dark,
    cursor: "pointer"
  },
  sortWrapper: {
    float: "left",
    marginLeft: 20
  },
  sortOptions: {
    width: "100%",
    "& > .MuiFormControlLabel-label": {
      fontSize: 16
    }
  },
  active: {
    background: "#00acc1",
    color: "#FFF"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  positionStart: {
    marginRight: 19
  }
}));

interface Props {
  onChange: (items: any, type: string) => void;
  sortFields?: {
    label: string;
    value: string;
    component?: React.ComponentType;
  }[];
  width?: string;
  placeholder?: string;
  noMargin?: boolean;
}

function SearchTextField({
  onChange,
  sortFields,
  width = "100px",
  placeholder = "Search..",
  noMargin = false
}: Props) {
  const classesforTxt = textFeildStyle();
  const classes = useStyles({ width, noMargin });

  const [query, setQuery] = useState<string>("");
  const [sortType, setSortType] = useState<string>(
    (sortFields && sortFields[0].value) || ""
  );

  const onTextChange = (q: string) => {
    setQuery(q);
    onChange(q, sortType);
  };

  const handleChange = (value: string) => {
    setSortType(value);
  };

  const clearQuery = () => {
    setQuery("");
    onChange("", sortType);
  };

  return (
    <div className={classes.root}>
      <TextField
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onTextChange(e.target.value)
        }
        value={query}
        className={classes.searchfeild}
        InputProps={{
          classes: classesforTxt,
          endAdornment: (
            <div>
              <InputAdornment
                position="start"
                onClick={() => null}
                classes={{ positionStart: classes.positionStart }}
              >
                <SearchIcon className={classes.searchIco} />
              </InputAdornment>
              {/* {query && (
                <InputAdornment position="start" onClick={() => setQuery("")}>
                  <ClearIcon className={classes.clearIco} />
                </InputAdornment>
              )} */}
            </div>
          )
        }}
      />
      {sortFields && sortFields.length && (
        <div className={classes.sortWrapper}>
          {sortFields.map(
            (item: { label: string; value: string; component?: any }) => {
              const { value, label, component: Component } = item;
              return (
                <div
                  className={`${sortType === value ? classes.active : ""} ${
                    classes.sortOptions
                  }`}
                  onClick={() => handleChange(value)}
                  aria-hidden
                  key={value}
                >
                  {Component && <Component />}
                  <span> {label} </span>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
}

export default SearchTextField;
