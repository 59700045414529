import * as PropTypes from "prop-types";
import React from "react";

import SleepScoreComponent from "./SleepScore";

class SleepScore extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  render() {
    return this.props.options.plotData ? (
      <SleepScoreComponent {...this.props} />
    ) : null;
  }
}

SleepScore.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    plotData: PropTypes.shape({
      series: PropTypes.object,
      height: PropTypes.number
    }).isRequired
  })
};

export default SleepScore;
