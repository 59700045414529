import { Button, makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles((theme: any) => ({
  button: {
    background: ({ outlineBtn }: { outlineBtn: boolean }) =>
      !outlineBtn ? theme.palette.primary.dark : "none",
    border: ({ outlineBtn }: { outlineBtn: boolean }) =>
      outlineBtn
        ? `1px solid ${theme.palette.primary.dark}`
        : `1px solid ${theme.palette.primary.dark}`,
    color: ({ outlineBtn }: { outlineBtn: boolean }) =>
      outlineBtn ? theme.palette.primary.dark : "#FFF",
    fontSize: 16,
    padding: "10px 16px",
    textTransform: "capitalize",
    "&:hover": {
      background: ({ outlineBtn }: { outlineBtn: boolean }) =>
        outlineBtn
          ? theme.palette.primary.lightBlue
          : theme.palette.primary.green,
      border: ({ outlineBtn }: { outlineBtn: boolean }) =>
        outlineBtn
          ? `1px solid ${theme.palette.primary.dark}`
          : `1px solid ${theme.palette.primary.green}`,
      color: ({ outlineBtn }: { outlineBtn: boolean }) =>
        outlineBtn ? theme.palette.primary.dark : "#FFF"
    },
    "& .MuiButton-label": {
      height: 16
    }
  },
  endIcon: {
    width: 40
  },
  btnText: {
    width: "calc(100% - 40px)",
    fontWeight: "bold"
  },
  disabledButton: {
    color: "#FFF !important" as any,
    background: "rgba(0, 0, 0, 0.38) !important" as any,
    border: "rgba(0, 0, 0, 0.38) !important" as any
  }
}));
function SMButton({
  text,
  icon,
  onClick,
  outlineBtn = false,
  disabled = false
}: {
  text: string;
  icon?: any;
  onClick: (event?: any) => void;
  outlineBtn?: boolean;
  disabled?: boolean;
}) {
  const classes = useStyles({ outlineBtn });
  return (
    <Button
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
      classes={{ disabled: classes.disabledButton, root: classes.button }}
    >
      {text}
    </Button>
  );
}

export default SMButton;
