import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  sortLogs: (
    type: "user" | "ruleType" | "firingTime",
    order: "ASC" | "DESC"
  ) => void;
  isUserLog: boolean;
}

function NotificationLogHeader({ sortLogs, isUserLog }: Props) {
  const classes = CommonStyles();
  const { t } = useTranslation();

  const [sortField, setSortField] = useState<string>("user");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const getSortOrder = (type: string) => {
    if (type === sortField && sortOrder === "ASC") {
      return "ASC";
    }
    if (type === sortField && sortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  const onHeaderClick = (type: "user" | "ruleType" | "firingTime") => {
    let order = "ASC";
    if (sortField === type) {
      order = sortOrder === "ASC" ? "DESC" : "ASC";
    }
    setSortField(type);
    setSortOrder(order as "ASC" | "DESC");
    sortLogs(type, order as "ASC" | "DESC");
  };

  return (
    <header className={classes.header} aria-hidden>
      <div
        className={classes.colHeader}
        aria-hidden
        onClick={() => onHeaderClick("firingTime")}
      >
        <span>{t("Notification Firing Time")}</span>
        <IconSort activeSortOrder={getSortOrder("firingTime")} />
      </div>

      {isUserLog ? (
        <div
          className={classes.colHeader}
          aria-hidden
          onClick={() => onHeaderClick("user")}
        >
          <span>{t("User")}</span>
          <IconSort activeSortOrder={getSortOrder("user")} />
        </div>
      ) : (
        ""
      )}

      {!isUserLog ? (
        <div className={classes.colHeader}>
          <span>{t("Device")}</span>
        </div>
      ) : (
        ""
      )}

      {isUserLog ? (
        <div
          aria-hidden
          onClick={() => onHeaderClick("ruleType")}
          className={`${classes.colHeader}`}
        >
          <span>{t("Rule Type")}</span>
          <IconSort activeSortOrder={getSortOrder("ruleType")} />
        </div>
      ) : (
        ""
      )}

      <div className={`${classes.colHeader}`}>
        <span>{t("Notification Message")}</span>
      </div>

      <div className={`${classes.colHeader}`}>
        <span>{t("Triggering Rule Name")}</span>
      </div>
    </header>
  );
}
export default NotificationLogHeader;
