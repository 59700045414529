import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Device } from "interfaces/device.interface";
import {
  INTERFACE_NOTIFICATION_TYPES,
  NOTIFICATION_TYPES
} from "constants/notificationContstants";
import {
  SMNotification,
  SMNotificationLog,
  SMSReceiver
} from "interfaces/notification.interface";
import { fetchAllTheUsers } from "store/actions/userActions";
import { getAllSMSRecievers } from "store/actions/notificationRecieversActions";
import BreadCrumb from "components/BreadCrump/BreadCrump";
import CommonStyles from "theme/CommonStyles";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import GreyContainer from "components/GreyContainer/GreyContainer";
import InfoBox from "components/InfoBox/InfoBox";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 28,
    position: "relative",
    paddingTop: 54
  },
  container: {
    paddingBottom: 75,
    float: "left",
    width: "100%"
  },
  title: {
    fontSize: 34,
    float: "left",
    marginBottom: 32
  },
  subTitle: {
    fontSize: 24,
    width: "100%",
    float: "left",
    marginBottom: 11
  },
  text: {
    fontSize: 16,
    lineHeight: "24px"
  },
  section: {
    float: "left",
    marginLeft: 32
  },
  effectivePeriod: {
    float: "left",
    marginRight: 10
  },
  singleLinetext: {
    fontSize: 16,
    lineHeight: "24px",
    float: "left",
    marginTop: 21,
    width: "100%",
    marginBottom: 32
  },

  subTitleBold: {
    fontSize: 20,
    width: "100%",
    float: "left",
    marginBottom: 11,
    fontWeight: 500
  },
  receivers: {
    margin: "16px 0 16px 0",
    fontSize: 16,
    width: "100%",
    float: "left"
  }
}));

interface Props {
  type: INTERFACE_NOTIFICATION_TYPES;
  notificationLogEntity: { [key: string]: SMNotificationLog };
  routePath: string;
  id: string;
  deviceEntity: { [key: string]: Device };
}

function NotificationLogDetails({
  type,
  notificationLogEntity,
  routePath,
  id,
  deviceEntity
}: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [notificationLog, setNotificationLog] = useState<SMNotificationLog>();
  const [smsRecieversEntity, setSmsRecieversEntity] = useState<{
    [key: string]: SMSReceiver;
  }>({});

  const smsRecievers = useSelector((state: any) => {
    return state?.smsRecievers.recievers || [];
  });

  useEffect(() => {
    const normalizedSMSReceiver: { [key: string]: SMSReceiver } = {};
    for (let i = 0; i < smsRecievers.length; i += 1) {
      normalizedSMSReceiver[smsRecievers[i].id || 0] = smsRecievers[i];
    }
    setSmsRecieversEntity(normalizedSMSReceiver);
  }, [smsRecievers]);

  useEffect(() => {
    const log = notificationLogEntity[id];
    if (log) {
      setNotificationLog(log);
    }
  }, [notificationLogEntity]);

  useEffect(() => {
    dispatch(fetchAllTheUsers());
    dispatch(getAllSMSRecievers());
  }, [dispatch]);

  const getDeviceInfo = (rule?: SMNotification) => {
    if (rule && rule.device_id && deviceEntity) {
      return deviceEntity[rule.device_id]?.name || "";
    }
    return "";
  };

  const getNotificationType = (notifiationType: string): string => {
    if (notifiationType === "movement") {
      return "Movement";
    }
    if (notifiationType === "out_of_bed") {
      return "Out of Bed";
    }
    if (notifiationType === "sound") {
      return "Sound";
    }
    return "";
  };

  const isUserRule = () => {
    return type === NOTIFICATION_TYPES.USER_RULES;
  };
  return (
    <div className={classes.container}>
      <GreyContainer>
        <BreadCrumb
          text={
            type === NOTIFICATION_TYPES.USER_RULES
              ? t("Notifications Log for Users")
              : t("Notifications Log for Devices")
          }
        />
        <div>
          <div className={classes.title}>
            {notificationLog?.notification.name}{" "}
          </div>
        </div>
        <InfoBox>
          <div className={classes.subTitle}>{notificationLog?.message}</div>
          <div className={classes.section}>
            <DeviceInfoText
              text={`${t("Notification Firing Time")}`}
              field={
                <div className={classes.text}>
                  {" "}
                  {notificationLog?.firingTime}{" "}
                </div>
              }
            />
            <DeviceInfoText
              text={isUserRule() ? `${t("User")}` : `${t("Device")}`}
              field={
                isUserRule() ? (
                  <div className={classes.text}>
                    {" "}
                    {notificationLog?.notification.user?.display_name}{" "}
                  </div>
                ) : (
                  <div> {getDeviceInfo(notificationLog?.notification)}</div>
                )
              }
            />
            <DeviceInfoText
              text={`${t("Rule Type")}`}
              field={
                isUserRule() ? (
                  <div className={classes.text}>
                    {getNotificationType(
                      notificationLog?.notification?.type || ""
                    )}
                  </div>
                ) : (
                  <div> {t("Device Offline")}</div>
                )
              }
            />
            <DeviceInfoText
              text={`${t("Message")}`}
              field={
                <div className={classes.text}> {notificationLog?.message} </div>
              }
            />
          </div>
        </InfoBox>
        <InfoBox>
          <div className={classes.subTitle}>
            {t("Triggering Notification Rule")}
          </div>
          <div className={classes.section}>
            <DeviceInfoText
              text={`${t("Rule Name")}`}
              field={
                <div className={classes.text}>
                  {" "}
                  {notificationLog?.notification.name}{" "}
                </div>
              }
            />
            <DeviceInfoText
              text={`${t("Effective Period")}`}
              field={
                <div className={classes.text}>
                  {" "}
                  {notificationLog?.notification.effective_period.map(
                    (period: string) => (
                      <div className={classes.effectivePeriod} key={period}>
                        {" "}
                        {period}
                        {notificationLog?.notification.effective_period.length >
                          1 &&
                        notificationLog?.notification.effective_period.indexOf(
                          period
                        ) === 0
                          ? " - "
                          : ""}
                      </div>
                    )
                  )}{" "}
                </div>
              }
            />
          </div>
          <div className={classes.subTitleBold} style={{ marginTop: 11 }}>
            {t("Rule Criteria - When to send notification message")}
          </div>
          <div className={classes.section}>
            <div className={classes.singleLinetext}>
              {isUserRule()
                ? `${t("SEND_USER_NOTIFICATION_MESSAGE_TEXT", {
                    minimumTime:
                      notificationLog?.notification.rules?.sendnotification[0]
                        .minTimePresent || ""
                  })}`
                : t("SEND_DEVICE_NOTIFICATION_MESSAGE_TEXT", {
                    minimumTime:
                      notificationLog?.notification.rules?.sendnotification[0]
                        .minTimePresent || ""
                  })}
            </div>
            {isUserRule() ? (
              <div className={classes.singleLinetext} style={{ marginTop: 0 }}>
                {t("AVARAGE_MOVEMENT_TEXT", {
                  last: notificationLog?.notification.rules?.sendnotification[0]
                    .avarageMovementInTime,
                  threshold:
                    notificationLog?.notification.rules?.sendnotification[0]
                      .threshold
                })}{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.subTitleBold}>
            {t("Rule Criteria - When to OK message")}
          </div>
          <div className={classes.section}>
            <div className={classes.singleLinetext}>
              {" "}
              {t("Device is online")}{" "}
            </div>
          </div>
        </InfoBox>
        <InfoBox>
          <div className={classes.subTitle}>{t("Notification Recievers")}</div>
          <div className={classes.subTitleBold} style={{ marginTop: 11 }}>
            {t("SMS Recievers")}
          </div>
          <div className={classes.section}>
            {notificationLog?.notification?.recievers?.map(
              (receiverId: string) => (
                <div className={classes.receivers} key={receiverId}>
                  {smsRecieversEntity[receiverId]?.receiver || ""}
                </div>
              )
            )}
          </div>
          <div className={classes.subTitle} style={{ marginTop: 11 }}>
            {t("Sensio Velfred recievers")}
          </div>
        </InfoBox>
      </GreyContainer>
    </div>
  );
}

export default NotificationLogDetails;
