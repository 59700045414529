import moment from "moment";

const sleepHistoryOptions = () => ({
  timeInDeepSleep: {
    getValue: (sleepReport) => sleepReport.time_in_deep_sleep
  },
  timeInLightSleep: {
    getValue: (sleepReport) => sleepReport.time_in_light_sleep
  },
  timeInREMSleep: {
    getValue: (sleepReport) => sleepReport.time_in_rem_sleep
  },
  timeWakeAfterSleepOnset: {
    getValue: (sleepReport) => sleepReport.time_wake_after_sleep_onset
  },
  sleepOnset: {
    getValue: (sleepReport) => sleepReport.sleep_onset
  },
  timeAsleep: {
    getValue: (sleepReport) => sleepReport.time_asleep
  },
  timeWakePrePostSleep: {
    getValue: (sleepReport) => sleepReport.time_wake_pre_post_sleep
  },
  relativeSessionStart: {
    getValue: (sleepReport) => sleepReport.relative_session_start
  }
});

const emptyPlotData = () => {
  return Object.keys(sleepHistoryOptions()).reduce((acc, currVal) => {
    return { ...acc, [currVal]: [] };
  }, {});
};

export const getSleepHistoryStagesPlotData = (sessions) => {
  if (!sessions) {
    return null;
  }
  const newPlotSleepData = emptyPlotData();

  sessions.forEach((session, i) => {
    const sleepReport = session?._embedded?.sleep_analysis?.report;
    if (sleepReport) {
      const timestamp = Number(moment.utc(sleepReport.session_start));
      Object.keys(sleepHistoryOptions()).map((key) =>
        newPlotSleepData[key].push({
          x: timestamp,
          y: sleepHistoryOptions()[key].getValue(sleepReport),
          ix: i
        })
      );
    }
  });
  return {
    series: newPlotSleepData,
    threshold: Math.min(...newPlotSleepData.relativeSessionStart)
  };
};
