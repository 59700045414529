import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { AppRouteProps } from "interfaces/router.interface";
import { Device } from "interfaces/device.interface";
import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import {
  createNewSubUser,
  deleteUserAction,
  fetchAllTheUsers,
  resetUserPreloaders,
  upadateUserInformation
} from "store/actions/userActions";
import {
  generateAutoFillEmail,
  generateAutoFillPassword
} from "utils/userManagement";
import { getAllDevices } from "store/actions/devicesActions";
import { getAllUserGroups } from "store/actions/groupActions";
import {
  getGroupsToUpdate,
  normalizedGroupsEntity
} from "utils/groupManagement";
import { toastSucess } from "utils/toast.util";
import SomnofySwitchRoute from "components/SomnofySwitchRoute/SomnofySwitchRoute";

import UsersList from "./usersList/UsersList";
import UsersView from "./usersView/UsersView";
import userRoutes, { usersRouteNames } from "./users.routes";

/**
 * @name DevicesList
 * @description This is wrapper component for the login form.
 * @returns LoginForm componet
 */
function UsersManagement() {
  const [usersEntity, setUsersEntity] = useState<any>();
  const [devicesEntityWithUserID, setDevicesEntity] = useState<{
    [key: string]: Device;
  }>({});
  const { t } = useTranslation();

  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});

  const dispatch = useDispatch();

  const usersState = useSelector((state: any) => {
    return state?.users || {};
  });

  const devices: Device[] = useSelector((state: any) => {
    return state?.deviceReducer?.devices || [];
  });

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  useEffect(() => {
    dispatch(fetchAllTheUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllDevices());
  }, []);

  useEffect(() => {
    if (devices.length) {
      const normalizedDevice: any = {};
      for (let i = 0; i < devices.length; i += 1) {
        const key = `${devices[i].serial_number}+${devices[i].user_id}`;
        normalizedDevice[key] = devices[i];
      }
      // device entity
      setDevicesEntity(normalizedDevice);
    }
  }, [devices]);

  useEffect(() => {
    if (usersState.currentAction === USER_ACTIONS.deleteSuccess) {
      toastSucess(t("User deleted successfully"));
      dispatch(resetUserPreloaders());
    }

    if (usersState.users) {
      const { users } = usersState;
      if (usersState.users.length) {
        const normalizedUsers: any = {};
        for (let i = 0; i < users.length; i += 1) {
          normalizedUsers[users[i].id] = users[i];
        }
        setUsersEntity(normalizedUsers);
      }
    } else {
      setUsersEntity({});
    }
  }, [usersState]);

  const filterDeviceUsers = (users: User[]) => {
    return users?.filter(
      (user: User) =>
        user.attributes?.user_type !== UserAttributesTypes.viewer &&
        user.parent_id
    );
  };

  const onSaveAction = (updatedUser: User, currentUserGroupsIds: string[]) => {
    const groups: Group[] = getGroupsToUpdate({
      user: updatedUser,
      groupEntity,
      currentUserGroupsIds
    });
    if (updatedUser.id) {
      const userWithEmailAndPass = {
        ...updatedUser,
        attributes: {
          user_type: UserAttributesTypes.device,
          privileges: []
        }
      };
      dispatch(upadateUserInformation({ user: userWithEmailAndPass, groups }));
    } else {
      const userWithEmailAndPass = {
        ...updatedUser,
        email: !updatedUser.email
          ? generateAutoFillEmail(updatedUser, loginedUser)
          : updatedUser.email,
        password: !updatedUser.password
          ? generateAutoFillPassword(8)
          : updatedUser.password,
        attributes: {
          user_type: UserAttributesTypes.device,
          privileges: []
        }
      };
      dispatch(createNewSubUser({ user: userWithEmailAndPass, groupEntity }));
    }
  };

  const onDelete = (updatedUser: User) => {
    dispatch(deleteUserAction({ user: updatedUser }));
  };

  /**
   * Returns route link based on the paramter of custom route type.
   *
   * @param {props} props of type location.
   * @return {currentRoute} single route object
   */
  const getRender = (props: any, currentRoute: AppRouteProps) => {
    switch (currentRoute.name) {
      // device list page
      case usersRouteNames.usersList:
        if (usersState) {
          return (
            <UsersList
              users={filterDeviceUsers(usersState.users)}
              routePath={currentRoute.path}
              devicesEntityWithUserID={devicesEntityWithUserID}
              onSaveAction={onSaveAction}
              onDelete={onDelete}
            />
          );
        }
        return <div>Loading Users...</div>;

      // Device view page
      case usersRouteNames.viewUser:
        return (
          usersEntity &&
          groupEntity && (
            <UsersView
              routePath={currentRoute.path}
              usersEntity={usersEntity}
              id={props.match.params.id}
              groupEntity={groupEntity}
              onSaveAction={onSaveAction}
              loginedUser={loginedUser}
              onDelete={onDelete}
            />
          )
        );

      // default case
      default:
        return <div>no users</div>;
    }
  };
  // Returns the location to any component.
  return (
    <div>
      <SomnofySwitchRoute routes={userRoutes} renderFunction={getRender} />
    </div>
  );
}

export default UsersManagement;
