import { AppRouteProps } from "interfaces/router.interface";

import NightReportContainer from "./nightReport/NightReportContainer";

export const reportingsRouteNames = {
  home: "/",
  nightReport: "Night Report"
};
export const reportingsRoutes: AppRouteProps[] = [
  {
    name: reportingsRouteNames.home,
    path: "/app/reporting",
    exact: true,
    redirectPath: "/app/reporting/nightReport"
  },
  {
    name: reportingsRouteNames.nightReport,
    path: "/app/reporting/nightReport",
    exact: false,
    component: NightReportContainer
  }
];
export default reportingsRoutes;
