import React, { useState } from "react";
import _ from "lodash";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { User } from "interfaces/user.interface";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import DiscreteSlider from "components/DiscreteSlider/DiscreteSlider";
import SMButton from "components/SMButton/SMButton";
import SMTextField from "components/SMTextField/SMTextField";

import DisableSleepTrackingComponent from "../deviceView/components/DisableSleepTrackingComponent";
import UsersAutoSuggest from "../deviceView/components/UsersAutoSuggest";

interface Props {
  filterType?: "device" | "user";
  common: any;
  device: Device;
  onClick: (event: any, devieObj: Device) => void;
  searchQuery?: string;
  t: any;
  user: any;
  deviceSettings: DeviceSettings;
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editDevice: { [key: string]: boolean };
  setEditDevice: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  saveDevice: ({
    deviceName,
    userID,
    deviceSerialNumber,
    settings
  }: {
    deviceName: string;
    userID: string;
    deviceSerialNumber: string;
    settings: DeviceSettings;
  }) => void;
}

function DeviceListRowEdit({
  filterType,
  common,
  onClick,
  searchQuery,
  device,
  t,
  user,
  deviceSettings,
  selectedItems,
  setSelectedItems,
  editDevice,
  setEditDevice,
  saveDevice
}: Props) {
  const { name, serial_number, time_since_last_activity } = device;
  const { birth_year, display_name, gender } = user;

  const [deviceName, setDeviceName] = useState<string>(_.clone(device.name));
  const [userID, setUserID] = useState<string>(_.clone(device.user_id));
  const [errorMessage, setErrorMessage] = useState<string>();
  const [settings, setDeviceSettings] = useState<DeviceSettings>(
    _.cloneDeep(deviceSettings)
  );

  const onSettingsChange = (key: string, event: any, value: any) => {
    const updatedSettings: DeviceSettings = {
      ...settings,
      [key]: value
    } as DeviceSettings;
    setDeviceSettings(updatedSettings);
  };

  return (
    <div className={`${common.lightBlue}`}>
      <div className={`${common.row}`} aria-hidden="true">
        <BulkActionCheckBox
          wrapperClassName={common.checkboxlistWraper}
          id={device.serial_number}
          selected={selectedItems}
          setSelected={setSelectedItems}
        />

        <div className={`${common.col} ${common.colorPrimary}`}>
          <SMTextField
            placeholder={t("e.g. Kari")}
            onSave={(val: string) => {
              setDeviceName(val);
            }}
            value={deviceName}
            delay={0}
            width="100%"
            borderRed={(errorMessage && !deviceName) || false}
          />
        </div>

        <div className={common.col}>{serial_number}</div>
        <div className={common.colLarge}>
          <UsersAutoSuggest
            userId={userID || ""}
            onChange={(userObj: User) => setUserID(userObj?.id || "")}
          />
        </div>
        <div className={common.col}>
          {time_since_last_activity <= 600 && (
            <span style={{ color: "#212121" }}>{t("Online")}</span>
          )}
          {(time_since_last_activity >= 600 ||
            time_since_last_activity == null) && (
            <span style={{ color: "#B00020" }}>{t("Offline")}</span>
          )}
        </div>

        <div className={common.col}>
          <DiscreteSlider
            value={settings.distanceLimit}
            displayFormater={(val: number) => `${val}m`}
            steps={0.1}
            min={0.5}
            autoWidth
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              value: number | number[]
            ) => onSettingsChange("distanceLimit", event, value)}
          />
        </div>

        <div className={common.col}>
          <DisableSleepTrackingComponent
            deviceSettings={settings}
            onChange={onSettingsChange}
            device={device}
            t={t}
            showLabel={false}
            inline={false}
          />
        </div>
        <div className={`${common.col}`} />
      </div>
      <div className={`${common.row} ${common.alignFlexEnd}`}>
        <div className={common.actionBtnWrapper}>
          <div className={common.actionBtn}>
            <SMButton
              text={t("Save")}
              onClick={() => {
                setEditDevice({ ...editDevice, [device.serial_number]: false });
                saveDevice({
                  deviceName,
                  userID,
                  deviceSerialNumber: device.serial_number,
                  settings
                });
              }}
            />
          </div>
          <div className={common.actionBtn}>
            <SMButton
              outlineBtn
              text={t("Cancel")}
              onClick={() =>
                setEditDevice({ ...editDevice, [device.serial_number]: false })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default DeviceListRowEdit;
