import { Group } from "interfaces/group.interface";
import { UserGroupType } from "constants/userGroupConstants";

import * as types from "../actionTypes/groupsActionTypes";
import { GROUP_ACTIONS } from "../actionTypes/groupsActionTypes";

export default function userGroupsReducer(
  state: any = {},
  action: types.userGroupsActionTypes
) {
  switch (action.type) {
    case types.UPDATE_USER_GROUPS_CURRENT_ACTION:
      return {
        ...state,
        currentAction: action.payload,
        newItem: ""
      };

    case types.ADD_NEW_USER_GROUP_ACTION_TYPE:
      return {
        ...state,
        currentAction:
          action.payload.type === UserGroupType.device
            ? GROUP_ACTIONS.createUserGroupSuccess
            : GROUP_ACTIONS.createEmployeeGroupSuccess,
        groups: [action.payload, ...state.groups],
        newItem: action.payload
      };
    case types.FETCH_USER_GROUPS_ACTION_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        currentAction: GROUP_ACTIONS.fetchSuccess
      };

    case types.EDIT_USER_GROUPS_ACTION_SUCCESS:
      return {
        ...state,
        currentAction: GROUP_ACTIONS.updateSuccess,
        groups: state.groups.map((group: Group) =>
          group.id === action.payload.id ? { ...action.payload } : group
        )
      };

    case types.DELETE_USER_GROUPS_ACTION_SUCCESS:
      return {
        ...state,
        currentAction: GROUP_ACTIONS.deleteSuccess,
        groups: state.groups.filter(
          (group: Group) => group.id !== action.payload.id
        )
      };

    case types.DELETE_MULTI_GROUPS_ACTION_SUCCESS:
      return {
        ...state,
        currentAction: GROUP_ACTIONS.deleteSuccess,
        groups: state.groups.filter(
          (group: Group) => action.payload.indexOf(group.id) === -1
        )
      };
    default:
      return state;
  }
}
