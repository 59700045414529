import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

function ErrorIcon(): JSX.Element {
  return (
    <div style={{ position: "absolute", left: -4, color: "#A50520" }}>
      {" "}
      <ErrorOutlineIcon style={{ width: 20, height: 20 }} />{" "}
    </div>
  );
}

export default ErrorIcon;
