import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import ResizeDetector from "react-resize-detector";
import moment from "moment";

import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);
export default class HypnogramChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    const { plotData, enableHeartRateMean } = this.props;
    return (
      <ResizeDetector
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <HighchartsReact
          ref={this.chartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={getPlotOptions(plotData, enableHeartRateMean || "")}
        />
      </ResizeDetector>
    );
  }
}

HypnogramChart.propTypes = {
  plotData: PropTypes.shape({
    series: PropTypes.object,
    threshold: PropTypes.object
  }),
  enableHeartRateMean: PropTypes.string
};

const getPlotOptions = (plotData, enableHeartRateMean) => {
  const { series } = plotData;
  const showHeartRate =
    series.heartRateMean.length > 0 && enableHeartRateMean === "true";
  const showExternalHeartRate = series.externalHeartRateMean.length > 0;
  const showExternalSpO2 = series.externalSpO2Mean.length > 0;

  let yAxisExternalSpO2Min = 100;
  let yAxisExternalSpO2Max = 0;
  for (let i = 0; i < series.externalSpO2Mean.length; i += 1) {
    if (
      series.externalSpO2Mean[i].y > 0 &&
      series.externalSpO2Mean[i].y < yAxisExternalSpO2Min
    )
      yAxisExternalSpO2Min = series.externalSpO2Mean[i].y;
    if (series.externalSpO2Mean[i].y > yAxisExternalSpO2Max)
      yAxisExternalSpO2Max = series.externalSpO2Mean[i].y;
  }
  yAxisExternalSpO2Min -= (yAxisExternalSpO2Max - yAxisExternalSpO2Min) * 0.05;
  yAxisExternalSpO2Max += (yAxisExternalSpO2Max - yAxisExternalSpO2Min) * 0.05;

  const plotYAxisLowTop = "40%";
  const plotYAxisLowHeight = "60%";

  return {
    chart: {
      type: "line",
      backgroundColor: "transparent"
    },
    title: {
      text: null // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b"
      },
      title: {
        text: "Date"
      },
      ordinal: false

      // offset: -50,
    },
    yAxis: [
      {
        // 0: SleepStages

        title: {
          text: null
        },
        min: 0,
        max: 4,

        // alignTicks: false,
        // allowDecimals: false,
        // tickInterval: 1,

        gridLineWidth: 0,
        height: "30%",

        plotLines: [
          {
            value: 0,
            color: "lightgrey",
            width: 1,
            zIndex: 30, // TODO: Adjusted to 3 to get the labels in front of the colored zones. This adjustment correlates to the opacity adjustment of the zones.
            label: {
              text: "",
              align: "left",
              x: 2,
              y: 10
            }
          },
          {
            value: 1,
            color: "rgba(0, 0, 139, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Deep"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold"
              }
            }
          },
          {
            value: 2,
            color: "rgba(173, 216, 230, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Light"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold"
              }
            }
          },
          {
            value: 3,
            color: "rgba(255, 140, 0, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("REM"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold"
              }
            }
          },
          {
            value: 4,
            color: "rgba(139, 0, 0, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Awake"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold"
              }
            }
          }

          /*
                    {
                        value: 5,
                        color: 'lightgrey',
                        width: 1,
                        zIndex: 1,
                        label: {
                            text: 'No presence',
                            align: 'left',
                            x: 2
                        }
                    }
*/
        ],

        labels: {
          enabled: false

          /*
                    enabled: true,
                    align: "left",
                    formatter: function() {
                        switch (this.value) {
                            case 1:
                                return "Deep"
                            case 2:
                                return "Light"
                            case 3:
                                return "REM"
                            case 4:
                                return "Awake"
                            case 5:
                                return "Not present"
                            default:
                                return ""
                        }
                    },
*/
        }
      },
      {
        // 1

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 2

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 3

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 4

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 5

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 6

        title: {
          text: null
        },
        min: 0,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 7: Sound

        title: {
          text: null
        },
        min: 20,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      },
      {
        // 8: SpO2

        title: {
          text: null
        },
        min: yAxisExternalSpO2Min,
        max: yAxisExternalSpO2Max,
        labels: {
          enabled: false
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0
      }
    ],
    navigator: {
      enabled: true,
      series: {
        data: series.hypnogram,
        type: "line"
      },
      yAxis: {
        min: 0
      },
      height: 30

      // top: '50%',
    },
    rangeSelector: {
      enabled: false
    },
    legend: {
      enabled: true

      // y: -100,
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        },
        marker: {
          enabled: false
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null,
        navigatorOptions: {
          type: "line",
          dataGrouping: {
            smoothed: true
          },
          showInLegend: false,
          lineWidth: 1,
          marker: {
            enabled: false
          },
          step: true,
          zones: [
            {
              value: 1.01,
              color: "rgba(0, 0, 139, 1)"
            },
            {
              value: 2.01,
              color: "rgba(173, 216, 230, 1)"
            },
            {
              value: 3.01,
              color: "rgba(255, 140, 0, 1)"
            },
            {
              value: 4.01,
              color: "rgba(139, 0, 0, 1)"
            },
            {
              color: "rgba(0,0,0,0)"
            }
          ]
        }
      }
    },
    tooltip: {
      formatter() {
        const { points } = this;
        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);
        let tooltipMarkup = pointsLength
          ? `<span style="font-size: 10px">${t("Time")}: ${moment
              .utc(timestamp)
              .format("HH:mm")}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (points[index].series.name === "Sleep Stage") {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            switch (points[index].y) {
              case 0:
                tooltipMarkup += "Unknown";
                break;
              case 1:
                tooltipMarkup += "Deep";
                break;
              case 2:
                tooltipMarkup += "Light";
                break;
              case 3:
                tooltipMarkup += "REM";
                break;
              case 4:
                tooltipMarkup += "Wake";
                break;
              case 5:
                tooltipMarkup += "Not present";
                break;
              default:
                tooltipMarkup += "Unknown";
                break;
            }
            tooltipMarkup += "</b><br/>";
          } else if (
            points[index].series.name.substring(0, 16) === "Respiration Rate"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} RPM</b><br/>`;
          else if (points[index].series.name.substring(0, 10) === "Heart Rate")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} BPM</b><br/>`;
          else if (points[index].series.name.substring(0, 4) === "SpO2")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} %</b><br/>`;
          else if (points[index].series.name === "Movement")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)}</b><br/>`;
          else if (points[index].series.name === "Distance")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} m</b><br/>`;
          else if (points[index].series.name === "Light")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} lux</b><br/>`;
          else if (points[index].series.name === "Sound")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} dBA</b><br/>`;
          else if (points[index].series.name === "Bio Signal Quality")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} </b><br/>`;
        }

        return tooltipMarkup;
      },
      shared: true
    },
    series: [
      {
        name: "Sleep Stage",
        cursor: "pointer",
        yAxis: 0,
        turboThreshold: 3000,
        data: series.hypnogram,
        visible: true,
        showInLegend: false,
        showInNavigator: true,
        type: "column",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
        zones: [
          {
            // TODO: Adjusted opacity to get rid of "lines" caused by points packing up on each other

            value: 1.01,
            color: "rgba(0, 0, 139, 1)",
            className: "zone-deep"
          },
          {
            value: 2.01,
            color: "rgba(173, 216, 230, 1)",
            className: "zone-light"
          },
          {
            value: 3.01,
            color: "rgba(255, 140, 0, 1)",
            className: "zone-rem"
          },
          {
            value: 4.01,
            color: "rgba(139, 0, 0, 1)",
            className: "zone-awake"
          },
          {
            color: "rgba(0,0,0,0)"
          }
        ]
      },
      {
        name: "Respiration Rate Mean",
        cursor: "pointer",
        yAxis: 1,
        color: "blue",
        turboThreshold: 3000,
        data: series.respirationRateMean,
        visible: true,
        showInLegend: true
      },
      {
        name: "Respiration Rate Variance",
        cursor: "pointer",
        yAxis: 1,
        color: "lightblue",
        turboThreshold: 3000,
        data: series.respirationRateVarMean,
        visible: false,
        showInLegend: true
      },
      {
        name: "Heart Rate Mean",
        cursor: "pointer",
        yAxis: 2,
        color: "red",
        turboThreshold: 3000,
        data: series.heartRateMean,
        visible: false,
        showInLegend: showHeartRate
      },
      {
        name: "Heart Rate Variance",
        cursor: "pointer",
        yAxis: 2,
        color: "rgba(180, 0, 0, 1)",
        turboThreshold: 3000,
        data: series.heartRateVar,
        visible: false,
        showInLegend: false // Not validated yet. showHeartRate
      },
      {
        name: "Heart Rate Mean (external)",
        cursor: "pointer",
        yAxis: 2,
        color: "purple",
        turboThreshold: 3000,
        data: series.externalHeartRateMean,
        visible: false,
        showInLegend: showExternalHeartRate
      },
      {
        name: "Heart Rate Variance (external)",
        cursor: "pointer",
        yAxis: 2,
        color: "rgba(180, 0, 0, 1)",
        turboThreshold: 3000,
        data: series.externalHeartRateVar,
        visible: false,
        showInLegend: false // Not validated yet. showHeartRate
      },
      {
        name: "SpO2 Mean (external)",
        cursor: "pointer",
        yAxis: 8,
        color: "rgba(100, 100, 255, 1)",
        turboThreshold: 3000,
        data: series.externalSpO2Mean,
        visible: false,
        showInLegend: showExternalSpO2
      },
      {
        name: "Movement",
        cursor: "pointer",
        yAxis: 3,
        color: "black",
        turboThreshold: 3000,
        data: series.movementMean,
        visible: false,
        showInLegend: true
      },
      {
        name: "Distance",
        cursor: "pointer",
        yAxis: 4,
        color: "orange",
        turboThreshold: 3000,
        data: series.distanceMean,
        visible: false,
        showInLegend: true
      },
      {
        name: "Light",
        cursor: "pointer",
        yAxis: 6,
        color: "red",
        turboThreshold: 3000,
        data: series.lightAmbient,
        visible: false,
        showInLegend: true
      },
      {
        name: "Sound",
        cursor: "pointer",
        yAxis: 7,
        color: "green",
        turboThreshold: 3000,
        data: series.soundAmplitude,
        visible: false,
        showInLegend: true
      },
      {
        name: "Bio Signal Quality",
        cursor: "pointer",
        yAxis: 5,
        color: "brown",
        turboThreshold: 3000,
        data: series.signalQualityMean,
        visible: false,
        showInLegend: true
      }
    ]
  };
};
