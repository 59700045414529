import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { SMSReceiver } from "interfaces/notification.interface";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import BulkActions from "components/BulkActions/BulkActions";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";
import SMButton from "components/SMButton/SMButton";
import SearchTextField from "components/SearchTextField/SearchTextField";

export const useStyles = makeStyles((theme) => ({
  addNewItem: {
    float: "left",
    cursor: "pointer",
    marginBottom: 6.5
  },
  perPageWrapper: {
    float: "right"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 25,
    marginBottom: 25
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  }
}));

interface Props {
  onSearch: (q: string) => void;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  addNew: () => void;
  selectedItems: { [key: string]: boolean };
  sortedReceivers: SMSReceiver[];
  bulkDelete: (recievers: SMSReceiver[]) => void;
  openDeleteDialog: boolean;
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteReceiver: React.Dispatch<React.SetStateAction<SMSReceiver | null>>;
  deleteReceiver: SMSReceiver | null;
}

function NotificationReceiversBanner({
  onSearch,
  setItemsPerPage,
  addNew,
  selectedItems,
  sortedReceivers,
  bulkDelete,
  openDeleteDialog,
  setOpenDeleteDialog,
  setDeleteReceiver,
  deleteReceiver
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = (key: string) => {
    if (key === "remove_selected") {
      setOpenDeleteDialog(true);
    }
  };

  return (
    <div>
      <PerPageListItems
        type={USER_SETTINGS_CONSTANTS.user_notifications_recievers_per_page}
        onChange={setItemsPerPage}
        label={t("Per page")}
        total={sortedReceivers?.length}
      />
      <div className={classes.addNewItem}>
        <SMButton
          icon={<Plus />}
          text={t("Add SMS Receiver")}
          onClick={addNew}
        />
      </div>
      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={(q: string) => onSearch(q)}
          width="320px"
          placeholder={t("Search for SMS receiver")}
          noMargin
        />
      </div>
      <div className={classes.bulkActionWrapper}>
        <BulkActions
          selectedItems={selectedItems}
          actions={[{ key: "remove_selected", label: t("Delete Selected") }]}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

export default NotificationReceiversBanner;
