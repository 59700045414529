import { withStyles } from "@material-ui/core/styles";
import MuiCardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { setLocalSettings } from "utils/localStorage";

import i18n from "../../i18n/config";

const styles = () => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  action: {
    marginRight: 10
  }
});

const SectionHeader = (props) => {
  const { action, actions, classes, setMode, mode } = props;
  let actionComponent = action;

  const handleAlignment = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: newMode
      });
    }
  };
  actionComponent = (
    <div>
      {actions ? (
        <div className={classes.content}>{actions.map((a) => a)}</div>
      ) : (
        ""
      )}
      {setMode ? (
        <div className={classes.action}>
          <ToggleButtonGroup value={mode} exclusive onChange={handleAlignment}>
            <ToggleButton value="history">{i18n.t("History")}</ToggleButton>
            <ToggleButton value="last_night">
              {i18n.t("Last Night")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div>
      <MuiCardHeader
        className={classes.root}
        action={actionComponent}
        {...props}
      />
    </div>
  );
};

SectionHeader.propTypes = {
  action: PropTypes.any,
  actions: PropTypes.any,
  classes: PropTypes.any,
  setMode: PropTypes.any,
  mode: PropTypes.string
};

export default withStyles(styles)(SectionHeader);
