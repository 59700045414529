import { Device, Devices } from "interfaces/device.interface";

import * as types from "../actionTypes/devicesActionTypes";
import { devicesServices } from "../../services/devices/devicesService";

export const restDeviceCurrentAction = (): types.devicesActionTypes => {
  return {
    type: types.RESET_DEVICE_ACTION
  };
};

/**
 * Redux action type for to to update all the devices
 *
 * @param data devices array of type ${Devices}
 * @return payload- array of devices
 */
export const getAllDeviceActionSuccess = (
  data: Devices
): types.devicesActionTypes => {
  return {
    type: types.FETCH_DEVICES_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type for to to fetch all the devices
 *
 * @param none
 * @return fetch device action type.
 */
export const getAllDeviceAction = (): types.devicesActionTypes => {
  return {
    type: types.FETCH_DEVICES_ACTION
  };
};

/**
 * Redux action type for to to change the user
 *
 * @param none
 * @return change user action
 */
export const changeDeviceUserAction = (): types.devicesActionTypes => {
  return {
    type: types.CHANGE_USER_ACTION
  };
};

/**
 * Redux action type
 *
 * @param data device object
 * @return device object
 */
export const changeDeviceUserActionSuccess = (
  data: Device
): types.devicesActionTypes => {
  return {
    type: types.CHANGE_USER_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type
 *
 * @param data device object
 * @return device object
 */
export const unRegisterDeviceActionSuccess = (
  data: string
): types.devicesActionTypes => {
  return {
    type: types.UNREGISTER_DEVICE_SUCCESS_ACTION,
    payload: data
  };
};

/**
 * Redux action type
 *
 * @param data device serial number as string array
 * @return  object
 */
export const deleteMultiDeviceSuccessAction = (
  data: string[]
): types.deleteMultipleDevicesSuccessAction => {
  return {
    type: types.DELETE_MULTI_DEVICES_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action to get all the devices
 *
 * @param none
 * @return devices array sorted based on the last-activity
 */
export function getAllDevices() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(getAllDeviceAction());
    devicesServices.fetchDevices().then((data: Devices) => {
      if (data?._embedded?.devices) {
        // eslint-disable-next-line no-nested-ternary
        data._embedded.devices.sort((a: Device, b: Device) => {
          if (a.time_since_last_activity > b.time_since_last_activity) {
            return 1;
          }
          if (b.time_since_last_activity > a.time_since_last_activity) {
            return -1;
          }
          return 0;
        });
      }
      dispatch(getAllDeviceActionSuccess(data));
    });
  };
}

/**
 * Redux action to change device user
 *
 * @param name: new name to set
 * @param id: user id to set
 * @param deviceSerialNumber: serial number of the device
 * @return device obbject with updated data
 */
export const changeDeviceUser = ({
  name,
  id,
  deviceSerialNumber
}: {
  name: string;
  id: string;
  deviceSerialNumber: string;
}) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(changeDeviceUserAction());
    devicesServices
      .changeDeviceInformation({ name, id, deviceSerialNumber })
      .then((data: Device) => {
        dispatch(changeDeviceUserActionSuccess(data));
      });
  };
};

/**
 * Redux action to reset pre-loaders
 *
 * @return update user action preloader
 */
export const resetDeviceCurrentAction = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(restDeviceCurrentAction());
  };
};

/**
 * Redux action to reset pre-loaders
 *
 * @return update user action preloader
 */
export const unRegisterDeviceAction = ({
  deviceSerialNumber
}: {
  deviceSerialNumber: string;
}) => {
  return function (dispatch: (arg0: any) => void) {
    return devicesServices.unRegisterDevice(deviceSerialNumber).then(() => {
      dispatch(unRegisterDeviceActionSuccess(deviceSerialNumber));
    });
  };
};

export const deleteMultipleDevices = (devices: Device[]) => {
  const processed = [];
  const success: string[] = [];
  const failed: string[] = [];

  const dispatchSuccess = (dispatch: any) => {
    if (processed.length >= devices.length && success.length > 0) {
      dispatch(deleteMultiDeviceSuccessAction(success));
    }
  };
  return async function (dispatch: (arg0: any) => void) {
    for (let i = 0; i < devices.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await devicesServices
        .unRegisterDevice(devices[i].serial_number)
        .then((data: any) => {
          processed.push(devices[i].serial_number);
          success.push(devices[i].serial_number);
          dispatchSuccess(dispatch);
        })
        .catch((error: any) => {
          processed.push(devices[i].serial_number);
          failed.push(devices[i].serial_number);
          dispatchSuccess(dispatch);
        });
    }
  };
};
