import * as PropTypes from "prop-types";
import React from "react";

import SleepStagesComponent from "./SleepStages";

// class SleepStages extends React.Component {
//     render() {
//         return this.props.options.plotData ? <SleepStagesComponent {...this.props} /> : null;
//     }
// }

function SleepStages(props) {
  return props.options.plotData ? <SleepStagesComponent {...props} /> : null;
}

SleepStages.propTypes = {
  options: PropTypes.shape({
    plotData: PropTypes.shape({
      series: PropTypes.object,
      threshold: PropTypes.number
    })
  }).isRequired
};

export default SleepStages;
