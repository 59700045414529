import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import React from "react";

import { AppRouteProps } from "interfaces/router.interface";

function SomnofySwitchRoute({
  routes,
  renderFunction,
  wrapperClass,
  basePath
}: {
  routes: AppRouteProps[];
  wrapperClass?: string;
  basePath?: string;
  renderFunction: (
    props: RouteComponentProps,
    route: AppRouteProps
  ) => JSX.Element;
}) {
  return (
    <div className={wrapperClass || ""}>
      <Switch>
        {routes.map((route: AppRouteProps) => {
          return (
            <Route
              exact={route.exact}
              key={route.path}
              path={basePath ? `${basePath}/${route.path}` : route.path}
              render={(props: RouteComponentProps) => {
                if (route.redirectPath) {
                  return (
                    <Redirect
                      to={
                        basePath
                          ? `${basePath}/${route.redirectPath}`
                          : route.redirectPath || "/"
                      }
                    />
                  );
                }
                return renderFunction(props, route);
              }}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default SomnofySwitchRoute;
