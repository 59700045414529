import { AppRouteProps } from "interfaces/router.interface";

export const usersRouteNames = {
  usersList: "usersList",
  viewUser: "viewUser"
};
export const userRoutes: AppRouteProps[] = [
  {
    name: usersRouteNames.usersList,
    path: "/app/settings/users",
    exact: true
  },
  {
    name: usersRouteNames.viewUser,
    path: "/app/settings/users/:id",
    exact: false
  }
];
export default userRoutes;
