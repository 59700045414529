import { toast } from "react-toastify";

import { User } from "interfaces/user.interface";
import { toastError, toastSucess } from "utils/toast.util";

// Success handler
export async function handleResponse(response: any) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  )
    return response.data;
  if (response.status === 400) {
    // const error = await response.text();
    throw new Error("server error");
  }
  throw new Error("Network response was not ok.");
}

// Error Handler. In a real app, would likely call an error logging service.
export function handleError(error: any) {
  console.error(`API call failed. ${error}`);
}

export const showErrorToastForUser = (error: any) => {
  // eslint-disable-next-line no-console
  const errorMessage = error?.response?.data?.errors;
  if (errorMessage && Array.isArray(errorMessage) && errorMessage.length) {
    for (let i = 0; i < errorMessage.length; i += 1) {
      const arr = errorMessage[i];
      if (arr?.param !== "display_name") {
        toastError(`${arr?.param ? arr.param : ""} : ${arr?.msg}`);
      }
    }
  }
  const onlyMessage = error?.response?.data?.message;
  if (onlyMessage) {
    toastError(`${onlyMessage}`);
  }
  const onlyMsg = error?.response?.data?.msg;
  if (onlyMsg) {
    toastError(`${onlyMsg}`);
  }

  const onlyError = error?.response?.data?.error;
  if (onlyError) {
    toastError(`${onlyError}`);
  }
};
