import {
  faEye,
  faEyeSlash,
  faQuestion,
  faUser,
  faWind
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography/Typography";

import "../../css/InformationTile.css";
import { Highlighted } from "../index";
import {
  getBackgroundColor,
  getStatusIcon,
  getStatusText,
  getTileScaleCopy
} from "../../utils/informationTile.util";
import { styles } from "./informationTile.styles";
import Card from "../Cards/Card";
import CardActions from "../Cards/CardActions";
import CardContent from "../Cards/CardContent";
import CardHeader from "../Cards/CardHeader";

library.add(faUser, faEye, faWind, faEyeSlash, faQuestion);

const InformationTileComponent = (props) => {
  const { classes, information, tileScale, query, ...rest } = props;
  const respirationRate = information.getRPM();
  const { t } = useTranslation();

  // let session_status = "Aktiv";
  // if (!information.hasActiveSession()) session_status = "Avsluttet";
  // if (!information.isDeviceOnline()) session_status = "Avbrutt";

  // Verdiene under bare estimerer størrelser som burde passe til gitte kort-bredder
  // Mulig en bedre løsning vil være å definerte gitte intervaller med størrelser
  const nameFontSize = (42 * tileScale) / 100; // null
  const userNameFontSize = (32 * tileScale) / 100; // null
  const statusFontSize = (36 * tileScale) / 100; // Math.floor(cardWidth / 8)
  const timeFontSize = (28 * tileScale) / 100; // Math.floor(cardWidth / 14)
  const avatarScale = (120 * tileScale) / 100; // null //Math.floor(cardWidth / 4)
  const cardHeaderPadding = (32 * tileScale) / 100; // Math.floor(cardWidth / 20)
  // const cardHeaderPadding2 = [(1 * tileScale) / 100, (23 * tileScale) / 100]; // Math.floor(cardWidth / 20)
  const cardActionPadding = (21 * tileScale) / 100; // Math.floor(cardWidth / 15)

  const tileScaleCopy = getTileScaleCopy(tileScale);
  const marginCard = (tileScale / 500) * 10;

  function classList(classesList) {
    return Object.entries(classesList)
      .filter((entry) => entry[1])
      .map((entry) => entry[0])
      .join(" ");
  }

  return (
    <Grid
      {...rest}
      className={`${
        classes[`root-size-${Math.floor((tileScale / 100) * 10)}`]
      }`}
      item
      style={{ marginBottom: marginCard }}
    >
      <Card
        square
        className={classList({
          "tileWidth-150": tileScaleCopy <= 150,
          "tileWidth-120": tileScaleCopy <= 120,
          "tileWidth-90": tileScaleCopy <= 90,
          "tileWidth-60": tileScaleCopy <= 60,
          "tileWidth-38": tileScaleCopy <= 38,
          "tileWidth-26": tileScaleCopy <= 26,
          "tileWidth-19": tileScaleCopy <= 19,
          "tileWidth-14": tileScaleCopy <= 14,
          "tileWidth-10": tileScaleCopy <= 10,
          "tileWidth-6": tileScaleCopy <= 6,
          "tileWidth-3": tileScaleCopy <= 3,
          "tileWidth-2": tileScaleCopy <= 2,
          "tileWidth-1": tileScaleCopy <= 1
        })}
        style={{ backgroundColor: getBackgroundColor(information.getStatus()) }}
      >
        <CardHeader
          disableTypography
          style={{
            paddingLeft: cardHeaderPadding,
            paddingRight: cardHeaderPadding,
            paddingTop: cardHeaderPadding,
            paddingBottom: cardHeaderPadding / 2
          }}
          title={
            <Typography
              style={{
                fontSize: nameFontSize,
                lineHeight: `${100 + (tileScale / 100) * 30}%`
              }}
              variant="h3"
              noWrap
            >
              {information.getRoom()}
            </Typography>
          }
        />
        <CardHeader
          disableTypography
          style={{
            paddingTop: 0,
            paddingBottom: 2,
            paddingLeft: cardHeaderPadding
          }}
          title={
            <Typography
              style={{
                fontSize: userNameFontSize,
                lineHeight: `${100 + (tileScale / 100) * 30}%`
              }}
              variant="subtitle1"
              noWrap
            >
              <Highlighted
                text={information.user.display_name}
                highlight={query}
              />
            </Typography>
          }
        />
        <Divider />
        <CardContent
          style={{ padding: cardHeaderPadding }}
          className={classes.content}
        >
          <Avatar
            style={{ width: avatarScale, height: avatarScale }}
            src={getStatusIcon(information.getStatus())}
          />

          <div className={classes.statusText}>
            <Typography style={{ fontSize: statusFontSize }} variant="h4">
              {getStatusText(information.getStatus(), t)}
            </Typography>
            {information.status === "away" ||
            information.status === "disconnected" ? (
              <Typography style={{ fontSize: timeFontSize }} variant="h6">
                {information.getStatusDurationText()}
              </Typography>
            ) : (
              ""
            )}
          </div>
        </CardContent>
        <Divider />
        <CardActions
          style={{ padding: cardActionPadding }}
          className={classes.cardActions}
        >
          <div className={classes.flex}>
            <Typography style={{ fontSize: timeFontSize }} variant="h5">
              {information.getStatusMovementText()}
            </Typography>
          </div>
          {/* {(respirationRate > 0 || information.getSpO2() > 0) && ( */}
          <div className={classes.flex}>
            <Typography style={{ fontSize: timeFontSize }} variant="h5">
              {information.getSpO2() > 0 && ` SpO2: ${information.getSpO2()}`}
            </Typography>
          </div>
          {/* )} */}
          {/* {respirationRate === 0 && ( */}
          <div className={classes.flex}>
            <Typography
              className="rpmSizing"
              style={{ fontSize: timeFontSize }}
              variant="h5"
            >
              <span style={{ float: "right" }}>
                {`RR: ${information.getRPM() || "-"}`}
              </span>
            </Typography>
          </div>
          {/* )} */}
        </CardActions>
      </Card>
    </Grid>
  );
};

InformationTileComponent.propTypes = {
  classes: PropTypes.any,
  information: PropTypes.any,
  tileScale: PropTypes.any,
  rest: PropTypes.any,
  query: PropTypes.string
};
export default withStyles(styles)(InformationTileComponent);
