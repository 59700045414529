import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

import {
  NightReportItem,
  NightReportItemStatus
} from "interfaces/nightReport.interface";

function NightReportListRow({
  item,
  classes,
  t,
  customClases,
  message
}: {
  item: NightReportItem;
  classes: any;
  t: any;
  customClases: any;
  message: string;
}) {
  return (
    <div
      className={`${classes.row} ${classes.evenChildBackground}`}
      aria-hidden
    >
      <div className={classes.col}>{item?.device?.name}</div>
      <div className={classes.col}>{item?.user?.display_name}</div>
      <div className={classes.col} style={{ flexGrow: 2 }}>
        <div className={classes.floatLeft}>
          <span>
            <ErrorOutlineIcon className={classes.errorIcon} />{" "}
          </span>
          <span className={customClases.infoText}> {message} </span>
        </div>
      </div>

      <div className={classes.col} />
      <div className={classes.col} />
      <div className={classes.col} />
      <div className={classes.col} />
      <div className={classes.col} />
    </div>
  );
}
export default NightReportListRow;
