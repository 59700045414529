import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 25
  },
  actionButtons: {
    margin: "0 10px",
    float: "left"
  },
  groupUsersItem: {
    padding: "0",
    paddingBottom: 16,
    "& span": {
      color: "dimgrey",
      fontSize: "13px",
      fontStyle: "italic"
    }
  },
  undoTimerBtn: {
    float: "left",
    border: "1px solid #05ACC1",
    background: "#05ACC1",
    color: "#FFF",
    padding: "5px 10px",
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      border: "1px solid #05ACC1",
      background: "#FFF",
      color: "#000",
      transition: "all .5s ease"
    }
  },
  undoTimerTime: {
    float: "left",
    marginRight: 10,
    marginTop: 5,
    color: "#F15050",
    fontWeight: "bold"
  },
  addNewGroup: {
    float: "left",
    cursor: "pointer",
    marginBottom: 4.5
  },
  container: {
    background: "#FFF",
    width: "100%",
    float: "left",
    padding: "56px 32px 81px 32px",
    borderRadius: "8px 8px 0 0",
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  },
  listContainer: {
    marginTop: 28,
    position: "relative",
    paddingTop: 54
  }
}));
