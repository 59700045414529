import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

interface Props {
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  total: number;
  showItems: number;
}
export const useStyles = makeStyles((theme: any) => ({
  showMore: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    marginTop: 25,
    float: "left",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));
function ShowMoreComponent({
  showMore,
  setShowMore,
  total,
  showItems
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      {showMore && (
        <div
          data-ref="preventParentClick"
          className={classes.showMore}
          aria-hidden
          onClick={() => setShowMore(false)}
        >
          {t("SHOW_MORE_PLACEHOLDER", { show: total - showItems })}
        </div>
      )}
      {!showMore && (
        <div
          data-ref="preventParentClick"
          aria-hidden
          className={classes.showMore}
          onClick={(event: any) => {
            event.stopPropagation();
            setShowMore(true);
          }}
        >
          {t("Show Less")}{" "}
        </div>
      )}
    </div>
  );
}

export default ShowMoreComponent;
