import { TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { UserSettingsConstantsType } from "constants/localSettingsConstants";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";

export const useStyles = makeStyles((theme: any) => ({
  perPage: {
    verticalAlign: "middle",
    "& .MuiInputBase-input": {
      border: `1px solid ${theme.palette.primary.listHeader}`,
      padding: 0,
      textAlign: "center",
      borderRadius: "4px",
      fontSize: 12,
      width: 32,
      height: 24,
      color: `${theme.palette.primary.listHeader}`,
      margin: "0 8px"
    },
    "& ::before": {
      border: 0
    },
    "& ::after": {
      border: 0
    }
  },

  numberInput: {
    padding: 15
  },
  inputRoot: {
    borderRadius: 0,
    "&:hover::before": {
      border: "0 !important"
    }
  },
  radio: {
    padding: 0
  },
  perPageWrapper: {
    float: "right",
    "& > span": {
      color: theme.palette.primary.listHeader,
      fontSize: 14
    }
  },
  totalRecords: {
    color: theme.palette.primary.listHeader,
    float: "right",
    fontSize: 14,
    marginTop: 18
  }
}));

function PerPageListItems({
  type,
  label,
  onChange,
  total
}: {
  type: UserSettingsConstantsType;
  label: string;
  onChange: Dispatch<SetStateAction<number>>;
  total?: number;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const perPage = +getLocalSettings(type) || 30;
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage);

  useEffect(() => {
    // update in local storage
    setLocalSettings({ [type]: +itemsPerPage });
    if (onChange) {
      onChange(+itemsPerPage);
    }
  }, [itemsPerPage]);

  return (
    <div className={classes.perPageWrapper}>
      <span>{t("Display")}</span>
      <TextField
        type="text"
        InputProps={{
          inputProps: { min: "10", max: "100", step: "1" },
          classes: {
            input: classes.numberInput,
            root: classes.inputRoot
          }
        }}
        value={itemsPerPage}
        className={classes.perPage}
        onChange={(e: any) => setItemsPerPage(+e.target.value)}
      />
      <span>{t("records per page")}</span>
      <div style={{ clear: "both" }} />
      <div className={classes.totalRecords}>
        {t("TOTAL_RECORDS_PLACEHOLDER", { total })}
      </div>
    </div>
  );
}
export default PerPageListItems;
