import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { Device } from "interfaces/device.interface";
import { isEmpty } from "utils/deviceManagement";

import { getAllDevices } from "../../../store/actions/devicesActions";
import DeviceView from "./deviceView/DeviceView";
import DevicesList from "./devicesList/DeviceList";
import deviceRoutes, {
  DeviceRouteType,
  deviceRouteNames
} from "./devices.routes";

/**
 * @name DevicesList
 * @description This is wrapper component for the login form.
 * @returns LoginForm componet
 */
function DevicesSettings() {
  const dispatch = useDispatch();
  const [usersEntity, setUsersEntity] = useState<any>();
  const [deviceEntity, setDeviceEntity] = useState<{ [key: string]: Device }>();

  /**
   * React device from state.
   *
   * @return users
   */
  const devices: Device[] = useSelector((state: any) => {
    return state?.deviceReducer.devices || [];
  });

  /**
   * React useSelector hook
   *
   * @return users
   */
  const users: any = useSelector((state: any) => {
    return state?.users?.users || [];
  });

  /**
   * React useSelector hook
   *
   * @return indicate active loading devices
   */
  const loading: any = useSelector((state: any) => {
    return state?.deviceReducer?.loading;
  });

  /**
   * React hook to fetch program info data
   *
   * @param {dispatch} dispatch user object
   * @return dispatch action.
   */
  useEffect(() => {
    dispatch(getAllDevices());
  }, []);

  /**
   * Create normalized users
   *
   * @param {users} user object
   * @return {usersEntity} create a normalized user object
   */
  useEffect(() => {
    if (users.length) {
      const normalizedUsers: any = {};
      for (let i = 0; i < users.length; i += 1) {
        normalizedUsers[users[i].id] = users[i];
      }
      setUsersEntity(normalizedUsers);
    }
  }, [users]);

  /**
   * Create normalized devices
   *
   * @param {devices} devices object
   * @return {deviceEntity} create a normalized devices object
   */
  useEffect(() => {
    if (devices.length) {
      const normalizedDevice: any = {};
      for (let i = 0; i < devices.length; i += 1) {
        normalizedDevice[devices[i].serial_number] = devices[i];
      }
      // device entity
      setDeviceEntity(normalizedDevice);
    }
  }, [devices, loading]);

  /**
   * Returns route link based on the paramter of custom route type.
   *
   * @param {props} props of type location.
   * @return {currentRoute} single route object
   */
  const getRender = (props: any, currentRoute: DeviceRouteType) => {
    switch (currentRoute.name) {
      // device list page
      case deviceRouteNames.deviceList:
        if (usersEntity && devices) {
          return (
            <DevicesList
              devices={devices}
              users={usersEntity}
              routePath={currentRoute.path}
            />
          );
        }
        return <div> Loading</div>;

      // Device view page
      case deviceRouteNames.deviceView:
        if (!isEmpty(deviceEntity) && !isEmpty(usersEntity)) {
          return (
            <DeviceView
              id={props.match.params.id}
              usersEntity={usersEntity}
              deviceEntity={deviceEntity}
              loading={loading}
              key={props.match.params.id}
              routePath={currentRoute.path}
            />
          );
        }
        break;

      // default case
      default:
        return <div>Loading</div>;
    }
    return <div>Loading</div>;
  };

  // Returns the location to any component.
  return (
    <div>
      <Switch>
        {deviceRoutes.map((devicesRoute: DeviceRouteType) => {
          return (
            <Route
              exact={devicesRoute.exact}
              key={devicesRoute.path}
              path={devicesRoute.path}
              render={(props: RouteComponentProps) =>
                getRender(props, devicesRoute)
              }
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default DevicesSettings;
