import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  container: {
    float: "left",
    "& > .MuiFormControlLabel-root": {
      marginRight: 0,
      marginLeft: 0,
      "& > .MuiButtonBase-root": {
        padding: 0
      }
    }
  }
}));

function CheckBoxCustom({
  checked,
  onChange
}: {
  checked: boolean;
  onChange: (value: boolean) => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FormControlLabel
        data-ref="preventParentClick"
        value={checked}
        control={<Checkbox color="default" />}
        label=""
        labelPlacement="end"
        checked={checked}
        onChange={(event, value) => {
          onChange(value);
        }}
        style={{
          marginRight: 12,
          float: "left"
        }}
      />
    </div>
  );
}
export default CheckBoxCustom;
