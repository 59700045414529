import {
  FormControl,
  InputLabel,
  Select,
  Theme,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import { setLanguage } from "utils/localStorage";
import i18n from "i18n/config";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    height: 50
  }
}));

function SetAppLanguage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const langSelected = event.target.value as string;
    setLanguage(langSelected);
    window.location.reload();
  };
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="grouped-native-select">
          {t("Select Language")}
        </InputLabel>
        <Select native defaultValue={i18n.language} onChange={handleChange}>
          <optgroup label={t("Language Norwegian")}>
            <option value="nb_healthwelfare">
              {t("Language for Health and Welfare")}{" "}
            </option>
            <option value="nb_sleep_research">
              {t("Language for Sleep Researchers")}
            </option>
            <option value="nb_private_customer">
              {" "}
              {t("Language for Private Customer")}{" "}
            </option>
          </optgroup>
          <optgroup label={t("Language English")}>
            <option value="en"> {t("Language English")} </option>
          </optgroup>
        </Select>
      </FormControl>
    </div>
  );
}

export default SetAppLanguage;
