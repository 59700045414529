import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import React from "react";

export const useStyles = makeStyles((theme: any) => ({
  paginationItem: {
    listStyle: "none",
    float: "left",
    margin: "0 6px",
    textAlign: "center",
    color: theme.palette.dark,
    border: `1px solid ${theme.palette.dark}`,
    fontSize: 14,
    minWidth: 32,
    minHeight: 32,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    justifyContent: "center",
    "& > a": {
      textDecoration: "none"
    }
  },
  pagination: {
    float: "right",
    marginTop: 36,
    cursor: "pointer",
    position: ({ fixedStyle }: { fixedStyle: boolean }) =>
      fixedStyle ? "fixed" : "relative",
    bottom: 0,
    fontSize: 10,
    padding: "5px 0",
    "& > ul": {
      margin: 0
    }
  },
  paginationItemActive: {
    background: theme.palette.primary.lightBlue
  },
  paginationActiveLink: {
    // color: `${theme.palette.primary.lightGreen} !important`
  },
  paginationLink: {
    color: theme.palette.dark
  },
  itemClassForText: {
    color: theme.palette.dark,
    border: "none",
    fontSize: 16
  }
}));

interface Props {
  activePage: number;
  itemsPerPage: number;
  itemsLength: number;
  handlePageChange: (pageNumber: number) => void;
}

function SMPagination({
  activePage,
  itemsPerPage,
  itemsLength,
  handlePageChange
}: Props): JSX.Element {
  const fixedStyle = false;
  const classes = useStyles({ fixedStyle });
  const { t } = useTranslation();
  return (
    <div className={classes.pagination}>
      <Pagination
        itemClass={classes.paginationItem}
        linkClass={classes.paginationLink}
        activeClass={classes.paginationItemActive}
        activeLinkClass={classes.paginationActiveLink}
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={itemsLength}
        pageRangeDisplayed={itemsPerPage}
        onChange={handlePageChange}
        prevPageText={t("Previous")}
        nextPageText={t("Next")}
        itemClassFirst={classes.itemClassForText}
        itemClassNext={classes.itemClassForText}
        itemClassPrev={classes.itemClassForText}
        itemClassLast={classes.itemClassForText}
        hideFirstLastPages
      />
    </div>
  );
}
export default SMPagination;
