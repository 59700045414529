import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";

import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";

interface AutoSuggestTypes {
  onChange?: (id: User) => void;
  userId: string;
  placeholder?: string;
}

export const useStyles = makeStyles((theme: any) => ({
  autoSuggestTextfield: {
    border: `1px solid ${theme.palette.primary.input}`,
    height: 50,
    margin: 0,
    borderRadius: 5,
    boxSizing: "border-box",
    paddingLeft: 12
  },
  inputRoot: {
    padding: 0,
    paddingLeft: 4,
    margin: 0,
    "& input": {
      height: 48,
      padding: "0 !important"
    }
  },
  popupIndicator: {
    width: 12,
    height: 7,
    marginRight: 20,
    "& .MuiIconButton-root": {
      margin: 0,
      padding: 0,
      fontSize: 12
    }
  },
  clearIndicator: {
    display: "none"
  }
}));

export default function AutoSuggest({
  onChange,
  userId,
  placeholder
}: AutoSuggestTypes) {
  const classes = useStyles();
  const [newUser, setNewUser] = useState<any>();

  const users: any = useSelector((state: any) => {
    return state?.users?.users || [];
  });

  useEffect(() => {
    if (users && userId) {
      const userObj = users?.find((user: User) => user.id === userId) || {};
      setNewUser(userObj);
    }
  }, [userId, users]);

  const onSelectUser = (selectedUser: any) => {
    setNewUser(selectedUser);
    if (onChange) {
      onChange(selectedUser);
    }
  };

  const ignoreViewerUsers = (allUsers: User[]): User[] => {
    return allUsers.filter((user: User) => {
      return user.attributes?.user_type !== UserAttributesTypes.viewer;
    });
  };

  if (users.length) {
    return (
      <div>
        <Autocomplete
          value={newUser ?? {}}
          className={classes.autoSuggestTextfield}
          options={ignoreViewerUsers(users)}
          getOptionLabel={(option: any) => option?.display_name ?? ""}
          closeIcon={null}
          popupIcon={
            <div>
              <DownArrowOutline />
            </div>
          }
          classes={{
            endAdornment: classes.popupIndicator,
            clearIndicator: classes.clearIndicator
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder || ""}
              classes={{
                root: classes.inputRoot
              }}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              margin="normal"
            />
          )}
          onChange={(event, option) => {
            onSelectUser(option);
          }}
        />
      </div>
    );
  }
  return <div>No users found</div>;
}
