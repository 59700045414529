import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  addNewUser: {
    float: "left",
    cursor: "pointer",
    marginBottom: 6.5
  },
  perPageWrapper: {
    float: "right"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 25
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  }
}));
