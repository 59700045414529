import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";

import * as types from "../actionTypes/usersActionTypes";

export default (state: any, action: types.usersActionTypes) => {
  switch (action.type) {
    case types.GET_USERS:
      return {
        ...state,
        fetching: true,
        error: false,
        currentAction: types.USER_ACTIONS.reset
      };
    case types.GET_USERS_FULFILLED:
      return {
        ...state,
        users: action.payload,
        fetching: false,
        error: false,
        currentAction: "",
        newUser: ""
      };
    case types.GET_USERS_REJECTED:
      return {
        ...state,
        fetching: false,
        error: true
      };
    case types.CHANGE_USER_PASSWORD_ACTION:
      return {
        ...state,
        passwordActionLoading: true
      };
    case types.CHANGE_USER_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        loading: "password-changed",
        passwordActionLoading: false
      };

    case types.UPDATE_USER_ACTION:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_USER_SUCCESS_ACTION:
      return {
        ...state,
        loading: false,
        currentAction:
          action.payload.attributes?.user_type === UserAttributesTypes.device
            ? types.USER_ACTIONS.updateSuccess
            : types.USER_ACTIONS.updateEmployeeSuccess,
        users: state.users.map((user: User) =>
          user.id === action.payload.id ? { ...action.payload } : user
        )
      };

    case types.ADD_NEW_USER_ACTION:
      return {
        ...state,
        loading: "loading",
        currentAction: types.USER_ACTIONS.addNewUser
      };
    case types.ADD_NEW_USER_SUCCESS_ACTION:
      return {
        ...state,
        loading: "saved",
        currentAction:
          action.payload.attributes?.user_type === UserAttributesTypes.device
            ? types.USER_ACTIONS.createSuccess
            : types.USER_ACTIONS.createEmployeeSuccess,
        users: [...state.users, action.payload],
        newUser: action.payload
      };

    case types.DELETE_USER_ACTION:
      return {
        ...state,
        loading: "deleting",
        currentAction: types.USER_ACTIONS.deleteUser
      };

    case types.DELETE_USER_ACTION_SUCCESS:
      return {
        ...state,
        loading: "deleted",
        users: state.users.filter(
          (user: User) => user.id !== action.payload.id
        ),
        currentAction: types.USER_ACTIONS.deleteSuccess
      };

    case types.RESET_USER_PRELOADERS:
      return {
        ...state,
        loading: "",
        currentAction: types.USER_ACTIONS.reset,
        newUser: ""
      };

    case types.DELETE_MULTI_USER_ACTION_SUCCESS:
      return {
        ...state,
        loading: "deleted",
        users: state.users.filter(
          (user: User) => action.payload.indexOf(user.id) === -1
        ),
        currentAction: types.USER_ACTIONS.deleteSuccess
      };

    default:
      return { ...state };
  }
};
