export const getSleepStagesPlotData = (session) => {
  if (!session) {
    return null;
  }
  const sleepReport = session._embedded.sleep_analysis.report;
  return {
    series: {
      timeAwakeAfterSleepOnset: sleepReport.time_wake_after_sleep_onset,
      timeInREMSleep: sleepReport.time_in_rem_sleep,
      timeInLightSleep: sleepReport.time_in_light_sleep,
      timeInDeepSleep: sleepReport.time_in_deep_sleep,
      timeInNoPresence: sleepReport.time_in_no_presence
    }
  };
};
