import { useLocation } from "react-router-dom";
import React from "react";

import LoginForm from "./components/LoginForm";

/**
 * @name LoginContainer
 * @description This is wrapper component for the login form.
 * @returns LoginForm componet
 */

function LoginContainer() {
  // Returns the location to any component.

  const location = useLocation();
  return <LoginForm location={location} />;
}

export default LoginContainer;
