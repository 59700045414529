import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import {
  INTERFACE_NOTIFICATION_LOG_TYPES,
  NOTIFICATION_LOG_TYPES,
  NOTIFICATION_TYPES,
  USER_NOTIFICATION_TYPES
} from "constants/notificationContstants";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { convertUsersToOptionType } from "utils/userManagement";
import { getAvailbleUsers } from "utils/groupManagement";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";
import SMCustomDateFilter from "components/SMCustomDateFilter/SMCustomDateFilter";
import SMCustomSelect from "components/SMCustomSelect/SMCustomSelect";
import SearchTextField from "components/SearchTextField/SearchTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 28,
    position: "relative",
    paddingTop: 54
  },
  container: {
    background: "#FFF",
    width: "100%",
    float: "left",
    padding: "56px 32px 81px 32px",
    borderRadius: "8px 8px 0 0",
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  addNewGroup: {
    float: "left",
    cursor: "pointer"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 6,
    marginBottom: 40
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  },
  filterWrapper: {
    width: "100%",
    float: "left"
  },
  radioFilters: {
    float: "right"
  },
  radioFilter: {
    "& > span": {
      fontSize: 16
    }
  },
  floatRight: {
    float: "right"
  },
  filterComponent: {
    width: 108,
    float: "left",
    marginRight: 8
  },
  firingtimeContainer: {
    float: "left",
    width: 243,
    marginRight: 8
  },
  filterRuleTypeWrapper: {
    float: "left",
    minWidth: 140
  }
}));

interface Props {
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  type: INTERFACE_NOTIFICATION_LOG_TYPES;
  onSearch: (q: string) => void;
  total: number;
  users: User[];
  onUserFilterChange: ({
    users,
    from,
    to,
    notificationTypes
  }: {
    users: User[];
    from: string;
    to: string;
    notificationTypes: { id: string; name: string; value: string }[];
  }) => void;
}

function NotificationLogListBanner({
  setItemsPerPage,
  type,
  onSearch,
  total,
  users,
  onUserFilterChange
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<
    { id: string; name: string; value: string }[]
  >([]);

  const [from, setFromDate] = useState<string>("");
  const [to, setToDate] = useState<string>("");

  useEffect(() => {
    onUserFilterChange({
      users: selectedUsers,
      notificationTypes: selectedTypes,
      from,
      to
    });
  }, [from, to, selectedUsers, selectedTypes]);

  return (
    <div>
      <div className={classes.firingtimeContainer}>
        <SMCustomDateFilter
          allSelectedText={t("Notification Firing Time (All)")}
          customSeletedText={t("Notification Firing Time (Custom)")}
          pastDayText={t("Last 24 hours")}
          pastWeekText={t("Last 7 days")}
          pastMonthText={t("Last 30 days")}
          customRangeText={t("Custom range")}
          buttonText={t("Apply Filter")}
          onChange={(value: string[]) => {
            if (Array.isArray(value)) {
              setFromDate(value[0]);
              setToDate(value[1]);
            }
          }}
          title="Filter by Date"
        />
      </div>
      <div className={classes.filterComponent}>
        <SMCustomSelect
          optionsList={
            convertUsersToOptionType(
              getAvailbleUsers(users, UserGroupType.device)
            ) as any[]
          }
          selectedList={selectedUsers as any[]}
          prependText={t("User")}
          onChange={(options: any[]) => {
            setSelectedUsers(options);
          }}
          filterType
          labels={{
            txtShowMore: t("Show All"),
            txtShowLess: t("Show Less"),
            txtSelectClearToggle: t("Select All / Clear All"),
            txtClearAll: t("Clear All"),
            txtSelected: t("Selected"),
            txtNotSelected: t("Not Selected")
          }}
        />
      </div>

      {type === NOTIFICATION_LOG_TYPES.USER_LOG ? (
        <div className={classes.filterRuleTypeWrapper}>
          <SMCustomSelect
            optionsList={USER_NOTIFICATION_TYPES as any[]}
            selectedList={selectedTypes}
            prependText={t("Rule Type")}
            onChange={(options: any[]) => {
              setSelectedTypes(options);
            }}
            filterType
            labels={{
              txtShowMore: t("Show All"),
              txtShowLess: t("Show Less"),
              txtSelectClearToggle: t("Select All / Clear All"),
              txtClearAll: t("Clear All"),
              txtSelected: t("Selected"),
              txtNotSelected: t("Not Selected")
            }}
          />
        </div>
      ) : (
        ""
      )}

      <PerPageListItems
        type={USER_SETTINGS_CONSTANTS.user_notifications_per_page}
        onChange={setItemsPerPage}
        label={t("Per page")}
        total={total}
      />

      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={(q: string) => onSearch(q)}
          width="320px"
          placeholder={
            type === NOTIFICATION_TYPES.USER_RULES
              ? t("Search for user")
              : t("Search for rule name")
          }
          noMargin
        />
      </div>
    </div>
  );
}

export default NotificationLogListBanner;
