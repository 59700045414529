import { customizationTypes } from "../types";

export default (state, action) => {
  switch (action.type) {
    case customizationTypes.SET_STYLE_TYPE:
      return {
        ...state,
        styleType: action.payload
      };
    default:
      return { ...state };
  }
};
