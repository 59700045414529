import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Dashboard as SomnofyDashboard } from "../../lib";
import { getToken } from "../../utils/localStorage";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserId: this.props.location.pathname.split("/")[3]
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const selectedUserId = location.pathname.split("/")[3];
      this.setState({ selectedUserId });
    });
  }

  static getDerivedStateFromProps(nextProps) {
    const { newPath } = nextProps.location.pathname;
    if (newPath) {
      const selectedUserId = newPath.split("/")[3];
      this.setState({ selectedUserId });
    }
  }

  render() {
    const { selectedUserId } = this.state;
    const { user } = this.props;
    const apiInfo = { token: getToken(), authType: "Basic" };

    if (selectedUserId) {
      return (
        <div>
          <SomnofyDashboard
            apiInfo={apiInfo}
            selectedUserId={selectedUserId}
            key={selectedUserId}
            userDisplayName={
              user
                ? `${user?.display_name} (${user.gender}, ${user.birth_year})`
                : ""
            }
          />
        </div>
      );
    }
    if (user) {
      return (
        <div>
          <SomnofyDashboard
            apiInfo={apiInfo}
            selectedUserId={user.id}
            key={user.id}
            userDisplayName={user.display_name}
          />
        </div>
      );
    }
    return <div>SOMNOFY - QUANTUM OF SLEEP</div>;
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user
});

Dashboard.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.any
};

export default connect(mapStateToProps)(Dashboard);
