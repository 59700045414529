import axios from "axios";

import { DeviceSettings } from "interfaces/device.interface";

import { API_SERVICES } from "../../constants/constants";
import { getToken } from "../../utils/localStorage";
import { handleError, handleResponse } from "../responseHandler";

export const devicesServices = {
  fetchDevices,
  fetchDeviceSettings,
  changeDeviceInformation,
  updateDeviceSettings,
  unRegisterDevice
};

// Function to load channels
async function fetchDevices() {
  const url = API_SERVICES.getAlldevices;
  return axios({
    method: "get",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function fetchDeviceSettings(deviceSerialNumber: string) {
  const url = API_SERVICES.getdevicesSettings.replace("$$", deviceSerialNumber);
  return axios({
    method: "get",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function changeDeviceInformation({
  name,
  id,
  deviceSerialNumber
}: {
  name: string;
  id: string;
  deviceSerialNumber: string;
}) {
  const url = API_SERVICES.changeUser.replace("$$", deviceSerialNumber);
  return axios({
    method: "put",
    url,
    data: { name, user_id: id },
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function updateDeviceSettings(
  data: DeviceSettings,
  deviceSerialNumber: string
) {
  const url = API_SERVICES.updateDevicesSettings.replace(
    "$$",
    deviceSerialNumber
  );
  return axios({
    method: "put",
    url,
    data,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function unRegisterDevice(deviceSerialNumber: string) {
  const url = API_SERVICES.deleteDevice.replace("$$", deviceSerialNumber);
  return axios({
    method: "delete",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
