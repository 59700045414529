import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import { getCheckedItemsCount } from "utils/userManagement";

interface Props {
  selectedItems: { [key: string]: boolean };
  actions?: { label: string; key: string }[];
  handleClick?: (key: string) => void;
}

export const useStyles = makeStyles((theme: any) => ({
  selected: {
    color: theme.palette.primary.listHeader,
    fontSize: 16,
    float: "left",
    padding: "9px 0"
  },
  actionLabel: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    float: "left",
    margin: "0 8px",
    "& > .MuiButton-text": {
      margin: 0,
      lineHeight: "20px"
    }
  },
  divider: {
    float: "left",
    width: 1,
    height: 13,
    background: theme.palette.primary.dark,
    marginLeft: 16,
    marginTop: 13
  }
}));

function BulkActions({ selectedItems, actions, handleClick }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <div className={classes.selected}>
        {getCheckedItemsCount(selectedItems)}{" "}
        {getCheckedItemsCount(selectedItems) === 1
          ? t("item selected")
          : t("items selected")}
      </div>
      {actions && actions.length && getCheckedItemsCount(selectedItems) > 0 ? (
        <div className={classes.divider} />
      ) : (
        ""
      )}
      {actions &&
        getCheckedItemsCount(selectedItems) > 0 &&
        actions.map((action: { label: string; key: string }) => (
          <Button
            onClick={() => handleClick && handleClick(action.key)}
            className={classes.actionLabel}
            key={action.key}
          >
            {action.label}
          </Button>
        ))}
    </div>
  );
}

export default BulkActions;
