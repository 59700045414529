import { withStyles } from "@material-ui/core/styles";
import MuiCardHeader from "@material-ui/core/CardHeader";
import React from "react";

const styles = () => ({
  action: {
    display: "flex",
    alignItems: "center",
    "&>": {
      marginLeft: 50
    }
  }
});

const CardHeader = (props) => {
  const { action, actions, classes, ...rest } = props;
  let actionComponent = action;

  if (actions) {
    actionComponent = (
      <div className={classes.action}>{actions.map((a) => a)}</div>
    );
  }

  return <MuiCardHeader action={actionComponent} classes={classes} {...rest} />;
};

CardHeader.propTypes = MuiCardHeader.propTypes;

export default withStyles(styles)(CardHeader);
