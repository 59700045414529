import PropTypes from "prop-types";
import React from "react";

/*
 *  Shared Component: NotFound
 *  @description: Page not found component
 *
 *  @param text: display text to the component
 */

function NotFound({ text = "Not Found" }) {
  return <h3 style={{ textAlign: "center", fontSize: "18px" }}>{text}</h3>;
}

NotFound.propTypes = {
  text: PropTypes.any
};
export default NotFound;
