import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { User } from "interfaces/user.interface";
import {
  changeDeviceUser,
  unRegisterDeviceAction
} from "store/actions/devicesActions";
import { updateDeviceSettings } from "store/actions/devicesSettingsActions";
import AddUserSlidingForm from "components/AddUserSlidingForm/AddUserSlidingForm";
import AutoSuggestSelect from "components/AutoSuggestSelect/AutoSuggestSelect";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import DiscreteSlider from "components/DiscreteSlider/DiscreteSlider";
import InfoBox from "components/InfoBox/InfoBox";
import SMTextField from "components/SMTextField/SMTextField";
import SaveCancelDeleteButton from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";
import SwipeableTemporaryDrawer from "components/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";
import TIMEZONES from "utils/timezones";

import DeviceNameComponent from "./components/DeviceNameComponent";
import DisableSleepTrackingComponent from "./components/DisableSleepTrackingComponent";
import UsersAutoSuggest from "./components/UsersAutoSuggest";

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: "56px 32px 0 32px",
    background: theme.palette.secondary.grey,
    width: "100%",
    float: "left"
  },
  autoSuggestWrapper: {
    "-webkit-transition": "height 1s ease",
    width: "100%",
    height: "auto",
    float: "left",
    border: 0,
    padding: 0,
    marginTop: 0,
    position: "relative"
  },
  online: {
    color: "#4caf50"
  },
  offline: {
    color: "#d50000" // @Todo(fr@vitalthings.com): move to theme
  },
  serialNumber: {
    color: theme.palette.primary.grey,
    fontSize: 14
  },
  fieldWrapper: {
    width: 250,
    float: "left"
  },
  radioGroup: {
    flexDirection: "row"
  },
  drawerWrapper: {
    float: "left",
    marginLeft: 117,
    marginTop: 3
  }
}));

interface DeviceInformationProps {
  deviceSettings: DeviceSettings;
  device: Device;
  user: any;
  newUser?: User;
}
function DeviceInformation({
  deviceSettings,
  device,
  user,
  newUser
}: DeviceInformationProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [toggleUserSearchBox, setUserToggle] = useState<boolean>(false);
  const [toggleUserAdd, setToggleUserAdd] = useState<boolean>(false);

  const [settings, setDeviceSettings] = useState<DeviceSettings>();
  const [deviceName, setDeviceName] = useState<string>(_.clone(device.name));
  const [userID, setUserID] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    setUserID(_.clone(newUser ? newUser.id : user.id));
  }, [user, newUser]);

  useEffect(() => {
    setDeviceSettings(_.cloneDeep(deviceSettings));
  }, [deviceSettings]);

  const onCancel = () => {
    setDeviceSettings(_.cloneDeep(deviceSettings));
    setDeviceName(_.clone(device.name));
    setUserID(_.clone(user.id));
  };

  const onSettingsChange = (name: string, event: any, value: any) => {
    const updatedSettings: DeviceSettings = {
      ...settings,
      [name]: value
    } as DeviceSettings;
    setDeviceSettings(updatedSettings);
  };

  const onSave = () => {
    if (deviceName && userID && settings) {
      dispatch(
        changeDeviceUser({
          name: deviceName,
          id: userID,
          deviceSerialNumber: device.serial_number
        })
      );
      dispatch(updateDeviceSettings(settings, device.serial_number));
    }
  };

  const onDelete = () => {
    dispatch(
      unRegisterDeviceAction({ deviceSerialNumber: device.serial_number })
    );
  };

  if (!settings) {
    return <></>;
  }
  return (
    <div className={classes.wrapper}>
      <div>
        <DeviceNameComponent
          device={device}
          deviceName={deviceName}
          onSave={(name: string) => setDeviceName(name)}
        />
      </div>

      <InfoBox>
        <DeviceInfoText
          text={`${t("Device Name")}`}
          field={
            <SMTextField
              placeholder={t("e.g. Room A01")}
              onSave={(val: string) => setDeviceName(val)}
              value={deviceName || ""}
              delay={0}
            />
          }
        />
        <DeviceInfoText
          field={<div>{device.serial_number}</div>}
          text={`${t("Serial Number")}`}
        />
        <DeviceInfoText
          text={`${t("Current user")}`}
          field={
            <div>
              <div className={classes.fieldWrapper}>
                <UsersAutoSuggest
                  userId={userID || ""}
                  onChange={(userObj: User) => setUserID(userObj?.id || "")}
                />
              </div>
              <div className={classes.drawerWrapper}>
                <SwipeableTemporaryDrawer
                  label={t("Add User")}
                  component={<AddUserSlidingForm onclose={setToggleUserAdd} />}
                  setToggle={setToggleUserAdd}
                  toggle={toggleUserAdd}
                />
              </div>
            </div>
          }
        />
      </InfoBox>

      <InfoBox>
        <DisableSleepTrackingComponent
          deviceSettings={settings}
          onChange={onSettingsChange}
          device={device}
          t={t}
        />
        <DeviceInfoText
          text={`${t("Distance")} (${t("meter")})`}
          infoText={t("Set the maximum radar distance")}
          field={
            <DiscreteSlider
              value={settings.distanceLimit}
              displayFormater={(val: number) => `${val}m`}
              steps={0.1}
              min={0.5}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                value: number | number[]
              ) => onSettingsChange("distanceLimit", event, value)}
            />
          }
        />

        <DeviceInfoText
          text={`${t("Display Intensity")}`}
          infoText={t("Adjust the display light intensity")}
          field={
            <DiscreteSlider
              min={1}
              max={100}
              steps={1}
              value={settings.displayIntensityBias}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                value: number | number[]
              ) => onSettingsChange("displayIntensityBias", event, value)}
            />
          }
        />

        <DeviceInfoText
          text={`${t("Display Mode")}`}
          field={
            <div>
              <RadioGroup
                value={settings.display ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onSettingsChange(
                    "display",
                    event,
                    event.target.value as string
                  )
                }
                classes={{ root: classes.radioGroup }}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio />}
                  label={t("Clock and circle")}
                />

                <FormControlLabel
                  value="circle"
                  control={<Radio />}
                  label={t("Circle only")}
                />

                <FormControlLabel
                  value="off"
                  control={<Radio />}
                  label={t("Off")}
                />
              </RadioGroup>
            </div>
          }
        />

        <DeviceInfoText
          text={`${t("Audio")}`}
          field={
            <RadioGroup
              value={settings.audio ?? ""}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                value: string
              ) => {
                onSettingsChange("audio", event, value);
              }}
              classes={{ root: classes.radioGroup }}
            >
              <FormControlLabel
                value="on"
                control={<Radio />}
                label={t("On")}
              />

              <FormControlLabel
                value="off"
                control={<Radio />}
                label={t("Off")}
              />
            </RadioGroup>
          }
        />
      </InfoBox>
      <InfoBox>
        <DeviceInfoText
          text={`${t("Time Format")}`}
          field={
            <RadioGroup
              value={settings.timeFormat ?? ""}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                value: string
              ) => {
                onSettingsChange("timeFormat", event, value);
              }}
              classes={{ root: classes.radioGroup }}
            >
              <FormControlLabel
                value="24"
                control={<Radio />}
                label={t("24 Hour")}
              />

              <FormControlLabel
                value="12"
                control={<Radio />}
                label={t("AM/PM")}
              />
            </RadioGroup>
          }
        />

        <DeviceInfoText
          text={`${t("Time Zone")}`}
          field={
            <AutoSuggestSelect
              defaultValue={settings.timezone}
              options={TIMEZONES}
              onSelect={(timezone: string) =>
                onSettingsChange("timezone", null, !timezone ? "" : timezone)
              }
            />
          }
        />
      </InfoBox>

      <SaveCancelDeleteButton
        txtSave={t("Save")}
        txtCancel={t("Cancel")}
        txtDelete={t("Delete this device")}
        onCancel={onCancel}
        onSave={() => onSave()}
        onDelete={() => onDelete()}
        deleteConfirmTitle={t("Delete this device")}
        deleteConfirmText={t(
          "Deleting this device will remove the unit from your user. No sleep data will be deleted. You can add the device to a different user after deletion"
        )}
        disabledSaveButton={
          userID === user.id &&
          deviceName === device.name &&
          _.isEqual(deviceSettings, settings)
        }
      />
    </div>
  );
}

export default DeviceInformation;
