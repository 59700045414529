import { Devices } from "interfaces/device.interface";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { setToken } from "services/utils/api/localStorage";
import { showErrorToastForUser } from "services/responseHandler";
import { userServices } from "services/users/usersService";

import * as types from "../actionTypes/usersActionTypes";
import { multiGroupUpdate } from "./groupActions";
import i18n from "../../i18n/config";

/**
 * Redux action type for to to update all the devices
 *
 * @param data devices array of type ${Devices}
 * @return payload- array of devices
 */
export const changeUserPasswordAction = (
  data: Devices
): types.changeUserPasswordAction => {
  return {
    type: types.CHANGE_USER_PASSWORD_ACTION
  };
};

export const changeUserPasswordSuccessAction = (
  data: User
): types.changeUserPasswordSuccessAction => {
  return {
    type: types.CHANGE_USER_PASSWORD_ACTION_SUCCESS,
    payload: data
  };
};

export const updateUserAction = (data: User): types.updateUserAction => {
  return {
    type: types.UPDATE_USER_ACTION
  };
};

export const updateUserSuccessAction = (
  data: User
): types.updateUserSuccessAction => {
  return {
    type: types.UPDATE_USER_SUCCESS_ACTION,
    payload: data
  };
};

export const addNewUserAction = (data: User): types.addNewUserAction => {
  return {
    type: types.ADD_NEW_USER_ACTION
  };
};

export const addNewUserSuccessAction = (
  data: User
): types.addNewUserSuccessAction => {
  return {
    type: types.ADD_NEW_USER_SUCCESS_ACTION,
    payload: data
  };
};

export const deleteUserActionType = (data: User): types.deleteUserAction => {
  return {
    type: types.DELETE_USER_ACTION
  };
};

export const deleteUserSuccessAction = (
  data: User
): types.deleteUserSuccessAction => {
  return {
    type: types.DELETE_USER_ACTION_SUCCESS,
    payload: data
  };
};

export const deleteMultiUserSuccessAction = (
  data: string[]
): types.deleteMultipleUserSuccessAction => {
  return {
    type: types.DELETE_MULTI_USER_ACTION_SUCCESS,
    payload: data
  };
};

export const fetchAllUsersSuccessAction = (
  data: User[]
): types.getUsersFulfilledFACtion => {
  return {
    type: types.GET_USERS_FULFILLED,
    payload: data
  };
};

export const resetUserPreloadersActionType = (): types.resetUserPreloaders => {
  return {
    type: types.RESET_USER_PRELOADERS
  };
};

/**
 * Redux action to change device user
 *
 * @param name: new name to set
 * @param id: user id to set
 * @param deviceSerialNumber: serial number of the device
 * @return device obbject with updated data
 */
export const changeUserPassword = ({
  newPassword,
  user,
  subUser
}: {
  newPassword: string;
  user: User;
  subUser: boolean;
}) => {
  return function (dispatch: (arg0: any) => void) {
    userServices
      .changeUserPassword(newPassword, subUser, user)
      .then((data: User) => {
        // update auth token
        if (!subUser) {
          const authToken = btoa(`${user.email}:${newPassword}`);
          setToken(authToken);
        }
        dispatch(changeUserPasswordSuccessAction(data));
      });
  };
};

/**
 * Redux action to update user information
 *
 * @param user: User object with
 * @return updated user information
 */
export const upadateUserInformation = ({
  user,
  groups
}: {
  user: User;
  groups: Group[];
}) => {
  return function (dispatch: (arg0: any) => void) {
    userServices.updateUserInformation(user).then((data: User) => {
      dispatch(multiGroupUpdate(groups));
      dispatch(updateUserSuccessAction(data));
    });
  };
};

/**
 * Redux action to add new user
 *
 * @param user: User object
 * @return updated user data with id
 */
export const createNewSubUser = ({
  user,
  groupEntity = {}
}: {
  user: User;
  groupEntity?: { [key: string]: Group };
}) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(addNewUserAction(user));
    userServices.addNewUser(user).then((data: User) => {
      const updateGroups: Group[] = [];
      const userGroups = user?.user_groups ?? [];
      for (let i = 0; i < userGroups.length; i += 1) {
        if (groupEntity[userGroups[i]]) {
          const currentGroup = { ...groupEntity[userGroups[i]] };
          currentGroup.users.push(data.id);
          updateGroups.push(currentGroup);
        }
      }
      dispatch(multiGroupUpdate(updateGroups));
      dispatch(addNewUserSuccessAction(data));
    });
  };
};

/**
 * Redux action to delete user
 *
 * @param user: User object
 * @return updated user data with id
 */
export const deleteUserAction = ({ user }: { user: User }) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(deleteUserActionType(user));
    userServices.deleteUser(user).then((data: any) => {
      dispatch(deleteUserSuccessAction(user));
    });
  };
};

/**
 * Redux action to fetch all the users
 *
 * @return return all the system users
 */
export const fetchAllTheUsers = () => {
  return function (dispatch: (arg0: any) => void) {
    userServices.fetchAllUsers().then((data: any) => {
      dispatch(fetchAllUsersSuccessAction(data?._embedded?.users ?? [])); // action.payload._embedded.devices
    });
  };
};

/**
 * Redux action to reset pre-loaders
 *
 * @return update user action preloader
 */
export const resetUserPreloaders = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetUserPreloadersActionType());
  };
};

/**
 * Redux action to reset pre-loaders
 *
 * @return update user action preloader
 */
export const deleteMultipleUsers = (users: User[]) => {
  const processed = [];
  const success: string[] = [];
  const failed: string[] = [];

  const dispatchSuccess = (dispatch: any) => {
    if (processed.length >= users.length && success.length > 0) {
      dispatch(deleteMultiUserSuccessAction(success));
    }
  };
  return async function (dispatch: (arg0: any) => void) {
    for (let i = 0; i < users.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await userServices
        .deleteUser(users[i])
        .then((data: any) => {
          processed.push(users[i].id);
          success.push(users[i].id);
          dispatchSuccess(dispatch);
        })
        .catch((error: any) => {
          processed.push(users[i].id);
          failed.push(users[i].id);
          dispatchSuccess(dispatch);
        });
    }
  };
};
