/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import React from "react";

/*
 *  Shared Component: Highlighted
 *  @description: This component will recieve a text and highlighter query, and it wil
 *  hightlight the highligher inside the text
 *
 *  @param text: display text to highlight
 *  @param highlight : highlighter query
 */

const escape = document.createElement("textarea");
function escapeHTML(html) {
  escape.textContent = html;
  return escape.innerHTML;
}
function Highlighted({ text = "", highlight = "" }) {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const query = highlight.replace(/(?=[() ])/g, "\\");
  const regex = new RegExp(`(${escapeHTML(query)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span
              style={{ textDecoration: "underline", fontWeight: "bold" }}
              key={i}
            >
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
}

Highlighted.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string
};
export default Highlighted;
