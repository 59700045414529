import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import { SortTypes } from "constants/app.enum";
import { User } from "interfaces/user.interface";
import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  curSortOrder: "ASC" | "DESC";
  curSortField: SortTypes;
  onClick: (type: SortTypes) => void;
  loginedUser: User;
  allSelected: boolean;
  onToggleSelectAll: (value: boolean) => void;
  isAdmin: boolean;
}
function EmployeesListHeader({
  curSortOrder,
  curSortField,
  loginedUser,
  onClick,
  allSelected,
  onToggleSelectAll,
  isAdmin
}: Props): JSX.Element {
  const classes = CommonStyles();
  const { t } = useTranslation();

  const getSortOrder = (type: SortTypes) => {
    if (type === curSortField && curSortOrder === "ASC") {
      return "ASC";
    }
    if (type === curSortField && curSortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  return (
    <header className={classes.header} aria-hidden>
      {isAdmin ? (
        <div className={`${classes.checkboxlistWraper}`}>
          <FormControlLabel
            value="view_monitor"
            control={<Checkbox color="default" />}
            label=""
            labelPlacement="end"
            checked={allSelected}
            onChange={(event, value) => {
              onToggleSelectAll(value);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        aria-hidden
        onClick={() => onClick(SortTypes?.UserNameSort)}
        className={classes.colHeader}
      >
        <span>{t("Full Name")}</span>{" "}
        <IconSort activeSortOrder={getSortOrder(SortTypes?.UserNameSort)} />
      </div>
      <div className={classes.colHeader}>
        {" "}
        <span>{t("Email Address")}</span>
      </div>

      <div className={classes.colHeader} onClick={() => null} aria-hidden>
        <span>{t("Belongs to Employee Groups")}</span>
      </div>
      <div
        className={classes.colHeader}
        onClick={() => null}
        aria-hidden
        style={{ textAlign: "center" }}
      >
        <span style={{ float: "none" }}>{t("Admin Permission")}</span>
      </div>
      <div className={`${classes.colHeader}`}>
        <span>{t("Access")}</span>
      </div>
      {isAdmin ? (
        <div className={`${classes.colHeader} ${classes.txtCenter}`}>
          <span className={classes.floatNone}>{t("Actions")}</span>
        </div>
      ) : (
        <></>
      )}
    </header>
  );
}

export default EmployeesListHeader;
