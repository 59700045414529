import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  root: {
    height: "1vh",
    width: "1vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const Loading = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object
};
export default withStyles(styles)(Loading);
