import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import ResizeDetector from "react-resize-detector";
import Typography from "@material-ui/core/Typography/Typography";
import highchartsMore from "highcharts/highcharts-more";
import moment from "moment";
import solidGauge from "highcharts/modules/solid-gauge";

highchartsMore(Highcharts);
solidGauge(Highcharts);

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    const { size, time, title } = this.props;
    const options = getPlotOptions(size, toTimeObject(time));
    return time ? (
      <ResizeDetector
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <div>
          <Typography align="center">{title}</Typography>
          <HighchartsReact
            ref={this.chartRef}
            highcharts={Highcharts}
            constructorType="chart"
            options={options}
          />
          <Typography variant="h4" align="center">
            {moment.utc(time).format("HH:mm")}
          </Typography>
        </div>
      </ResizeDetector>
    ) : null;
  }
}

const toTimeObject = (time) => ({
  hours: time.getUTCHours(),
  minutes: time.getUTCMinutes(),
  seconds: time.getUTCSeconds()
});

const getPlotOptions = (height, clockTime) => {
  const clockHours = clockTime.hours + clockTime.minutes / 60;
  const clockMinutes =
    (clockTime.minutes * 12) / 60 + (clockTime.seconds * 12) / 3600;

  return {
    chart: {
      type: "gauge",
      backgroundColor: "transparent",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height
    },

    credits: {
      enabled: false
    },

    title: {
      text: null
    },

    pane: {
      size: "110%"
    },

    yAxis: {
      labels: {
        distance: -15,
        style: {
          color: "#aaa"
        }
      },
      min: 0,
      max: 12,
      lineWidth: 0,
      showFirstLabel: false,

      minorTickInterval: "auto",
      minorTickWidth: 1,
      minorTickLength: 2,
      minorTickPosition: "inside",
      minorGridLineWidth: 0,
      minorTickColor: "#777",

      tickInterval: 1,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 6,
      tickColor: "#666",
      title: null
    },

    tooltip: {
      enabled: false
    },

    series: [
      {
        data: [
          {
            id: "hour",
            y: clockHours,
            dial: {
              radius: "60%",
              baseWidth: 4,
              baseLength: "95%",
              rearLength: 0
            }
          },
          {
            id: "minute",
            y: clockMinutes,
            dial: {
              baseLength: "95%",
              rearLength: 0
            }
          }
        ],
        animation: false,
        dataLabels: {
          enabled: false
        }
      }
    ]
  };
};

Clock.propTypes = {
  size: PropTypes.any,
  time: PropTypes.any,
  title: PropTypes.string
};
export default Clock;
