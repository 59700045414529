import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import { SortTypes } from "constants/app.enum";
import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  curSortOrder: "ASC" | "DESC";
  curSortField: SortTypes;
  onClick: (type: SortTypes) => void;
  allSelected: boolean;
  onToggleSelectAll: (value: boolean) => void;
}
function DeviceListHeader({
  curSortOrder,
  curSortField,
  onClick,
  allSelected,
  onToggleSelectAll
}: Props): JSX.Element {
  const classes = CommonStyles();
  const { t } = useTranslation();

  const getSortOrder = (type: SortTypes) => {
    if (type === curSortField && curSortOrder === "ASC") {
      return "ASC";
    }
    if (type === curSortField && curSortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  return (
    <header className={classes.header} aria-hidden>
      <div className={`${classes.checkboxlistWraper}`}>
        <FormControlLabel
          value="view_monitor"
          control={<Checkbox color="default" />}
          label=""
          labelPlacement="end"
          checked={allSelected}
          onChange={(event, value) => {
            onToggleSelectAll(value);
          }}
        />
      </div>
      <div
        className={classes.colHeader}
        onClick={() => onClick(SortTypes?.DeviceSort)}
        aria-hidden
      >
        <span> {t("Device")} </span>{" "}
        <IconSort activeSortOrder={getSortOrder(SortTypes?.DeviceSort)} />
      </div>
      <div
        className={classes.colHeader}
        onClick={() => onClick(SortTypes?.SerialNumber)}
        aria-hidden
      >
        <span>{t("Serial Number")}</span>
        <IconSort activeSortOrder={getSortOrder(SortTypes?.SerialNumber)} />
      </div>
      <div
        className={classes.colLargeHeader}
        onClick={() => onClick(SortTypes?.UserSort)}
        aria-hidden
      >
        <span> {t("User")} </span>{" "}
        <IconSort activeSortOrder={getSortOrder(SortTypes?.UserSort)} />
      </div>
      <div
        className={classes.colHeader}
        onClick={() => onClick(SortTypes?.StatusSort)}
        aria-hidden
      >
        <span> {t("Status")} </span>{" "}
        <IconSort activeSortOrder={getSortOrder(SortTypes?.StatusSort)} />
      </div>
      <div className={classes.colHeader}>
        <span> {t("Distance")} </span>
      </div>
      <div className={classes.colHeader}>
        <span>{t("Disable Sleep Tracking")}</span>
      </div>
      <div className={`${classes.colHeader} ${classes.txtCenter}`}>
        <span className={classes.floatNone}>{t("Actions")}</span>
      </div>
    </header>
  );
}

export default DeviceListHeader;
