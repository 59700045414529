import * as PropTypes from "prop-types";
import React from "react";

import SleepHistoryStagesComponent from "./SleepHistoryStages";

// class SleepHistoryStages extends React.Component {
//     render() {
//         return this.props.options && this.props.options.plotData ? (
//             <SleepHistoryStagesComponent {...this.props} />
//         ) : null;
//     }
// }

function SleepHistoryStages(props) {
  return props.options && props.options.plotData ? (
    <SleepHistoryStagesComponent {...props} />
  ) : null;
}

SleepHistoryStages.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    onChartMouseOver: PropTypes.func,
    onChartClick: PropTypes.func,
    plotData: PropTypes.shape({
      series: PropTypes.object,
      threshold: PropTypes.object
    }).isRequired
  })
};

export default SleepHistoryStages;
