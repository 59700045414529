import { useSelector } from "react-redux";
import React from "react";

import { User } from "interfaces/user.interface";
import { isAdminUser } from "utils/userManagement";
import RouteTabs from "components/RouteTabs/RouteTabs";

import {
  notificationRouteNames as routeLabel,
  notificationRoutes as routes
} from "./notification.routes";

function NotificationContainer() {
  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });
  return (
    <RouteTabs
      routes={routes}
      routeLabel={routeLabel}
      isAdmin={isAdminUser(loginedUser)}
    />
  );
}
export default NotificationContainer;
