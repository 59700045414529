import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { UserPrivileges } from "constants/userContstants";
import { equalCustomizerForUser } from "utils/app.util";
import {
  fetchAllTheUsers,
  resetUserPreloaders
} from "store/actions/userActions";
import {
  ignoreParentUser,
  isAdminUser,
  privillagesCheckBoxChecked,
  validateEmployeeData
} from "utils/userManagement";
import { toastError, toastSucess } from "utils/toast.util";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import GreyContainer from "components/GreyContainer/GreyContainer";
import InfoBox from "components/InfoBox/InfoBox";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMViewUsersList from "components/SMViewUsersList/SMViewUsersList";
import SaveCancelDeleteButtons from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

import EmailAndPasswordField from "./EmailAndPasswordField";

export const useStyles = makeStyles((theme: any) => ({
  left: {
    float: "left",
    width: "50%",
    maxWidth: 600
  },
  radioGroup: {
    flexDirection: "row"
  },
  changePassword: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    cursor: "pointer"
  },
  passwordWrapper: {
    transitionDuration: ".2s, .2s, .35s",
    transitionProperty: "top, bottom, width",
    transitionTimingFunction: "linear, linear, ease"
  },
  passwordInputWrapper: {
    width: 254
  },
  actionBtn: {
    float: "right",
    marginRight: 12
  },
  actionBtnWrapper: {
    //  marginLeft:275
    marginRight: 50,
    float: "right"
  },
  checkboxWrapper: {
    marginTop: 16
  },
  showAdvanced: {
    fontSize: 16,
    marginTop: 32,
    color: theme.palette.primary.dark,
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: "normal"
  }
}));

interface Props {
  user: User;
  loginedUser: User;
  deviceUsers: User[];
  usersEntity: { [key: string]: User };
  groupEntity: { [key: string]: Group };
  onCreate: (user: User) => void;
  onDeleteUser: () => void;
  onUpdate: (user: User) => void;
  onChangePassword: (user: User, password: string) => void;
  onBack: () => void;
}

function EmployeeInfo({
  user,
  loginedUser,
  deviceUsers,
  usersEntity,
  groupEntity,
  onCreate,
  onDeleteUser,
  onUpdate,
  onChangePassword,
  onBack
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [passwords, setPasswords] = useState<{ new: string; confirm: string }>({
    new: "",
    confirm: ""
  });

  const [employee, setEmployee] = useState<User>(_.cloneDeep(user));
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [advanced, setAdvanced] = useState<boolean>(false);

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  useEffect(() => {
    if (groupState.currentAction === GROUP_ACTIONS.createEmployeeGroupSuccess) {
      if (groupState.newItem && groupState.newItem.id) {
        const grps = employee.user_groups || [];
        grps.push(groupState.newItem.id);
        onChange(grps, "user_groups");
      }
    }
  }, [groupState]);

  useEffect(() => {
    if (userState.currentAction === USER_ACTIONS.createEmployeeSuccess) {
      toastSucess(t("Employee added successfully"));
      resetEmployeeObject();
      dispatch(resetUserPreloaders());
      dispatch(fetchAllTheUsers());
      onBack();
    }

    if (userState.currentAction === USER_ACTIONS.createSuccess) {
      if (userState.newUser && userState.newUser.id) {
        const subList = employee.view_users || [];
        subList.push(userState.newUser.id);
        onChange(subList, "view_users");
      }
    }

    if (userState.currentAction === USER_ACTIONS.updateEmployeeSuccess) {
      toastSucess(t("Employee updated successfully"));
      dispatch(resetUserPreloaders());
      onBack();
    }
  }, [userState]);

  const onChange = (
    value:
      | string
      | { user_type?: string; privileges?: string[] }
      | string[]
      | boolean,
    key: string
  ) => {
    let updatedUser: User = {
      ...employee,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;

    if (key === "act_as_root_user") {
      const attributes = {
        ...(updatedUser.attributes || {}),
        privileges: value
          ? [UserPrivileges.view_dashbaord, UserPrivileges.view_monitor]
          : [UserPrivileges.view_monitor]
      };
      updatedUser = {
        ...updatedUser,
        attributes
      };
    }
    setEmployee(updatedUser);
  };

  const saveEmployee = () => {
    setErrorMessage("");
    const updatedEmployee = { ...employee };
    updatedEmployee.gender = "Male";
    updatedEmployee.birth_year = new Date().getFullYear();

    const error = validateEmployeeData(updatedEmployee);
    setErrorMessage(error);

    if (error) {
      toastError(error);
      return;
    }

    if (updatedEmployee.id) {
      onUpdate(updatedEmployee);
    } else if (!passwords.new || !passwords.confirm) {
      setErrorMessage(t("Please enter password"));
      // show toast
      toastError(t("Please enter password"));
    } else if (passwords.new === passwords.confirm) {
      onCreate({ ...updatedEmployee, password: passwords.new });
    } else {
      setErrorMessage(t("Password should match"));

      // show toast
      toastError(t("Password should match"));
    }
  };

  const resetEmployeeObject = () => {
    setEmployee(_.cloneDeep(user));
  };

  return (
    <GreyContainer title={employee.display_name}>
      <InfoBox>
        <div className={classes.left}>
          <DeviceInfoText
            text={`${t("First Name")}*`}
            field={
              <div>
                <SMTextField
                  onSave={(val: string) => {
                    onChange(val, "first_name");
                  }}
                  value={employee.first_name}
                  delay={0}
                  borderRed={(errorMessage && !employee.first_name) || false}
                  placeholder={t("e.g. Kari")}
                />
              </div>
            }
          />

          <DeviceInfoText
            text={`${t("Last Name")}`}
            field={
              <div>
                <SMTextField
                  onSave={(val: string) => {
                    onChange(val, "last_name");
                  }}
                  value={employee.last_name}
                  delay={0}
                  placeholder={t("Nordmann")}
                />
              </div>
            }
          />

          {/* <DeviceInfoText
            text={`${t("Email")}*`}
            field={
              <div>
                <SMTextField
                  onSave={(val: string) => {
                    onChange(val, "email");
                  }}
                  value={employee.email}
                  delay={0}
                  borderRed={(errorMessage && !employee.email) || false}
                  placeholder={t("e.g. Kari.Nordmann@norge.kommune.no")}
                />
              </div>
            }
          /> */}

          <EmailAndPasswordField
            user={employee}
            errorMessage={errorMessage}
            onChange={onChange}
            onChangePassword={onChangePassword}
            setErrorMessage={setErrorMessage}
            setPasswords={setPasswords}
          />
        </div>

        {employee.parent_id || employee.id === "" ? (
          <div className={classes.left}>
            <DeviceInfoText
              text={`${t("Admin Permission")}`}
              labelWidth={183}
              field={
                <div>
                  <RadioGroup
                    value={employee.act_as_root_user ? "Yes" : "No"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(event.target.value === "Yes", "act_as_root_user")
                    }
                    classes={{ root: classes.radioGroup }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label={t("Yes")}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label={t("No")}
                    />
                  </RadioGroup>
                </div>
              }
            />

            {!employee.act_as_root_user ? (
              <DeviceInfoText
                text={`${t("Granted Access")}`}
                labelPositionTop
                labelWidth={183}
                field={
                  <div>
                    <div>
                      <FormControlLabel
                        value="view_monitor"
                        control={<Checkbox color="default" />}
                        label={t("Monitor")}
                        labelPlacement="end"
                        checked={employee.attributes?.privileges?.includes(
                          UserPrivileges.view_monitor
                        )}
                        onChange={(event, value) => {
                          const attributes = privillagesCheckBoxChecked(
                            employee,
                            value,
                            UserPrivileges.view_monitor
                          );
                          onChange(attributes, "attributes");
                        }}
                      />
                      <div className={classes.checkboxWrapper}>
                        <FormControlLabel
                          value="view_dashboard"
                          control={<Checkbox color="default" />}
                          label={t("Sleep records")}
                          labelPlacement="end"
                          checked={employee.attributes?.privileges?.includes(
                            UserPrivileges.view_dashbaord
                          )}
                          onChange={(event, value) => {
                            const attributes = privillagesCheckBoxChecked(
                              employee,
                              value,
                              UserPrivileges.view_dashbaord
                            );
                            onChange(attributes, "attributes");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </InfoBox>

      <InfoBox>
        <SMGroupLists
          label={t("Belongs to Employee Groups")}
          defaultValues={employee.user_groups || []}
          lists={Object.values(groupEntity).filter(
            (group: Group) => group.type === UserGroupType.viewer
          )}
          onChange={onChange}
          searchPlaceholder={t("Search")}
          disabled={!employee.parent_id && employee.id !== ""}
          userGroupType={UserGroupType.viewer}
        />

        {!advanced ? (
          <Button
            onClick={() => setAdvanced(true)}
            className={classes.showAdvanced}
          >
            {t("Show Advanced Permissions")}
          </Button>
        ) : (
          ""
        )}

        {advanced ? (
          <div>
            <SMViewUsersList
              label={t("Can View Users")}
              lists={ignoreParentUser(loginedUser, deviceUsers)}
              onChange={onChange}
              searchPlaceholder={t("Search")}
              defaultValues={employee?.view_users}
              disabled={!employee.parent_id && employee.id !== ""}
              userGroupType={UserGroupType.device}
            />
            <Button
              onClick={() => setAdvanced(false)}
              className={classes.showAdvanced}
            >
              {t("Hide Advanced Permissions")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </InfoBox>

      <SaveCancelDeleteButtons
        txtSave={employee.id ? t("Save") : t("Add Employee")}
        txtCancel={t("Cancel")}
        txtDelete={t("Delete this employee")}
        onCancel={resetEmployeeObject}
        onSave={saveEmployee}
        onDelete={() => (employee.id ? onDeleteUser() : null)}
        hideDelete={!isAdminUser(loginedUser) || !employee.id}
        disabledSaveButton={_.isEqual(
          _.omit(user, ["display_name"]),
          _.omit(employee, ["display_name"])
        )}
      />
      <div style={{ clear: "both" }} />
    </GreyContainer>
  );
}
export default EmployeeInfo;
