import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";

import DialogBox from "./DialogBox";

export const useStyles = makeStyles((theme: any) => ({
  dialogLabel: {
    fontSize: 16,
    color: theme.palette.primary.red,
    fontWeight: 500,
    textDecoration: "none",
    textTransform: "none"
  }
}));

function SMDeleteDialog({
  buttonLabel,
  onDelete,
  deleteConfirmText,
  deleteConfirmTitle
}: {
  buttonLabel: string;
  onDelete: () => void;
  deleteConfirmText?: string;
  deleteConfirmTitle?: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} className={classes.dialogLabel}>
        {buttonLabel}
      </Button>
      <DialogBox
        open={open}
        setOpen={setOpen}
        title={deleteConfirmTitle || t("Delete users")}
        dialogDesc={
          deleteConfirmText ||
          t(
            "Deleting this employee will remove all data associated with this employee. This action is non-reversable"
          )
        }
        onDelete={onDelete}
        confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
        confirmPlaceHolder={t("Key in confirmation text here")}
        confirmText={t("I AM SURE")}
        buttonOk={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </div>
  );
}

export default SMDeleteDialog;
