import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

function BulkActionCheckBox({
  wrapperClassName,
  selected,
  setSelected,
  id
}: {
  wrapperClassName: string;
  selected: { [key: string]: boolean };
  setSelected: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  id: string;
}) {
  return (
    <div className={wrapperClassName} data-ref="preventParentClick">
      <FormControlLabel
        value="select"
        control={<Checkbox color="default" />}
        label=""
        labelPlacement="end"
        checked={!!selected[id]}
        onChange={(e, value) => {
          setSelected({ ...selected, [id]: value });
        }}
      />
    </div>
  );
}
export default BulkActionCheckBox;
