import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { User } from "interfaces/user.interface";
import {
  generateAutoFillEmail,
  generateAutoFillPassword
} from "utils/userManagement";
import { toastError } from "utils/toast.util";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import PasswordTextField from "components/PasswordTexField/PasswordTextField";
import SMButton from "components/SMButton/SMButton";
import SMTextField from "components/SMTextField/SMTextField";

export const useStyles = makeStyles((theme: any) => ({
  changePassword: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    cursor: "pointer"
  },
  passwordWrapper: {
    transitionDuration: ".2s, .2s, .35s",
    transitionProperty: "top, bottom, width",
    transitionTimingFunction: "linear, linear, ease"
  },
  passwordInputWrapper: {
    width: 254
  },
  actionBtnWrapper: {
    //  marginLeft:275
    marginRight: 82,
    float: "right"
  },
  actionBtn: {
    float: "right",
    marginRight: 12
  },
  emailInfo: {
    width: 230,
    fontSize: 12,
    marginTop: 5
  }
}));

interface Props {
  errorMessage: string;
  user: User;
  onChange: (value: string, key: string) => void;
  onChangePassword: (user: User, password: string) => void;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setPasswords: React.Dispatch<
    React.SetStateAction<{
      new: string;
      confirm: string;
    }>
  >;
  autofill?: boolean;
  enable_email?: boolean;
  enable_password?: boolean;
  loginedUser?: User;
}

function EmailAndPasswordField({
  user,
  onChange,
  errorMessage,
  onChangePassword,
  setErrorMessage,
  setPasswords,
  autofill = false,
  enable_email = true,
  enable_password = true,
  loginedUser
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [newPassword, setnewPassword] = useState<string>();
  const [toggleChangePassword, setToggleChangePassword] =
    useState<boolean>(false);

  useEffect(() => {
    if (autofill && !user.id) {
      const pass = generateAutoFillPassword(8);
      setnewPassword(pass);
      setConfirmPassword(pass);
      if (loginedUser) {
        onChange(generateAutoFillEmail(user, loginedUser), "email");
      }
    }
  }, []);

  useEffect(() => {
    setPasswords({
      new: newPassword || "",
      confirm: confirmPassword || ""
    });
  }, [confirmPassword, newPassword]);

  const showPasswordError = (password?: string) => {
    return (
      (errorMessage && (!password || confirmPassword !== newPassword)) || false
    );
  };

  const changePassword = () => {
    if (!newPassword || !confirmPassword) {
      setErrorMessage(t("Please enter password"));
      // show toast
      toastError(t("Please enter password"));
    } else if (newPassword === confirmPassword) {
      onChangePassword(user, newPassword);
      setToggleChangePassword(false);
    } else {
      setErrorMessage(t("Password should match"));
      toastError(t("Password should match"));
    }
  };

  return (
    <div>
      {enable_email && (
        <DeviceInfoText
          text={`${t("Email")}${autofill ? "" : "*"}`}
          field={
            <div>
              <SMTextField
                onSave={(val: string) => {
                  onChange(val, "email");
                }}
                value={user.email}
                delay={0}
                borderRed={(errorMessage && !user.email) || false}
                placeholder={t("e.g. Kari.Nordmann@norge.kommune.no")}
              />
              {autofill ? (
                <div className={classes.emailInfo}>
                  {t(
                    "This field will be auto generated if you leave this field blank"
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          }
        />
      )}
      {enable_password ? (
        <>
          {user.id && !toggleChangePassword && (
            <DeviceInfoText
              field={
                <div className={classes.changePassword}>
                  <span
                    aria-hidden
                    onClick={() => setToggleChangePassword(true)}
                  >
                    {t("Change Password")}
                  </span>
                </div>
              }
            />
          )}

          {(!user.id || toggleChangePassword) && (
            <div className={classes.passwordWrapper}>
              <DeviceInfoText
                text={`${autofill ? t("Password") : t("New password")}${
                  autofill ? "" : "*"
                }`}
                field={
                  <div className={classes.passwordInputWrapper}>
                    <PasswordTextField
                      value={newPassword}
                      autofill
                      placeholder={t("Password")}
                      label={t("Password")}
                      onChange={(val: string) => setnewPassword(val)}
                      borderRed={showPasswordError(newPassword)}
                    />
                  </div>
                }
              />

              {!autofill || toggleChangePassword ? (
                <DeviceInfoText
                  text={`${t("Confirm Password")}*`}
                  field={
                    <div className={classes.passwordInputWrapper}>
                      <PasswordTextField
                        autofill
                        value={confirmPassword}
                        placeholder={t("Password")}
                        label={t("Password")}
                        onChange={(val: string) => setConfirmPassword(val)}
                        borderRed={showPasswordError(confirmPassword) || false}
                      />
                    </div>
                  }
                />
              ) : (
                ""
              )}
            </div>
          )}

          {user.id && toggleChangePassword && (
            <div className={classes.actionBtnWrapper}>
              <div className={classes.actionBtn}>
                <SMButton
                  outlineBtn
                  text={t("Cancel")}
                  onClick={() => setToggleChangePassword(false)}
                />
              </div>
              <div className={classes.actionBtn}>
                <SMButton text={t("Save")} onClick={changePassword} />
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default EmailAndPasswordField;
