import { makeStyles } from "@material-ui/core/styles";
import MuiCardActions from "@material-ui/core/CardActions";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    padding: "8px 10px"
  }
}));
function CardActions(props) {
  const classes = useStyles();
  return <MuiCardActions classes={{ root: classes.root }} {...props} />;
}
CardActions.propTypes = MuiCardActions.propTypes;

export default CardActions;
