import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes, UserPrivileges } from "constants/userContstants";
import { UserGroupType } from "constants/userGroupConstants";
import {
  createNewSubUser,
  fetchAllTheUsers,
  resetUserPreloaders
} from "store/actions/userActions";
import { getAllUserGroups } from "store/actions/groupActions";
import {
  getUserBaseObject,
  ignoreParentUser,
  privillagesCheckBoxChecked,
  validateEmployeeData
} from "utils/userManagement";
import { normalizedGroupsEntity } from "utils/groupManagement";
import { toastError, toastSucess } from "utils/toast.util";
import PasswordTextField from "components/PasswordTexField/PasswordTextField";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMViewUsersList from "components/SMViewUsersList/SMViewUsersList";
import SaveCancelDeleteButton from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "32px 37px 32px 32px",
    width: 551,
    color: theme.palette.primary.listHeader
  },
  content: {},
  title: {
    fontSize: 24,
    marginBottom: 24,
    float: "left"
  },
  titleWrapper: {
    width: "100%",
    float: "left"
  },
  close: {
    float: "right",
    cursor: "pointer"
  },
  formFieldWrapper: {
    marginTop: 32,
    width: "100%",
    float: "left"
  },
  label: {
    fontSize: 16,
    width: "100%",
    float: "left"
  },
  radioGroup: {
    flexDirection: "row",
    float: "left"
  },
  radio: {
    marginRight: 34
  },
  formElem: {
    float: "left",
    width: 377
  },
  footerWrapper: {
    marginTop: 24,
    float: "left"
  },
  accessWrapper: {
    marginTop: 64
  },
  viewerAccessWrapper: {
    marginTop: 25,
    width: "100%",
    float: "left"
  },
  viewerAccessItem: {
    marginTop: 16,
    float: "left",
    width: "100%"
  },
  showAdvanced: {
    fontSize: 16,
    marginTop: 32,
    color: theme.palette.primary.dark,
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: "normal"
  }
}));
function AddEmployeeDrawer({
  onclose
}: {
  onclose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [employee, setEmployee] = useState<User>(
    _.cloneDeep(getUserBaseObject(UserAttributesTypes.viewer))
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [newPassword, setnewPassword] = useState<string>();
  const [deviceUsers, setDeviceUsers] = useState<User[]>([]);

  const [advanced, setAdvanced] = useState<boolean>(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, [dispatch]);

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  useEffect(() => {
    const dvcUsers = userState.users.filter((user: User) => {
      return user.attributes?.user_type !== UserAttributesTypes.viewer;
    });
    // set device users
    setDeviceUsers(dvcUsers);

    if (userState.currentAction === USER_ACTIONS.createEmployeeSuccess) {
      toastSucess(t("Employee added successfully"));
      restUser();
      dispatch(resetUserPreloaders());
      dispatch(fetchAllTheUsers());
    }
  }, [userState]);

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  const restUser = () => {
    setEmployee(_.cloneDeep(getUserBaseObject(UserAttributesTypes.viewer)));
    onclose(false);
  };

  const validateDateAndSave = () => {
    setErrorMessage("");

    const updatedEmployee = _.cloneDeep(employee);
    updatedEmployee.gender = "Male";
    updatedEmployee.birth_year = new Date().getFullYear();

    const error = validateEmployeeData(updatedEmployee);
    setErrorMessage(error);

    if (error) {
      toastError(t(error));
      return;
    }

    if (!newPassword || !confirmPassword) {
      setErrorMessage(t("Please enter password"));
      // show toast
      toastError(t("Please enter password"));
    } else if (newPassword === confirmPassword) {
      updatedEmployee.password = newPassword;
      dispatch(createNewSubUser({ user: updatedEmployee, groupEntity }));
    } else {
      setErrorMessage(t("Password should match"));

      // show toast
      toastError(t("Password should match"));
    }
  };

  const onChange = (value: any, key: string) => {
    let updatedUser: User = {
      ...employee,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;

    if (key === "act_as_root_user") {
      const attributes = {
        ...(updatedUser.attributes || {}),
        privileges: [UserPrivileges.view_dashbaord, UserPrivileges.view_monitor]
      };
      updatedUser = {
        ...updatedUser,
        attributes
      };
    }
    setEmployee(updatedUser);
  };

  const showPasswordError = (password?: string) => {
    return (
      (errorMessage && (!password || confirmPassword !== newPassword)) || false
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>{t("Add Employee")}</div>
        <div className={classes.close} onClick={() => restUser()} aria-hidden>
          <CloseIcon />
        </div>
      </div>
      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("First Name")}*`}</div>
        <SMTextField
          placeholder={t("e.g. Kari")}
          onSave={(val: string) => {
            onChange(val, "first_name");
          }}
          value={employee.first_name}
          delay={0}
          borderRed={(errorMessage && !employee.first_name) || false}
          width={343}
        />
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Last Name")}`}</div>
        <SMTextField
          placeholder={t("Nordmann")}
          onSave={(val: string) => {
            onChange(val, "last_name");
          }}
          value={employee.last_name}
          delay={0}
          borderRed={(errorMessage && !employee.last_name) || false}
          width={343}
        />
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Email")}`}</div>
        <div className={classes.formElem}>
          <SMTextField
            onSave={(val: string) => {
              onChange(val, "email");
            }}
            value={employee.email}
            delay={0}
            borderRed={(errorMessage && !employee.email) || false}
            placeholder={t("e.g. Kari.Nordmann@norge.kommune.no")}
            width={343}
          />
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("New password")}`}</div>
        <div className={classes.formElem}>
          <PasswordTextField
            placeholder={t("Password")}
            label={t("Password")}
            onChange={(val: string) => setnewPassword(val)}
            borderRed={showPasswordError(newPassword)}
          />
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Confirm Password")}`}</div>
        <div className={classes.formElem}>
          <PasswordTextField
            placeholder={t("Password")}
            label={t("Password")}
            onChange={(val: string) => setConfirmPassword(val)}
            borderRed={showPasswordError(confirmPassword) || false}
          />
        </div>
      </div>

      <div className={`${classes.formFieldWrapper} ${classes.accessWrapper}`}>
        <div className={classes.label}>{`${t("Admin Permission")}`}</div>
        <div>
          <RadioGroup
            value={employee.act_as_root_user ? "Yes" : "No"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value === "Yes", "act_as_root_user")
            }
            classes={{ root: classes.radioGroup }}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label={t("Yes")}
            />
            <FormControlLabel value="No" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </div>

        {!employee.act_as_root_user ? (
          <div className={classes.viewerAccessWrapper}>
            <div className={classes.label}>{`${t("Granted Access")}`}</div>
            <div className={classes.viewerAccessItem}>
              <FormControlLabel
                value="view_monitor"
                control={<Checkbox color="default" />}
                label={t("Monitor")}
                labelPlacement="end"
                checked={employee.attributes?.privileges?.includes(
                  UserPrivileges.view_monitor
                )}
                onChange={(event, value) => {
                  const attributes = privillagesCheckBoxChecked(
                    employee,
                    value,
                    UserPrivileges.view_monitor
                  );
                  onChange(attributes, "attributes");
                }}
              />
            </div>
            <div className={classes.viewerAccessItem}>
              <FormControlLabel
                value="view_dashboard"
                control={<Checkbox color="default" />}
                label={t("Sleep records")}
                labelPlacement="end"
                checked={employee.attributes?.privileges?.includes(
                  UserPrivileges.view_dashbaord
                )}
                onChange={(event, value) => {
                  const attributes = privillagesCheckBoxChecked(
                    employee,
                    value,
                    UserPrivileges.view_dashbaord
                  );
                  onChange(attributes, "attributes");
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={`${classes.formFieldWrapper} ${classes.accessWrapper}`}>
        <div className={classes.label}>{`${t(
          "Belongs to Employee Groups"
        )}`}</div>
        <SMGroupLists
          label={t("Employee Groups")}
          defaultValues={employee.user_groups || []}
          lists={Object.values(groupEntity).filter(
            (group: Group) => group.type === UserGroupType.viewer
          )}
          onChange={onChange}
          searchPlaceholder={t("Search")}
          userGroupType={UserGroupType.viewer}
          showItems={6}
          inline
          hideLabel
          hideDrawer
        />
      </div>

      {!advanced ? (
        <Button
          onClick={() => setAdvanced(true)}
          className={classes.showAdvanced}
        >
          {t("Show Advanced Permissions")}
        </Button>
      ) : (
        ""
      )}

      {advanced ? (
        <div className={`${classes.formFieldWrapper} ${classes.accessWrapper}`}>
          <div className={classes.label}>{`${t("Can View Users")}`}</div>
          <SMViewUsersList
            label={t("Can View Users")}
            lists={ignoreParentUser(loginedUser, deviceUsers)}
            onChange={onChange}
            searchPlaceholder={t("Search")}
            defaultValues={employee?.view_users}
            userGroupType={UserGroupType.device}
            showItems={6}
            hideDrawer
            hideLabel
            inline
          />
          <Button
            onClick={() => setAdvanced(false)}
            className={classes.showAdvanced}
          >
            {t("Hide Advanced Permissions")}
          </Button>
        </div>
      ) : (
        ""
      )}

      <div className={classes.footerWrapper}>
        <SaveCancelDeleteButton
          txtSave={t("Add Employee")}
          txtCancel={t("Cancel")}
          txtDelete=""
          onCancel={restUser}
          onSave={validateDateAndSave}
          onDelete={() => {}}
          hideDelete
        />
      </div>
    </div>
  );
}
export default AddEmployeeDrawer;
