import _Card from "../components/Cards/Card";
import _CardActions from "../components/Cards/CardActions";
import _CardActionsAreas from "../components/Cards/CardActionsArea";
import _CardContent from "../components/Cards/CardContent";
/* Views */
/* ----- Import React components here ----- */
/* Somnofy components */
import _CardHeader from "../components/Cards/CardHeader";
import _CardMedia from "../components/Cards/CardMedia";
import _Dashboard from "../components/Dashboard";
import _Hypnogram from "../components/Hypnograms";
import _InformationTile from "../components/InformationTile";
import _Monitor from "../@modules/monitor/components/Monitor";
/* Common components */
import _Section from "../components/Sections/Section";
import _SectionContent from "../components/Sections/SectionContent";
import _SectionHeader from "../components/Sections/SectionHeader";
import _SessionDetails from "../components/SessionDetails";
import _SleepHistory from "../components/SleepHistory";
import _SleepHistoryStages from "../components/SleepHistoryStages";
import _SleepHistoryVitals from "../components/SleepHistoryVitals";
import _SleepScore from "../components/SleepScore";
import _SleepStages from "../components/SleepStages";
import _SleepStatistics from "../components/SleepStatistics";

/* ----- And export the component here ----- */

/* Somnofy components */

export const Hypnogram = _Hypnogram;
export const SessionDetails = _SessionDetails;
export const SleepHistoryStages = _SleepHistoryStages;
export const SleepHistoryVitals = _SleepHistoryVitals;
export const SleepScore = _SleepScore;
export const SleepStages = _SleepStages;
export const SleepStatistics = _SleepStatistics;
export const SleepHistory = _SleepHistory;
export const InformationTile = _InformationTile;
export const Monitor = _Monitor;
export const Dashboard = _Dashboard;

/* Common components */

export const Section = _Section;
export const SectionHeader = _SectionHeader;
export const SectionContent = _SectionContent;
export const Card = _Card;
export const CardContent = _CardContent;
export const CardHeader = _CardHeader;
export const CardMedia = _CardMedia;
export const CardActions = _CardActions;
export const CardActionsAreas = _CardActionsAreas;
