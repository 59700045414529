import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography/Typography";

import { useStyles } from "./loginform.styles";

function LoginForm({ inputFields, onSubmit, onChange, error }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={24}>
        <Typography variant="title" align="center">
          {t("Login to your Somnofy account")}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          {inputFields &&
            inputFields.map((field) => (
              <TextField
                className={classes.textField}
                required={field.required}
                key={field.name}
                fullwidth
                label={field.label || null}
                placeholder={field.placeholder}
                name={field.name}
                type={field.type || "text"}
                onChange={onChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {field.startAdornment}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {field.endAdornment}
                    </InputAdornment>
                  )
                }}
              />
            ))}
          <div className={classes.buttonContainer}>
            {/* <Button variant={"extendedFab"}>Cancel</Button> */}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={onSubmit}
              className={classes.button}
            >
              {t("Login")}
            </Button>
            <div className={classes.error}>{error}</div>
          </div>
        </form>
      </Paper>
    </div>
  );
}

LoginForm.propTypes = {
  inputFields: PropTypes.any,
  onSubmit: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any
};
export default LoginForm;
