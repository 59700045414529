import axios from "axios";

const URL = "/v1/devices";
const defaultQuery = { view_users_devices_hack: "true" };

const getDevices = (q = {}) => {
  const query = { ...defaultQuery, ...q };
  const info = { params: query };

  return axios.get(URL, info).then((res) => res.data._embedded.devices);
};

export default {
  getDevices
};
