import UserDevice from "../wrappers/UserDevice";
import UserDeviceSession from "../wrappers/UserDeviceSession";
import devices from "./devices";
import i18n from "../../../i18n/config";
import sessions from "./sessions";
import users from "./users";

const getUserDeviceSessions = (userDevices) => {
  return sessions.getLatestSessionsForUser().then((items) => {
    const userDeviceSessions = [];
    userDevices
      .sort((a, b) => {
        if (a.device.name > b.device.name) {
          return 1;
        }
        return a.device.name < b.device.name ? -1 : 0;
      })
      .forEach((userDevice) => {
        const session = items.find(
          (item) => item.user_id === userDevice.getUserId()
        );
        userDeviceSessions.push(
          new UserDeviceSession(userDevice.user, userDevice.device, session)
        );
      });
    return new Promise((resolve) => {
      resolve(userDeviceSessions);
    });
  });
};

const getDeviceUsers = () => {
  const promises = [
    devices.getDevices(),
    users.getUsers(),
    sessions.getLatestSessionsForUser()
  ];
  return Promise.all(promises).then((res) => {
    const deviceList = res[0];
    const usersList = res[1];
    const latestSessions = res[2];
    const userDevices = [];
    const latestSessionEntity = {};
    for (let i = 0; i < latestSessions.length; i += 1) {
      latestSessionEntity[latestSessions[i].device_serial_number] =
        latestSessions[i];
    }

    // sort based on the last activity
    deviceList.sort((a, b) => {
      return a.time_since_last_activity - b.time_since_last_activity;
    });

    usersList.forEach((user) => {
      const devicesConnected = deviceList.filter(
        (item) => item.user_id === user.id
      );

      if (devicesConnected.length !== 0) {
        if (devicesConnected.length > 1) {
          const deviceWithLatestSessionData = devicesConnected.find(
            (device) => latestSessionEntity[device.serial_number]
          );
          if (deviceWithLatestSessionData) {
            userDevices.push(new UserDevice(user, deviceWithLatestSessionData));
          }
        } else {
          userDevices.push(new UserDevice(user, devicesConnected[0]));
        }
      }
    });
    return new Promise((resolve) => {
      resolve(userDevices);
    });
  });
};

export default {
  getUserDeviceSessions,
  getDeviceUsers
};
