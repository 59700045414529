import PropTypes from "prop-types";
import React from "react";

import SleepHistoryVitalsChart from "./SleepHistoryVitalsChart";

const _emptyFunction = () => false;

const SleepHistoryVitalsComponent = (props) => {
  const { onChartMouseOver, onChartClick, plotData } = props.options;
  const { enableHeartRateMean } = props;
  return (
    <div>
      <SleepHistoryVitalsChart
        onChartMouseOver={onChartMouseOver || _emptyFunction}
        onChartClick={onChartClick || _emptyFunction}
        plotData={plotData}
        enableHeartRateMean={enableHeartRateMean}
      />
    </div>
  );
};

SleepHistoryVitalsComponent.propTypes = {
  options: PropTypes.any,
  enableHeartRateMean: PropTypes.string
};

export default SleepHistoryVitalsComponent;
