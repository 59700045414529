import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { SortTypes } from "constants/app.enum";
import {
  allChecked,
  sortDevices,
  toggleAllDevicesChecked,
  viewDeviceDetails
} from "utils/deviceManagement";
import {
  changeDeviceUser,
  deleteMultipleDevices,
  unRegisterDeviceAction
} from "store/actions/devicesActions";
import { updateDeviceSettings } from "store/actions/devicesSettingsActions";
import BulkActions from "components/BulkActions/BulkActions";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import SMPagination from "components/SMPagination/SMPagination";

import DeviceListHeader from "./DeviceListHeader";
import DeviceListRow from "./DeviceListRow";
import DeviceSearch from "./DeviceSearch";

interface DevicesListInterface {
  devices: Device[];
  users: any;
  routePath: string;
}

/**
 * @name DevicesList
 * @description This is wrapper component for the login form.
 * @returns LoginForm componet
 */
function DevicesList({
  devices,
  users,
  routePath
}: DevicesListInterface): JSX.Element {
  const common = CommonStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activePage, setCurrentPage] = useState(1);
  const [currentDevices, setCurrentDevices] = useState<Device[]>();
  const [sortedDevices, setSortedDevices] = useState<Device[]>();

  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [curSortField, setSortField] = useState<number>(2);
  const [filterType, setFilterType] = useState<"device" | "user">("device");
  const [searchQuery, setSearchQuery] = useState<string>();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteDevice, setDeleteDevice] = useState<Device>();
  const [editDevice, setEditDevice] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setSortedDevices([...devices]);
  }, [devices]);

  useEffect(() => {
    if (sortedDevices) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentDevices(sortedDevices.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [sortedDevices, activePage, itemsPerPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const sortList = (sortType: SortTypes): void => {
    if (!Number.isNaN(sortType)) {
      let order = "ASC";
      if (curSortField === sortType) {
        order = curSortOrder === "ASC" ? "DESC" : "ASC";
      }
      setSortField(sortType);
      setSortOrder(order as "ASC" | "DESC");
      const sortedArray = sortDevices(devices, order, sortType, users);
      setSortedDevices(sortedArray);
    }
  };

  const onChange = (q: string, sortType: any) => {
    if (!sortedDevices) {
      return;
    }
    setSearchQuery(q);
    setFilterType("device");
    let arrCopy: Device[] = [...devices];
    arrCopy = arrCopy.filter((item: any) => {
      return item.name.toLowerCase().includes(q.toLowerCase());
    });
    setCurrentPage(1);
    setSortedDevices([...arrCopy]);
  };

  const onToggleSelectAll = (checked: boolean) => {
    if (currentDevices) {
      setSelectedItems({
        ...selectedItems,
        ...toggleAllDevicesChecked(currentDevices, checked)
      });
    }
  };

  const handleClick = (key: string) => {
    if (key === "remove_selected") {
      setOpenDeleteDialog(true);
    }
  };

  const bulkDelete = () => {
    setCurrentPage(1);
    const devicesToDelete: Device[] = devices.filter((device: Device) => {
      return selectedItems[device.serial_number];
    });
    dispatch(deleteMultipleDevices(devicesToDelete));
    setSelectedItems({});
  };

  const ifBulkDelete = () => {
    const selected = devices?.filter((device: Device) => {
      return selectedItems[device.serial_number];
    });
    return selected?.length > 0;
  };

  const onSingleDelete = () => {
    if (deleteDevice) {
      dispatch(
        unRegisterDeviceAction({
          deviceSerialNumber: deleteDevice.serial_number
        })
      );
      setDeleteDevice(undefined);
    }
  };

  const saveDevice = ({
    deviceName,
    userID,
    deviceSerialNumber,
    settings
  }: {
    deviceName: string;
    userID: string;
    deviceSerialNumber: string;
    settings: DeviceSettings;
  }) => {
    if (deviceName && userID && settings) {
      dispatch(
        changeDeviceUser({
          name: deviceName,
          id: userID,
          deviceSerialNumber
        })
      );
      dispatch(updateDeviceSettings(settings, deviceSerialNumber));
    }
  };

  return (
    <div className={common.tabContentWrapper}>
      <div className={common.searchWrapper}>
        <DeviceSearch
          onChange={onChange}
          devicesPerPage={(val: number) => setItemsPerPage(val)}
          total={devices.length}
        />
      </div>

      <div className={common.bulkActionWrapper}>
        <BulkActions
          selectedItems={selectedItems}
          actions={[{ key: "remove_selected", label: t("Delete Selected") }]}
          handleClick={handleClick}
        />
      </div>

      <DeviceListHeader
        curSortOrder={curSortOrder}
        curSortField={curSortField}
        onClick={sortList}
        onToggleSelectAll={onToggleSelectAll}
        allSelected={allChecked(currentDevices || [], selectedItems)}
      />

      <div className={common.rowContainer}>
        {currentDevices &&
          currentDevices.map((device: Device, index) => {
            return (
              users[device.user_id] && (
                <DeviceListRow
                  key={device.serial_number}
                  device={device}
                  user={users[device.user_id]}
                  onClick={(event: any, devieObj: Device) => {
                    viewDeviceDetails(event, devieObj, history);
                  }}
                  filterType={filterType}
                  searchQuery={searchQuery}
                  t={t}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  setEditDevice={setEditDevice}
                  editDevice={editDevice}
                  onDelete={(deleteDvc: Device) => {
                    setDeleteDevice(deleteDevice);
                    setOpenDeleteDialog(true);
                  }}
                  saveDevice={saveDevice}
                />
              )
            );
          })}
      </div>
      {sortedDevices && sortedDevices.length > itemsPerPage && (
        <SMPagination
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          itemsLength={sortedDevices?.length}
          handlePageChange={handlePageChange}
        />
      )}

      {/* Delete dialog box */}
      <DialogBox
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={() => (ifBulkDelete() ? bulkDelete() : onSingleDelete())}
        title={t("Delete device (s)")}
        dialogDesc={t(
          "Deleting this device(s) will remove all data associated with this device(s). This action is non-reversable"
        )}
        confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
        confirmPlaceHolder={t("Key in confirmation text here")}
        confirmText={t("I AM SURE")}
        buttonOk={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </div>
  );
}
export default DevicesList;
