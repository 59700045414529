import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    padding: 16
  },
  fixedHeader: {
    position: "absolute",
    left: 0,
    right: 0,
    marginTop: theme.spacing(2)
  },
  main: {
    height: "100%"
  },
  contentWrapper: {
    overflow: "auto",
    height: "calc(100% - 124px)"
  },
  contentContainer: {
    width: "unset",
    margin: "unset"
  },
  headerTextTime: {
    fontWeight: "bold"
  },
  headerTextDate: {
    color: "#898989"
  },
  headerButton: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5
    },
    minWidth: 55,
    minHeight: 55,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  clock: {
    textAlign: "center"
  },
  refresh: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 20
  },
  flexGrow: {
    flex: 1
  },
  textRight: {
    textAlign: "right"
  },
  textLeft: {
    textAlign: "left"
  },
  header: {
    display: "flex",
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    padding: "0 6px"
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& > div": {
      marginBottom: theme.spacing(4)
    }
  },
  refreshButton: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5
    },
    marginLeft: "10px"
  },
  dashboardModal: {
    overflow: "auto"
  },
  dashboardModalContent: {
    margin: 38,
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    overflow: "auto",
    height: "calc(100vh - 76px)",
    overflowY: "auto",
    zIndex: 999
  },
  dashboardModalCloseIcon: {
    position: "absolute",
    right: 18,
    top: 18,
    backgroundColor: theme.palette.background.default,
    border: "1px solid rgba(0, 0, 0, 0.4)",
    borderRadius: 23,
    fontSize: 38,
    cursor: "pointer"
  },
  scaleSliderContainer: {
    width: "100%",
    justifyContent: "left",
    "& > *": {
      width: "160px !important",
      maxWidth: "60%"
    }
  },
  bottomInformation: {
    marginTop: theme.spacing(4)
  },
  slightlyVisible: {
    opacity: 60
  },
  hideBackground: {
    backgroundColor: "rgba(255, 255, 255, 0.1)"

    // opacity: 0.5,
  },
  scaledY: {
    transform: "scaleY(2)"
  },
  searchBox: {
    float: "left",
    width: "150px",
    marginLeft: "10px"
  },
  layoutIcon: {
    marginTop: "14px",
    color: "#898989",
    float: "left"
  },
  dayInfo: {
    textAlign: "center"
  },
  settingsIcon: {
    textAlign: "right"
  },
  sortWrapper: {
    width: "100%"
  },
  sortOptions: {
    float: "right",
    marginRight: "15px"
  }
}));
