import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import { changeUserPassword } from "store/actions/userActions";
import { getUserBaseObject } from "utils/userManagement";
import { isEmpty } from "utils/deviceManagement";

import UserInformation from "./components/UpdateUserInformation";

interface Props {
  usersEntity: { [key: string]: User } | undefined;
  routePath: string;
  id: string;
  groupEntity: { [key: string]: Group };
  onSaveAction: (user: User, currentUserGroupsIds: string[]) => void;
  loginedUser: User;
  onDelete: (updatedUser: User) => void;
}
function UsersView({
  routePath,
  usersEntity,
  id: currentUserID,
  groupEntity,
  onSaveAction,
  loginedUser,
  onDelete
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setCurrentUser] = useState<User>();
  const [currentUserGroupsIds, setCurrentUserGroups] = useState<string[]>([]);

  const onBack = () => {
    const path = `${routePath.replace("/:id", "")}`;
    history.push(path);
  };

  const onChangePassword = (currentUser: User, password: string) => {
    const subUser = loginedUser.id !== currentUser.id;
    dispatch(
      changeUserPassword({
        newPassword: password,
        user: currentUser,
        subUser
      })
    );
  };

  useEffect(() => {
    if (
      currentUserID !== "0" &&
      usersEntity !== undefined &&
      !isEmpty(usersEntity)
    ) {
      const currentUser = usersEntity[currentUserID];

      if (!currentUser) {
        onBack();
      } else {
        // reset value
        const viewUsers = [];
        if (currentUser?.view_users && Array.isArray(currentUser?.view_users)) {
          for (let i = 0; i < currentUser.view_users.length; i += 1) {
            if (usersEntity[currentUser.view_users[i]]) {
              viewUsers.push(usersEntity[currentUser.view_users[i]]);
            }
          }
        }
        setCurrentUser(currentUser);
        if (currentUser.user_groups) {
          setCurrentUserGroups([...currentUser.user_groups]);
        }
      }
    } else {
      setCurrentUser(getUserBaseObject(UserAttributesTypes.device));
    }
  }, [currentUserID, usersEntity]);

  return (
    <div>
      {user && loginedUser && usersEntity && (
        <UserInformation
          user={user}
          loginedUser={loginedUser}
          groupEntity={groupEntity}
          onSave={(userUpdated: User) =>
            onSaveAction(userUpdated, currentUserGroupsIds)
          }
          onDelete={onDelete}
          onBack={onBack}
          onChangePassword={onChangePassword}
        />
      )}
    </div>
  );
}
export default UsersView;
