import PropTypes from "prop-types";
import React from "react";

import HypnogramComponent from "./Hypnogram";

class Hypnogram extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  render() {
    if (!this.props.options.plotData) {
      return null;
    }
    return <HypnogramComponent {...this.props} />;
  }
}

Hypnogram.propTypes = {
  options: PropTypes.any
};

export default Hypnogram;
