import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: "100%",
    float: "left",
    margin: "0",
    outline: "none"
  },
  input: {
    border: ({ borderRed }: { borderRed: boolean }) =>
      borderRed
        ? `1px solid ${theme.palette.primary.red}`
        : `1px solid ${theme.palette.primary.input}`,
    borderRadius: 5,
    "& button.MuiIconButton-root": {
      color: theme.palette.primary.dark
    },
    "& > input": {
      padding: 16,
      color: theme.palette.primary.input
    },
    "&:focus": {
      outline: "none"
    }
  },
  txtField: {}
}));

function PasswordTextField({
  label,
  onChange,
  required = false,
  placeholder,
  borderRed = false,
  value = "",
  autofill = false
}: {
  label: string;
  onChange: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  borderRed?: boolean;
  value?: string;
  autofill?: boolean;
}): JSX.Element {
  const [password, setPassword] = useState<string>(value || "");
  const [togglePassword, setTogglePassword] = useState<boolean>(!!autofill);
  const classes = useStyles({ borderRed });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (autofill) {
      setPassword(value);
    }
  }, [value]);

  return (
    <FormControl className={classes.formControl}>
      <Input
        disableUnderline
        type={togglePassword ? "text" : "password"}
        required={required}
        value={password}
        autoComplete="off"
        onChange={handleChange}
        className={classes.input}
        placeholder={placeholder}
        name={label}
        inputProps={{
          autoComplete: "off",
          classes: {
            input: classes.txtField
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setTogglePassword(!togglePassword)}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                event.preventDefault()
              }
            >
              {togglePassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default PasswordTextField;
