import { makeStyles } from "@material-ui/core/styles";
import MuiCardContent from "@material-ui/core/CardContent";
import React from "react";

export const useStyles = makeStyles(() => ({
  root: {
    padding: "0 10px"
  }
}));

function CardContent(props) {
  const classes = useStyles();
  return <MuiCardContent classes={classes} {...props} />;
}

export default CardContent;
