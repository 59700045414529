import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export const useStyles = makeStyles((theme: any) => ({
  cardWrapper: {
    float: "left",
    padding: ({ padding }: { padding: number }) => padding || 32,
    width: "100%",
    position: "relative",
    background: theme.palette.white,
    marginBottom: 32
  }
}));

type Props = {
  children?: React.ReactChild | React.ReactChild[];
  padding?: number;
};
function InfoBox({ children, padding = 32 }: Props): JSX.Element {
  const classes = useStyles({ padding });
  return <div className={classes.cardWrapper}>{children}</div>;
}

export default InfoBox;
