import { NightReport } from "interfaces/nightReport.interface";

export const FETCH_NIGHT_REPORT = "FETCH_NIGHT_REPORT";
export const FETCH_NIGHT_REPORT_SUCCESS = "FETCH_NIGHT_REPORT_SUCCESS";

export interface fetchNightReportActionType {
  type: typeof FETCH_NIGHT_REPORT;
}
export interface fetchNightReportSuccessActionType {
  type: typeof FETCH_NIGHT_REPORT_SUCCESS;
  payload: NightReport;
}

export type nightReportActionTypes =
  | fetchNightReportActionType
  | fetchNightReportSuccessActionType;
