/* eslint-disable react/no-array-index-key */
import { CircularProgress, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React, { useEffect, useRef, useState } from "react";

import { NightReport, NightReportItem } from "interfaces/nightReport.interface";
import { NightReportSortOrder, sortNightReportByType } from "utils/report.util";
import { NightReportSortTypes } from "constants/app.enum";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { User } from "interfaces/user.interface";
import { fetchNightReport } from "store/actions/nightReportActions";
import { getLocalSettings, getToken } from "utils/localStorage";
import Dashboard from "components/Dashboard";
import InfoBox from "components/InfoBox/InfoBox";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";

import NightReportHeader from "./components/NightReportHeader";
import NightReportListHeader from "./components/NightReportListHeader";
import NightReportToPrint from "./components/NightReportToPrint";
import NightReportView from "./components/NightReportView";
import useStyles from "./nightReport.style";

function NightReportContainer() {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const componentRef = useRef<any>(null);

  const [nightReportItems, setNightReportItems] = useState<NightReportItem[]>();

  const [timeframeStart, setTimeframeStart] = useState<string>();
  const [createdAt, setCreatedAt] = useState<string>();
  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [curSortField, setSortField] = useState<number>(2);

  const perPage =
    +getLocalSettings(USER_SETTINGS_CONSTANTS.reports_per_page) || 10;
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage);

  const nightReport: NightReport = useSelector((state: any) => {
    return state?.reportReducer?.report || {};
  });

  const apiInfo = { token: getToken(), authType: "Basic" };
  const [dashboardModalUser, setDashboardModalUser] = useState<string>("");
  const [modalUserDisplayName, setModalUserDisplayName] = useState<string>("");

  const sortList = (sortType: NightReportSortTypes) => {
    if (!Number.isNaN(sortType)) {
      let order = "ASC";
      if (curSortField === sortType) {
        order = curSortOrder === "ASC" ? "DESC" : "ASC";
      }
      setSortField(sortType);
      setSortOrder(order as "ASC" | "DESC");
      const sortedReports: NightReportItem[] = sortNightReportByType(
        nightReportItems || [],
        order as "ASC" | "DESC",
        sortType
      );
      setNightReportItems(sortedReports);
    }
  };

  useEffect(() => {
    setNightReportItems(
      nightReport?.items
        ?.sort(
          (a: NightReportItem, b: NightReportItem) =>
            NightReportSortOrder[a.status] - NightReportSortOrder[b.status]
        )
        .sort((a: NightReportItem, b: NightReportItem) =>
          `${a?.device?.name}`.localeCompare(`${b.device?.name}`)
        )
    );
    setCreatedAt(nightReport.created_at);
    setTimeframeStart(nightReport.report_timeframe_start);
  }, [nightReport]);

  useEffect(() => {
    dispatch(fetchNightReport());
  }, []);

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const openDashBoardPopup = (item: NightReportItem) => {
    if (item && item.user && item.user.id) {
      setDashboardModalUser(item.user.id);
      setModalUserDisplayName(
        `${item.user.display_name} (${item.user.sex},${item.user.birth_year})`
      );
    } else {
      setDashboardModalUser("");
    }
  };

  const closeDashboardPopup = () => {
    setDashboardModalUser("");
    setModalUserDisplayName("");
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>{t("Night Report")}</div>
      <div className={classes.wrapper}>
        <InfoBox padding={16}>
          {timeframeStart && createdAt ? (
            <NightReportHeader
              timeframeStart={timeframeStart}
              createdAt={createdAt}
              loginedUser={loginedUser}
              printRef={componentRef}
            />
          ) : (
            ""
          )}
          <div className={classes.perPageWrapper}>
            <PerPageListItems
              type={USER_SETTINGS_CONSTANTS.reports_per_page}
              label={t("Per page")}
              onChange={setItemsPerPage}
              total={nightReportItems?.length}
            />
          </div>
          <NightReportListHeader
            curSortOrder={curSortOrder}
            curSortField={curSortField}
            onClick={sortList}
          />
          {nightReportItems ? (
            <NightReportView
              nightReportItems={nightReportItems}
              t={t}
              itemsPerPage={itemsPerPage}
              openDashBoardPopup={openDashBoardPopup}
            />
          ) : (
            <div className={classes.progressWrapper}>
              {" "}
              <CircularProgress classes={{ root: classes.progress }} />
            </div>
          )}

          <div className={classes.legends}>
            <div className={classes.legendsTitle}>{t("Legends")}</div>
            <div className={classes.desc}>
              <ErrorOutlineIcon className={classes.legendsIcon} />
              <span>
                {t(
                  "Flagged for attention if there is a difference compared to the baseline value, as follows"
                )}
                :
              </span>
            </div>
            <ul className={classes.legendItems}>
              <li>
                {t("Respiration Rate difference > 15 % (below or above)")}
              </li>
              <li>{t("Movement difference > 30% (below or above)")}</li>
              <li>{t("Total Sleep Time difference > 30% (below or above)")}</li>
            </ul>
            <div className={classes.baseLineHeights}>
              {t(
                "(*) Baseline Nights the number of nights used to calculate the baseline value, which is the average measurement for the past 21 days"
              )}
            </div>
          </div>
        </InfoBox>
        {/* 
            Print  
        */}
        <div style={{ display: "none" }}>
          <NightReportToPrint
            timeframeStart={timeframeStart}
            createdAt={createdAt}
            loginedUser={loginedUser}
            items={nightReportItems}
            t={t}
            ref={componentRef}
          />
        </div>

        <Modal
          disablePortal
          className={classes.dashboardModal}
          open={dashboardModalUser !== ""}
          onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") =>
            closeDashboardPopup()
          }
        >
          <div className={classes.dashboardModalContent}>
            <CloseIcon
              onClick={() => closeDashboardPopup()}
              className={classes.dashboardModalCloseIcon}
            />
            <Dashboard
              apiInfo={apiInfo}
              selectedUserId={dashboardModalUser}
              key={dashboardModalUser}
              userDisplayName={modalUserDisplayName}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default NightReportContainer;
