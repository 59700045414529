import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { UserGroupType } from "constants/userGroupConstants";
import AddGroupDrawer from "components/AddGroupDrawer/AddGroupDrawer";
import Highlighted from "components/Highlighted/HighLighted";
import SearchTextField from "components/SearchTextField/SearchTextField";
import ShowMoreComponent from "components/ShowMoreComponent/ShowMoreComponent";
import SwipeableTemporaryDrawer from "components/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    width: "100%",
    float: "left",
    marginBottom: 20
  },
  listWrapper: {
    width: "100%",
    float: "left"
    // minHeight: 175
  },
  subUser: {
    float: "left",
    padding: 8,
    fontSize: 16,
    width: ({ inline }: { inline: boolean }) => (inline ? "100%" : "33%")
  },
  title: {
    fontSize: 24,
    width: "100%",
    float: "left",
    margin: "16px 0"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 17
  },
  checkBox: {
    paddingRight: 15
  },
  showMoreWrapper: {
    marginLeft: ({ inline }: { inline: boolean }) =>
      inline ? 15 : "calc(66% + 14px)",
    float: "left"
  },
  drawerWrapper: {
    float: "left",
    marginLeft: 117,
    marginTop: 3
  }
}));

function SMGroupLists({
  lists,
  onChange,
  label,
  searchPlaceholder,
  defaultValues,
  disabled,
  inline = false,
  hideLabel = false,
  hideDrawer = false,
  userGroupType,
  showItems = 9
}: {
  lists: Group[];
  onChange: (value: string[], key: string) => void;
  label: string;
  searchPlaceholder: string;
  defaultValues: string[];
  disabled?: boolean;
  inline?: boolean;
  hideLabel?: boolean;
  userGroupType: string;
  hideDrawer?: boolean;
  showItems?: number;
}) {
  const classes = useStyles({ inline });
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");

  const [total, setTotal] = useState<number>();
  const [showMore, setShowMore] = useState<boolean>(true);
  const [filteredItems, setFilteredItems] = useState<Group[]>();

  const [toggleAddGroup, setToggleAddGroup] = useState<boolean>(false);

  useEffect(() => {
    const filteredList = filterArray(lists, query);
    setFilteredItems(
      showMore ? filterArray(lists, query)?.splice(0, showItems) : filteredList
    );
    setTotal(filteredList.length);
  }, [query, lists, showMore]);

  const onCheckBoxChange = (id: string, checked: boolean) => {
    const subList = defaultValues || [];
    if (checked) {
      subList.push(id);
    } else {
      _.remove(subList, (item) => {
        return item === id;
      });
    }
    onChange(subList, "user_groups");
  };

  const filterArray = (arr: Group[], q: string) => {
    return arr.filter((item: Group) => {
      return item.name.toLowerCase().includes(q.toLowerCase());
    });
  };

  return (
    <div className={classes.container}>
      {!hideLabel ? <div className={classes.title}>{label}</div> : ""}
      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={(q: string) => setQuery(q)}
          width="326px"
          placeholder={searchPlaceholder}
        />

        {!hideDrawer ? (
          <div className={classes.drawerWrapper}>
            <SwipeableTemporaryDrawer
              label={
                userGroupType === UserGroupType.device
                  ? t("Add User Group")
                  : t("Add Employee Group")
              }
              component={
                <AddGroupDrawer
                  onclose={setToggleAddGroup}
                  userGroupType={userGroupType}
                />
              }
              setToggle={setToggleAddGroup}
              toggle={toggleAddGroup}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={classes.listWrapper}>
        {filteredItems && filteredItems.length
          ? filteredItems.map((group: Group) => (
              <div className={classes.subUser} key={group.id}>
                <FormControlLabel
                  value={group.id}
                  disabled={disabled}
                  control={
                    <Checkbox color="default" className={classes.checkBox} />
                  }
                  label={<Highlighted text={group.name} highlight={query} />}
                  labelPlacement="end"
                  checked={defaultValues?.includes(group.id)}
                  onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                    onCheckBoxChange(group.id, checked)
                  }
                />
              </div>
            ))
          : ""}
      </div>

      {total && total > showItems ? (
        <div className={classes.showMoreWrapper}>
          <ShowMoreComponent
            total={total}
            showMore={showMore}
            showItems={showItems}
            setShowMore={setShowMore}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default SMGroupLists;
