import { AppRouteProps } from "interfaces/router.interface";

import DevicesSettings from "./devices/DevicesSettings";
import EmployeesManagement from "./employees/EmployeesManagement";
import GroupsManagement from "./userGroups/UserGroupsManagement";
import UsersManagement from "./users/UsersManagement";

export const settingsRouteNames = {
  home: "/",
  devices: "Devices",
  users: "Users",
  employees: "Employees",
  groups: "Groups"
};

export const settingsRoutes: AppRouteProps[] = [
  {
    name: settingsRouteNames.devices,
    path: "/app/settings/devices",
    exact: false,
    component: DevicesSettings
  },
  {
    name: settingsRouteNames.home,
    path: "/app/settings",
    exact: true,
    redirectPath: "/app/settings/devices",
    redirectPathIfNotAdmin: "/app/settings/users"
  },
  {
    name: settingsRouteNames.users,
    path: "/app/settings/users",
    exact: false,
    component: UsersManagement
  },
  {
    name: settingsRouteNames.employees,
    path: "/app/settings/employees",
    exact: false,
    component: EmployeesManagement
  },
  {
    name: settingsRouteNames.groups,
    path: "/app/settings/groups",
    exact: false,
    component: GroupsManagement
  }
];
export default settingsRoutes;
