export interface NightReportItemMetric {
  value: number;
  baseline: number;
  deviation: number;
  deviation_pct?: number | null | string;
  deviation_alert: boolean;
}

export const NightReportItemStatus = {
  PRESENCE_AND_SLEEP: "PRESENCE_AND_SLEEP",
  NO_PRESENCE: "NO_PRESENCE",
  NO_SLEEP: "NO_SLEEP"
};

export interface NightReportItem {
  status: "PRESENCE_AND_SLEEP" | "NO_PRESENCE" | "NO_SLEEP";
  selected_session_is_in_progress?: boolean;
  baseline_night_count?: number;
  respiration_rate?: NightReportItemMetric;
  movement?: NightReportItemMetric;
  time_asleep: NightReportItemMetric;
  out_of_bed: NightReportItemMetric;
  sleep_score: NightReportItemMetric;
  timeframe_start: string;
  timeframe_end: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    display_name: string;
    sex: string;
    birth_year: number;
  };
  device?: {
    id: string;
    name: string;
    serial_number: string;
  };
}

export interface NightReport {
  items: NightReportItem[];
  created_at: string;
  report_timeframe_start: string;
}
