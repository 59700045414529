import { EntityDeviceSettings } from "interfaces/device.interface";

export const FETCH_DEVICES_SETTINGS_ACTION_SUCCESS =
  "FETCH_DEVICES_SETTINGS_ACTION_SUCCESS";
export const FETCH_DEVICES_SETTINGS_ACTION = "FETCH_DEVICES_SETTINGS_ACTION";
export const UPDATE_DEVICES_SETTINGS_ACTION_SUCCESS =
  "UPDATE_DEVICES_SETTINGS_ACTION_SUCCESS";
export const RESET_DEVICE_SETTINGS_ACTION = "RESET_DEVICE_SETTINGS_ACTION";

export interface updateDevicesSettingsSuccessActionType {
  type: typeof UPDATE_DEVICES_SETTINGS_ACTION_SUCCESS;
  payload: EntityDeviceSettings;
}

export interface resetDevicesSettingsActionType {
  type: typeof RESET_DEVICE_SETTINGS_ACTION;
}
export interface fetchDevicesSettingsActionType {
  type: typeof FETCH_DEVICES_SETTINGS_ACTION;
}
export interface LoadDevicesSettingsSuccessActionType {
  type: typeof FETCH_DEVICES_SETTINGS_ACTION_SUCCESS;
  payload: EntityDeviceSettings;
}

export type devicesSettingsActionTypes =
  | fetchDevicesSettingsActionType
  | LoadDevicesSettingsSuccessActionType
  | updateDevicesSettingsSuccessActionType
  | resetDevicesSettingsActionType;
