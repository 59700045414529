import axios from "axios";

const URL = "/v1/users";
const defaultQuery = {
  limit: 999,
  offset: 0,
  fields: ["settings", "clients", "view_users"]
};

const getUsers = (q = {}) => {
  const query = { ...defaultQuery, ...q };
  const info = { params: query };
  return axios.get(URL, info).then((res) => res.data._embedded.users);
};

export default {
  getUsers
};
