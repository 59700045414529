import { Tooltip, makeStyles } from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: 8,
    width: "100%",
    float: "left",
    position: "relative",
    display: "flex",
    alignItems: ({
      labelWidth,
      labelPositionTop
    }: {
      labelWidth: number;
      labelPositionTop: boolean;
    }) => (labelPositionTop ? "flex-start" : "center"),
    minHeight: 66
  },

  value: {
    fontSize: 14,
    float: "left"
  },
  label: {
    width: ({
      labelWidth,
      labelPositionTop
    }: {
      labelWidth: number;
      labelPositionTop: boolean;
    }) => labelWidth || 245,
    fontSize: 16,
    float: "left",
    fontWeight: "normal"
  },
  toolTip: {
    height: 20,
    width: 20,
    top: 4,
    position: "relative",
    left: 4
  },
  toolTipCls: {
    fontSize: 16
  }
}));

export interface StyleProps {
  background: string | undefined;
}

function DeviceInfoText({
  text,
  field: toggledView,
  infoText,
  labelWidth = 245,
  labelPositionTop = false
}: {
  text?: string;
  field?: JSX.Element;
  infoText?: string;
  labelWidth?: number;
  labelPositionTop?: boolean;
}) {
  const classes = useStyles({ labelWidth, labelPositionTop });
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState<boolean>(false);
  return (
    <div className={`${classes.contentWrapper}`}>
      <div className={classes.label || ""}>
        {text}
        {infoText && text && (
          <Tooltip
            placement="top"
            classes={{ tooltip: classes.toolTipCls }}
            title={infoText}
            arrow
            open={tooltipIsOpen}
            disableTouchListener
          >
            <HelpOutlineOutlinedIcon
              className={classes.toolTip}
              onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.value}> {toggledView}</div>
    </div>
  );
}
export default DeviceInfoText;
