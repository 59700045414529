import { MenuItem, Select, makeStyles } from "@material-ui/core";
import React from "react";

import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";

interface Props {
  value: any;
  onChange: (value: string) => void;
  options: { name: string; value: string }[];
  defaultValue?: any;
  width?: number;
  placeholder?: string;
}

const useStyles = makeStyles((theme: any) => ({
  sliderContainerCls: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    fontSize: 14
  },
  selectRoot: {
    border: `1px solid ${theme.palette.primary.input}`,
    borderRadius: 5,
    "&::focus": {
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.input}`
    }
  },
  select: {
    width: ({ boxWidth }: { boxWidth: number }) => boxWidth,
    height: 20,
    padding: 14
  },
  icon: {
    position: "absolute",
    right: 18,
    top: 23
  }
}));

function DropDownSelect({
  value,
  onChange,
  options,
  defaultValue,
  width,
  placeholder
}: Props): JSX.Element {
  const boxWidth = width || 212;
  const classes = useStyles({ boxWidth });
  const [selected, setSelected] = React.useState<string>(`${value}`);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string);
    onChange(event.target.value as string);
  };
  return (
    <div className={classes.sliderContainerCls}>
      {options && (
        <Select
          disableUnderline
          value={selected}
          onChange={handleChange}
          defaultValue={options[0]}
          placeholder={placeholder || ""}
          IconComponent={() => (
            <div>
              <DownArrowOutline className={classes.icon} />
            </div>
          )}
          classes={{
            select: classes.select,
            root: classes.selectRoot
          }}
        >
          {options.map((option: { name: string; value: string }) => {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </div>
  );
}
export default React.memo(DropDownSelect);
