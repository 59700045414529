import { SortTypes } from "constants/app.enum";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes, UserPrivileges } from "constants/userContstants";

import i18n from "../i18n/config";

export const sortUsers = (
  users: User[],
  order = "ASC",
  typeToSort: number
): User[] => {
  const usersArray = [...users];

  let sortedUsers: User[] = [];
  switch (+typeToSort) {
    case SortTypes.UserNameSort:
      sortedUsers = usersArray
        .slice()
        .sort((a: User, b: User) => (a.display_name > b.display_name ? 1 : -1));
      break;
    default:
      return users;
  }

  if (order === "DESC") {
    return sortedUsers.reverse();
  }
  return sortedUsers;
};

export const generateBirthYearOptions = (): number[] => {
  const NUM_YEARS = 120;
  const date = new Date();
  const firstYear = date.getFullYear() - NUM_YEARS;
  return Array.from(
    Array(NUM_YEARS),
    (_, x) => x + firstYear + 1
  ).reverse() as number[];
};

// user role
export const getUserRole = (user: User): string => {
  if (user.attributes) {
    return user.attributes.user_type || "default";
  }
  return "default";
};

export const getUserBaseObject = (type?: string): User => {
  const userObj = {
    id: "",
    first_name: "",
    last_name: "",
    display_name: "",
    email: "",
    gender: "Male",
    height: "",
    weight: "",
    birth_year: new Date().getFullYear(),
    language_preference: "en-gb",
    view_users: [],
    password: "",
    user_groups: [],
    attributes: {
      user_type: type || UserAttributesTypes.device,
      privileges: [UserPrivileges.view_monitor]
    }
  };
  return { ...userObj };
};

export const generateAutoFillPassword = (length: number): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const filterAlphaNumerics = (text: string) => {
  let output = "";
  for (let i = 0; i < text.length; i += 1) {
    if (
      (text.charCodeAt(i) >= 48 && text.charCodeAt(i) <= 57) ||
      (text.charCodeAt(i) >= 65 && text.charCodeAt(i) <= 90) ||
      (text.charCodeAt(i) >= 97 && text.charCodeAt(i) <= 122)
    ) {
      output += text.charAt(i);
    }
  }
  return output;
};

export const generateAutoFillEmail = (
  user: User,
  loginedUser: User
): string => {
  const emailArray = loginedUser.email.split("@");
  return `${emailArray[0]}+${
    filterAlphaNumerics(user.first_name?.toLocaleLowerCase()) || ""
  }${user.last_name ? "_" : ""}${
    filterAlphaNumerics(user.last_name?.toLocaleLowerCase()) || ""
  }-${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}@${emailArray[1]}`;
};

export const ignoreParentUser = (loginedUser: User, users: User[]) => {
  return users.filter((user: User) => user.parent_id);
};

export const isAdminUser = (loginUser: User): boolean => {
  return (!loginUser.parent_id || loginUser.act_as_root_user) ?? false;
};

export const canAddNewUser = (loginUser: User, id: string) => {
  return isAdminUser(loginUser) && id === "0";
};

export const canDeletedUser = (loginUser: User, user: User) => {
  return user.parent_id === loginUser.id || loginUser.act_as_root_user;
};

export const isCurrentUserIsAdmin = ({
  loginUser,
  currentUser
}: {
  currentUser: User;
  loginUser: User;
}) => {
  return isAdminUser(loginUser) && loginUser.id === currentUser.id;
};

export const isViewUser = (loginUser: User): boolean => {
  return loginUser.attributes?.user_type === UserAttributesTypes.viewer;
};

export const canEditUser = ({
  loginUser,
  currentUser
}: {
  loginUser: User;
  currentUser: User;
}): boolean => {
  return (
    loginUser.id === currentUser.id || isAdminUser(loginUser) // ||  currentUser.parent_id === loginUser.id
  );
};

export const canSetPrivilages = (loginUser: User, user: User) => {
  // only show if admin open viewer in edit mode or admin selected user type as viewer while creating user
  if (isAdminUser(loginUser)) {
    return true;
  }
  return false;
};

export const canViewMonitor = (loginedUser: User): boolean => {
  if (loginedUser) {
    const userType = loginedUser.attributes?.user_type;
    const privileges: string[] = loginedUser.attributes?.privileges || [];
    if (userType === UserAttributesTypes.viewer) {
      return privileges.includes(UserPrivileges.view_dashbaord);
    }
  }
  return true;
};

export const sortUsersBySearchQuery = ({
  q,
  type = "name",
  users
}: {
  q: string;
  type: string;
  users: User[];
}): User[] => {
  if (!users) {
    return [];
  }
  let arrCopy: User[] = [...users];
  if (type === "name") {
    arrCopy = arrCopy.filter((item: User) => {
      return item.display_name.toLowerCase().includes(q.toLowerCase());
    });
  } else if (type === "birth_year") {
    arrCopy = arrCopy.filter((item: User) => {
      return item.birth_year.toString().includes(q);
    });
  }
  return arrCopy;
};

export const getUserType = (user: User): string => {
  if (user.act_as_root_user) {
    return UserAttributesTypes.default;
  }
  if (user.attributes && user.attributes.user_type) {
    return user.attributes.user_type;
  }
  return UserAttributesTypes.default;
};

export const validateEmployeeData = (updatedUser: User): string => {
  let errorMessage = "";
  if (!updatedUser.first_name) {
    errorMessage = i18n.t("Please enter firstname");
  } else if (!updatedUser.email) {
    errorMessage = i18n.t("Please enter email");
  }
  return errorMessage;
};

export const privillagesCheckBoxChecked = (
  user: User,
  value: boolean,
  type: any
) => {
  //  UserPrivileges
  let seelctedPrvillages;
  if (value) {
    seelctedPrvillages = user.attributes?.privileges
      ? [...user.attributes?.privileges, type]
      : [type];
  } else {
    seelctedPrvillages =
      user.attributes?.privileges?.filter(
        (prvlgs: string) => prvlgs !== type
      ) || [];
  }

  const attributes = {
    ...(user.attributes || {}),
    privileges: seelctedPrvillages
  };
  return attributes;
};

export const getUserAccess = (user: User) => {
  if (!user) {
    return [];
  }
  if (!user.parent_id) {
    return [i18n.t("Admin")];
  }
  if (user.act_as_root_user) {
    return [i18n.t("Admin")];
  }
  const privillages = [];

  if (user.attributes?.privileges?.includes(UserPrivileges.view_monitor)) {
    privillages.push(i18n.t("Monitor"));
  }
  if (user.attributes?.privileges?.includes(UserPrivileges.view_dashbaord)) {
    privillages.push(i18n.t("Sleep records"));
  }
  return privillages;
};

export const getCheckedItemsCount = (selectedItems: {
  [key: string]: boolean;
}) => {
  let checked: number = 0;
  if (!selectedItems) {
    return checked;
  }
  Object.entries(selectedItems).forEach(([key, value]) => {
    if (value) {
      checked += 1;
    }
  });
  return checked;
};

export const allChecked = (
  currentUsers: User[],
  selectedItems: { [key: string]: boolean }
) => {
  if (!currentUsers || currentUsers?.length === 0) {
    return false;
  }
  return (
    currentUsers.filter((user: User) => selectedItems[user.id]).length ===
    currentUsers.length
  );
};

export const toggleAllUsersChecked = (users: User[], checked: boolean) => {
  const checkedUsers: { [key: string]: boolean } = {};
  users?.forEach((user: User) => {
    checkedUsers[user.id] = checked;
  });
  return checkedUsers;
};

export const viewUserDetails = (event: any, userObj: User, history: any) => {
  const dataRef = event.target.getAttribute("data-ref");
  if (
    dataRef === "preventParentClick" ||
    event.target.type === "checkbox" ||
    event.target.tagName === "path"
  ) {
    return;
  }
  const path = `${history.location.pathname}/${userObj.id}`;
  history.push(path);
};

export const convertUsersToOptionType = (users: User[]) => {
  return users.map((user: User) =>
    Object.assign(user, { name: user.display_name })
  );
};
