import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import { UserGroupType } from "constants/userGroupConstants";

import i18n from "../i18n/config";

export const filterUserGroup = ({
  type,
  userGroups
}: {
  type: string;
  userGroups: Group[];
}) => {
  return userGroups.filter((userGroup: Group) => {
    return userGroup.type === type;
  });
};

export const sortGroupsBySearchQuery = ({
  q,
  type = "name",
  groups
}: {
  q: string;
  type: string;
  groups: Group[];
}): Group[] => {
  if (!groups) {
    return [];
  }
  let arrCopy: Group[] = [...groups];
  if (type === "name") {
    arrCopy = arrCopy.filter((item: Group) => {
      return item.name.toLowerCase().includes(q.toLowerCase());
    });
  }
  //  else if (type === "birth_year") {
  //     arrCopy = arrCopy.filter((item: User) => {
  //       return item.birth_year.toString().includes(q);
  //     });
  //   }
  return arrCopy;
};

export const sortGroups = (
  groups: Group[],
  order = "ASC",
  typeToSort: string
): Group[] => {
  const groupsArray = [...groups];
  let sortedGroups: Group[] = [];
  if (typeToSort === "name") {
    sortedGroups = groupsArray
      .slice()
      .sort((a: Group, b: Group) => (a.name > b.name ? 1 : -1));
  }
  if (order === "DESC") {
    return sortedGroups.reverse();
  }
  return sortedGroups;
};

export const getDeviceUsers = (
  userEntity: { [key: string]: User },
  group: Group
): User[] => {
  const deviceUsers: User[] = [];
  if (group.users && Array.isArray(group.users) && group.users.length) {
    for (let i = 0; i < group.users.length; i += 1) {
      if (userEntity[group.users[i]]) {
        deviceUsers.push(userEntity[group.users[i]]);
      }
    }
  }
  return deviceUsers;
};

export const getViewUsers = (
  userGroupsEntity: { [key: string]: Group },
  group: Group
): Group[] => {
  const viewUserGroups: Group[] = [];
  if (
    group.view_user_groups &&
    Array.isArray(group.view_user_groups) &&
    group.view_user_groups.length
  ) {
    for (let i = 0; i < group.view_user_groups.length; i += 1) {
      if (userGroupsEntity[group.view_user_groups[i]]) {
        viewUserGroups.push(userGroupsEntity[group.view_user_groups[i]]);
      }
    }
  }
  return viewUserGroups;
};

export const getAvailbleUsers = (users: User[], type: string) => {
  const userType =
    type === UserGroupType.device
      ? UserAttributesTypes.device
      : UserAttributesTypes.viewer;
  if (users && users.length) {
    return users.filter((user: User) => {
      return user.attributes?.user_type === userType;
    });
  }
  return [];
};

export const getAllDeviceUserGroups = (groups: Group[]) => {
  if (groups && groups.length) {
    return groups.filter((group: Group) => {
      return group.type === UserGroupType.device;
    });
  }
  return [];
};

export const normalizedGroupsEntity = (
  groups: Group[]
): { [key: string]: Group } => {
  const normalizedGroups: { [key: string]: Group } = {};
  for (let i = 0; i < groups.length; i += 1) {
    normalizedGroups[groups[i].id] = groups[i];
  }
  return normalizedGroups;
};

export const getGroupsToUpdate = ({
  user,
  groupEntity,
  currentUserGroupsIds
}: {
  user: User;
  groupEntity: { [key: string]: Group };
  currentUserGroupsIds: string[];
}) => {
  if (groupEntity && user) {
    const groups = user?.user_groups?.map((group) => groupEntity[group]) || [];
    const selectedUserGroupsIds = groups.map((grp: Group) => grp.id) ?? [];
    const shouldDelete = currentUserGroupsIds.filter(
      (i: string) => selectedUserGroupsIds.indexOf(i) < 0
    );
    const shouldAdd = selectedUserGroupsIds.filter(
      (i: string) => currentUserGroupsIds.indexOf(i) < 0
    );
    const addUser: Group[] = [];
    for (let i = 0; i < shouldAdd.length; i += 1) {
      const groupObj = groupEntity[shouldAdd[i]];
      const updatedGroupUsers = [...groupObj.users, user.id];
      const updatedGroup: Group = { ...groupObj, users: updatedGroupUsers };
      addUser.push(updatedGroup);
    }

    const deleteUser: Group[] = [];
    for (let i = 0; i < shouldDelete.length; i += 1) {
      const groupObj = groupEntity[shouldDelete[i]];
      const updatedGroupUsers = groupObj.users.filter(
        (id: string) => id !== user.id
      );
      const updatedGroup = { ...groupObj, users: updatedGroupUsers };
      deleteUser.push(updatedGroup);
    }
    const updateGroups: Group[] = addUser.concat(deleteUser);
    return updateGroups;
  }
  return [];
};

export const getGroupBaseObject = (type: string): Group => {
  return {
    name: "",
    id: "0",
    type,
    users: [],
    view_user_groups: []
  };
};

export const getGroupSaveButtonLabel = ({
  updatedGroup,
  userGroupType
}: {
  updatedGroup: Group;
  userGroupType: string;
}) => {
  let saveBtnLabel = i18n.t("Save");
  if (
    updatedGroup &&
    (updatedGroup.id === "0" || !updatedGroup.id) &&
    userGroupType === UserGroupType.device
  ) {
    saveBtnLabel = i18n.t("Add User Group");
  } else if (
    updatedGroup &&
    (updatedGroup.id === "0" || !updatedGroup.id) &&
    userGroupType === UserGroupType.viewer
  ) {
    saveBtnLabel = i18n.t("Add Employee Group");
  }
  return saveBtnLabel;
};

export const toggleAllGroupsChecked = (groups: Group[], checked: boolean) => {
  const checkedGroups: { [key: string]: boolean } = {};
  groups?.forEach((group: Group) => {
    checkedGroups[group.id] = checked;
  });
  return checkedGroups;
};

export const viewGroupDetails = (event: any, groupObj: Group, history: any) => {
  const dataRef = event.target.getAttribute("data-ref");
  if (
    dataRef === "preventParentClick" ||
    event.target.type === "checkbox" ||
    event.target.tagName === "path"
  ) {
    return;
  }
  const path = `${history.location.pathname}/${groupObj.id}`;
  history.push(path);
};

export const allGroupsChecked = (
  currentGroups: Group[],
  selectedItems: { [key: string]: boolean }
) => {
  if (!currentGroups || currentGroups?.length === 0) {
    return false;
  }
  return (
    currentGroups.filter((group: Group) => selectedItems[group.id]).length ===
    currentGroups.length
  );
};
