import axios from "axios";

import { API_URL } from "../../../constants/constants";
import { getToken, setToken } from "../../../utils/localStorage";

const logIn = (username, password) => {
  const authToken = btoa(`${username}:${password}`);
  return axios
    .post(
      `${API_URL}/v1/auth/login`,
      {},
      {
        headers: { Authorization: `Basic ${authToken}` },
        params: { fields: ["clients"] }
      }
    )
    .then((res) => {
      setToken(authToken);
      return res;
    });
};

// for basic auth only:

const authenticate = (token = null) => {
  if (!token) {
    // eslint-disable-next-line no-param-reassign
    token = getToken();
  }
  const authString = atob(token).split(":");

  return axios.post(
    `${API_URL}/v1/auth/login`,
    { email: authString[0], password: authString[1] },
    {
      headers: { Authorization: `Basic ${token}` },
      params: { fields: ["clients"] }
    }
  );
};

export default {
  logIn,
  authenticate
};
