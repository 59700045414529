import { MenuItem, Select, makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: "0px 32px 0 32px",
    background: theme.palette.secondary.grey,
    width: "100%",
    float: "left"
  },
  title: {
    fontSize: 34,
    marginBottom: 14
  }
}));

type Props = {
  children: React.ReactChild | React.ReactChild[];
  title?: string;
};

function GreyContainer({ children, title }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {title ? <div className={classes.title}>{title}</div> : ""}
      {children}
    </div>
  );
}
export default GreyContainer;
