import { useSelector } from "react-redux";
import React from "react";

import { User } from "interfaces/user.interface";
import RouteTabs from "components/RouteTabs/RouteTabs";

import {
  reportingsRouteNames as routeLabel,
  reportingsRoutes as routes
} from "./reporting.routes";

function ReportingContainer() {
  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  if (!loginedUser) {
    return <div />;
  }
  return <RouteTabs routes={routes} routeLabel={routeLabel} hideTabs />;
}
export default ReportingContainer;
