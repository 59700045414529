import { authTypes } from "../types";

export default (state, action) => {
  switch (action.type) {
    case authTypes.LOGIN:
      return {
        ...state,
        fetching: true,
        error: false
      };
    case authTypes.LOGIN_FULFILLED:
      return {
        ...state,
        user: action.payload.data,
        fetching: false,
        error: false
      };

    case authTypes.LOGIN_REJECTED:
      return {
        ...state,
        user: null,
        fetching: false,
        error: true
      };
    case authTypes.AUTHENTICATE:
      return {
        ...state,
        fetching: true,
        error: false
      };
    case authTypes.AUTHENTICATE_FULFILLED:
      return {
        ...state,
        user: action.payload.data,
        fetching: false,
        error: false
      };

    case authTypes.AUTHENTICATE_REJECTED:
      return {
        ...state,
        user: null,
        fetching: false,
        error: true
      };
    case authTypes.LOGOUT:
      return {
        ...state,
        user: null,
        fetching: false,
        error: false
      };
    default:
      return { ...state };
  }
};
