import { getSleepScorePlotData } from "./SleepScoreFormatter";
import { getSleepStagesPlotData } from "./SleepStagesFormatter";
import { getSleepStatisticsPlotData } from "./SleepStatisticsFormatter";

export const getSessionDetailsPlotData = (session) => {
  return {
    sleepScorePlotData: getSleepScorePlotData(session),
    sleepStagesPlotData: getSleepStagesPlotData(session),
    sleepStatisticsPlotData: getSleepStatisticsPlotData(session)
  };
};
