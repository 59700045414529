import { SMNotificationLog } from "interfaces/notification.interface";

export const GET_NOTIFICATION_LOG = "GET_NOTIFICATION_LOG";
export const GET_NOTIFICATION_LOG_SUCCESS = "GET_NOTIFICATION_LOG_SUCCESS";

export interface getNotificationLogAction {
  type: typeof GET_NOTIFICATION_LOG;
}
export interface getNotificationLogSuccessAction {
  type: typeof GET_NOTIFICATION_LOG_SUCCESS;
  payload: SMNotificationLog[];
}

export type notificationLogActionTypes =
  | getNotificationLogAction
  | getNotificationLogSuccessAction;
