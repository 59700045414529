import moment from "moment";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { SortTypes } from "constants/app.enum";
import { User } from "interfaces/user.interface";

import i18n from "../i18n/config";

interface Duration extends moment.Duration {
  _data: any;
}

export const isDisabledSleepTracking = (settings: DeviceSettings) => {
  return (
    (settings &&
      settings.controlSchedule &&
      Array.isArray(settings.controlSchedule) &&
      settings.controlSchedule[0] &&
      settings.controlSchedule[0].enabled) ||
    false
  );
};

export const getDisabledSleepTracking = (
  settings: DeviceSettings
): string[] => {
  if (
    settings &&
    settings.controlSchedule &&
    Array.isArray(settings.controlSchedule) &&
    settings.controlSchedule[0] &&
    settings.controlSchedule[0].enabled
  ) {
    const timeSpan = settings.controlSchedule[0].timespan || [];
    if (timeSpan.length) {
      return timeSpan;
    }
  }
  return [];
};

export const momentDataToTimeString = (duration: any) => {
  let str = "";
  if (duration) {
    str += duration.years ? `${duration.years}y ` : "";
    str += duration.months ? `${duration.months}m ` : "";
    str += duration.days ? `${duration.days}d ` : "";
    str += duration.hours ? `${duration.hours}h ` : "";
    str += duration.minutes ? `${duration.minutes}m ` : "";
  }
  return `${str}`;
};

export const isEmpty = (obj: any) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const navObj = (obj: any, currentKey: string, direction: number) => {
  let index = Object.keys(obj).indexOf(currentKey) + direction;
  if (index >= Object.keys(obj).length) {
    index = 0;
  } else if (index < 0) {
    index = Object.keys(obj).length - 1;
  }
  return Object.values(obj)[index];
};

export const convertHHMMtominutes = (timeHM: string): number => {
  const timeStrings = timeHM.split(":");
  if (timeStrings.length <= 1) {
    return 0;
  }
  const hour: number = +timeStrings[0];
  const minutes: number = +timeStrings[1];
  if (!Number.isNaN(hour) && !Number.isNaN(minutes)) {
    return hour * 60 + minutes;
  }
  return 0;
};

export const convertsMinutesToHHMM = (mintes: number, ampm = false): string => {
  const hours: number = mintes / 60;
  const rhours: number = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const hhmm = `${rhours < 10 ? `0${rhours}` : rhours}:${
    rminutes < 10 ? `0${rminutes}` : rminutes
  }`;
  if (ampm) {
    return tConv24(hhmm);
  }
  return hhmm;
};

export const tConv24 = (time24: string): string => {
  const ts = time24;
  if (ts.split(":").length <= 1) {
    return `${time24}`;
  }
  const H = +ts.split(":")[0];
  const h = H % 12 || 12;
  const hString = h < 10 ? `0${h}` : h;
  const ampm = H < 12 ? "AM" : "PM";
  return `${hString}:${ts.split(":")[1]} ${ampm}`;
};

// {name:"Clock and circle",value:"normal"},{name:"Circle only",value:"circle"},{name:"Off",value:"off"}
export const getDisplayFormat = (value: string): string => {
  let displayFormat = "";
  switch (value) {
    case "normal":
      displayFormat = "Clock and circle";
      break;
    case "circle":
      displayFormat = "Circle only";
      break;
    case "off":
      displayFormat = "Off";
      break;
    default:
      displayFormat = "off";
  }
  return displayFormat;
};

export const getDuration = (lastActivity: number) => {
  if (lastActivity >= 600 || lastActivity == null) {
    const duration = moment.duration(
      lastActivity,
      i18n.t("seconds")
    ) as Duration;
    return `${momentDataToTimeString(duration?._data)} ${i18n.t("ago")}`;
  }
  return i18n.t("now");
};

export const sortDevices = (
  devices: Device[],
  order = "ASC",
  typeToSort: number,
  users: any
): Device[] => {
  const dataArray = [...devices];

  let sortedDevices = [];
  let usersAsArray = [];
  let sortedUserIds: string[] = [];
  switch (+typeToSort) {
    case SortTypes.DeviceSort:
      sortedDevices = dataArray
        .slice()
        .sort((a: Device, b: Device) => (a.name > b.name ? 1 : -1));
      break;
    case SortTypes.SerialNumber:
      sortedDevices = dataArray
        .slice()
        .sort((a: Device, b: Device) =>
          a.serial_number > b.serial_number ? 1 : -1
        );
      break;
    case SortTypes.UserSort:
      usersAsArray = Object.keys(users)
        .map((item: any) => users[item])
        .slice()
        .sort((a: any, b: any) => (a.display_name > b.display_name ? 1 : -1));
      sortedUserIds = usersAsArray.map((user: any) => user.id);
      sortedDevices = dataArray.slice().sort((a, b) => {
        return (
          sortedUserIds.indexOf(a.user_id) - sortedUserIds.indexOf(b.user_id)
        );
      });
      break;
    case SortTypes.StatusSort:
      sortedDevices = dataArray.sort((a: Device, b: Device) => {
        if (a.time_since_last_activity > b.time_since_last_activity) {
          return 1;
        }
        if (b.time_since_last_activity > a.time_since_last_activity) {
          return -1;
        }
        return 0;
      });
      break;

    default:
      return devices;
  }

  if (order === "DESC") {
    return sortedDevices.reverse();
  }
  return sortedDevices;
};

export const filterDevicesByUsersName = (
  devices: Device[],
  users: any,
  q: string
): Device[] => {
  const dataArray = [...devices];

  let filteredDevices = [];
  let usersAsArray = [];
  let sortedUserIds: string[] = [];

  usersAsArray = Object.keys(users).map((item: any) => users[item]);
  const userArray = [...usersAsArray];

  const usersFiltered = userArray.filter((item: any) => {
    return item.display_name.toLowerCase().includes(q.toLowerCase());
  });

  sortedUserIds = usersFiltered.map((user: any) => user.id);
  filteredDevices = dataArray.slice().filter((item: Device) => {
    return sortedUserIds.indexOf(item.user_id) !== -1;
  });
  return filteredDevices;
};

export const getDeviceUser = (
  devicesEntityWithUserID: { [key: string]: Device },
  user: User
): Device[] => {
  const deviceIds = Object.keys(devicesEntityWithUserID);
  const ids: string[] = deviceIds.filter((id: string) => {
    const arr = id.split("+");
    const user_id = Array.isArray(arr) && arr.length > 1 ? arr[1] : "";
    return user_id === user.id;
  });
  return ids.map((id: string) => devicesEntityWithUserID[id]);
};

export const toggleAllDevicesChecked = (
  devices: Device[],
  checked: boolean
) => {
  const checkedDevices: { [key: string]: boolean } = {};
  devices?.forEach((device: Device) => {
    checkedDevices[device.serial_number] = checked;
  });
  return checkedDevices;
};

export const viewDeviceDetails = (
  event: any,
  deviceObj: Device,
  history: any
) => {
  const dataRef = event.target.getAttribute("data-ref");
  if (
    dataRef === "preventParentClick" ||
    event.target.type === "checkbox" ||
    event.target.tagName === "path"
  ) {
    return;
  }
  const path = `${history.location.pathname}/${deviceObj.serial_number}`;
  history.push(path);
};

export const allChecked = (
  currentDevices: Device[],
  selectedItems: { [key: string]: boolean }
) => {
  if (!currentDevices || currentDevices?.length === 0) {
    return false;
  }
  return (
    currentDevices.filter(
      (device: Device) => selectedItems[device.serial_number]
    ).length === currentDevices.length
  );
};
