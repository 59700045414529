import "moment/locale/en-gb";
import "moment/locale/nb";

import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { withTheme } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import IconButton from "@material-ui/core/IconButton";
import MomentUtils from "@date-io/moment";
import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import ToolbarText from "@material-ui/pickers/_shared/ToolbarText";
import moment from "moment";

import { getLocale, localeMap } from "../../utils/date.util";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      from: this.props.fromValue,
      to: this.props.toValue,
      hover: null,
      open: false
    };

    this.i18n = this.props.i18n;

    this.onChange = this.onChange.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  onChange(dates) {
    this.props.onChangeFrom(dates[0]);
    this.props.onChangeTo(dates[1]);
    this.props.onChange(dates);
  }

  renderDay(_date, selectedDate, dayInCurrentMonth, dayComponent) {
    const date = moment(_date);
    const { from, to, hover } = this.state;
    const { theme } = this.props;
    const style = {
      margin: 0,
      backgroundColor: theme.palette.background.paper
    };

    const selectedColor = theme.palette.primary.main;
    const hoverColorBetween = theme.palette.primary.light;
    const hoverColor = theme.palette.primary.light;

    if (from && to && date.isBetween(from, to, "day", "[]")) {
      style.backgroundColor = selectedColor;
      style.color = "white";
      if (date.isSame(hover, "day")) {
        style.backgroundColor = hoverColorBetween;
      }
    } else if (hover && date.isSame(hover, "day")) {
      style.borderRadius = "50%";
      style.backgroundColor = hoverColor;
    }
    if (date.isBetween(from, to, "day")) {
      style.borderRadius = "0";
    }
    if (date.isSame(from, "day")) {
      style.color = "white";
      style.backgroundColor = selectedColor;
      style.borderRadius = "50% 0 0 50%";
    }
    if (date.isSame(to, "day")) {
      style.color = "white";
      style.backgroundColor = selectedColor;
      style.borderRadius = "0 50% 50% 0";
    }
    if (from && hover && !to) {
      if (hover.isAfter(from)) {
        if (date.isBetween(from, hover, "day")) {
          style.backgroundColor = selectedColor;
          style.borderRadius = "0";
          style.color = "white";
        } else if (date.isSame(from, "day")) {
          style.borderRadius = "50% 0 0 50%";
        } else if (date.isSame(hover, "day")) {
          style.borderRadius = "0 50% 50% 0";
        }
      } else if (hover.isBefore(from)) {
        if (date.isBetween(hover, from, "day")) {
          style.backgroundColor = selectedColor;
          style.borderRadius = "0";
          style.color = "white";
        } else if (date.isSame(from, "day")) {
          style.borderRadius = "0 50% 50% 0";
        } else if (date.isSame(hover, "day")) {
          style.borderRadius = "50% 0 0 50%";
        }
      }
    }

    return React.cloneElement(dayComponent, {
      onClick: (e) => {
        e.stopPropagation();
        if (!from) {
          this.setState({ from: _date });
        } else if (!to) {
          this.setState({ to: _date });
          this.onChange([from, _date].sort((a, b) => a - b));
          this.setState({ open: false });
        } else {
          this.setState({ from: _date, to: null });
        }
      },
      onMouseEnter: () => this.setState({ hover: _date }),
      onMouseLeave: () => this.setState({ hover: null }),
      style
    });
  }

  toggleCalendar() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={getLocale()}>
        <IconButton onClick={this.toggleCalendar}>
          <CalendarIcon />
        </IconButton>
        <MuiDatePicker
          {...this.props}
          open={this.state.open}
          value={this.props.fromValue || this.props.toValue}
          autoOk
          onClose={() =>
            this.setState({
              open: false
            })
          }
          disableFuture
          renderDay={this.renderDay}
          ToolbarComponent={() => (
            <Toolbar
              style={{ backgroundColor: this.props.theme.palette.primary.main }}
            >
              <ToolbarText
                variant="h6"
                label={`${
                  this.state.from ? this.state.from.format("D. MMM") : " "
                } – ${this.state.to ? this.state.to.format("D. MMM") : " "}`}
              />
            </Toolbar>
          )}
          TextFieldComponent={() => null}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

DatePicker.propTypes = {
  fromValue: PropTypes.any,
  toValue: PropTypes.any,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  onChange: PropTypes.func,
  theme: PropTypes.any,
  i18n: PropTypes.object
};

export default withTheme(withTranslation()(DatePicker));
