export default class UserDevice {
  constructor(user, device) {
    this.user = user;
    this.device = device;
  }

  /**
   * The name of the user
   * @returns {string}
   */
  getName() {
    const name = this.user.display_name;
    if (name == null || name === " " || name === "") return null;
    return name;
  }

  getUserId() {
    return this.user.id;
  }

  /**
   * The name of the device, which is also the room name
   * @returns {*}
   */
  getRoom() {
    return this.device.name;
  }
}
