import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 28,
    position: "relative"
    // paddingTop: 54
  },
  container: {
    background: "#FFF",
    width: "100%",
    float: "left",
    padding: "56px 33px 81px 33px"
    // borderRadius: "8px 8px 0 0",
    // border: `2px solid ${theme.palette.primary.dark}`,
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  addNewGroup: {
    float: "left",
    cursor: "pointer"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 6
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  },
  filterWrapper: {
    width: "100%",
    float: "left"
  },
  radioFilters: {
    float: "right"
  },
  radioFilter: {
    "& > span": {
      fontSize: 16
    }
  },
  floatRight: {
    float: "right"
  },
  ruleTypeWrapper: {
    width: 133,
    float: "left"
  },
  userFilter: {
    width: 133,
    float: "left",
    marginLeft: 15
  },
  customFilterContainer: {
    marginTop: 40,
    float: "left"
  }
}));
