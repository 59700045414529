import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import { ReactComponent as DownArrowFilled } from "assets/icons/DownArrowFilled.svg";
import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import SMButton from "components/SMButton/SMButton";
import SearchTextField from "components/SearchTextField/SearchTextField";

export interface SMOption {
  name: string;
  id: string;
  [key: string]: boolean | string | number;
}
interface Props {
  optionsList: SMOption[];
  selectedList: SMOption[];
  appendText?: string;
  searchPlaceholder?: string;
  labels?: {
    txtShowMore?: string;
    txtShowLess?: string;
    txtSelectClearToggle?: string;
    txtClearAll?: string;
    txtSelected?: string;
    txtNotSelected?: string;
  };
  onChange: (items: SMOption[]) => void;
  filterType?: boolean;
  prependText?: string;
}

export const useStyles = makeStyles((theme: any) => ({
  customSelectSontainer: {
    width: "100%",
    float: "left",
    position: "relative"
  },
  selectectText: {
    padding: "16px 14px",
    border: `1px solid ${theme.palette.primary.grey}`,
    borderRadius: 5,
    fontSize: 16,
    color: theme.palette.primary.listHeader,
    cursor: "pointer"
  },
  downArrow: {
    float: "right",
    marginRight: 8,
    marginLeft: 13
  },
  upArrow: {
    transform: "rotate(180deg)" /* Equal to rotateZ(45deg) */
  },
  active: {},
  optionUl: {
    margin: 0,
    padding: 0,
    float: "left",
    width: "100%"
  },
  itemName: {
    fontSize: 16,
    color: theme.palette.primary.listHeader
  },
  customSelectOptions: {
    padding: "16px 16px 16px 11px",
    width: "100%",
    float: "left",
    listStyleType: "none",
    cursor: "pointer",
    "&  .MuiFormControlLabel-root": {
      marginRight: 12,
      marginLeft: 0
    }
  },
  label: {
    fontSize: 16,
    color: theme.palette.primary.listHeader,
    padding: "7px 16px 16px 8px",
    width: "100%",
    float: "left"
  },

  showListContainer: {
    padding: 32,
    width: "100%",
    float: "left",
    background: "#FFF",
    boxShadow:
      "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    zIndex: 666,
    minWidth: 436
  },
  listSection: {},
  floatLeft: {
    float: "left"
  },
  btnWithNoBg: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    cursor: "pointer",
    textTransform: "capitalize"
  },
  btnWrapper: {
    float: "left",
    width: "100%",
    borderBottom: `2px solid ${theme.palette.secondary.grey}`,
    marginBottom: 9,
    padding: "10px 0"
  },
  checkBox: {
    padding: 0
  },
  listSectionWrapper: {
    maxHeight: 440,
    overflowY: "scroll",
    width: "100%",
    float: "left"
  },
  divider: {
    color: theme.palette.primary.dark,
    marginTop: 8,
    float: "left",
    fontWeight: "bold"
  },
  footer: {
    float: "right",
    padding: "32px 32px 0 32px"
  },
  okButtonWrapper: {
    marginLeft: 42,
    float: "right"
  },
  positionTop: {
    marginTop: ({ marginTop }: { marginTop: number }) => `-${marginTop}px`
  },
  roundedCorner: {
    borderRadius: 32,
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: "5px 12px 6px 12px",
    fontSize: 14,
    height: 32,
    color: theme.palette.primary.dark
  },
  downArrowFilled: {
    position: "absolute",
    right: 13,
    top: 4
  },
  upArrowFilled: {
    transform: "rotate(180deg)" /* Equal to rotateZ(45deg) */,
    lineHeight: "28px"
  }
}));

function SMCustomSelect({
  optionsList,
  searchPlaceholder = "Search",
  selectedList = [],
  appendText,
  prependText,
  labels = {
    txtShowMore: "Show All",
    txtShowLess: "Show Less",
    txtSelectClearToggle: "Select All / Clear All",
    txtClearAll: "Clear All",
    txtSelected: "Selected",
    txtNotSelected: "Not Selected"
  },
  onChange,
  filterType = false
}: Props): JSX.Element {
  const marginTop =
    (optionsList.length * 56 < 440 ? optionsList.length * 56 + 90 : 440) + 273;
  const classes = useStyles({ marginTop });
  const [selectText, setSelectText] = useState<any>();
  const [toggle, setToggle] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [showAll, setShowAll] = useState<boolean>(false);
  const [posTop, setPosTop] = useState<boolean>(false);
  const [filteredOptionList, setFilteredOptionList] = useState<SMOption[]>([]);
  const [optionsNotSelected, setOptionsNotSelected] = useState<SMOption[]>([]);
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setText();
    init();
    //  document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [optionsList]);

  useEffect(() => {
    setText();
  }, [selected]);

  const handleListDisplay = (e: any) => {
    if (e.clientY + 500 > window.innerHeight && !toggle && e.clientY > 650) {
      setPosTop(true);
    } else {
      setPosTop(false);
    }
    setToggle(!toggle);
    if (toggle) {
      resetSelected();
    }
  };

  const handleOptionClick = (id: string, value: boolean) => {
    setSelected({ ...selected, [id]: value });
  };

  // const handleClickOutside = (e: any) => {
  //   if (
  //     !getClosest(e.target, classes.selectectText) &&
  //     !getClosest(e.target, classes.showListContainer) &&
  //     toggle
  //   ) {
  //     setToggle(false);
  //     resetSelected();
  //   }
  // };

  const resetSelected = () => {
    const slctd: { [key: string]: boolean } = {};
    selectedList?.forEach((option: SMOption) => {
      if (option && option.id) {
        slctd[option.id] = true;
      }
    });
    setSelected(slctd);

    const selectedIds: string[] = selectedList.map(
      (item: SMOption) => item?.id
    );
    const filtrdList = optionsList.filter(
      (item: SMOption) => selectedIds.indexOf(item.id) === -1
    );
    setOptionsNotSelected(filtrdList);
    setFilteredOptionList(filtrdList);
  };

  const setText = () => {
    const totalSelected = Object.values(selected).filter((v) => v).length;
    if (!filterType) {
      setSelectText(`${totalSelected} ${appendText || ""}`);
    } else {
      setSelectText(
        `${prependText || ""} (${
          totalSelected === optionsList.length ? "All" : totalSelected
        }) ${appendText || ""}`
      );
    }
  };

  const init = () => {
    resetSelected();
  };

  const onSearch = (q: string) => {
    const arrCopy = optionsNotSelected.filter((item: SMOption) => {
      return item.name.toString().includes(q);
    });
    setFilteredOptionList(arrCopy);
  };

  const toggeleSelectAll = () => {
    const slctd: { [key: string]: boolean } = { ...selected };
    const slctdItems: SMOption[] = optionsList.filter(
      (option: SMOption) => selected[option.id]
    );
    const allSelectd = slctdItems.length === optionsList.length;
    optionsList.forEach((option: SMOption) => {
      slctd[option.id] = !allSelectd;
    });
    setSelected(slctd);
  };

  const applyChanges = () => {
    const selectedItems: SMOption[] = optionsList.filter(
      (option: SMOption) => selected[option.id]
    );

    const selectedIds: string[] = selectedItems.map(
      (item: SMOption) => item?.id
    );
    const filtrdList = optionsList.filter(
      (item: SMOption) => selectedIds.indexOf(item.id) === -1
    );

    onChange(selectedItems);
    setToggle(false);

    setOptionsNotSelected(filtrdList);
    setFilteredOptionList(filtrdList);
  };

  const getClosest = (target: any, classname: string): boolean => {
    if (target?.classList?.contains(classname)) {
      return true;
    }
    if (target.parentNode?.nodeName === "BODY") {
      return false;
    }
    return getClosest(target.parentNode, classname);
  };

  return (
    <div className={classes.customSelectSontainer}>
      <div
        className={`${toggle ? classes.active : ""} ${classes.selectectText} ${
          filterType ? classes.roundedCorner : ""
        }`}
        onClick={(e: any) => handleListDisplay(e)}
        aria-hidden
      >
        {selectText}
        {!filterType ? (
          <span
            className={`${classes.downArrow} ${toggle ? classes.upArrow : ""}`}
          >
            <DownArrowOutline />
          </span>
        ) : (
          ""
        )}
        {filterType ? (
          <span
            className={`${classes.downArrowFilled} ${
              toggle ? classes.upArrowFilled : ""
            }`}
          >
            <DownArrowFilled />
          </span>
        ) : (
          ""
        )}
      </div>

      {toggle && (
        <div
          ref={listContainerRef}
          className={`${classes.showListContainer}  ${
            posTop ? classes.positionTop : ""
          }`}
        >
          {/* ${
            posTop ? classes.positionTop : ""
          } */}
          <SearchTextField
            onChange={onSearch}
            width="100%"
            placeholder={searchPlaceholder}
          />
          <div className={classes.listSectionWrapper}>
            {selectedList && selectedList.length ? (
              <CustomOptionList
                title={labels?.txtSelected || ""}
                classes={classes}
                list={selectedList}
                enableShowAll
                showAll={showAll}
                handleOptionClick={handleOptionClick}
                selected={selected}
                txtShowLess={labels.txtShowLess}
                txtShowMore={labels.txtShowMore}
                setShowAll={setShowAll}
              />
            ) : (
              ""
            )}
            <CustomOptionList
              title={labels?.txtNotSelected || ""}
              classes={classes}
              list={filteredOptionList}
              enableShowAll={false}
              showAll
              handleOptionClick={handleOptionClick}
              selected={selected}
            />
          </div>

          <div className={classes.footer}>
            <div className={classes.btnWithNoBg}>
              <Button
                onClick={toggeleSelectAll}
                className={`${classes.btnWithNoBg} ${classes.floatLeft}`}
              >
                {labels.txtSelectClearToggle}
              </Button>
              <div className={classes.okButtonWrapper}>
                <SMButton text="Ok" onClick={applyChanges} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SMCustomSelect;

function CustomOptionList({
  classes,
  list,
  title,
  enableShowAll,
  showAll,
  handleOptionClick,
  selected,
  txtShowLess,
  txtShowMore,
  setShowAll
}: {
  classes: any;
  title: string;
  enableShowAll: boolean;
  showAll: boolean;
  txtShowMore?: string;
  txtShowLess?: string;
  list: SMOption[];
  selected: { [key: string]: boolean };
  setShowAll?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOptionClick: (id: string, value: boolean) => void;
}): JSX.Element {
  const selectedListSize = 3;
  return (
    <div className={classes.listSection}>
      {title ? <div className={classes.label}>{title}</div> : ""}
      <ul className={classes.optionUl}>
        {list
          .slice(0, showAll ? list.length : selectedListSize)
          .map((option: any) => {
            return (
              <li
                className={classes.customSelectOptions}
                data-name={option.name}
                key={option.id}
                onClick={(e: any) =>
                  handleOptionClick(option.id, !!selected[option.id])
                }
                aria-hidden
              >
                <span>
                  <FormControlLabel
                    value="select"
                    control={
                      <Checkbox color="default" className={classes.checkBox} />
                    }
                    label=""
                    labelPlacement="end"
                    checked={!!selected[option.id]}
                    onChange={(e, value) => {
                      handleOptionClick(option.id, value);
                    }}
                  />
                </span>
                <span className={classes.itemName}>{option.name}</span>
              </li>
            );
          })}
      </ul>

      {list && list.length > 3 && enableShowAll ? (
        <div className={classes.btnWrapper}>
          <Button
            onClick={() => setShowAll && setShowAll(!showAll)}
            className={classes.btnWithNoBg}
          >
            {showAll ? txtShowLess : txtShowMore}
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
