import React from "react";

import SMButton from "components/SMButton/SMButton";

function UserListSaveCancelButtons({
  classes,
  txtSave,
  txtCancel,
  onSave,
  onCancel
}: {
  classes: any;
  txtSave: string;
  onSave: () => void;
  txtCancel: string;
  onCancel: () => void;
}) {
  return (
    <div className={`${classes.row} ${classes.alignFlexEnd}`}>
      <div className={classes.actionBtnWrapper}>
        <div className={classes.actionBtn}>
          <SMButton text={txtSave} onClick={onSave} />
        </div>
        <div className={classes.actionBtn}>
          <SMButton outlineBtn text={txtCancel} onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}
export default UserListSaveCancelButtons;
