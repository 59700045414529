import Dexie, { Table } from "dexie";

import { SMNotification, SMSReceiver } from "interfaces/notification.interface";

import { populate } from "./populate";
// import { populate } from './populate';
// import { TodoItem } from './TodoItem';
// import { TodoList } from './TodoList';

export class NotificationDB extends Dexie {
  notification!: Table<SMNotification, number>;

  recievers!: Table<SMSReceiver, number>;

  constructor() {
    super("NotificationDB");
    this.version(15).stores({
      recievers: "++id",
      notification: "++id"
    });
  }
}
export const db = new NotificationDB();
db.on("populate", populate);
db.open().catch(function (err) {
  console.error(`Failed to open db: ${err.stack || err}`);
});
