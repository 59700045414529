import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authReducer";
import customizationReducer from "./customizationReducer";
import deviceReducer from "./devicesReducer";
import devicesSettingsReducer from "./devicesSettingsReducer";
import notificationLogReducer from "./notificationsLogReducer";
import notificationReducer from "./notificationReducer";
import reportReducer from "./reportReducer";
import smsRecievers from "./SMSRecieversReducer";
import userGroupsReducer from "./userGroupReducer";
import usersReducer from "./usersReducer";

const persistConfig = {
  key: "SOMNOFY_CUSTOMIZATION",
  storage
};

export default combineReducers({
  auth: authReducer,
  users: usersReducer,
  deviceReducer,
  devicesSettings: devicesSettingsReducer,
  customization: persistReducer(persistConfig, customizationReducer),
  userGroupsReducer,
  reportReducer,
  notificationReducer,
  notificationLogReducer,
  smsRecievers
});
