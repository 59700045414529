import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import { ReactComponent as DownArrowFilled } from "assets/icons/DownArrowFilled.svg";
import SMButton from "components/SMButton/SMButton";

export interface SMOption {
  name: string;
  id: string;
  [key: string]: boolean | string | number;
}
interface Props {
  onChange: (items: string[]) => void;
  title: string;
  allSelectedText?: string;
  customSeletedText?: string;
  pastDayText?: string;
  pastWeekText?: string;
  pastMonthText?: string;
  customRangeText?: string;
  buttonText?: string;
}

export const useStyles = makeStyles((theme: any) => ({
  customSelectSontainer: {
    width: "100%",
    float: "left",
    position: "relative"
  },
  active: {},
  showListContainer: {
    padding: 32,
    width: "100%",
    float: "left",
    background: "#FFF",
    boxShadow:
      "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    zIndex: 666,
    minWidth: 375
  },
  positionTop: {
    marginTop: ({ marginTop }: { marginTop: number }) => `-${marginTop}px`
  },
  roundedCorner: {
    borderRadius: 32,
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: "5px 12px 6px 12px",
    fontSize: 14,
    height: 32,
    color: theme.palette.primary.dark,
    cursor: "pointer"
  },
  downArrowFilled: {
    position: "absolute",
    right: 13,
    top: 4
  },
  upArrowFilled: {
    transform: "rotate(180deg)" /* Equal to rotateZ(45deg) */,
    lineHeight: "28px"
  },
  applyButtonWrapper: {
    marginTop: 26,
    float: "left"
  },
  label: {
    fontSize: 16,
    marginTop: 29
  },
  inputRoot: {
    width: "100%",
    maxHeight: 48,
    border: `1px solid ${theme.palette.primary.input}`,
    borderRadius: 5,
    padding: "14px 16px",
    color: theme.palette.primary.input,
    margin: 0
  },
  txtField: {
    fontSize: 14,
    lineHeight: 20,
    padding: 0,
    margin: 0
  },
  title: {
    color: theme.palette.primary.listHeader,
    width: "100%",
    fontSize: 20,
    lineHeight: "24px",
    marginBottom: 16
  },
  dialogPaper: {
    backgroundColor: theme.palette.primary.lightBlue,
    marginLeft: 27,
    marginTop: 20,
    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: theme.palette.primary.lightBlue
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

function SMCustomDateFilter({
  onChange,
  title = "Filter by Date",
  allSelectedText = "Notification Firing Time (All)",
  customSeletedText = "Notification Firing Time (Custom)",
  pastDayText = "Last 24 hours",
  pastWeekText = "Last 7 days",
  pastMonthText = "Last 30 days",
  customRangeText = "Custom range",
  buttonText = "Apply Filter"
}: Props): JSX.Element {
  //   const marginTop =
  //     (optionsList.length * 56 < 440 ? optionsList.length * 56 + 90 : 440) + 273;
  const marginTop = 0;
  const classes = useStyles({ marginTop });
  const [selectText, setSelectText] = useState<any>();
  const [toggle, setToggle] = useState<boolean>(false);
  const [posTop, setPosTop] = useState<boolean>(false);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [option, setOption] = useState<"custom" | "all" | "7" | "24" | "30">(
    "all"
  );
  const [fromDate, setFromDate] = useState<string>("2014-08-18T21:11:54");
  const [toDate, setToDate] = useState<string>("2014-08-18T21:11:54");

  const handleDateChange = (date: string, type: "from" | "to") => {
    if (type === "from") {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };
  useEffect(() => {
    setText();
  }, []);

  useEffect(() => {
    setText();
  }, []);

  const handleListDisplay = (e: any) => {
    if (e.clientY + 500 > window.innerHeight && !toggle && e.clientY > 650) {
      setPosTop(true);
    } else {
      setPosTop(false);
    }
    setToggle(!toggle);
  };

  const setText = () => {
    if (option === "all") {
      setSelectText(allSelectedText);
    } else {
      setSelectText(customSeletedText);
    }
  };

  const applyChanges = () => {
    setText();
    setToggle(false);
    if (option === "custom") {
      onChange([fromDate, toDate]);
    } else if (option === "all") {
      onChange(["", ""]);
    } else {
      const numberOfDays = Number(option);
      const to = new Date();
      const from = new Date(to);
      from.setDate(from.getDate() - numberOfDays);
      onChange([from.toDateString(), to.toDateString()]);
    }
  };

  return (
    <div className={classes.customSelectSontainer}>
      <div
        className={`${toggle ? classes.active : ""} ${classes.roundedCorner}`}
        onClick={(e: any) => handleListDisplay(e)}
        aria-hidden
      >
        {selectText}
        <span
          className={`${classes.downArrowFilled} ${
            toggle ? classes.upArrowFilled : ""
          }`}
        >
          <DownArrowFilled />
        </span>
      </div>

      {toggle && (
        <div
          ref={listContainerRef}
          className={`${classes.showListContainer}  ${
            posTop ? classes.positionTop : ""
          }`}
        >
          <div className={classes.title}>{title}</div>
          <RadioGroup
            value={option}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setOption(
                event.target.value as "custom" | "all" | "7" | "24" | "30"
              )
            }
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={pastDayText}
            />
            <FormControlLabel
              value="7"
              control={<Radio />}
              label={pastWeekText}
            />
            <FormControlLabel
              value="30"
              control={<Radio />}
              label={pastMonthText}
            />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label={customRangeText}
            />
          </RadioGroup>
          {option === "custom" ? (
            <div>
              <div className={classes.label}>From Date </div>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.inputRoot}
                    PopoverProps={{
                      PaperProps: {
                        classes: {
                          root: classes.dialogPaper
                        }
                      }
                    }}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    value={fromDate}
                    onChange={(date: MaterialUiPickersDate, value: any) => {
                      handleDateChange(value, "from");
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.txtField
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.label}>To Date </div>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    PopoverProps={{
                      PaperProps: {
                        classes: {
                          root: classes.dialogPaper
                        }
                      }
                    }}
                    className={classes.inputRoot}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    value={toDate}
                    onChange={(date: MaterialUiPickersDate, value: any) => {
                      handleDateChange(value, "to");
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.txtField
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={classes.applyButtonWrapper}>
            <SMButton text={buttonText} onClick={applyChanges} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SMCustomDateFilter;
