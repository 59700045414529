import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import rawRoutes from "./routes";

export const populateWithUsers = (routes = [], users) => {
  return routes.map((route) => {
    if (route.collapse) {
      if (route.withUserRoutes && users) {
        const userRoutes = users.map((user) => route.withUserRoutes(user));
        return { ...route, views: [...(route.views || []), ...userRoutes] };
      }
      return {
        ...route,
        views: [
          ...(route.views || []),
          ...populateWithUsers(route.views, users)
        ]
      };
    }
    return { ...route };
  });
};

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: []
    };

    this.getActiveRoute = this.getActiveRoute.bind(this);
    this.renderRoutes = this.renderRoutes.bind(this);
  }

  getActiveRoute(routes = this.state.routes) {
    const activeRoute = { name: "Somnofy" };
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse) {
        const collapsedRoute = this.getActiveRoute(routes[i].views);
        if (!_.isEqual(collapsedRoute, activeRoute)) {
          return collapsedRoute;
        }
      } else if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) > -1
      ) {
        return routes[i];
      }
    }
    return activeRoute;
  }

  getRoutes() {
    return this.state.routes.filter(
      (route) => route.layout === this.props.layout
    );
  }

  renderRoutes(routes) {
    return routes.map((route, key) => {
      if (route.collapse) {
        return [
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={route.path}
          />,
          this.renderRoutes(route.views)
        ];
      }
      if (route.layout === this.props.layout) {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={route.path}
          />
        );
      }
      return null;
    });
  }

  static getDerivedStateFromProps(nextProps, state) {
    // populate routes with new props
    const { users } = nextProps;
    const populatedRoutes = populateWithUsers([...rawRoutes], users);
    return { ...state, routes: populatedRoutes };
  }

  render() {
    return <Switch>{this.renderRoutes(this.state.routes)}</Switch>;
  }
}

Router.propTypes = {
  layout: PropTypes.any,
  users: PropTypes.any
};

const mapStateToProps = (state) => {
  return { users: state.users.users };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Router
);
