import React from "react";
import _ from "lodash";

import { Device, DeviceSettings } from "interfaces/device.interface";
import { getDisabledSleepTracking } from "utils/deviceManagement";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import Highlighted from "components/Highlighted/HighLighted";
import UserListActionButtons from "components/UserListActionButtons/UserListActionButtons";

interface Props {
  filterType?: "device" | "user";
  common: any;
  device: Device;
  onClick: (event: any, devieObj: Device) => void;
  searchQuery?: string;
  t: any;
  user: any;
  deviceSettings: DeviceSettings;
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  onDelete: (user: Device) => void;
  editDevice: { [key: string]: boolean };
  setEditDevice: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
}

function DeviceListRowView({
  filterType,
  common,
  onClick,
  searchQuery,
  device,
  t,
  user,
  deviceSettings,
  selectedItems,
  setSelectedItems,
  onDelete,
  editDevice,
  setEditDevice
}: Props) {
  const { name, serial_number, time_since_last_activity } = device;
  const { birth_year, display_name, gender } = user;
  return (
    <div
      className={`${common.row} ${common.evenChildBackground}`}
      onClick={(event: any) => onClick(event, device)}
      onKeyDown={(event: any) => onClick(event, device)}
      aria-hidden="true"
    >
      <BulkActionCheckBox
        wrapperClassName={common.checkboxlistWraper}
        id={device.serial_number}
        selected={selectedItems}
        setSelected={setSelectedItems}
      />

      <div className={`${common.col} ${common.colorPrimary}`}>
        {filterType !== "device" && name}
        {filterType === "device" && (
          <Highlighted text={name} highlight={searchQuery} />
        )}
      </div>

      <div className={common.col}>{serial_number}</div>
      <div className={common.colLarge}>
        {filterType !== "user" &&
          `${display_name}${" "}(${t(gender)},${birth_year})`}
        {filterType === "user" && (
          <Highlighted
            text={`${display_name}${" "}(${gender},${birth_year})`}
            highlight={searchQuery}
          />
        )}
      </div>
      <div className={common.col}>
        {time_since_last_activity <= 600 && (
          <span style={{ color: "#212121" }}>{t("Online")}</span>
        )}
        {(time_since_last_activity >= 600 ||
          time_since_last_activity == null) && (
          <span style={{ color: "#B00020" }}>{t("Offline")}</span>
        )}
      </div>
      <div className={common.col}>
        {deviceSettings?.distanceLimit &&
          `${deviceSettings?.distanceLimit?.toFixed(2)} meter(s)`}
      </div>
      <div className={common.col}>
        {`${getDisabledSleepTracking(deviceSettings).join(" - ")}`}
      </div>

      <UserListActionButtons
        classes={common}
        onEditClick={() => {
          const updated = _.mapValues(editDevice, () => false);
          setEditDevice({ ...updated, [device.serial_number]: true });
        }}
        onDeleteClick={() => onDelete(device)}
      />
    </div>
  );
}
export default DeviceListRowView;
