import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import { UserGroupType } from "constants/userGroupConstants";
import { createNewSubUser, fetchAllTheUsers } from "store/actions/userActions";
import {
  generateAutoFillEmail,
  generateAutoFillPassword,
  generateBirthYearOptions,
  getUserBaseObject
} from "utils/userManagement";
import { getAllUserGroups } from "store/actions/groupActions";
import { normalizedGroupsEntity } from "utils/groupManagement";
import { toastError, toastSucess } from "utils/toast.util";
import AutoSuggestSelect from "components/AutoSuggestSelect/AutoSuggestSelect";
import PasswordTextField from "components/PasswordTexField/PasswordTextField";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMTextFieldWithIcon from "components/SMTextFieldWithIcon/SMTextFieldWithIcon";
import SaveCancelDeleteButton from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "32px 37px 32px 32px",
    width: 551,
    color: theme.palette.primary.listHeader
  },
  content: {},
  title: {
    fontSize: 24,
    marginBottom: 24,
    float: "left"
  },
  titleWrapper: {
    width: "100%",
    float: "left"
  },
  close: {
    float: "right",
    cursor: "pointer"
  },
  formFieldWrapper: {
    marginTop: 32,
    width: "100%",
    float: "left"
  },
  label: {
    fontSize: 16,
    width: "100%",
    float: "left"
  },
  radioGroup: {
    flexDirection: "row",
    float: "left"
  },
  radio: {
    marginRight: 34
  },
  formElem: {
    float: "left",
    width: 377
  },
  footerWrapper: {
    marginTop: 24,
    float: "left"
  },
  sexFieldWrapper: {
    marginTop: 64
  },
  endAdornmentStyle: {
    paddingRight: 18
  },
  emailInfo: {
    width: 230,
    fontSize: 12,
    marginTop: 5
  }
}));
function AddUserSlidingForm({
  onclose
}: {
  onclose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [user, setUser] = useState<User>(
    _.cloneDeep(getUserBaseObject(UserAttributesTypes.device))
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});
  const [customSettings, setCustomSettings] = useState<any>({});

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, [dispatch]);

  useEffect(() => {
    fetch("/custom_settings.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((settings: any) => {
        setCustomSettings(settings.custom_settings);
      });
  }, []);

  useEffect(() => {
    if (userState.currentAction === USER_ACTIONS.createSuccess) {
      toastSucess(t("User added successfully"));
      restUser();
      // dispatch(resetUserPreloaders());
      dispatch(fetchAllTheUsers());
    }
  }, [userState.currentAction]);

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  useEffect(() => {
    const updatedUser: User = {
      ...user,
      email: generateAutoFillEmail(user, loginedUser),
      password: generateAutoFillPassword(8)
    };
    setUser(updatedUser);
  }, []);

  const handleUserIDBlur = () => {
    onChange(generateAutoFillEmail(user, loginedUser), "email");
  };

  const restUser = () => {
    setUser(_.cloneDeep(getUserBaseObject(UserAttributesTypes.device)));
    onclose(false);
  };

  const validateDateAndSave = () => {
    setErrorMessage("");
    if (!user.first_name) {
      setErrorMessage(t("User ID is missing. Please enter User ID"));
      // show toast
      toastError(t("User ID is missing. Please enter User ID"));
    } else {
      const userWithEmailAndPass = {
        ...user,
        email: user.email || generateAutoFillEmail(user, loginedUser),
        password: user.password || generateAutoFillPassword(8),
        attributes: {
          user_type: UserAttributesTypes.device,
          privileges: []
        }
      };
      dispatch(createNewSubUser({ user: userWithEmailAndPass, groupEntity }));
    }
  };

  const onChange = (value: any, key: string) => {
    const updatedUser: User = {
      ...user,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;
    setUser(updatedUser);
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>{t("Add User")}</div>
        <div className={classes.close} onClick={() => restUser()} aria-hidden>
          <CloseIcon />
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("User Id")}*`}</div>
        <SMTextField
          placeholder={t("User ID")}
          onSave={(val: string) => {
            onChange(val, "first_name");
          }}
          value={user.first_name}
          delay={0}
          borderRed={(errorMessage && !user.first_name) || false}
          width={343}
          handleBlur={handleUserIDBlur}
        />
      </div>

      {customSettings &&
      !!customSettings[loginedUser.email]?.administration?.users
        ?.enable_email ? (
        <div className={classes.formFieldWrapper}>
          <div className={classes.label}>{`${t("Email")}`}</div>
          <div>
            <SMTextField
              onSave={(val: string) => {
                onChange(val, "email");
              }}
              value={user.email}
              delay={0}
              placeholder={t("e.g. Kari.Nordmann@norge.kommune.no")}
              width={343}
            />
            <div className={classes.emailInfo}>
              {t(
                "This field will be auto generated if you leave this field blank"
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {customSettings &&
      !!customSettings[loginedUser.email]?.administration?.users
        ?.enable_password ? (
        <div className={classes.formFieldWrapper}>
          <div className={classes.label}>{`${t("Password")}`}</div>
          <div style={{ width: 377 }}>
            <PasswordTextField
              value={user.password}
              autofill
              placeholder={t("Password")}
              label=""
              onChange={(val: string) => onChange(val, "password")}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Last Name")}`}</div>
        <SMTextField
          placeholder={t("User's Last name")}
          onSave={(val: string) => {
            onChange(val, "last_name");
          }}
          value={user.last_name}
          delay={0}
          borderRed={(errorMessage && !user.last_name) || false}
          width={343}
        />
      </div> */}

      <div className={`${classes.formFieldWrapper} ${classes.sexFieldWrapper}`}>
        <div className={classes.label}>{`${t("Sex")}`}</div>
        <div>
          <RadioGroup
            value={user.gender}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value as string, "gender")
            }
            classes={{ root: classes.radioGroup }}
          >
            <FormControlLabel
              value="Male"
              control={<Radio />}
              label={t("Male")}
              className={classes.radio}
            />
            <FormControlLabel
              value="Female"
              control={<Radio />}
              label={t("Female")}
              className={classes.radio}
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label={t("Other")}
              className={classes.radio}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Birth Year")}`}</div>
        <div className={classes.label}>
          <AutoSuggestSelect
            defaultValue={`${user.birth_year}`}
            options={generateBirthYearOptions()}
            onSelect={(value: string) => onChange(value, "birth_year")}
            width={377}
          />
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Height")}`}</div>
        <div className={classes.formElem}>
          <SMTextFieldWithIcon
            placeholder={t("Example 165 cm")}
            onChange={(val: string) => {
              onChange(val, "height");
            }}
            value={user.height ?? ""}
            endAdornment={
              <div className={classes.endAdornmentStyle}>meter</div>
            }
          />
        </div>
      </div>

      <div className={classes.formFieldWrapper}>
        <div className={classes.label}>{`${t("Weight")}`}</div>
        <div className={classes.formElem}>
          <SMTextFieldWithIcon
            placeholder={t("Example 78 kg")}
            onChange={(val: string) => {
              onChange(val, "weight");
            }}
            value={user.weight ?? ""}
            endAdornment={<div className={classes.endAdornmentStyle}>kg</div>}
          />
        </div>
      </div>

      <div className={`${classes.formFieldWrapper} ${classes.sexFieldWrapper}`}>
        <div className={classes.label}>{`${t("Belongs to User Groups")}`}</div>
        <SMGroupLists
          label={t("User Groups")}
          defaultValues={user.user_groups || []}
          lists={Object.values(groupEntity).filter(
            (group: Group) => group.type === UserGroupType.device
          )}
          onChange={onChange}
          searchPlaceholder={t("Search")}
          userGroupType={UserGroupType.device}
          showItems={6}
          inline
          hideLabel
          hideDrawer
        />
      </div>

      <div className={classes.footerWrapper}>
        <SaveCancelDeleteButton
          txtSave={t("Add User")}
          txtCancel={t("Cancel")}
          txtDelete=""
          onCancel={restUser}
          onSave={validateDateAndSave}
          onDelete={() => {}}
          hideDelete
        />
      </div>
    </div>
  );
}
export default AddUserSlidingForm;
