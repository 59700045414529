export const sessionTypes = {
  GET_SESSIONS: "GET_SESSIONS",
  GET_SESSIONS_PENDING: "GET_SESSIONS_PENDING",
  GET_SESSIONS_FULFILLED: "GET_SESSIONS_FULFILLED",
  GET_SESSIONS_REJECTED: "GET_SESSIONS_REJECTED",

  GET_SESSION: "GET_SESSION",
  GET_SESSION_PENDING: "GET_SESSION_PENDING",
  GET_SESSION_FULFILLED: "GET_SESSION_FULFILLED",
  GET_SESSION_REJECTED: "GET_SESSION_REJECTED",

  SET_ACTIVE_SESSION_IX: "SET_ACTIVE_SESSION",
  SET_HOVER_SESSION_IX: "SET_HOVER_SESSION"
};

export const sleepAnalysisTypes = {
  GET_SLEEP_ANALYSIS: "GET_SLEEP_ANALYSIS",
  GET_SLEEP_ANALYSIS_PENDING: "GET_SLEEP_ANALYSIS_PENDING",
  GET_SLEEP_ANALYSIS_REJECTED: "GET_SLEEP_ANALYSIS_REJECTED",
  GET_SLEEP_ANALYSIS_FULFILLED: "GET_SLEEP_ANALYSIS_FULFILLED"
};

export const authTypes = {
  LOGIN: "LOGIN",
  LOGIN_REJECTED: "LOGIN_REJECTED",
  LOGIN_FULFILLED: "LOGIN_FULFILLED",

  AUTHENTICATE: "AUTHENTICATE",
  AUTHENTICATE_REJECTED: "AUTHENTICATE_REJECTED",
  AUTHENTICATE_FULFILLED: "AUTHENTICATE_FULFILLED",

  LOGOUT: "LOGOUT",
  LOGOUT_REJECTED: "LOGOUT_REJECTED",
  LOGOUT_FULFILLED: "LOGOUT_FULFILLED"
};

export const customizationTypes = {
  SET_STYLE_TYPE: "SET_STYLE_TYPE"
};
