import "../../../css/Monitor.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { InputLabel } from "@material-ui/core";
import { faCog, faRedo } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EnterFullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitFullscreenIcon from "@material-ui/icons/FullscreenExit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import React, { useSelector, useState } from "react";
import Select from "@material-ui/core/Select";
import SettingsIcon from "@material-ui/icons/Settings";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import cn from "classnames";

import { NotFound } from "../../../components/index";
import { canViewMonitor } from "../../../utils/userManagement";
import { useStyles } from "./moniter.styles";
import Dashboard from "../../../components/Dashboard";
import InformationTile from "../../../components/InformationTile";

const get = require("lodash.get");

const pad = (num) => {
  return num < 10 ? `0${num}` : num;
};

const SortTypes = {
  AZ: "a-z",
  LastActivity: "lastActivity"
};
library.add(faCog, faRedo);

function MonitorComponent({
  users,
  time,
  lastUpdated,
  updateSessions,
  setRefreshTime,
  refreshTime,
  dialogOpen,
  toggleDialog,
  tileScale,
  setTileScale,
  toggleDashboardModal,
  dashboardModalUser,
  dashboardModalUserName,
  apiInfo,
  loginedUser
}) {
  const classes = useStyles();
  const [scaleFocused, toggleScaleFocus] = useState(false);
  const [query, setQuery] = useState("");
  const [sortType, setSortType] = useState("");
  const handle = useFullScreenHandle();
  const { t } = useTranslation();
  const weekdays = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday")
  ];

  /*
   * FilterdSortedUsers
   * @description: This fucntion will return filter users sessions with sorted based
   *               on the sorting type
   * @param usersession: List of user session props
   * @param searchQuery: Search query to filter data
   * @param sortType   : Sort type, which is a type of SortTypes enum
   */
  const filterdSortedUsers = (uSessions, searchQuery, sType) => {
    let userSessions = uSessions;
    if (userSessions) {
      userSessions = userSessions.filter((session) => {
        return session.user.display_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    }

    if (sType === SortTypes.AZ) {
      userSessions.sort((a, b) =>
        a.user.display_name > b.user.display_name ? 1 : -1
      );
    } else if (sType === SortTypes.LastActivity) {
      userSessions.sort((a, b) => {
        const dateA = new Date(a.device.last_activity_at).getTime();
        const dateB = new Date(b.device.last_activity_at).getTime();
        return dateA < dateB ? 1 : -1;
      });
    }
    return userSessions;
  };

  function classList(clsList) {
    return Object.entries(clsList)
      .filter((entry) => entry[1])
      .map((entry) => entry[0])
      .join(" ");
  }

  const getSearchBarWidth = () => {
    const marginLeft = 10;
    const iconSize = marginLeft + 38 + (tileScale / 100 - 0.2) * 10;
    return `calc(100% - ${iconSize}px)`;
  };

  const handleChange = (event) => {
    setSortType(event.target.value);
  };

  return (
    <FullScreen style={{ height: "100%" }} handle={handle}>
      <div className={classes.root}>
        <div className={classes.main}>
          <div
            className={cn(classes.header, {
              [classes.fixedHeader]: handle.active
            })}
          >
            <Grid container spacing={3}>
              <Grid item xs>
                {!handle.active ? (
                  <EnterFullscreenIcon
                    onClick={handle.enter}
                    className={classes.layoutIcon}
                    style={{ fontSize: 38 + (tileScale / 100 - 0.2) * 10 }}
                  />
                ) : (
                  <ExitFullscreenIcon
                    onClick={handle.exit}
                    className={classes.layoutIcon}
                    style={{ fontSize: 38 + (tileScale / 100 - 0.2) * 10 }}
                  />
                )}

                <div className={classes.search}>
                  <TextField
                    className={classes.searchBox}
                    label={t("Search")}
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    style={{ width: getSearchBarWidth() }}
                  />

                  <div className={classes.sortWrapper}>
                    <FormControlLabel
                      onChange={handleChange}
                      className={classes.sortOptions}
                      checked={sortType === SortTypes.AZ}
                      value={SortTypes.AZ}
                      control={<Radio color="default" />}
                      label={t("A — Z")}
                    />
                    <FormControlLabel
                      onChange={handleChange}
                      className={classes.sortOptions}
                      value={SortTypes.LastActivity}
                      checked={sortType === SortTypes.LastActivity}
                      control={<Radio color="default" />}
                      label={t("last actvity")}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.dayInfo}>
                  <Typography
                    style={{ fontSize: (tileScale / 100) * 64 }}
                    variant="h2"
                  >
                    {pad(time.getHours())}:{pad(time.getMinutes())}
                  </Typography>
                  <Typography
                    style={{ fontSize: (tileScale / 100) * 42 }}
                    variant="h4"
                  >
                    {weekdays[time.getDay() - 1]} {time.getDate()}.
                    {pad(time.getMonth() + 1)}.
                    {time.getFullYear().toString().substr(2)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.settingsIcon}>
                  <SettingsIcon
                    onClick={toggleDialog}
                    style={{
                      color: "#898989",
                      fontSize: 32 + (tileScale / 100 - 0.2) * 10
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div
            className={classes.contentWrapper}
            style={{
              ...(handle.active && {
                marginTop: 67 + (tileScale / 100) * 10 * 5.8
              })
            }}
          >
            {users ? (
              <Grid
                className={classList({
                  "grid-150": tileScale <= 150,
                  "grid-120": tileScale <= 120,
                  "grid-90": tileScale <= 90,
                  "grid-60": tileScale <= 60,
                  "grid-38": tileScale <= 38,
                  "grid-26": tileScale <= 26,
                  "grid-19": tileScale <= 19,
                  "grid-14": tileScale <= 14,
                  "grid-10": tileScale <= 10,
                  "grid-6": tileScale <= 6,
                  "grid-3": tileScale <= 3,
                  "grid-2": tileScale <= 2,
                  "grid-1": tileScale <= 1
                })}
              >
                {filterdSortedUsers(users, query, sortType).map((user, i) => (
                  <InformationTile
                    onClick={() => {
                      if (
                        get(
                          user.clients,
                          "com-vitalthings-somnofy-webui.monitor_only",
                          false
                        )
                      )
                        return;
                      if (canViewMonitor(loginedUser)) {
                        toggleDashboardModal(
                          user.getUserId(),
                          `${user.getName()} (${user.user?.gender || ""}, ${
                            user?.user?.birth_year || ""
                          })`
                        );
                      }
                    }}
                    information={user}
                    // Todo: (fr@vitalthings.com)
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    query={query}
                    tileScale={tileScale}
                  />
                ))}

                {!filterdSortedUsers(users, query, sortType).length && (
                  <NotFound text={t("Result not found")} />
                )}
              </Grid>
            ) : (
              <CircularProgress />
            )}
            <div className={classes.bottomInformation}>
              <Typography
                style={{ fontSize: (tileScale / 100) * 32 }}
                align="center"
                variant="h5"
              >
                &quot;{t("Awake in bed")}&quot;{" "}
                {t("AWAKE_FILTER_PLACEHOLDER", { awakeFilter: "2" })} &quot;
                {t("Not in bed")}&quot;{" "}
                {t("Not in bed text", { notInBedFilter: "1" })} &quot;
                {t("Movement")}&quot;{" "}
                {t("MOVEMENT_PLACEHOLDER", { movementFilter: "40" })}
              </Typography>
              <Typography
                style={{ fontSize: (tileScale / 100) * 32 }}
                align="center"
                variant="h5"
              >
                {t("AUTOMATIC_UPDATE_PLACEHOLDER", { refreshTime })}{" "}
                {t("Last updated")}: {pad(lastUpdated.getHours())}:
                {pad(lastUpdated.getMinutes())}:{pad(lastUpdated.getSeconds())}
                <FontAwesomeIcon
                  icon="redo"
                  onClick={updateSessions}
                  className={classes.refreshButton}
                />
              </Typography>
            </div>
          </div>
        </div>
        <Dialog
          disablePortal
          open={dialogOpen}
          hideBackdrop={scaleFocused}
          classes={{ paper: scaleFocused && classes.hideBackground }}
        >
          <DialogTitle className={classes.slightlyVisible}>
            {t("Monitor settings")}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.slightlyVisible}>
              <InputLabel>{`${t(
                "Time interval for automatic updating"
              )}:`}</InputLabel>
              <Select
                className={classes.margin}
                value={refreshTime}
                onChange={setRefreshTime}
              >
                <MenuItem value={10}>10 {t("seconds")}</MenuItem>
                <MenuItem value={30}>30 {t("seconds")}</MenuItem>
                <MenuItem value={60}>1 {t("minute")}</MenuItem>
                <MenuItem value={120}>2 {t("minutes")}</MenuItem>
                <MenuItem value={300}>5 {t("minutes")}</MenuItem>
                <MenuItem value={600}>10 {t("minutes")}</MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel>{t("Scale")} </InputLabel>
              <Slider
                value={tileScale || "60"}
                valueLabelDisplay="on"
                step={1}
                min={1}
                max={150}
                onMouseDown={() => toggleScaleFocus(true)}
                onTouchStart={() => toggleScaleFocus(true)}
                onChangeCommitted={() => toggleScaleFocus(false)}
                onChange={(e, val) => setTileScale(val)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>{t("ok")}</Button>
          </DialogActions>
        </Dialog>
        <Modal
          disablePortal
          className={classes.dashboardModal}
          open={!!dashboardModalUser}
          onClose={() => toggleDashboardModal(null)}
        >
          <div
            className={classes.dashboardModalContent}
            style={{
              ...(handle.active && { margin: 0, height: "100%", width: "100%" })
            }}
          >
            <CloseIcon
              onClick={() => toggleDashboardModal(null)}
              className={classes.dashboardModalCloseIcon}
            />
            <Dashboard
              apiInfo={apiInfo}
              selectedUserId={dashboardModalUser}
              key={dashboardModalUser}
              userDisplayName={dashboardModalUserName}
            />
          </div>
        </Modal>
      </div>
    </FullScreen>
  );
}

MonitorComponent.propTypes = {
  users: PropTypes.any,
  time: PropTypes.any,
  lastUpdated: PropTypes.any,
  updateSessions: PropTypes.any,
  setRefreshTime: PropTypes.any,
  refreshTime: PropTypes.any,
  dialogOpen: PropTypes.any,
  toggleDialog: PropTypes.any,
  tileScale: PropTypes.any,
  setTileScale: PropTypes.any,
  toggleDashboardModal: PropTypes.any,
  dashboardModalUser: PropTypes.any,
  dashboardModalUserName: PropTypes.any,
  apiInfo: PropTypes.any,
  loginedUser: PropTypes.any
};

export default MonitorComponent;
