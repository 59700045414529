import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import CheckBoxCustom from "components/CustomFromElements/CheckBoxCustom";
import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  sortList: (type: string, order: "ASC" | "DESC") => void;
  allSelected: boolean;
  onToggleSelectAll: (checked: boolean) => void;
  isUserRule: boolean;
}

export const useStyles = makeStyles((theme) => ({
  checkboxWrapper: {},
  checkboxRoot: {
    marginRight: 12,
    float: "left"
  }
}));

function NotificationRuleListHeader({
  sortList,
  allSelected,
  onToggleSelectAll,
  isUserRule
}: Props) {
  const classes = CommonStyles();
  const custClasses = useStyles();
  const { t } = useTranslation();

  const [sortField, setSortField] = useState<string>("user");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const getSortOrder = (type: string) => {
    if (type === sortField && sortOrder === "ASC") {
      return "ASC";
    }
    if (type === sortField && sortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  const onHeaderClick = (type: string) => {
    let order = "ASC";
    if (sortField === type) {
      order = sortOrder === "ASC" ? "DESC" : "ASC";
    }
    setSortField(type);
    setSortOrder(order as "ASC" | "DESC");
    sortList(type, order as "ASC" | "DESC");
  };

  function RenderFirstColumn() {
    if (isUserRule) {
      return (
        <>
          <div
            aria-hidden
            onClick={() => onHeaderClick("user")}
            className={classes.colHeader}
          >
            <span>{t("User")}</span>
            <IconSort activeSortOrder={getSortOrder("user")} />
          </div>
        </>
      );
    }
    return (
      <div className={classes.colHeader}>
        <CheckBoxCustom
          checked={allSelected}
          onChange={(value) => {
            onToggleSelectAll(value);
          }}
        />
        <div>{t("Device")}</div>
      </div>
    );
  }

  function RenderSecondColumn() {
    if (isUserRule) {
      return (
        <>
          <div className={custClasses.checkboxWrapper}>
            <CheckBoxCustom
              checked={allSelected}
              onChange={(value) => {
                onToggleSelectAll(value);
              }}
            />
            <div className={classes.floatLeft}>{t("Rule Type")}</div>
          </div>
        </>
      );
    }
    return <div>{t("Notification Name")}</div>;
  }

  return (
    <header className={classes.header} aria-hidden>
      <RenderFirstColumn />
      <div className={classes.colLargeHeader} onClick={() => null} aria-hidden>
        <RenderSecondColumn />
      </div>
      <div
        aria-hidden
        onClick={() => onHeaderClick("status")}
        className={`${classes.colHeader}`}
      >
        <span>{t("Rule Status")}</span>
        <IconSort activeSortOrder={getSortOrder("status")} />
      </div>
      <div className={`${classes.colHeader}`}>
        <span className={classes.floatNone}>{t("Effective Period")}</span>
      </div>
      {isUserRule ? (
        <div className={`${classes.colHeader}`}>
          <span className={classes.floatNone}>{t("Note")}</span>
        </div>
      ) : (
        ""
      )}
      <div className={`${classes.colHeader}`}>
        <span className={classes.floatNone}>{t("Threshold")}</span>
      </div>
      <div className={`${classes.colHeader} ${classes.txtCenter}`}>
        <span className={classes.floatNone}>{t("Actions")}</span>
      </div>
    </header>
  );
}
export default NotificationRuleListHeader;
