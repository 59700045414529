import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { SMSReceiver } from "interfaces/notification.interface";
import Highlighted from "components/Highlighted/HighLighted";
import ShowMoreComponent from "components/ShowMoreComponent/ShowMoreComponent";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    width: "100%",
    float: "left",
    marginBottom: 20
  },
  listWrapper: {
    width: "100%",
    float: "left"
    // minHeight: 175
  },
  subUser: {
    float: "left",
    padding: 8,
    fontSize: 16,
    width: ({ inline }: { inline: boolean }) => (inline ? "100%" : "33%")
  },
  title: {
    fontSize: 24,
    width: "100%",
    float: "left",
    margin: "16px 0"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 17
  },
  checkBox: {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 15
  },
  showMoreWrapper: {
    marginLeft: ({ inline }: { inline: boolean }) =>
      inline ? 15 : "calc(66% + 14px)",
    float: "left"
  },
  drawerWrapper: {
    float: "left",
    marginLeft: 117,
    marginTop: 3
  }
}));

function SMNotificationRecieverList({
  lists,
  onChange,
  defaultValues,
  disabled,
  showItems = 9
}: {
  lists: SMSReceiver[];
  onChange: (value: string[]) => void;
  defaultValues: string[];
  disabled?: boolean;
  showItems?: number;
}) {
  const inline = false;
  const classes = useStyles({ inline });
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");
  const [total, setTotal] = useState<number>();
  const [showMore, setShowMore] = useState<boolean>(true);
  const [filteredItems, setFilteredItems] = useState<SMSReceiver[]>();

  useEffect(() => {
    const filteredList = filterArray(lists, query);
    setFilteredItems(
      showMore ? filterArray(lists, query)?.splice(0, showItems) : filteredList
    );
    setTotal(filteredList.length);
  }, [query, lists, showMore]);

  const onCheckBoxChange = (id: string, checked: boolean) => {
    const subList = defaultValues.slice();
    if (checked) {
      subList.push(id);
    } else {
      _.remove(subList, (item) => {
        return item === id;
      });
    }
    onChange(subList);
  };

  const filterArray = (arr: SMSReceiver[], q: string) => {
    return arr.filter((item: SMSReceiver) => {
      return item.receiver.toLowerCase().includes(q.toLowerCase());
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.listWrapper}>
        {filteredItems && filteredItems.length
          ? filteredItems.map((reciever: SMSReceiver) => (
              <div className={classes.subUser} key={reciever.id}>
                <FormControlLabel
                  value={reciever.id}
                  disabled={disabled}
                  control={
                    <Checkbox color="default" className={classes.checkBox} />
                  }
                  label={
                    <Highlighted text={reciever.receiver} highlight={query} />
                  }
                  labelPlacement="end"
                  checked={defaultValues?.includes(reciever.id)}
                  onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                    onCheckBoxChange(reciever.id, checked)
                  }
                />
              </div>
            ))
          : ""}
      </div>

      {total && total > showItems ? (
        <div className={classes.showMoreWrapper}>
          <ShowMoreComponent
            total={total}
            showMore={showMore}
            showItems={showItems}
            setShowMore={setShowMore}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default SMNotificationRecieverList;
