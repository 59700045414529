import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import {
  allGroupsChecked,
  filterUserGroup,
  getDeviceUsers,
  sortGroups,
  sortGroupsBySearchQuery,
  toggleAllGroupsChecked,
  viewGroupDetails
} from "utils/groupManagement";
import {
  deleteGroupAction,
  deleteMultipleGroups,
  updateUserGroup
} from "store/actions/groupActions";
import { isAdminUser } from "utils/userManagement";
import BulkActions from "components/BulkActions/BulkActions";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";
import SMButton from "components/SMButton/SMButton";
import SMPagination from "components/SMPagination/SMPagination";
import SearchTextField from "components/SearchTextField/SearchTextField";
import SecondaryTabHeader from "components/RouteTabs/SecondaryTabHeader";

import { useStyles } from "./grouplist.styles";
import GroupListHeader from "./GroupListHeader";
import GroupListRow from "./GroupListRow";
import groupsRoutes from "../userGroups.routes";

interface Props {
  loginedUser: User;
  userGroupType: string;
  userGroupsEntity: { [key: string]: Group };
  usersEntity: { [key: string]: User };
  routePath: string;
}

function GroupList({
  loginedUser,
  userGroupType,
  userGroupsEntity,
  usersEntity,
  routePath
}: Props) {
  const commonCls = CommonStyles();
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [sortedGroups, setSortedGroups] = useState<Group[]>([]);
  const [activePage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentGroups, setCurrentGroups] = useState<Group[]>();

  const [searchQuery, setSearchQuery] = useState<string>();
  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [curSortField, setSortField] = useState<string>("name");

  // bulik actions
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<{ [key: string]: boolean }>({});
  const [deleteGroup, setDeleteGroup] = useState<Group>();

  const currentAction: string = useSelector((state: any) => {
    return state?.userGroupsReducer.currentAction || "";
  });

  useEffect(() => {
    if (currentAction === GROUP_ACTIONS.deleteSuccess) {
      setSelectedItems({});
    }
  }, [currentAction]);

  useEffect(() => {
    const grps = filterUserGroup({
      type: userGroupType,
      userGroups: Object.values(userGroupsEntity)
    });
    if (grps && grps.length) {
      grps.sort((a: Group, b: Group) => (a.name > b.name ? 1 : -1));
      setSortedGroups(grps);
    } else {
      setSortedGroups([]);
    }
  }, [userGroupsEntity]);

  useEffect(() => {
    if (itemsPerPage >= sortedGroups?.length) {
      setCurrentPage(1);
    }
    if (sortedGroups) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentGroups(sortedGroups.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [sortedGroups, activePage, itemsPerPage]);

  useEffect(() => {
    if (deleteGroup) {
      setOpenDeleteDialog(true);
    }
  }, [deleteGroup]);

  const onChange = (q: string, selectedType: string = "name") => {
    setSearchQuery(q);
    setCurrentPage(1);
    const grps = filterUserGroup({
      type: userGroupType,
      userGroups: Object.values(userGroupsEntity)
    });

    const filteredGroups = sortGroupsBySearchQuery({
      q,
      type: "name",
      groups: grps
    });
    setSortedGroups(filteredGroups);
    setCurrentPage(1);
  };

  const sortList = (sortType: string): void => {
    let order = "ASC";
    if (curSortField === sortType) {
      order = curSortOrder === "ASC" ? "DESC" : "ASC";
    }
    setSortField(sortType);
    setSortOrder(order as "ASC" | "DESC");
    setSortedGroups(sortGroups(sortedGroups, order, sortType));
  };

  const onEdit = (group: Group) => {
    dispatch(updateUserGroup(group));
  };

  const addNewGroup = () => {
    const path = `${routePath}/0`;
    history.push(path);
  };

  const handleClick = (key: string) => {
    if (key === "remove_selected") {
      setOpenDeleteDialog(true);
    }
  };

  const onToggleSelectAll = (checked: boolean) => {
    if (currentGroups) {
      setSelectedItems({
        ...selectedItems,
        ...toggleAllGroupsChecked(currentGroups, checked)
      });
    }
  };

  const bulkDelete = () => {
    setCurrentPage(1);
    const groupsToDelete: Group[] = sortedGroups.filter((user: Group) => {
      return selectedItems[user.id];
    });
    dispatch(deleteMultipleGroups(groupsToDelete));
    setSelectedItems({});
  };

  const onSingleDelete = () => {
    if (deleteGroup) {
      setDeleteGroup(undefined);
      dispatch(deleteGroupAction(deleteGroup));
    }
  };

  const ifBulkDelete = () => {
    const selected = sortedGroups?.filter((group: Group) => {
      return selectedItems[group.id];
    });
    return selected?.length > 0;
  };

  const onSaveAction = (group: Group) => {
    setEditGroup({ ...editGroup, [group.id]: false });
    dispatch(updateUserGroup(group));
  };

  return (
    <div className={classes.listContainer}>
      <SecondaryTabHeader routes={groupsRoutes} />
      <div className={`${commonCls.tabContentWrapperNoTopPadding}`}>
        <div className={classes.container}>
          <PerPageListItems
            type={USER_SETTINGS_CONSTANTS.groups_per_page}
            onChange={setItemsPerPage}
            label={t("Per page")}
            total={sortedGroups.length}
          />

          <div className={classes.addNewGroup}>
            <SMButton
              icon={<Plus />}
              text={
                userGroupType === UserGroupType.viewer
                  ? t("Add Employee Group")
                  : t("Add User Group")
              }
              onClick={addNewGroup}
            />
          </div>

          <div className={classes.searchWrapper}>
            <SearchTextField
              onChange={(q: string) => onChange(q)}
              width="320px"
              placeholder={
                userGroupType === UserGroupType.viewer
                  ? t("e.g. Department A")
                  : t("e.g. Section A")
              }
            />
          </div>
          <div className={classes.bulkActionWrapper}>
            <BulkActions
              selectedItems={selectedItems}
              actions={[
                { key: "remove_selected", label: t("Delete Selected") }
              ]}
              handleClick={handleClick}
            />
          </div>

          <GroupListHeader
            curSortOrder={curSortOrder}
            curSortField={curSortField}
            onClick={sortList}
            userGroupType={userGroupType}
            isAdmin={isAdminUser(loginedUser)}
            onToggleSelectAll={onToggleSelectAll}
            allSelected={allGroupsChecked(currentGroups || [], selectedItems)}
          />

          <div className={commonCls.rowContainer}>
            {currentGroups &&
              currentGroups.map((group: Group, index) => {
                return (
                  <GroupListRow
                    key={group.id}
                    history={history}
                    searchQuery={searchQuery}
                    commonCls={commonCls}
                    classes={classes}
                    group={group}
                    userGroupType={userGroupType}
                    onEdit={onEdit}
                    deviceUsers={getDeviceUsers(usersEntity, group)}
                    groupEntity={userGroupsEntity}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    editGroup={editGroup}
                    setEditGroup={setEditGroup}
                    onDelete={(grp: Group) => setDeleteGroup(grp)}
                    viewGroupDetails={(event: any, groupObj: Group) =>
                      viewGroupDetails(event, groupObj, history)
                    }
                    onSaveAction={onSaveAction}
                    usersEntity={usersEntity}
                    t={t}
                  />
                );
              })}
          </div>

          {/* Pagination  */}
          {sortedGroups && sortedGroups?.length > itemsPerPage && (
            <SMPagination
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              itemsLength={sortedGroups?.length}
              handlePageChange={(pageNumber: number) =>
                setCurrentPage(pageNumber)
              }
            />
          )}

          {/* Delete dialog box */}
          <DialogBox
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            onDelete={() => (ifBulkDelete() ? bulkDelete() : onSingleDelete())}
            title={t("Delete users")}
            dialogDesc={t(
              "Deleting this groups will remove all data associated with this groups. This action is non-reversable"
            )}
            confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
            confirmPlaceHolder={t("Key in confirmation text here")}
            confirmText={t("I AM SURE")}
            buttonOk={t("Delete")}
            buttonCancel={t("Cancel")}
          />
        </div>
      </div>
    </div>
  );
}

export default GroupList;
