import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";

import Card from "../Cards/Card";
import CardContent from "../Cards/CardContent";
import CardHeader from "../Cards/CardHeader";
import Hypnogram from "../Hypnograms";
import SingleTextField from "../SingleTextField/SingleTextField";
import SleepScore from "../SleepScore";
import SleepStages from "../SleepStages";
import SleepStatistics from "../SleepStatistics";

const styles = (theme) => ({
  root: {
    margin: 40
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: 12
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

const SessionDetailsComponent = (props) => {
  const {
    classes,
    options,
    hypnogramOptions,
    showHypnogram,
    onToggleHypnogram,
    sleepReport,
    enableHeartRateMean
  } = props;

  const { sleepScoreOptions, sleepStagesOptions, sleepStatisticsOptions } =
    options;
  const { t } = useTranslation();
  const getAirQuality = (value) => {
    if (!value) return "";

    if (value < 0) {
      return "&nbsp;";
    }

    const x = Math.round(value);

    switch (true) {
      case x <= 50 && x >= 0:
        return t("Great");
      case x <= 100:
        return t("Good");
      case x <= 150:
        return t("Fair");
      case x <= 200:
        return t("Poor");
      case x <= 250:
        return t("Bad");
      case x <= 350:
        return t("Very Bad");
      case x <= 500:
        return t("Extremely Bad");
      default:
        return "&nbsp;";
    }
  };
  return sleepScoreOptions && sleepStagesOptions && sleepStatisticsOptions ? (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12} alignItems="stretch">
          <Card>
            <CardHeader title={sleepStagesOptions.title || t("Sleep stages")} />
            <CardContent>
              <SleepStages options={sleepStagesOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Card>
            <CardHeader
              title={sleepStatisticsOptions.title || t("Sleep Statistics")}
            />
            <CardContent>
              <SleepStatistics
                options={sleepStatisticsOptions}
                enableHeartRateMean={enableHeartRateMean}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} xs={12} alignItems="stretch">
          <Card>
            <CardHeader title={sleepScoreOptions.title || t("Sleep Score")} />
            <CardContent>
              <SleepScore options={sleepScoreOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12} alignItems="stretch">
          <Card>
            <CardHeader title={t("Enviornment details")} />
            <CardContent>
              <Grid container spacing={3} direction="row">
                <Grid item xs={4} lg={4}>
                  <Typography align="center">
                    {t("Temperature")} &#8451;
                  </Typography>
                  <Typography variant="h4" align="center">
                    {sleepReport.temperature_mean || ""}
                  </Typography>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SingleTextField
                    title={`${t("Humidity")} %`}
                    text={sleepReport.air_humidity_mean}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <SingleTextField
                    title={t("Air Quality")}
                    text={getAirQuality(sleepReport.indoor_air_quality_mean)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12} alignItems="stretch">
          <Card>
            <CardHeader
              title={
                (hypnogramOptions && hypnogramOptions.title) || t("Hypnogram")
              }
              actions={[
                <Typography key="showHypnogram">{`${
                  showHypnogram ? t("Hide") : t("Show")
                } ${t("Hypnogram")}`}</Typography>,
                <IconButton
                  className={cn(classes.expand, {
                    [classes.expandOpen]: showHypnogram
                  })}
                  onClick={onToggleHypnogram}
                  aria-expanded={showHypnogram}
                  aria-label="Show vitals"
                  key="Show vitals"
                >
                  <ExpandMoreIcon />
                </IconButton>
              ]}
            />
            <CardContent>
              <Collapse
                in={hypnogramOptions && showHypnogram}
                mountOnEnter
                unmountOnExit
              >
                <Hypnogram
                  options={hypnogramOptions}
                  enableHeartRateMean={enableHeartRateMean}
                />
              </Collapse>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

SessionDetailsComponent.propTypes = {
  classes: PropTypes.any,
  options: PropTypes.any,
  hypnogramOptions: PropTypes.any,
  showHypnogram: PropTypes.any,
  onToggleHypnogram: PropTypes.any,
  sleepReport: PropTypes.any,
  enableHeartRateMean: PropTypes.string
};

export default withStyles(styles)(SessionDetailsComponent);
