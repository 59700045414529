import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { NightReportSortTypes, SortTypes } from "constants/app.enum";
import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  curSortOrder: "ASC" | "DESC";
  curSortField: NightReportSortTypes;
  onClick: (type: NightReportSortTypes) => void;
}
function NightReportListHeader({
  curSortOrder,
  curSortField,
  onClick
}: Props): JSX.Element {
  const classes = CommonStyles();
  const { t } = useTranslation();

  const getSortOrder = (type: NightReportSortTypes) => {
    if (type === curSortField && curSortOrder === "ASC") {
      return "ASC";
    }
    if (type === curSortField && curSortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  return (
    <header
      className={classes.header}
      style={{ borderBottom: "1px solid #212121", alignItems: "flex-start" }}
    >
      <div className={classes.colHeader} 
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.DeviceSort)}
      >
        <span> {t("Device")} </span>
        <IconSort activeSortOrder={getSortOrder(NightReportSortTypes?.DeviceSort)} />
      </div>
      <div className={classes.colHeader} 
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.UserSort)}>
        <span>{t("User Id")}</span>
        <IconSort activeSortOrder={getSortOrder(NightReportSortTypes?.DeviceSort)} />
      </div>
      <div className={classes.colHeader} 
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.RespirationRateSort)}
      > 
        <span> {t("Respiration Rate (rpm)")}</span>
        <IconSort activeSortOrder={getSortOrder(NightReportSortTypes?.RespirationRateSort)} />
      </div>
      <div className={classes.colHeader}
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.MovementSort)}
      >
        <span> {t("Movement")}</span>
        <IconSort activeSortOrder={getSortOrder(NightReportSortTypes.MovementSort)} />
      </div>

      <div className={classes.colHeader}
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.TotalSleepTimeSort)}>
        <span> {t("Total Sleep Time")} </span>
        <IconSort activeSortOrder={getSortOrder(NightReportSortTypes.TotalSleepTimeSort)} />
      </div>
      <div className={`${classes.colHeader}`}
        aria-hidden
        onClick={()=>onClick(NightReportSortTypes.SleepScoreSort)}
      >
         <span className={`${classes.floatRight}`}> <IconSort activeSortOrder={getSortOrder(NightReportSortTypes.SleepScoreSort)} /> </span>
        <span className={`${classes.floatRight}`}> {t("Sleep Score")} </span>
      </div>

      <div className={`${classes.colHeader} ${classes.txtRight}`}   aria-hidden
        onClick={()=>onClick(NightReportSortTypes.OutOfBedSort)}>
        <span className={`${classes.floatRight}`} > <IconSort activeSortOrder={getSortOrder(NightReportSortTypes.OutOfBedSort)} /></span>
        <span className={`${classes.floatRight}`} style={{marginRight:10}}>
          {" "}
          {`${t("Out of Bed")} (${t("no.of times")})`}{" "}
        </span>
      </div>

      <div className={`${classes.colHeader} ${classes.txtCenter}`}  
       >
        <Tooltip
          placement="top"
          classes={{ tooltip: classes.toolTipCls }}
          title={`${t(
            "Whether a session is in progress at the time report is generated"
          )}`}
        >
            <span className={`${classes.txtCenter} ${classes.floatNone}`}>
              {t("Session in Progress")}
            </span>
        </Tooltip>
      </div>

      <div className={`${classes.colHeader} ${classes.txtCenter}`} aria-hidden
        onClick={()=>onClick(NightReportSortTypes.BaselineNightsSort)}>
        <Tooltip
          placement="top"
          classes={{ tooltip: classes.toolTipCls }}
          title={`${t(
            "Number of nights with data available for baseline in the past 21 days"
          )}`}
        >
          <div>
            <span className={`${classes.txtCenter} ${classes.floatNone}`}>
              {t("Baseline Nights")}
              {" (*)"}
            </span>
            <span className={`${classes.floatRight}`}><IconSort activeSortOrder={getSortOrder(NightReportSortTypes.BaselineNightsSort)} /> </span>
          </div>
        </Tooltip>
      </div>
    </header>
  );
}

export default NightReportListHeader;
