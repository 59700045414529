import { useSelector } from "react-redux";
import React from "react";

import { AppRouteProps } from "interfaces/router.interface";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import { isAdminUser } from "utils/userManagement";
import RouteTabs from "components/RouteTabs/RouteTabs";

import {
  settingsRouteNames as routeLabel,
  settingsRoutes as routes
} from "./settings.routes";

function SettingsContainer() {
  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const canView = (route: AppRouteProps) => {
    if (
      (route.name === routeLabel.devices || route.name === routeLabel.groups) &&
      loginedUser.attributes?.user_type === UserAttributesTypes.viewer
    ) {
      return false;
    }
    return true;
  };

  if (!loginedUser) {
    return <div />;
  }

  return (
    <RouteTabs
      routes={routes}
      routeLabel={routeLabel}
      canView={canView}
      isAdmin={isAdminUser(loginedUser)}
    />
  );
}
export default SettingsContainer;
