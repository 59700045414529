export const UserAttributesTypes = {
  device: "device",
  viewer: "viewer",
  default: "default"
};

export const UserPrivileges = {
  view_dashbaord: "view_dashbaord",
  view_monitor: "view_monitor"
};
