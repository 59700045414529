import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Device } from "interfaces/device.interface";
import { getDuration } from "utils/deviceManagement";

interface Props {
  device: Device;
  onSave: (name: string) => void;
  deviceName: string;
}

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    marginBottom: 38,
    width: "100%",
    float: "left",
    display: "flex",
    alignItems: "center"
  },
  editIcon: {
    float: "left",
    marginLeft: 32,
    width: 25
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  titleWraper: {
    display: "flex",
    alignItems: "center",
    float: "left"
  },
  title: {
    float: "left",
    fontSize: 34,
    maxWidth: "100%"
  },
  inputRoot: {
    "& .MuiInput-input": {
      fontSize: 34,
      height: 32,
      padding: 0,
      marginTop: 8
    }
  },
  status: {
    float: "left",
    marginLeft: 120,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    color: theme.palette.primary.grey
  },
  statusIcon: {
    width: 16,
    height: 16,
    borderRadius: 16,
    display: "block",
    float: "left",
    marginRight: 16,
    marginTop: 2
  },
  statusText: {
    marginLeft: 32
  },
  statusInfo: {
    minWidth: 85
  }
}));
function DeviceNameComponent({ device, onSave, deviceName }: Props) {
  const classes = useStyles();
  const [toggleDeviceNameEdit, setToggleDeviceNameEdit] =
    useState<boolean>(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(80);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setDeviceName(device.name);
  // }, [device.name]);

  useEffect(() => {
    if (divRef?.current?.clientWidth) {
      setWidth(divRef?.current?.clientWidth);
    }
    // const timeoutId = setTimeout(() => {
    //   onSave(deviceName);
    // }, 1000);
    // setWidth(deviceName.length * 22 > 120 ? deviceName.length * 22 : 120);
    // return () => clearTimeout(timeoutId);
  }, [divRef, toggleDeviceNameEdit]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWraper}>
        <div
          className={classes.title}
          // style={{ width: `${width}px`, display: "block" }}
        >
          {!toggleDeviceNameEdit && <div ref={divRef}>{deviceName} </div>}
          {/* {toggleDeviceNameEdit && (
            <div style={{ width }}>
              <TextField
                value={deviceName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onSave(e.target.value)
                }
                fullWidth
                classes={{
                  root: classes.inputRoot
                }}
                InputProps={{ disableUnderline: true }}
                onBlur={() => {
                  // setToggleDeviceNameEdit(false);
                }}
              />
            </div>
          )} */}
        </div>
        {/* <div className={classes.editIcon}>
          <ToggleEdit
            toggle={toggleDeviceNameEdit}
            setToggle={setToggleDeviceNameEdit}
          />
        </div> */}
      </div>
      <div className={classes.status}>
        {device.time_since_last_activity >= 600 ||
        device.time_since_last_activity == null ? (
          <div className={classes.statusInfo}>
            {" "}
            <span
              className={classes.statusIcon}
              style={{ background: "#E35D64" }}
            />{" "}
            <span>{t("Offline")} </span>{" "}
          </div>
        ) : (
          <div className={classes.statusInfo}>
            {" "}
            <span
              className={classes.statusIcon}
              style={{ background: "#457E89" }}
            />{" "}
            <span> {t("Online")}</span>
          </div>
        )}
        <span className={classes.statusText}>
          {t("Last online")}: {getDuration(device.time_since_last_activity)}
        </span>
      </div>
    </div>
  );
}

export default DeviceNameComponent;
