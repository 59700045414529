import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import React from "react";

import Clock from "../Clock/Clock";
import SingleTextField from "../SingleTextField/SingleTextField";
import SleepStatisticsComponent from "./SleepStatistics";

const CLOCK_SIZE = 120;

class SleepStatistics extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  render() {
    const { t, enableHeartRateMean } = this.props;
    if (!this.props.options.plotData) {
      return null;
    }
    const {
      timeAtStart,
      timeAtSleep,
      timeAtWakeup,
      timeAsleepString,
      sleepOnsetString,
      sleepEfficiency,
      timesAwakeAway,
      nonREMMeanHeartRate,

      // nonREMMeanExternalHeartRate,

      nonREMMeanRespirationRate

      // externalSpO2Mean,
    } = this.props.options.plotData.series;

    const elements = [
      {
        component: Clock,
        args: {
          title: t("Time at Start"),
          time: timeAtStart,
          size: CLOCK_SIZE
        }
      },
      {
        component: Clock,
        args: {
          title: t("First Asleep"),
          time: timeAtSleep,
          size: CLOCK_SIZE
        }
      },
      {
        component: Clock,
        args: {
          title: t("Woke up"),
          time: timeAtWakeup,
          size: CLOCK_SIZE
        }
      },

      {
        component: SingleTextField,
        args: {
          title: t("Total Time Asleep"),
          text: timeAsleepString
        }
      },
      {
        component: SingleTextField,
        args: {
          title: t("Sleep Onset"),
          text: sleepOnsetString
        }
      },
      {
        component: SingleTextField,
        args: {
          title: t("Sleep Efficiency"),
          text: `${sleepEfficiency} %`
        }
      },
      {
        component: SingleTextField,
        args: {
          title: t("Times Awake / Away"),
          text: timesAwakeAway
        }
      }
    ];

    if (enableHeartRateMean === "true") {
      elements.push({
        component: SingleTextField,
        args: {
          title: t("Mean Heart Rate"),
          text: nonREMMeanHeartRate
        }
      });
    }

    elements.push({
      component: SingleTextField,
      args: {
        title: t("Resting Respiration Rate"),
        text: nonREMMeanRespirationRate
      }
    });

    return <SleepStatisticsComponent {...this.props} elements={elements} />;
  }
}

SleepStatistics.propTypes = {
  options: PropTypes.shape({
    plotData: PropTypes.shape({
      series: PropTypes.object
    }).isRequired,
    rows: PropTypes.number
  }),
  t: PropTypes.func,
  enableHeartRateMean: PropTypes.string
};

export default withTranslation()(SleepStatistics);
