import { TextField, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";

interface Props {
  value: any;
  onSave: (text: string) => void;
  delay?: number;
  placeholder?: string;
  borderRed?: boolean;
  endAdornment?: JSX.Element;
  width?: number | string;
  handleBlur?: () => void;
  autoFocus?: boolean;
}

// const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
const useStyles = makeStyles((theme: any) => ({
  txtField: {
    width: ({
      borderRed,
      boxWidth
    }: {
      borderRed: boolean;
      boxWidth: number | string;
    }) => boxWidth,
    height: 20,
    border: ({
      borderRed,
      boxWidth
    }: {
      borderRed: boolean;
      boxWidth: number | string;
    }) =>
      borderRed
        ? `1px solid ${theme.palette.primary.red}`
        : `1px solid ${theme.palette.primary.input}`,
    borderRadius: 5,
    padding: 14,
    color: theme.palette.primary.input
  },
  inputRoot: {}
}));

function SMTextField({
  value,
  onSave,
  delay = 0,
  placeholder,
  borderRed = false,
  endAdornment,
  width,
  handleBlur,
  autoFocus
}: Props): JSX.Element {
  const boxWidth = width || 220;
  const classes = useStyles({ borderRed, boxWidth });
  const [txt, setTxt] = useState<string>("");

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      onSave(e.target.value);
    }
  };

  useEffect(() => {
    setTxt(`${value}`);
  }, [value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== txt) {
        onSave(txt ?? "");
      }
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [txt]);

  return (
    <TextField
      placeholder={placeholder}
      className={classes.inputRoot}
      value={txt}
      onKeyDown={keyPress}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setTxt(e.target.value);
      }}
      onBlur={() => (handleBlur ? handleBlur() : null)}
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.txtField
        }
      }}
      inputRef={(input) => {
        if (input != null && autoFocus) {
          input.focus();
        }
      }}
    />
  );
}
export default SMTextField;
