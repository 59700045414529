import PropTypes from "prop-types";
import React from "react";

import SleepHistoryStagesChart from "./SleepHistoryStagesChart";

const _emptyFunction = () => false;

const SleepHistoryStagesComponent = (props) => {
  const { onChartMouseOver, onChartClick, plotData } = props.options;
  return (
    <div>
      <SleepHistoryStagesChart
        onChartMouseOver={onChartMouseOver || _emptyFunction}
        onChartClick={onChartClick || _emptyFunction}
        plotData={plotData}
      />
    </div>
  );
};
SleepHistoryStagesComponent.propTypes = {
  options: PropTypes.any
};
export default SleepHistoryStagesComponent;
