import * as PropTypes from "prop-types";
import React from "react";

import SleepHistoryComponent from "./SleepHistory";

class SleepHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVitals: false
    };
  }

  render() {
    return this.props.options ? (
      <SleepHistoryComponent
        {...this.props}
        showVitals={this.state.showVitals}
        onToggleVitals={
          () =>
            this.setState((prevState) => ({
              showVitals: !prevState.showVitals
            }))
          // this.setState({ showVitals: !this.state.showVitals })
        }
      />
    ) : null;
  }
}

SleepHistory.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    sleepHistoryStagesOptions: PropTypes.shape({
      title: PropTypes.string,
      onChartMouseOver: PropTypes.func,
      onChartClick: PropTypes.func,
      plotData: PropTypes.shape({
        series: PropTypes.object,
        threshold: PropTypes.object
      }).isRequired
    }).isRequired,
    sleepHistoryVitalsOptions: PropTypes.shape({
      title: PropTypes.string,
      onChartMouseOver: PropTypes.func,
      onChartClick: PropTypes.func,
      plotData: PropTypes.shape({
        series: PropTypes.object,
        threshold: PropTypes.object
      }).isRequired
    }).isRequired,
    datePickerOptions: PropTypes.shape({
      onChangeFrom: PropTypes.func,
      onChange: PropTypes.func,
      fromValue: PropTypes.string,
      fromLabel: PropTypes.string,
      onChangeTo: PropTypes.func,
      toValue: PropTypes.string,
      toLabel: PropTypes.string
    }).isRequired
  })
};

export default SleepHistory;
