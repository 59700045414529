import {
  AppBar,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

import { User } from "interfaces/user.interface";

interface Props {
  onLogout: () => void;
  loginedUser: User;
  funcToggleMenu: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    background: "#FFF",
    boxShadow: "none",
    width: "100%",
    transition: "all 150ms ease 0s",
    minHeight: "80px",
    display: "block",
    clear: "both"
  },
  toolbar: {
    color: theme.palette.primary.listHeader,
    width: "100%",
    display: "block"
  },
  profile: {
    float: "right",
    borderRadius: 0,
    margin: "32px 15px",
    textTransform: "capitalize",
    color: theme.palette.primary.listHeader,
    fontSize: 16,
    padding: 0,
    "& span": {}
  },
  appBarRoot: {
    fontSize: 16,
    lineHeight: "16px"
  },
  menuItem: {},
  profileArrowIco: {
    paddingLeft: 8,
    lineHeight: "12px",
    color: theme.palette.primary.dark
  },
  toggleIcon: {
    color: "#000",
    paddingLeft: 15,
    paddingTop: 15,
    width: 40,
    height: 40,
    position: "absolute",
    zIndex: 10
  }
}));

/*
    Sidebar
*/
function AdminNavbarNew({ onLogout, loginedUser, funcToggleMenu }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const onLogoutAction = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    onLogout();
  };
  return (
    <AppBar
      className={classes.appBar}
      position="relative"
      classes={{ root: classes.appBarRoot }}
    >
      {" "}
      <div
        className={classes.toggleIcon}
        aria-hidden
        onClick={() => funcToggleMenu()}
      >
        {" "}
        <MenuIcon />{" "}
      </div>
      {/* position="fixed" */}
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.profile}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          onClick={handleToggle}
          color="inherit"
        >
          {loginedUser.display_name}
          <span className={classes.profileArrowIco}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}{" "}
          </span>
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={onLogoutAction}
                      className={classes.menuItem}
                    >
                      {t("Logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  );
}

export default AdminNavbarNew;
