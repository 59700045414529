import {
  FormControlLabel,
  InputAdornment,
  Radio,
  TextField,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";

export const textFeildStyle = makeStyles((theme: any) => ({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  root: {
    width: "100%",
    paddingLeft: 16,
    height: 54,
    border: `1px solid ${theme.palette.secondary.lightGrey}`,
    borderRadius: 5,
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.primary.dark}`
    }
  }
}));

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    marginBottom: 25
  },
  searchfeild: {
    float: "left",
    width: 326,
    height: 54,
    background: "#FFF"
  },
  searchIco: {
    color: theme.palette.dark,
    cursor: "pointer"
  },
  clearIco: {
    color: theme.palette.dark,
    cursor: "pointer"
  },
  sortWrapper: {
    float: "left",
    marginLeft: 20
  },
  sortOptions: {
    width: "100%",
    "& > .MuiFormControlLabel-label": {
      fontSize: 16
    }
  },
  active: {
    background: "#00acc1",
    color: "#FFF"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  perPage: {
    "& .MuiInputBase-input": {
      border: `1px solid ${theme.palette.secondary.lightGrey}`,
      padding: 0,
      textAlign: "center",
      borderRadius: "4px",
      fontSize: 12,
      width: 32,
      height: 24,
      color: "rgba(0, 0, 0, 0.38)",
      margin: "0 8px"
    },
    "& ::before": {
      border: 0
    },
    "& ::after": {
      border: 0
    }
  },
  numberInput: {
    padding: 15
  },
  inputRoot: {
    borderRadius: 0
  },
  radio: {
    padding: 0
  },
  perPageWrapper: {
    float: "right",
    "& > span": {
      color: theme.palette.primary.grey,
      fontSize: 14
    }
  },
  totalRecords: {
    color: theme.palette.primary.grey,
    float: "right",
    fontSize: 14,
    marginTop: 18
  }
}));

interface Props {
  onChange: (items: any, type: string) => void;

  sortFields?: {
    label: string;
    value: string;
    component?: React.ComponentType;
  }[];
  devicesPerPage: (val: number) => void;
  total?: number;
}

function DeviceSearch({ onChange, sortFields, total, devicesPerPage }: Props) {
  const classesforTxt = textFeildStyle();
  const classes = useStyles();
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>("");
  const perPage =
    +getLocalSettings(USER_SETTINGS_CONSTANTS.devices_per_page) || 10;
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage);

  const [sortType, setSortType] = useState<string>(
    (sortFields && sortFields[0].value) || ""
  );

  useEffect(() => {
    if (itemsPerPage) {
      devicesPerPage(itemsPerPage);
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.devices_per_page]: +itemsPerPage
      });
    }
  }, [itemsPerPage]);

  const onTextChange = (q: string) => {
    setQuery(q);
    onChange(q, sortType);
  };

  const handleChange = (value: string) => {
    setSortType(value);
  };

  return (
    <div className={classes.root}>
      <TextField
        placeholder={t("e.g. Room A01")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onTextChange(e.target.value)
        }
        value={query}
        className={classes.searchfeild}
        InputProps={{
          classes: classesforTxt,

          endAdornment: (
            <div>
              <InputAdornment position="start" onClick={() => null}>
                <SearchIcon className={classes.searchIco} />
              </InputAdornment>
              {/* {query && (
                <InputAdornment position="start" onClick={() => setQuery("")}>
                  <ClearIcon className={classes.clearIco} />
                </InputAdornment>
              )} */}
            </div>
          )
        }}
      />
      {sortFields && sortFields.length && (
        <div className={classes.sortWrapper}>
          {sortFields.map(
            (item: { label: string; value: string; component?: any }) => {
              const { value, label, component: Component } = item;
              return (
                <FormControlLabel
                  key={value}
                  onClick={() => handleChange(value)}
                  className={classes.sortOptions}
                  checked={sortType === value}
                  value={value}
                  control={<Radio color="default" className={classes.radio} />}
                  label={label}
                />
              );
            }
          )}
        </div>
      )}

      <PerPageListItems
        type={USER_SETTINGS_CONSTANTS.devices_per_page}
        label={t("Per page")}
        onChange={setItemsPerPage}
        total={total}
      />
    </div>
  );
}

export default DeviceSearch;
