export const styles = (theme) => {
  return {
    root: {
      cursor: "pointer"
    },

    content: {
      width: "100%",
      display: "flex",
      alignItems: "center"
    },
    contentStatusAvatar: {
      borderRadius: 0,
      height: 56,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "inherit",
        width: 132
      },
      [theme.breakpoints.up("md")]: {
        width: 56
      }
    },
    statusText: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: 10
    },
    cardActions: {
      display: "flex",
      "& :last-child": {
        marginLeft: "auto"
      }
    },
    cardActionsLungsAvatar: {
      height: 24,
      width: 24,
      left: -7
    },
    flex: {
      display: "flex"
    },
    flexCard: {
      width: "flex"
    },
    maxWidth: {
      maxWidth: "maxWidth"
    }
  };
};
