import { Group } from "interfaces/group.interface";

export const FETCH_USER_GROUPS_ACTION_SUCCESS =
  "FETCH_USER_GROUPS_ACTION_SUCCESS";
export const FETCH_USER_GROUPS_ACTION = "FETCH_USER_GROUPS_ACTION";

export const ADD_NEW_USER_GROUP_ACTION_TYPE = "ADD_NEW_USER_GROUP_ACTION_TYPE";

export const UPDATE_USER_GROUPS_CURRENT_ACTION = "USER_GROUPS_CURRENT_ACTION";

export const EDIT_USER_GROUPS_ACTION = "EDIT_USER_GROUPS_ACTION";
export const EDIT_USER_GROUPS_ACTION_SUCCESS =
  "EDIT_USER_GROUPS_ACTION_SUCCESS";

export const DELETE_USER_GROUPS_ACTION = "DELETE_USER_GROUPS_ACTION";
export const DELETE_USER_GROUPS_ACTION_SUCCESS =
  "DELETE_USER_GROUPS_ACTION_SUCCESS";

export const DELETE_MULTI_GROUPS_ACTION_SUCCESS =
  "DELETE_MULTI_GROUPS_ACTION_SUCCESS";

export const GROUP_ACTIONS = {
  fetching: "fetching",
  updating: "updating",
  deleting: "deleting",
  deleteSuccess: "deleteSuccess",
  fetchSuccess: "fetch-group-success",
  updateSuccess: "update-group-success",
  create: "create-group",
  createUserGroupSuccess: "create-group-success",
  createEmployeeGroupSuccess: "create-employee-group-success",
  reset: "",
  multiGroupUpdate: "multi-group-update",
  multiGroupUpdateSuccess: "multi-group-update-success"
};

export interface userGroupsCurrentActionType {
  type: typeof UPDATE_USER_GROUPS_CURRENT_ACTION;
  payload: string;
}

export interface addNewUserGroupSuccessActionType {
  type: typeof ADD_NEW_USER_GROUP_ACTION_TYPE;
  payload: Group;
}
export interface fetchUserGroupsSuccessActionType {
  type: typeof FETCH_USER_GROUPS_ACTION_SUCCESS;
  payload: Group[];
}
export interface editUserGroupSuccessActionType {
  type: typeof EDIT_USER_GROUPS_ACTION_SUCCESS;
  payload: Group;
}
export interface deleteUserGroupSuccessActionType {
  type: typeof DELETE_USER_GROUPS_ACTION_SUCCESS;
  payload: Group;
}

export interface deleteMultipleGroupsSuccessAction {
  type: typeof DELETE_MULTI_GROUPS_ACTION_SUCCESS;
  payload: string[];
}

export type userGroupsActionTypes =
  | userGroupsCurrentActionType
  | fetchUserGroupsSuccessActionType
  | editUserGroupSuccessActionType
  | deleteUserGroupSuccessActionType
  | deleteMultipleGroupsSuccessAction
  | addNewUserGroupSuccessActionType;
