import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import { SortTypes } from "constants/app.enum";
import { UserGroupType } from "constants/userGroupConstants";
import CommonStyles from "theme/CommonStyles";
import IconSort from "components/IconSort/IconSort";

interface Props {
  curSortOrder: "ASC" | "DESC";
  curSortField: string;
  onClick: (type: string) => void;
  userGroupType: string;
  isAdmin: boolean;
  allSelected: boolean;
  onToggleSelectAll: (value: boolean) => void;
}

function GroupListHeader({
  curSortOrder,
  curSortField,
  onClick,
  userGroupType,
  isAdmin,
  allSelected,
  onToggleSelectAll
}: Props) {
  const classes = CommonStyles();
  const { t } = useTranslation();

  const getSortOrder = (type: string) => {
    if (type === curSortField && curSortOrder === "ASC") {
      return "ASC";
    }
    if (type === curSortField && curSortOrder === "DESC") {
      return "DESC";
    }
    return null;
  };

  return (
    <header className={classes.header} aria-hidden>
      <div className={`${classes.checkboxlistWraper}`}>
        <FormControlLabel
          value="view_monitor"
          control={<Checkbox color="default" />}
          label=""
          labelPlacement="end"
          checked={allSelected}
          onChange={(event, value) => {
            onToggleSelectAll(value);
          }}
        />
      </div>
      <div
        aria-hidden
        onClick={() => onClick("name")}
        className={classes.colHeader}
      >
        <span>{t("Group Name")}</span>
        <IconSort activeSortOrder={getSortOrder("name")} />
      </div>
      <div className={classes.colHeader} onClick={() => null} aria-hidden>
        <span>
          {userGroupType === UserGroupType.viewer
            ? t("Employees in this group")
            : t("Users in this Group")}
        </span>
      </div>
      {userGroupType === UserGroupType.viewer ? (
        <div className={classes.colHeader} onClick={() => null} aria-hidden>
          <span>{t("Can View User Groups")}</span>
        </div>
      ) : (
        ""
      )}
      <div className={`${classes.colHeader} ${classes.txtCenter}`}>
        <span className={classes.floatNone}>{t("Actions")}</span>
      </div>
      {/* {isAdmin && (
        <div className={classes.colHeader} onClick={() => null} aria-hidden>
          <span>{t("Group Actions")}</span>
        </div>
      )} */}
    </header>
  );
}

export default GroupListHeader;
