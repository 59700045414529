import { Device, Devices } from "interfaces/device.interface";

export const FETCH_DEVICES_ACTION_SUCCESS = "FETCH_DEVICES_ACTION_SUCCESS";
export const FETCH_DEVICES_ACTION = "FETCH_DEVICES_ACTION";

export const CHANGE_USER_ACTION_SUCCESS = "CHANGE_USER_ACTION_SUCCESS";
export const CHANGE_USER_ACTION = "CHANGE_USER_ACTION";
export const RESET_DEVICE_ACTION = "RESET_DEVICE_ACTION";
export const UNREGISTER_DEVICE_ACTION = "UNREGISTER_DEVICE_ACTION";
export const UNREGISTER_DEVICE_SUCCESS_ACTION =
  "UNREGISTER_DEVICE_SUCCESS_ACTION";
export const DELETE_MULTI_DEVICES_ACTION_SUCCESS =
  "DELETE_MULTI_DEVICE_ACTION_SUCCESS";

export const DEVICE_ACTIONS = {
  deviceSettingsUpdateSuccess: "deviceSettingsUpdateSuccess",
  deviceUpdateSuccess: "deviceUpdateSuccess",
  createSuccess: "updateSuccess",
  reset: "",
  deleteSuccess: "deleteSuccess"
};
export interface fetchDevicesActionType {
  type: typeof FETCH_DEVICES_ACTION;
}
export interface resetDevicesActionType {
  type: typeof RESET_DEVICE_ACTION;
}
export interface LoadDevicesSuccessActionType {
  type: typeof FETCH_DEVICES_ACTION_SUCCESS;
  payload: Devices;
}

export interface changeDeviceUserActionType {
  type: typeof CHANGE_USER_ACTION;
}
export interface changeDeviceUserSuccessActionType {
  type: typeof CHANGE_USER_ACTION_SUCCESS;
  payload: Device;
}

export interface unRegisterDeviceActionType {
  type: typeof UNREGISTER_DEVICE_ACTION;
  payload: string;
}

export interface unRegisterDeviceSuccessActionType {
  type: typeof UNREGISTER_DEVICE_SUCCESS_ACTION;
  payload: string;
}
export interface deleteMultipleDevicesSuccessAction {
  type: typeof DELETE_MULTI_DEVICES_ACTION_SUCCESS;
  payload: string[];
}

export type devicesActionTypes =
  | fetchDevicesActionType
  | LoadDevicesSuccessActionType
  | changeDeviceUserActionType
  | changeDeviceUserSuccessActionType
  | unRegisterDeviceSuccessActionType
  | deleteMultipleDevicesSuccessAction
  | resetDevicesActionType;
