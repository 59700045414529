import {
  DeviceSettings,
  EntityDeviceSettings
} from "interfaces/device.interface";

import * as types from "../actionTypes/devicesSettingsActionTypes";
import { devicesServices } from "../../services/devices/devicesService";

/**
 * Redux action type for to to update all the settings
 *
 * @param data normalized device response object
 * @return payload- normalized device response object
 */
export const getAllDeviceSettingsSuccessAction = (
  data: EntityDeviceSettings
): types.devicesSettingsActionTypes => {
  return {
    type: types.FETCH_DEVICES_SETTINGS_ACTION_SUCCESS,
    payload: data
  };
};

export const restDeviceSettingAction = (): types.devicesSettingsActionTypes => {
  return {
    type: types.RESET_DEVICE_SETTINGS_ACTION
  };
};

export const updateDeviceSettingsSuccessAction = (
  data: EntityDeviceSettings
): types.devicesSettingsActionTypes => {
  return {
    type: types.UPDATE_DEVICES_SETTINGS_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type for to fetch all the devices settings.
 *
 * @param none
 * @return ActionType with device settins action
 */
export const getAllDevicesSettingsAction =
  (): types.devicesSettingsActionTypes => {
    return {
      type: types.FETCH_DEVICES_SETTINGS_ACTION
    };
  };

/**
 * Redux action for fetching device settings
 *
 * @param deviceSerialNumber serialNumber of the device
 * @return response date with the device settings information.
 */
export const fetchDeviceSettings = (deviceSerialNumber: string) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(getAllDevicesSettingsAction());
    devicesServices
      .fetchDeviceSettings(deviceSerialNumber)
      .then((data: DeviceSettings) => {
        dispatch(
          getAllDeviceSettingsSuccessAction({ [deviceSerialNumber]: data })
        );
      });
  };
};

/**
 * Redux action for updating device settings.
 *
 * @param data: devicesettings object with updated values
 * @param deviceSerialNumber: device unique serial number
 * @return dispatch success action with updated response.
 */
export const updateDeviceSettings = (
  data: DeviceSettings,
  deviceSerialNumber: string
) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(getAllDevicesSettingsAction());
    devicesServices
      .updateDeviceSettings(data, deviceSerialNumber)
      .then((response: DeviceSettings) => {
        dispatch(
          updateDeviceSettingsSuccessAction({ [deviceSerialNumber]: data })
        );
      });
  };
};

/**
 * Redux action to reset pre-loaders
 *
 * @return update device settings action preloader
 */
export const resetDeviceSettingsCurrentAction = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(restDeviceSettingAction());
  };
};
