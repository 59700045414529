import { useTranslation } from "react-i18next";
import React from "react";
import _ from "lodash";

import {
  INTERFACE_USER_NOTIFICATION_TYPES,
  NOTIFICATION_TYPES
} from "constants/notificationContstants";
import { SMNotification } from "interfaces/notification.interface";
import InfoBox from "components/InfoBox/InfoBox";
import SMTextField from "components/SMTextField/SMTextField";
import SMTextFieldWithIcon from "components/SMTextFieldWithIcon/SMTextFieldWithIcon";

import { useStyles } from "./notificationView.styles";

interface Props {
  onChange: (key: string, value: boolean | string | string[] | any) => void;
  notification: SMNotification;
  toggleAdvanced: boolean;
  setToggleAdvanced: React.Dispatch<React.SetStateAction<boolean>>;
}
function NotificationRuleCriteria({
  onChange,
  notification,
  toggleAdvanced,
  setToggleAdvanced
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const criteriaChange = (
    type: "sendnotification" | "sendOkMsg",
    property: string,
    value: string
  ) => {
    const rls = _.cloneDeep(notification.rules);
    let updated;
    if (rls && rls[type]) {
      if (type === "sendnotification" && Array.isArray(rls[type])) {
        const { sendnotification } = rls;
        sendnotification[0] = { ...sendnotification[0], [property]: value };
        updated = {
          ...rls,
          sendnotification
        };
      } else {
        updated = {
          ...rls,
          [type]: {
            ...rls[type],
            [property]: value
          }
        };
      }
    }
    onChange("rules", updated);
  };

  const isSoundNotification = () => {
    return (
      notification.type === INTERFACE_USER_NOTIFICATION_TYPES.SOUND ||
      notification.type === INTERFACE_USER_NOTIFICATION_TYPES.SOUND_HIGH
    );
  };
  const isMovementNotification = () => {
    return (
      notification.type === INTERFACE_USER_NOTIFICATION_TYPES.MOVEMENT ||
      notification.type === INTERFACE_USER_NOTIFICATION_TYPES.MOVEMENT_HIGH
    );
  };
  const isOutofBedNotification = () => {
    return (
      notification.type === INTERFACE_USER_NOTIFICATION_TYPES.OUT_OF_BED ||
      notification.type ===
        INTERFACE_USER_NOTIFICATION_TYPES.OUT_OF_BED_IMMEDIATE
    );
  };

  const getThresholdText = (): string => {
    if (isMovementNotification()) {
      return t("is greater than or equal to");
    }
    if (isOutofBedNotification()) {
      return t("Minimum Time out-of-bed is greater than or equal to");
    }
    if (isSoundNotification()) {
      return t("Sound Level is greater than or equal to");
    }
    return "";
  };

  const textForOKmessageThreshold = (): string => {
    if (isMovementNotification()) {
      return t("is less than");
    }
    if (isOutofBedNotification()) {
      return t("Time presence is greater than or equal to");
    }
    return "";
  };

  const getEndAdornmentText = () => {
    if (isOutofBedNotification()) {
      return t("mins");
    }
    if (isSoundNotification()) {
      return t("db");
    }
    return "";
  };

  const isUserRule = () => {
    return notification.notification_type === NOTIFICATION_TYPES.USER_RULES;
  };
  console.log(notification?.rules?.sendnotification[0]?.threshold);
  return (
    <div className={classes.criteriaWrapper}>
      <div
        className={`${classes.hideAdvanced}`}
        aria-hidden
        onClick={() => setToggleAdvanced(false)}
      >
        {" "}
        {t("Hide Advanced")}
      </div>
      <div className={classes.sectionHead}>
        {t("Notification Rule Criteria")}
      </div>
      <div className={classes.subSection}>
        {t("When to send notification message")}
      </div>
      <div className={classes.formRow}>
        <div>
          {isUserRule() ? (
            <div className={classes.criteriaLabel}>
              {t("Minimum time present")}
            </div>
          ) : (
            <div className={classes.criteriaAdornment}>
              {t("Minimum downtime is greater than or equal to")}
            </div>
          )}
          <div className={classes.criteriaInputBox}>
            <SMTextFieldWithIcon
              placeholder={t("e.g. 5")}
              onChange={(val: string) => {
                criteriaChange("sendnotification", "minTimePresent", val);
              }}
              value={
                notification?.rules?.sendnotification[0]?.minTimePresent || ""
              }
              width="120px"
              endAdornment={
                <div className={classes.endAdornmentStyle}>{t("mins")}</div>
              }
            />
          </div>
          {isUserRule() ? (
            <div className={classes.criteriaAdornment}>{t("AND")}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      {isUserRule() ? (
        <div className={classes.formRow}>
          <div>
            {isMovementNotification() ? (
              <>
                <div className={classes.criteriaLabel}>
                  {t("Average movement in the last")}
                </div>
                <div className={classes.criteriaInputBox}>
                  <SMTextFieldWithIcon
                    placeholder={t("e.g. 15")}
                    onChange={(val: string) => {
                      criteriaChange(
                        "sendnotification",
                        "avarageMovementInTime",
                        val
                      );
                    }}
                    value={
                      notification?.rules?.sendnotification[0]
                        ?.avarageMovementInTime || ""
                    }
                    width="120px"
                    endAdornment={
                      <div className={classes.endAdornmentStyle}>
                        {t("mins")}
                      </div>
                    }
                  />
                </div>{" "}
              </>
            ) : (
              ""
            )}

            <div className={classes.criteriaAdornment}>
              {getThresholdText()}
            </div>
            <div className={classes.criteriaInputBox}>
              <SMTextFieldWithIcon
                placeholder={t("e.g. 60")}
                onChange={(val: string) => {
                  criteriaChange("sendnotification", "threshold", val);
                }}
                value={
                  notification?.rules?.sendnotification[0]?.threshold || ""
                }
                width="120px"
                endAdornment={
                  <div className={classes.endAdornmentStyle}>
                    {getEndAdornmentText()}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={classes.formRow}>
        <div className={`${classes.subSection} ${classes.okMessage}`}>
          {t("When to send OK message")}
        </div>
        {isUserRule() ? (
          <div>
            {isMovementNotification() || isSoundNotification() ? (
              <>
                <div className={classes.criteriaLabel}>
                  {isMovementNotification()
                    ? t("Average movement in the last")
                    : t("Sound Level in the last")}
                </div>
                <div className={classes.criteriaInputBox}>
                  <SMTextFieldWithIcon
                    placeholder={t("e.g. 15")}
                    onChange={(val: string) => {
                      criteriaChange("sendOkMsg", "avarageMovementInTime", val);
                    }}
                    value={
                      notification?.rules?.sendOkMsg?.avarageMovementInTime ||
                      ""
                    }
                    width="120px"
                    endAdornment={
                      <div className={classes.endAdornmentStyle}>
                        {t("mins")}
                      </div>
                    }
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className={classes.criteriaAdornment}>
              {textForOKmessageThreshold()}
            </div>
            <div className={classes.criteriaInputBox}>
              <SMTextFieldWithIcon
                placeholder={t("e.g. 15")}
                onChange={(val: string) => {
                  criteriaChange("sendOkMsg", "threshold", val);
                }}
                value={notification?.rules?.sendOkMsg?.threshold || ""}
                width="120px"
                endAdornment={
                  <div className={classes.endAdornmentStyle}>
                    {getEndAdornmentText()}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <div className={classes.criteriaLabel}> {t("Device is online")}</div>
        )}
      </div>
    </div>
  );
}

export default NotificationRuleCriteria;
