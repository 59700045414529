import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Loading } from "../../components";
import { authenticate } from "../../store/actions/auth";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authChecked: false
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.authenticate().then(() => {
        this.setState({ authChecked: true });
      });
    }
  }

  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;

    // console.log({ ...this.props })

    if (!isAuthenticated && !this.state.authChecked) {
      return <Loading />;
    }
    return isAuthenticated ? (
      <Route {...rest} component={Component} />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.any,
  authenticate: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !_.isEmpty(state.auth.user)
  };
};

export default connect(mapStateToProps, { authenticate })(PrivateRoute);
