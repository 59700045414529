import moment from "moment";

import UserDevice from "./UserDevice";
import i18n from "../../../i18n/config";

const t = i18n.t.bind(i18n);
class UserDeviceSession extends UserDevice {
  constructor(user, device, session) {
    super(user, device);
    this.session = session;
    // Calculate status
    this.status = "unknown";
    this.statusDurationText = "";
    this.statusMovementText = "";
    if (!this.isDeviceOnline()) {
      this.status = "disconnected";
      const duration = moment.duration(
        this.device.time_since_last_activity,
        "seconds"
      );

      const totalDays = getDays(
        this.device.time_since_last_activity * 1000
      ).days;

      if (totalDays < 1) {
        this.statusDurationText = `${t("Duration")}: ${this.secondsToTimeString(
          duration._data
        )}`;
      } else {
        this.statusDurationText = `${t("Duration")}: ${totalDays}d`;
      }

      // const formatted = moment.utc(this.device.time_since_last_activity).format("DD HH:mm");
    } else if (this.hasSessionWithData()) {
      // Go through data.

      const statusFiltered = [];
      // awake length in 5 minut, data will update every 30 seconds, so 5 minute == 5* 2
      let awakeLength = 2 * 2;
      for (
        let i = 0;
        i < this.session._embedded.sleep_analysis.hypnogram.sleep_stage.length;
        i += 1
      ) {
        if (this.getStatusByIndex(i) === "asleep") {
          awakeLength = 0;
          statusFiltered.push("asleep");
        } else if (this.getStatusByIndex(i) === "away") {
          awakeLength += 1;
          statusFiltered.push("away");
        } // Awake
        else {
          awakeLength += 1;
          if (awakeLength >= 2 * 2) {
            if (statusFiltered[statusFiltered.length - 1] === "asleep") {
              for (
                let j = statusFiltered.length - awakeLength;
                j < statusFiltered.length;
                j += 1
              ) {
                if (statusFiltered[j] === "asleep") {
                  statusFiltered[j] = "awake";
                }
              }
            }
            statusFiltered.push("awake");
          } else {
            statusFiltered.push("asleep");
          }
        }
      }
      if (this.session.end_time != null) {
        this.status = "away";
      } else {
        this.status = statusFiltered[statusFiltered.length - 1];
        const movement =
          this.session._embedded.sleep_analysis.epoch_data.movement_mean[
            this.session._embedded.sleep_analysis.epoch_data.movement_mean
              .length - 1
          ];
        if (movement > 30) this.statusMovementText = t("Stor bevegelse");
        else if (movement > 10)
          this.statusMovementText = t("Moderat bevegelse");
        else if (movement > 2) this.statusMovementText = t("Litt bevegelse");
        else if (this.status === "away") this.statusMovementText = "";
        else this.statusMovementText = t("Rolig");
      }

      // Duration
      // Todo (fr@vitalthings.com)
      let i;
      for (i = statusFiltered.length - 1; i > 0; i -= 1) {
        if (statusFiltered[i] !== this.status) {
          break;
        }
      }

      // const timestamp = moment(this.session._embedded.sleep_analysis.hypnogram.timestamp[i]);
      // This will only work as expected when the TZ of the device AND the web client is the same.
      // const now = moment();
      // const delta = now - timestamp;
      // let timestamp_string =
      //     timestamp.date() != now.date() ? timestamp.format("HH:mm") : timestamp.format("HH:mm (D/M)");
      const duration = this.durationAsString(
        this.session._embedded.sleep_analysis.hypnogram.timestamp[i]
      );
      this.statusDurationText = `${t("Duration")}: ${
        statusFiltered[i] === this.status &&
        this.session._embedded.sleep_analysis.hypnogram.sleep_stage.length ===
          240
          ? "før "
          : ""
      }${duration}`;
    } else if (this.hasSession()) {
      if (this.session.status === "ENDED") {
        // if the session is ended but the sleep analysis is empty
        this.status = "away"; // set the status as away, as the session is ended
        this.statusDurationText = this.session.end_time
          ? this.durationAsString(this.session.end_time)
          : ""; // if the end_time is present, then calculate the duration text, empty otherwise
      } else {
        // if the session is not ended
        this.status = "awake";
        this.statusDurationText = "";
      }
    }

    // Calculate RPM

    this.rpm = 0;
    if (
      this.hasActiveSession() &&
      this.hasSessionWithData() &&
      (this.getStatus() === "awake" || this.getStatus() === "asleep")
    ) {
      let count = 0;
      let sum = 0.0;
      for (
        let i = 0;
        i <
        this.session._embedded.sleep_analysis.epoch_data.respiration_rate_mean
          .length;
        i += 1
      ) {
        if (
          this.session._embedded.sleep_analysis.epoch_data
            .respiration_rate_mean[i] > 0
        ) {
          sum +=
            this.session._embedded.sleep_analysis.epoch_data
              .respiration_rate_mean[i];
          count += 1;
        }
      }
      if (count > 5 * 2) this.rpm = Math.round(sum / count);
    }

    // Calculate SpO2

    this.spo2 = 0;
    if (
      this.hasActiveSession() &&
      this.hasSessionWithData() &&
      (this.getStatus() === "awake" || this.getStatus() === "asleep")
    ) {
      if (
        this.session._embedded.sleep_analysis.epoch_data.external_spo2_mean[
          -1
        ] > 0
      ) {
        this.spo2 =
          this.session._embedded.sleep_analysis.epoch_data.external_spo2_mean[
            -1
          ];
      }
    }

    // Calculate timestamp

    this.timestamp = null;
    if (this.hasSessionWithData()) {
      this.timestamp = new Date(
        Date.parse(
          this.session._embedded.sleep_analysis.hypnogram.timestamp[
            this.session._embedded.sleep_analysis.hypnogram.timestamp.length - 1
          ]
        )
      );
    } else if (this.hasSession()) {
      this.timestamp = new Date(Date.parse(this.session.start_time));
    }
  }

  /**
   * Sleep stage
   */

  getStatusByIndex(ix) {
    if (this?.session?._embedded?.sleep_analysis?.hypnogram?.sleep_stage[ix]) {
      switch (this.session._embedded.sleep_analysis.hypnogram.sleep_stage[ix]) {
        case 1:
        case 2:
        case 3:
          return "asleep";
        case 4:
          return "awake";
        case 5:
          return "away";
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  getStatus() {
    return this.status;
  }

  getRPM() {
    return this.rpm;
  }

  getSpO2() {
    return this.spo2;
  }

  getTimestamp() {
    return this.timestamp;
  }

  hasActiveSession() {
    return this.session != null && this.session.end_time == null;
  }

  isDeviceOnline() {
    return this.deviceTimeSinceActive() < 10 * 60;
  }

  deviceTimeSinceActive() {
    return this.device.time_since_last_activity;
  }

  hasSession() {
    return this.session != null;
  }

  hasSessionWithData() {
    return (
      this.session != null &&
      this.session._embedded &&
      this.session._embedded.sleep_analysis &&
      this.session._embedded.sleep_analysis.hypnogram &&
      this.session._embedded.sleep_analysis.hypnogram.sleep_stage != null
    );
  }

  toString() {
    return t("USER_AND_DEVICE_PLACEHOLDER", {
      userName: this.getName(),
      deviceName: this.getRoom()
    });
  }

  secondsToTimeString(duration) {
    let str = "";
    if (duration) {
      // str += duration.years ? `${duration.years}y ` : "";
      // str += duration.months ? `${duration.months}m ` : "";
      str += duration.days ? `${duration.days}d ` : "";
      str += duration.hours ? `${duration.hours}h ` : "";
      str += duration.minutes ? `${duration.minutes}m ` : "";
    }
    return `${str}`;
  }

  getStatusMovementText() {
    return this.statusMovementText;
  }

  getStatusDurationText() {
    return this.statusDurationText;
  }

  durationAsString(start, end) {
    if (!end) {
      this.end = moment();
    }
    const duration = moment.duration(moment(end).diff(moment(start)));

    if (duration._data && duration._data.days < 1) {
      return this.secondsToTimeString(duration._data);
    }
    const { days } = getDays(duration._milliseconds);
    return `${days}d`;
  }
}
function getDays(ms) {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return {
    days,
    hours,
    minutes
  };
}
export default UserDeviceSession;
