import { USER_SETTINGS_CONSTANTS } from "../constants/localSettingsConstants";

const TOKEN_KEY = "SOMNOFY_AUTH_TOKEN";
const STYLE_TYPE_KEY = "SOMNOFY_STYLE_TYPE";
const TIME_FILTER = "TIME_FILTER";
const LOCAL_SETTINGS = "LOCAL_SETTINGS";

export const LANG_KEY = "LANG_KEY";
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getStyleType = () => {
  return localStorage.getItem(STYLE_TYPE_KEY);
};

export const toggleStyleType = () => {
  const nextStyleType = getStyleType() === "light" ? "dark" : "light";
  localStorage.setItem(STYLE_TYPE_KEY, nextStyleType);
  return nextStyleType;
};

export const setFilter = (filter) => {
  localStorage.setItem(TIME_FILTER, JSON.stringify(filter));
};

export const removeLocalSetting = (key) => {
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  if (currentSettings[key]) {
    delete currentSettings[key];
    localStorage.setItem(LOCAL_SETTINGS, JSON.stringify(currentSettings));
  }
};

export const setLocalSettings = (settings) => {
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  const merged = { ...currentSettings, ...settings };
  localStorage.setItem(LOCAL_SETTINGS, JSON.stringify(merged));
};

export const getLocalSettings = (key) => {
  if (key === USER_SETTINGS_CONSTANTS.all) {
    return localStorage.getItem(LOCAL_SETTINGS) || {};
  }
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  return currentSettings[key];
};
export const getTimeFilter = () => {
  return localStorage.getItem(TIME_FILTER)
    ? JSON.parse(localStorage.getItem(TIME_FILTER))
    : null;
};

export const setLanguage = (lang) => {
  localStorage.setItem(LANG_KEY, lang);
};

export const getLanguage = (lang) => {
  return localStorage.getItem(LANG_KEY, lang);
};
