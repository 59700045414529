import { useSelector } from "react-redux";
import React from "react";

import { Monitor as SomnofyMonitor } from "./components/index";
import { getToken } from "../../utils/localStorage";

function MonitorContainer() {
  const apiInfo = { token: getToken(), authType: "Basic" };

  // const token = useSelector(state => {
  //     return state.auth.token;
  // });

  const user = useSelector((state) => {
    return state.auth.user;
  });

  /**
   * React useSelector hook for to fetch current logined user
   *
   * @return users
   */
  const loginedUser = useSelector((state) => {
    return state?.auth.user || {};
  });

  return (
    <SomnofyMonitor apiInfo={apiInfo} user={user} loginedUser={loginedUser} />
  );
}

export default MonitorContainer;
