import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  // Animations

  root: {
    marginTop: 28,
    position: "relative",
    paddingTop: 54
  },
  container: {
    paddingBottom: 75,
    float: "left",
    width: "100%"
  },

  title: {
    fontSize: 34
  },
  sectionHead: {
    fontSize: 24,
    marginBottom: 16
  },
  subTitle: {
    fontSize: 16
  },
  formContainer: {
    marginTop: 32
  },
  devicesWrapper: {
    width: 251,
    marginTop: 32
  },
  radioGroup: {
    flexDirection: "row"
  },
  formRow: {
    margin: "8px 0",
    float: "left",
    width: "100%"
  },
  subSection: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16
  },
  endAdornmentStyle: {
    paddingRight: 18
  },
  criteriaLabel: {
    float: "left",
    width: 272,
    fontSize: 16,
    paddingLeft: 32,
    marginTop: 15
  },
  criteriaInputBox: {
    float: "left",
    fontSize: 14
  },
  criteriaAdornment: {
    float: "left",
    fontSize: 16,
    margin: "15px 16px 15px 0",
    paddingLeft: 32
  },
  okMessage: {
    margin: "16px 0"
  },
  sectionContent: {
    marginTop: 16
  },
  showAdvanced: {
    color: theme.palette.primary.dark,
    cursor: "pointer",
    fontSize: 16
  },
  hideAdvanced: {
    float: "right",
    color: theme.palette.primary.dark,
    cursor: "pointer",
    fontSize: 16
  },
  criteriaWrapper: {
    float: "left",
    paddingTop: 32,
    width: "100%"
  }
}));
