import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PropTypes from "prop-types";
import React from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKey from "@material-ui/icons/VpnKey";
import _ from "lodash";

import { getErrorText } from "../../../../utils/api.util";
import { logIn } from "../../../../store/actions/auth";
import LoginFormComponent from "./LoginForm";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      shouldRedirect: false,
      error: ""
    };

    this.t = this.props.t;

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.inputFields = this.inputFields.bind(this);
  }

  inputFields() {
    return [
      {
        label: this.t("Username"),
        name: "username",
        type: "text",
        startAdornment: <AccountCircle />,
        required: true
      },
      {
        label: this.t("Password"),
        name: "password",
        type: this.state.showPassword ? "text" : "password",
        startAdornment: <VpnKey />,
        endAdornment: (
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => {
              this.setState((prevState) => ({
                showPassword: !prevState.showPassword
              }));
            }}
          >
            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
        required: true
      }
    ];
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ error: "" });
    const { username, password } = this.state;
    if (!!username && !!password) {
      this.props
        .logIn(username, password)
        .then((response) => {
          if (!response) {
            console.log("no response");
          }
          this.setState({ shouldRedirect: true });
        })
        .catch((err) => {
          const errorText =
            err && err.response && err.response
              ? getErrorText(err.response.status)
              : "Error";
          this.setState({ error: errorText });
        });
    } else if (!username && !password) {
      this.setState({ error: this.t("Please enter username and password") });
    } else if (!username) {
      this.setState({ error: this.t("Please enter username") });
    } else if (!password) {
      this.setState({ error: this.t("Please enter password") });
    }
  }

  render() {
    if (this.state.shouldRedirect && this.props.isLoggedIn) {
      const { from } = this.props.location.state || {
        from: { pathname: "/app/monitor" }
      };
      return <Redirect to={from} />;
    }

    return (
      <LoginFormComponent
        inputFields={this.inputFields()}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        error={this.state.error}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: !_.isEmpty(state.auth.user),
  user: state.auth.user,
  error: state.error
});

LoginForm.propTypes = {
  logIn: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.any,
  t: PropTypes.func
};

export default connect(mapStateToProps, { logIn })(
  withTranslation()(LoginForm)
);
