import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Device } from "interfaces/device.interface";
import { Group } from "interfaces/group.interface";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { SortTypes } from "constants/app.enum";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { User } from "interfaces/user.interface";
import {
  allChecked,
  getCheckedItemsCount,
  isAdminUser,
  sortUsers,
  toggleAllUsersChecked,
  viewUserDetails
} from "utils/userManagement";
import {
  deleteMultipleUsers,
  resetUserPreloaders
} from "store/actions/userActions";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import { normalizedGroupsEntity } from "utils/groupManagement";
import { toastSucess } from "utils/toast.util";
import BulkActions from "components/BulkActions/BulkActions";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";
import SMButton from "components/SMButton/SMButton";
import SMPagination from "components/SMPagination/SMPagination";
import SearchTextField from "components/SearchTextField/SearchTextField";

import { useStyles } from "./usersList.style";
import UserListRow from "./components/UserListRow";
import UsersListHeader from "./components/UsersListHeader";

interface UsersListInterface {
  users: User[];
  routePath: string;
  devicesEntityWithUserID: { [key: string]: Device };
  onSaveAction: (updatedUser: User, currentUserGroupsIds: string[]) => void;
  onDelete: (updatedUser: User) => void;
}

/**
 * @name UsersList
 * @description This is wrapper component for the login form.
 * @returns LoginForm componet
 */
function UsersList({
  users,
  routePath,
  devicesEntityWithUserID,
  onSaveAction,
  onDelete
}: UsersListInterface): JSX.Element {
  const classes = useStyles();
  const commonCls = CommonStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activePage, setCurrentPage] = useState(1);
  const [sortedUsers, setSortedUsers] = useState<User[]>();
  const [currentUsers, setCurrentUsers] = useState<User[]>();

  const [curSortField, setSortField] = useState<number>(3);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filterType, setFilterType] = useState<"name" | "birth_year">("name");
  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const perPage =
    +getLocalSettings(USER_SETTINGS_CONSTANTS.users_per_page) || 10;
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage);

  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});

  // bulk actions
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<{ [key: string]: boolean }>({});
  const [deleteUser, setDeleteUser] = useState<User>();

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  useEffect(() => {
    if (userState.currentAction === USER_ACTIONS.updateSuccess) {
      toastSucess(t("User updated successfully"));
      dispatch(resetUserPreloaders());
    }
  }, [userState.currentAction]);

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  useEffect(() => {
    // update in local storage
    setLocalSettings({
      [USER_SETTINGS_CONSTANTS.users_per_page]: +itemsPerPage
    });
  }, [itemsPerPage]);

  useEffect(() => {
    if (users) {
      setSortedUsers([
        ...users.sort((a: User, b: User) =>
          a.display_name > b.display_name ? 1 : -1
        )
      ]);
    }
  }, [users]);

  useEffect(() => {
    if (sortedUsers) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentUsers(sortedUsers.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [sortedUsers, activePage, itemsPerPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const sortList = (sortType: SortTypes): void => {
    if (!Number.isNaN(sortType)) {
      let order = "ASC";
      if (curSortField === sortType) {
        order = curSortOrder === "ASC" ? "DESC" : "ASC";
      }
      setSortField(sortType);
      setSortOrder(order as "ASC" | "DESC");
      const sortedArray = sortUsers(users, order, sortType);
      setSortedUsers(sortedArray);
    }
  };

  const onChange = (q: string, selectedType: string) => {
    let type = selectedType;
    if (!sortedUsers) {
      return;
    }
    if (!type) {
      type = "name";
    }
    setSearchQuery(q);
    setFilterType(type as "name" | "birth_year");
    let arrCopy: User[] = [...users];

    if (type === "name") {
      arrCopy = arrCopy.filter((item: User) => {
        return item.display_name.toLowerCase().includes(q.toLowerCase());
      });
    } else if (type === "birth_year") {
      arrCopy = arrCopy.filter((item: User) => {
        return item.birth_year.toString().includes(q);
      });
    }
    setCurrentPage(1);
    setSortedUsers(arrCopy);
  };

  const addNewUser = () => {
    const path = `${routePath}/0`;
    history.push(path);
  };

  const onToggleSelectAll = (checked: boolean) => {
    if (currentUsers) {
      setSelectedItems({
        ...selectedItems,
        ...toggleAllUsersChecked(currentUsers, checked)
      });
    }
  };

  const handleClick = (key: string) => {
    if (key === "remove_selected") {
      setOpenDeleteDialog(true);
    }
  };

  const onSave = (user: User, groupIds: string[]) => {
    setEditUser({ ...editUser, [user.id]: false });
    onSaveAction(user, groupIds);
  };

  const bulkDelete = () => {
    setCurrentPage(1);
    const usersToDelete: User[] = users.filter((user: User) => {
      return selectedItems[user.id];
    });
    dispatch(deleteMultipleUsers(usersToDelete));
    setSelectedItems({});
  };

  const onSingleDelete = () => {
    if (deleteUser) {
      onDelete(deleteUser);
      setDeleteUser(undefined);
    }
  };

  const ifBulkDelete = () => {
    const selected = users?.filter((user: User) => {
      return selectedItems[user.id];
    });
    return selected?.length > 0;
  };

  return (
    <div className={commonCls.tabContentWrapper}>
      <div className={classes.perPageWrapper}>
        <PerPageListItems
          type={USER_SETTINGS_CONSTANTS.users_per_page}
          label={t("Per page")}
          onChange={setItemsPerPage}
          total={sortedUsers?.length}
        />
      </div>

      {loginedUser && isAdminUser(loginedUser) && (
        <div className={classes.addNewUser}>
          <SMButton icon={<Plus />} text={t("Add User")} onClick={addNewUser} />
        </div>
      )}
      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={onChange}
          width="320px"
          placeholder={t("e.g. 156789")}
        />
      </div>
      {isAdminUser(loginedUser) ? (
        <div className={classes.bulkActionWrapper}>
          <BulkActions
            selectedItems={selectedItems}
            actions={[{ key: "remove_selected", label: t("Delete Selected") }]}
            handleClick={handleClick}
          />
        </div>
      ) : (
        <></>
      )}
      <UsersListHeader
        curSortOrder={curSortOrder}
        curSortField={curSortField}
        onClick={sortList}
        onToggleSelectAll={onToggleSelectAll}
        allSelected={allChecked(currentUsers || [], selectedItems)}
        isAdmin={isAdminUser(loginedUser)}
      />
      <div className={commonCls.rowContainer}>
        {currentUsers &&
          currentUsers.map((user: User, index) => {
            return (
              <UserListRow
                key={user.id}
                user={user}
                commonCls={commonCls}
                history={history}
                filterType={filterType}
                searchQuery={searchQuery ?? ""}
                devicesEntityWithUserID={devicesEntityWithUserID}
                groupEntity={groupEntity}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                t={t}
                onSaveAction={onSave}
                editUser={editUser}
                setEditUser={setEditUser}
                onDelete={(deleteMe: User) => {
                  setDeleteUser(deleteMe);
                  setOpenDeleteDialog(true);
                }}
                viewUserDetails={(event: any, userObj: User) =>
                  viewUserDetails(event, userObj, history)
                }
                loginedUser={loginedUser}
              />
            );
          })}
      </div>

      {sortedUsers && sortedUsers?.length > itemsPerPage && (
        <SMPagination
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          itemsLength={sortedUsers?.length}
          handlePageChange={handlePageChange}
        />
      )}

      {/* Delete dialog box */}
      <DialogBox
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={() => (ifBulkDelete() ? bulkDelete() : onSingleDelete())}
        title={ifBulkDelete() ? t("Delete users") : t("Delete this user")}
        dialogDesc={
          ifBulkDelete()
            ? t(
                "Deleting these users will remove all data associated with them. This action is non-reversable"
              )
            : t(
                "Deleting this user will remove all data associated with this user. This action is non-reversable"
              )
        }
        confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
        confirmPlaceHolder={t("Key in confirmation text here")}
        confirmText={t("I AM SURE")}
        buttonOk={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </div>
  );
}
export default UsersList;
