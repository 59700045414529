import axios from "axios";
import qs from "query-string";

import { API_URL } from "../../constants/constants";
import devices from "./api/devices";
import sessionsApi from "./api/sessions";
import sleepAnalysis from "./api/sleepAnalysis";
import userDeviceSession from "./api/deviceusers";
import usersApi from "./api/users";

export default class SomnofyClient {
  constructor(apiInfo, userId = null) {
    axios.defaults.paramsSerializer = (params) => qs.stringify(params);

    if (!apiInfo) {
      return;
    }
    axios.defaults.headers.common.Authorization = `${
      apiInfo.authType || "Bearer"
    } ${apiInfo.token}`;

    axios.defaults.baseURL = apiInfo.baseUrl || API_URL;
    if (userId !== null) {
      this.userId = userId;
    } else {
      this.userId = apiInfo.userId;
    }
  }

  getSessions(query = {}) {
    return sessionsApi.getSessions({ user_id: this.userId, ...query });
  }

  getSessionsForUser(userId, query = {}) {
    return sessionsApi.getSessions({ ...query, userId });
  }

  getUsers(query = {}) {
    return usersApi.getUsers({ user_id: this.userId, ...query });
  }

  getSleepAnalysis(sessionId) {
    return sleepAnalysis.getSleepAnalysis(sessionId, this.userId);
  }

  /* getUserDeviceSessions() {
        return userDeviceSession.getDevicesUsersSessions()
    } */

  getUserDevices() {
    return userDeviceSession.getDeviceUsers();
  }

  getSessionsForUserDevices(userDevices) {
    return userDeviceSession.getUserDeviceSessions(userDevices);
  }

  getDevices() {
    return devices.getDevices();
  }
}
