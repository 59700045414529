import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";

function UserListActionButtons({
  classes,
  onEditClick,
  onDeleteClick
}: {
  classes: any;
  onEditClick: () => void;
  onDeleteClick: () => void;
}) {
  return (
    <div
      className={`${classes.col} ${classes.txtCenter}`}
      data-ref="preventParentClick"
    >
      <div className={classes.actionIconWrapper}>
        <div
          onClick={onEditClick}
          data-ref="preventParentClick"
          aria-hidden
          className={`${classes.colorPrimary} ${classes.actionIcon}`}
        >
          <EditOutlinedIcon data-ref="preventParentClick" />
        </div>
        <div
          onClick={() => onDeleteClick()}
          aria-hidden
          className={`${classes.floatLeft} ${classes.colorPrimary} ${classes.actionIcon}`}
        >
          <DeleteOutlineOutlinedIcon data-ref="preventParentClick" />
        </div>
      </div>
    </div>
  );
}
export default UserListActionButtons;
