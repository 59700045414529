import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: theme.palette.secondary.grey,
    height: "calc(100vh - 70px)"
  },
  heading: {
    width: "100%",
    height: 80,
    background: "#FFF",
    fontSize: 48,
    paddingLeft: 34
  },
  wrapper: {
    padding: 32
  },
  perPageWrapper: {
    width: "100%",
    float: "left",
    margin: "26px 0 16px 0"
  },
  progressWrapper: {
    float: "left",
    width: "100%",
    textAlign: "center",
    marginTop: 25
  },
  progress: {
    color: theme.palette.primary.dark
  },
  infoText: {},
  legends: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.6)",
    float: "left",
    marginTop: 33
  },
  legendsIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    color: "#B00020",
    float: "left",
    "& > span": {}
  },
  legendsTitle: {
    fontWeight: 500,
    clear: "both",
    marginBottom: 8,
    color: "rgba(0, 0, 0, 0.87)"
  },
  desc: {
    clear: "both",
    lineHeight: "17px"
  },
  legendItems: {
    clear: "both",
    margin: 0,
    "& > li": {
      margin: "4px 0"
    }
  },
  baseLineHeights: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "20px",
    width: 480,
    marginTop: 16
  },
  dashboardModal: {
    overflow: "auto"
  },
  dashboardModalContent: {
    margin: 38,
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    overflow: "auto",
    height: "calc(100vh - 76px)",
    overflowY: "auto",
    zIndex: 999
  },
  dashboardModalCloseIcon: {
    position: "absolute",
    right: 18,
    top: 18,
    backgroundColor: theme.palette.background.default,
    border: "1px solid rgba(0, 0, 0, 0.4)",
    borderRadius: 23,
    fontSize: 38,
    cursor: "pointer"
  }
}));

export default useStyles;
