import React, { useEffect, useState } from "react";

import ShowMoreComponent from "components/ShowMoreComponent/ShowMoreComponent";

function TextRowWithViewMore({
  lists,
  commonCls
}: {
  lists: string[];
  commonCls: any;
}): JSX.Element {
  const showItems = 3;
  const [filteredItems, setFilteredItems] = useState<string[]>([]);
  const [showMore, setShowMore] = useState<boolean>(true);

  useEffect(() => {
    const groups = [...lists] || [];
    const items = showMore ? groups.splice(0, showItems) : groups;
    setFilteredItems(items);
  }, [showMore, lists]);

  return (
    <div>
      <div>
        {filteredItems?.map((text: string, index) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${text}-${index}`}
              className={commonCls.textList}
              style={{ marginBottom: lists.length < 2 ? 0 : 16 }}
            >
              {text}
            </div>
          );
        })}
      </div>
      <div className={commonCls.fullWidth}>
        {lists && lists.length && lists.length > showItems ? (
          <ShowMoreComponent
            total={lists.length}
            showMore={showMore}
            showItems={showItems}
            setShowMore={setShowMore}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default TextRowWithViewMore;
