import { authTypes } from "../types";
import { setToken } from "../../utils/localStorage";
import authApi from "../../services/utils/api/auth";

export const logIn = (username, password) => ({
  type: authTypes.LOGIN,
  payload: authApi.logIn(username, password)
});

export const authenticate = (token = null) => ({
  type: authTypes.AUTHENTICATE,
  payload: authApi.authenticate(token)
});

export const logOut = () => {
  setToken(null);
  return {
    type: authTypes.LOGOUT
  };
};

export const resetlogin = () => ({
  type: authTypes.AUTHENTICATE_REJECTED
});
