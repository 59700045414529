// @material-ui/icons
import WidgetsIcon from "@material-ui/icons/Widgets";

import { ReactComponent as Adminstration } from "assets/icons/adminstration.svg";
import { ReactComponent as GraphIcon } from "assets/icons/graph.svg";
import { ReactComponent as MonitorIcon } from "assets/icons/monitor.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as ReportIcon } from "assets/icons/report.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings-outlined.svg";

import { LoginContainer } from "../../@modules/login";
import { MonitorContainer } from "../../@modules/monitor";
// Features
import AppLocalisation from "../../@modules/appLocalisation/AppLocalisation";
import Dashboard from "../../@modules/dashboard";
import Landing from "../../@modules/landing";
import NotificationContainer from "../../@modules/notification/NotificationContainer";
import ReportingContainer from "../../@modules/reporting/ReportingContainer";
import SettingsContainer from "../../@modules/settings/SettingsContainer";
import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);

/**
 * path: Last subpath (URL = <layout> + <path>)
 * name: Display name for route
 * mini: Mini display name for route
 * icon: Display icon for route
 * component: Which component to render on route
 * layout: Which layout the route should be rendered in
 * requireAuth: Specifies whether the route should require the user to be authenticated
 * userRoute: A function which takes user as an argument and returns a route based on the user. Will be set as an array of views descending from the route.
 *
 */
const getInitials = (user) => {
  const initials = user.display_name.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

// The routes
export default [
  {
    path: "/monitor",
    name: t("Monitoring"),
    icon: MonitorIcon,
    mini: "MO",
    slug: "monitor",
    component: MonitorContainer,
    layout: "/app"
  },
  {
    path: "/dashboard",
    name: t("Sleep records"),
    slug: "dashboard",
    icon: GraphIcon,
    component: Dashboard,
    layout: "/app",
    collapse: true,
    withUserRoutes: (user) => ({
      path: `/dashboard/${user.id}`,
      name: user.display_name,
      mini: getInitials(user),
      component: Dashboard,
      layout: "/app"
    })
  },
  {
    path: "/login",
    name: t("Login"),
    icon: WidgetsIcon,
    slug: "login",
    mini: "LI",
    component: LoginContainer,
    layout: "/auth"
  },
  {
    path: "/reporting",
    name: t("Reporting"),
    mini: "ST",
    slug: "reporting",
    component: ReportingContainer,
    icon: ReportIcon,
    layout: "/app"
  },

  {
    path: "/settings",
    name: t("Administration"),
    icon: Adminstration,
    mini: "ST",
    slug: "administration",
    component: SettingsContainer,
    layout: "/app"
  },
  // {
  //   path: "/notification",
  //   name: t("Notification"),
  //   icon: NotificationIcon,
  //   mini: "ST",
  //   slug: "notification",
  //   component: NotificationContainer,
  //   layout: "/app"
  // },
  {
    path: "/appLocalisation",
    name: t("Settings"),
    icon: SettingsIcon,
    mini: "ST",
    slug: "appLocalisation",
    component: AppLocalisation,
    layout: "/app"
  },
  {
    path: "",
    slug: "somnofy",
    name: t("Somnofy"),
    mini: "S",
    component: Landing,
    layout: "/home"
  }
];

// AppLocalisation
