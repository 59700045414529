import { Checkbox, FormControlLabel } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import Highlighted from "components/Highlighted/HighLighted";
import TextRowWithViewMore from "components/TextRowWithViewMore/TextRowWithViewMore";

interface Props {
  searchQuery: string | undefined;
  commonCls: any;
  group: Group;
  userGroupType: string;
  deviceUsers?: User[];
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  viewGroupDetails: (event: any, group: Group) => void;
  setEditGroup: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editGroup: { [key: string]: boolean };
  onDelete: (group: Group) => void;
}

function GroupListRowView({
  searchQuery,
  commonCls,
  group,
  deviceUsers,
  groupEntity,
  userGroupType,
  selectedItems,
  setSelectedItems,
  viewGroupDetails,
  setEditGroup,
  editGroup,
  onDelete
}: Props) {
  return (
    <div
      className={`${commonCls.row} ${commonCls.evenChildBackground}`}
      key={group.id}
      aria-hidden
      onClick={(event: any) => viewGroupDetails(event, group)}
    >
      <div
        className={`${commonCls.checkboxlistWraper}`}
        data-ref="preventParentClick"
      >
        <FormControlLabel
          value="select"
          control={<Checkbox color="default" />}
          label=""
          labelPlacement="end"
          checked={!!selectedItems[group.id]}
          onChange={(e, value) => {
            setSelectedItems({ ...selectedItems, [group.id]: value });
          }}
        />
      </div>
      <div className={`${commonCls.col} ${commonCls.colorPrimary}`}>
        <Highlighted text={group.name} highlight={searchQuery} />
      </div>

      <div className={commonCls.col}>
        {deviceUsers && (
          <TextRowWithViewMore
            lists={deviceUsers.map((user: User) => user.display_name)}
            commonCls={commonCls}
          />
        )}
      </div>
      {userGroupType === UserGroupType.viewer && (
        <div className={commonCls.col}>
          <TextRowWithViewMore
            lists={group.view_user_groups.map(
              (id: string) => groupEntity[id]?.name
            )}
            commonCls={commonCls}
          />
        </div>
      )}
      <div
        className={`${commonCls.col} ${commonCls.txtCenter}`}
        data-ref="preventParentClick"
      >
        <div className={commonCls.actionIconWrapper}>
          <div
            onClick={() => {
              const updated = _.mapValues(editGroup, () => false);
              setEditGroup({ ...updated, [group.id]: true });
            }}
            data-ref="preventParentClick"
            aria-hidden
            className={`${commonCls.colorPrimary} ${commonCls.actionIcon}`}
          >
            <EditOutlinedIcon data-ref="preventParentClick" />
          </div>
          <div
            onClick={() => onDelete(group)}
            aria-hidden
            className={`${commonCls.colorPrimary} ${commonCls.actionIcon}`}
          >
            <DeleteOutlineOutlinedIcon data-ref="preventParentClick" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GroupListRowView;
