import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import React from "react";
import moment from "moment";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { getLocalSettings } from "utils/localStorage";

import {
  getHypnogramPlotData,
  getSessionDetailsPlotData,
  getSleepHistoryPlotData
} from "../../services/utils/formatters";
import Client from "../../services/apiClient";
import DashboardComponent from "./DashBoard";
import exportToExcel from "../../utils/exportToExcel";

moment.locale("en");

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: null,
      sleepHistoryOptions: null,
      sessionDetailsOptions: null,
      hypnogramOptions: null,
      dateFrom: moment().subtract(3, "months"),
      dateTo: moment().endOf("day"),
      filteredSession: null,
      report: null
    };

    this.exportTpoExcel = this.exportTpoExcel.bind(this);
    this.setDate = this.setDate.bind(this);
    this.loadSessions = this.loadSessions.bind(this);
    this.filterSessionstime = this.filterSessionstime.bind(this);
    this.toggleHypnogram = this.toggleHypnogram.bind(this);
    this.onHover = this.onHover.bind(this);
    this.showLastNight = this.showLastNight.bind(this);

    this.getReport = this.getReport.bind(this);
    this.renderSessionDetails = this.renderSessionDetails.bind(this);
    this.getSessionDetailsOptions = this.getSessionDetailsOptions.bind(this);
  }

  setDate(key, date) {
    // console.log(key, date)

    this.setState({
      [key]: date
    });
  }

  loadSessions({ dateFrom, dateTo }, callback = null) {
    const { t } = this.props;
    let dFrom = dateFrom;
    let dTo = dateTo;
    dFrom = dFrom.utc().startOf("day").toISOString();
    dTo = dateTo.utc().endOf("day").toISOString();
    this.client
      .getSessions({ from: dFrom, to: dTo })
      .then((res) => {
        let title = t("Sleep history");
        if (this.props.userDisplayName) {
          title = `${t("Sleep history")} ${t("for")} ${
            this.props.userDisplayName
          }`;
        }

        const sleepHistoryPlotOptions = getSleepHistoryPlotData(res);
        const sleepHistoryOptions = {
          sleepHistoryStagesOptions: {
            onChartMouseOver: this.onHover,
            onChartClick: () => console.log("click"),
            plotData: sleepHistoryPlotOptions.sleepHistoryStagesPlotData
          },
          sleepHistoryVitalsOptions: {
            onChartMouseOver: this.onHover,
            onChartClick: () => console.log("click"),
            plotData: sleepHistoryPlotOptions.sleepHistoryVitalsPlotData
          },
          datePickerOptions: {
            onChangeFrom: (from) => this.setDate("dateFrom", from),
            fromValue: this.state.dateFrom,
            fromLabel: "Date from",
            onChangeTo: (to) => this.setDate("dateTo", to),
            toValue: this.state.dateTo,
            toLabel: "Date to",
            onChange: (dates) => {
              if (!!dates[0] && !!dates[1]) {
                return this.loadSessions({
                  dateFrom: dates[0].utc().startOf("day"),
                  dateTo: dates[1].utc().endOf("day")
                });
              }
              return this.loadSessions({
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
              });
            }
          },
          title
        };

        // Todo: (fr@vitalthings.com)
        this.setState(
          {
            sessions: res,
            sleepHistoryOptions: {
              // eslint-disable-next-line react/no-access-state-in-setstate
              ...this.state.sleepHistoryOptions,
              ...sleepHistoryOptions
            }
          },
          () => {
            // if (callback) {
            //   // callback();
            // }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterSessionstime({ from, to, enabled }) {
    let sleepHistoryPlotOptions;
    if (enabled) {
      const format = "HH:mm";
      let endTimeIsNextDay = false;
      if (moment(from, format) > moment(to, format)) {
        endTimeIsNextDay = true;
      }
      const fromAsHourMinute = from.split(":");
      const toAsHourMinute = to.split(":");
      const sessions = this.state.sessions.filter((session) => {
        const start = moment(session.start_time).set({
          hour: fromAsHourMinute[0],
          minute: fromAsHourMinute[1]
        });
        const end = moment(session.start_time).set({
          hour: toAsHourMinute[0],
          minute: toAsHourMinute[1]
        });
        if (endTimeIsNextDay) {
          end.add(1, "d");
        }
        return moment(session.start_time).isBetween(start, end);
      });

      this.setState({
        filteredSession: _.cloneDeep(sessions)
      });

      sleepHistoryPlotOptions = getSleepHistoryPlotData(sessions);
    } else {
      this.setState({
        filteredSession: null
      });

      sleepHistoryPlotOptions = getSleepHistoryPlotData(this.state.sessions);
    }

    // Todo: (fr@vitalthings.com)
    const sleepHistoryStagesOptions = {
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state.sleepHistoryOptions.sleepHistoryStagesOptions,
      plotData: sleepHistoryPlotOptions.sleepHistoryStagesPlotData
    };

    // Todo: (fr@vitalthings.com)
    const sleepHistoryVitalsOptions = {
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state.sleepHistoryOptions.sleepHistoryVitalsOptions,
      plotData: sleepHistoryPlotOptions.sleepHistoryVitalsPlotData
    };

    // Todo: (fr@vitalthings.com)
    this.setState({
      sleepHistoryOptions: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.sleepHistoryOptions,
        sleepHistoryStagesOptions,
        sleepHistoryVitalsOptions
      }
    });
  }

  componentDidMount() {
    this.client = new Client(this.props.apiInfo, this.props.selectedUserId);
    this.loadSessions({
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo
    });
  }

  toggleHypnogram(e, id = null) {
    let sessionId;
    if (!id && this.state.ix != null) {
      sessionId = this.state.sessions[this.state.ix]?.session_id;
    } else {
      sessionId = id;
    }

    if (!sessionId) {
      return;
    }

    this.client.getSleepAnalysis(sessionId).then((res) => {
      const hypnogramOptions = {
        plotData: getHypnogramPlotData(res)
      };

      // Todo: (fr@vitalthings.com)
      this.setState({
        hypnogramOptions,
        // eslint-disable-next-line react/no-unused-state
        sleepAnalysis: res,
        // eslint-disable-next-line react/no-access-state-in-setstate
        showHypnogram: !this.state.showHypnogram
      });
    });
  }

  getReport(session) {
    return session?._embedded?.sleep_analysis?.report || {};
  }

  getSessionDetailsOptions(sessionDetailsPlotOptions) {
    return {
      sleepScoreOptions: {
        plotData: {
          ...sessionDetailsPlotOptions.sleepScorePlotData,
          height: 300
        }
      },
      sleepStagesOptions: {
        plotData: sessionDetailsPlotOptions.sleepStagesPlotData
      },
      sleepStatisticsOptions: {
        plotData: sessionDetailsPlotOptions.sleepStatisticsPlotData
      }
    };
  }

  renderSessionDetails(session, e) {
    const report = this.getReport(session);
    const sessionDetailsPlotOptions = getSessionDetailsPlotData(session);
    const sessionDetailsOptions = this.getSessionDetailsOptions(
      sessionDetailsPlotOptions
    );

    const dashboardViewMode = getLocalSettings(
      USER_SETTINGS_CONSTANTS.set_history_view_mode
    );

    this.setState(
      {
        ix: e.target.ix,
        showHypnogram: false,
        sessionDetailsOptions,
        report
      },
      () => {
        if (dashboardViewMode !== "history") {
          this.toggleHypnogram(e, session.session_id);
        }
      }
    );
  }

  onHover(src, e) {
    const session = this.state.filteredSession
      ? this.state.filteredSession[e.target.ix]
      : this.state.sessions[e.target.ix];
    this.renderSessionDetails(session, e);
  }

  exportTpoExcel() {
    exportToExcel(this.state.sessions, this.props.userDisplayName);
  }

  showLastNight() {
    const dFrom = moment().subtract(3, "months");
    const dateTo = moment().endOf("day");
    this.client
      .getSessions({
        from: dFrom.utc().startOf("day").toISOString(),
        to: dateTo.utc().endOf("day").toISOString()
      })
      .then((res) => {
        if (res && res.length) {
          const lastNight = res[res.length - 1];
          this.renderSessionDetails(lastNight, {
            target: { ix: res.length - 1 }
          });
        }
      });
  }

  render() {
    return (
      <DashboardComponent
        sleepHistoryOptions={this.state.sleepHistoryOptions}
        sessionDetailsOptions={this.state.sessionDetailsOptions}
        showHypnogram={this.state.showHypnogram}
        onToggleHypnogram={this.toggleHypnogram}
        hypnogramOptions={this.state.hypnogramOptions}
        filterSessionstime={this.filterSessionstime}
        exportTpoExcel={this.exportTpoExcel}
        sleepReport={this.state.report}
        showLastNight={this.showLastNight}
      />
    );
  }
}

Dashboard.propTypes = {
  apiInfo: PropTypes.shape({
    token: PropTypes.string.isRequired,
    userId: PropTypes.string,
    authType: PropTypes.string,
    baseUrl: PropTypes.string
  }),
  userDisplayName: PropTypes.string,
  selectedUserId: PropTypes.any,
  t: PropTypes.func
};

export default withTranslation()(Dashboard);
