import { User } from "interfaces/user.interface";

export const CHANGE_USER_PASSWORD_ACTION = "CHANGE_USER_PASSWORD_ACTION";

export const CHANGE_USER_PASSWORD_ACTION_SUCCESS =
  "CHANGE_USER_PASSWORD_ACTION_SUCCESS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_REJECTED = "GET_USERS_REJECTED";
export const GET_USERS_FULFILLED = "GET_USERS_FULFILLED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const UPDATE_USER_ACTION = "UPDATE_USERS_ACTION";
export const UPDATE_USER_SUCCESS_ACTION = "UPDATE_USER_SUCCESS_ACTION";

export const ADD_NEW_USER_ACTION = "ADD_NEW_USER_ACTION";
export const ADD_NEW_USER_SUCCESS_ACTION = "ADD_NEW_USER_SUCCESS_ACTION";

export const DELETE_USER_ACTION = "DELETE_USER_ACTION";
export const DELETE_USER_ACTION_SUCCESS = "DELETE_USER_ACTION_SUCCESS";
export const DELETE_MULTI_USER_ACTION_SUCCESS =
  "DELETE_MULTI_USER_ACTION_SUCCESS";

export const RESET_USER_PRELOADERS = "RESET_USER_PRELOADERS";

export const USER_ACTIONS = {
  addNewUser: "add-new-user",
  createSuccess: "createSuccess",
  updateSuccess: "updateSuccess",
  createEmployeeSuccess: "createEmployeeSuccess",
  updateEmployeeSuccess: "updateEmployeeSuccess",
  deleteUser: "deleteUser",
  deleteSuccess: "deleteSuccess",
  reset: ""
};
export interface getUsersACtion {
  type: typeof GET_USERS;
}
export interface getUsersFulfilledFACtion {
  type: typeof GET_USERS_FULFILLED;
  payload: User[];
}

export interface getUsersRejectedACtion {
  type: typeof GET_USERS_REJECTED;
}

export interface addNewUserAction {
  type: typeof ADD_NEW_USER_ACTION;
}

export interface addNewUserSuccessAction {
  type: typeof ADD_NEW_USER_SUCCESS_ACTION;
  payload: User;
}

export interface updateUserAction {
  type: typeof UPDATE_USER_ACTION;
}

export interface updateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS_ACTION;
  payload: User;
}

export interface changeUserPasswordAction {
  type: typeof CHANGE_USER_PASSWORD_ACTION;
}
export interface changeUserPasswordSuccessAction {
  type: typeof CHANGE_USER_PASSWORD_ACTION_SUCCESS;
  payload: User;
}

export interface deleteUserAction {
  type: typeof DELETE_USER_ACTION;
}
export interface deleteUserSuccessAction {
  type: typeof DELETE_USER_ACTION_SUCCESS;
  payload: User;
}
export interface deleteMultipleUserSuccessAction {
  type: typeof DELETE_MULTI_USER_ACTION_SUCCESS;
  payload: string[];
}
export interface resetUserPreloaders {
  type: typeof RESET_USER_PRELOADERS;
}

export type usersActionTypes =
  | getUsersACtion
  | getUsersFulfilledFACtion
  | getUsersRejectedACtion
  | addNewUserAction
  | addNewUserSuccessAction
  | updateUserAction
  | updateUserSuccessAction
  | deleteUserSuccessAction
  | deleteMultipleUserSuccessAction
  | deleteUserAction
  | changeUserPasswordAction
  | changeUserPasswordSuccessAction
  | resetUserPreloaders;
