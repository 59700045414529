import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import {
  changeUserPassword,
  createNewSubUser,
  deleteUserAction,
  upadateUserInformation
} from "store/actions/userActions";
import {
  getGroupsToUpdate,
  normalizedGroupsEntity
} from "utils/groupManagement";
import { getUserBaseObject } from "utils/userManagement";
import { isEmpty } from "utils/deviceManagement";

import EmployeeInfo from "./components/EmployeeInfo";

interface Props {
  routePath: string;
  id: string;
  usersEntity: { [key: string]: User } | undefined;
  deviceUsers: User[];
}

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: "bold",
    marginTop: 14,
    float: "left",
    width: "100%"
  },
  subtitleEditIcon: {
    float: "right",
    marginRight: 10
  }
}));

function EmployeeView({ routePath, id, usersEntity, deviceUsers }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [user, setCurrentUser] = useState<User>();

  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});
  const [currentUserGroupsIds, setCurrentUserGroups] = useState<string[]>([]);

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const actionIndicator = useSelector((state: any) => {
    return state.users.loading;
  });

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  useEffect(() => {
    if (id !== "0" && usersEntity !== undefined && !isEmpty(usersEntity)) {
      const currentUser = usersEntity[id];
      if (!currentUser) {
        onBack();
      } else {
        // reset value
        const viewUsers = [];
        if (currentUser?.view_users && Array.isArray(currentUser?.view_users)) {
          for (let i = 0; i < currentUser.view_users.length; i += 1) {
            if (usersEntity[currentUser.view_users[i]]) {
              viewUsers.push(usersEntity[currentUser.view_users[i]]);
            }
          }
        }
        setCurrentUser(currentUser);
        if (currentUser.user_groups) {
          setCurrentUserGroups([...currentUser.user_groups]);
        }
      }
    } else if (id === "0") {
      setCurrentUser(getUserBaseObject(UserAttributesTypes.viewer));
    }
  }, [id, usersEntity]);

  useEffect(() => {
    if (
      groupState?.currentAction === GROUP_ACTIONS.multiGroupUpdateSuccess &&
      actionIndicator === "saved"
    ) {
      // Todo
    }
  }, [actionIndicator, groupState]);

  const onBack = () => {
    const path = `${routePath.replace("/:id", "")}`;
    history.push(path);
  };

  const onCreate = (employee: User) => {
    dispatch(createNewSubUser({ user: employee, groupEntity }));
  };

  const onUpdate = (employee: User) => {
    const groups: Group[] = getGroupsToUpdate({
      user: employee,
      groupEntity,
      currentUserGroupsIds
    });
    dispatch(upadateUserInformation({ user: employee, groups }));
  };

  const onDeleteUser = (employee: User) => {
    if (employee && employee.id) {
      dispatch(deleteUserAction({ user: employee }));
    }
  };

  const onChangePassword = (currentUser: User, password: string) => {
    const subUser = loginedUser.id !== currentUser.id;
    dispatch(
      changeUserPassword({
        newPassword: password,
        user: currentUser,
        subUser
      })
    );
  };

  return (
    <div>
      {/* <div onClick={onClick} aria-hidden>Click </div> */}
      {/* If route param is zero then */}
      {loginedUser && user && usersEntity && (
        <EmployeeInfo
          user={user}
          loginedUser={loginedUser}
          deviceUsers={deviceUsers}
          usersEntity={usersEntity}
          groupEntity={groupEntity}
          onCreate={onCreate}
          onDeleteUser={() => onDeleteUser(user)}
          onUpdate={onUpdate}
          onChangePassword={onChangePassword}
          onBack={onBack}
        />
      )}
    </div>
  );
}

export default EmployeeView;
