import { DEVICE_ACTIONS } from "store/actionTypes/devicesActionTypes";

import * as types from "../actionTypes/devicesSettingsActionTypes";

export default function deviceSettingsReducer(
  state: any = {},
  action: types.devicesSettingsActionTypes
) {
  switch (action.type) {
    case types.FETCH_DEVICES_SETTINGS_ACTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        currentAction: ""
      };
    case types.UPDATE_DEVICES_SETTINGS_ACTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        currentAction: DEVICE_ACTIONS.deviceSettingsUpdateSuccess
      };
    case types.RESET_DEVICE_SETTINGS_ACTION:
      return {
        ...state,
        currentAction: DEVICE_ACTIONS.reset
      };
    default:
      return state;
  }
}
