import PropTypes from "prop-types";
import React from "react";

import SleepStagesChart from "./SleepStagesChart";

const SleepStagesComponent = (props) => {
  const { plotData } = props.options;
  return <SleepStagesChart plotData={plotData} />;
};

SleepStagesComponent.propTypes = {
  options: PropTypes.shape({
    plotData: PropTypes.shape({
      series: PropTypes.object,
      threshold: PropTypes.object
    }).isRequired
  })
};

export default SleepStagesComponent;
