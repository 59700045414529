import { SMSReceiver } from "interfaces/notification.interface";

export const GET_NOTIFICATION_RECIEVER = "GET_NOTIFICATION_RECIEVER";
export const GET_NOTIFICATION_RECIEVER_SUCCESS =
  "GET_NOTIFICATION_RECIEVER_SUCCESS";
export const ADD_NOTIFICATION_RECIEVER_SUCCESS =
  "ADD_NOTIFICATION_RECIEVER_SUCCESS";
export const UPDATE_NOTIFICATION_RECIEVER_SUCCESS =
  "UPDATE_NOTIFICATION_RECIEVER_SUCCESS";
export const RESET_NOTIFICATION_RECIEVER_PRELOADERS =
  "RESET_NOTIFICATION_RECIEVER_PRELOADERS";
export const DELETE_NOTIFICATION_RECIEVER_SUCCESS =
  "DELETE_NOTIFICATION_RECIEVER_SUCCESS";
export const BULK_DELETE_NOTIFICATION_RECIEVER_SUCCESS =
  "BULK_DELETE_NOTIFICATION_RECIEVER_SUCCESS";

export interface getNotificationRecieverAction {
  type: typeof GET_NOTIFICATION_RECIEVER;
}
export interface getNotificationRecieverSuccessAction {
  type: typeof GET_NOTIFICATION_RECIEVER_SUCCESS;
  payload: SMSReceiver[];
}
export interface addNotificationRecieverSuccess {
  type: typeof ADD_NOTIFICATION_RECIEVER_SUCCESS;
  payload: SMSReceiver;
}

export interface updateNotificationRecieverSuccess {
  type: typeof UPDATE_NOTIFICATION_RECIEVER_SUCCESS;
  payload: SMSReceiver;
}

export interface deleteNotificationReciverSuccess {
  type: typeof DELETE_NOTIFICATION_RECIEVER_SUCCESS;
  payload: SMSReceiver;
}

export interface bulkDeleteNotificationReciverSuccess {
  type: typeof BULK_DELETE_NOTIFICATION_RECIEVER_SUCCESS;
  payload: string[];
}

export interface resetNotificationRecieverPreloaders {
  type: typeof RESET_NOTIFICATION_RECIEVER_PRELOADERS;
}

export type notificationRecieverActionTypes =
  | getNotificationRecieverAction
  | getNotificationRecieverSuccessAction
  | addNotificationRecieverSuccess
  | updateNotificationRecieverSuccess
  | deleteNotificationReciverSuccess
  | resetNotificationRecieverPreloaders
  | bulkDeleteNotificationReciverSuccess;
