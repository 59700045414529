import { UserNotificationType } from "interfaces/notification.interface";
import i18n from "i18n/config";

export const NOTIFICATION_TYPES = {
  USER_RULES: "user",
  DEVICE_RULES: "device"
};

export const NOTIFICATION_LOG_TYPES = {
  USER_LOG: "user",
  DEVICE_LOG: "device"
};

export type INTERFACE_NOTIFICATION_LOG_TYPES =
  | typeof NOTIFICATION_LOG_TYPES.USER_LOG
  | typeof NOTIFICATION_LOG_TYPES.DEVICE_LOG;

export type INTERFACE_NOTIFICATION_TYPES =
  | typeof NOTIFICATION_TYPES.USER_RULES
  | typeof NOTIFICATION_TYPES.DEVICE_RULES;

export const USER_NOTIFICATION_TYPES: UserNotificationType[] = [
  {
    id: "1",
    name: i18n.t("Movement"),
    value: "movement"
  },
  {
    id: "11",
    name: i18n.t("Movement (High)"),
    value: "movement_high"
  },
  {
    id: "22",
    name: i18n.t("Out of bed (Immediate)"),
    value: "out_of_bed_immediate"
  },
  {
    id: "2",
    name: i18n.t("Out of Bed (5min)"),
    value: "out_of_bed"
  },
  {
    id: "3",
    name: i18n.t("Sound"),
    value: "sound"
  },
  {
    id: "4",
    name: i18n.t("Sound (High)"),
    value: "sound_high"
  }
];

export const INTERFACE_USER_NOTIFICATION_TYPES = {
  MOVEMENT: "movement",
  MOVEMENT_HIGH: "movement_high",
  OUT_OF_BED_IMMEDIATE: "out_of_bed_immediate",
  OUT_OF_BED: "out_of_bed",
  SOUND: "sound",
  SOUND_HIGH: "sound_high"
};

export const USER_NOTIFICATION_TYPES_TRANSLATED_NAMES = {
  movement: i18n.t("Movement"),
  movement_high: i18n.t("Movement (High)"),
  out_of_bed: i18n.t("Out of Bed (5min)"),
  out_of_bed_immediate: i18n.t("Out of bed (Immediate)"),
  sound: i18n.t("Sound"),
  sound_high: i18n.t("Sound")
};
