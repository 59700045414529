// Todo (fr@vitalthings.com)
/* eslint-disable no-nested-ternary */
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Laptop from "@material-ui/icons/Laptop";
import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import UserIcon from "@material-ui/icons/Person";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";

import { getToken } from "utils/localStorage";

import { authenticate, resetlogin } from "../store/actions/auth";
import Router from "../services/utils/Router";

const get = require("lodash.get");

const styles = (theme) => ({
  root: {
    backgroundColor: "#121217"
  },
  appBar: {
    backgroundColor: "transparent",
    color: "white",
    boxShadow: "unset !important"
  },
  toolBar: {
    color: "white",
    "& :last-child": {
      marginLeft: "auto"
    }
  },
  rightActionButtonIcon: {
    marginLeft: "12px !important"
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.routerRef = React.createRef();
  }

  componentDidMount() {
    if (getToken() === "null") {
      this.props.resetlogin();
    } else {
      this.props.authenticate();
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const router = this.routerRef.current;
    const Icon = this.props.isAuthenticated
      ? get(
          this.props.user.clients,
          "com-vitalthings-somnofy-webui.monitor_only",
          false
        )
        ? Laptop
        : DashboardIcon
      : UserIcon;
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Button
              component={Link}
              to={this.props.isAuthenticated ? "/app/monitor" : "/auth/login"}
              color="inherit"
            >
              {this.props.isAuthenticated
                ? get(
                    this.props.user.clients,
                    "com-vitalthings-somnofy-webui.monitor_only",
                    false
                  )
                  ? "Monitor"
                  : "Dashboard"
                : "Log in"}
              <div className={classes.rightActionButtonIcon}>
                <Icon />
              </div>
            </Button>
          </Toolbar>
        </AppBar>
        <Router layout="/home" ref={this.routerRef} />
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  authenticate: PropTypes.func,
  resetlogin: PropTypes.func,
  isAuthenticated: PropTypes.any,
  user: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !_.isEmpty(state.auth.user),
    user: state.auth.user
  };
};

export default withRouter(
  connect(mapStateToProps, { authenticate, resetlogin })(
    withStyles(styles)(Home)
  )
);
