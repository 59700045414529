import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import ResizeDetector from "react-resize-detector";
import moment from "moment";

import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);
export default class SleepHistoryStagesChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.plotData !== this.props.plotData;
  }

  render() {
    return (
      <ResizeDetector
        skipOnMount
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <HighchartsReact
          ref={this.chartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={getOptions({ ...this.props })}
        />
      </ResizeDetector>
    );
  }
}

const getOptions = ({ plotData, onChartClick, onChartMouseOver, width }) => {
  const { series, threshold } = plotData;
  return {
    chart: {
      type: "areaspline",
      backgroundColor: "transparent",
      width,
      ignoreHiddenSeries: false
    },
    title: {
      text: null // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b"
      },
      title: {
        text: "Date"
      },
      ordinal: false
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        enabled: true,
        formatter() {
          let dt = new Date(2000, 1, 1, 0, 0, 0, 0);
          dt = new Date(dt.getTime() + this.value * 1000);
          return dt.toLocaleTimeString(); // { timeZoneName: 'short', hour:'2-digit' });
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      series: {
        stacking: "normal",
        threshold
      },
      areaspline: {
        // lineColor: '#666666',

        lineWidth: 0,
        trackByArea: true,
        marker: {
          lineWidth: 1,
          lineColor: "#666666"
        }
      }
    },
    navigator: {
      enabled: false,
      series: {
        data: series.timeAsleep
      },
      yAxis: {
        min: 0
      }
    },
    rangeSelector: {
      enabled: false
    },
    series: [
      {
        name: t("Wake before and after sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("wbaas", e),
            mouseOver: (e) => onChartMouseOver("wbaas", e)
          }
        },
        color: "rgba(255, 255, 77, 1)",
        data: series.timeWakePrePostSleep,
        selected: false
      },
      {
        name: t("Sleep Onset"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("onset", e),
            mouseOver: (e) => onChartMouseOver("onset", e)
          }
        },
        color: "rgba(255, 168, 168, 1)",
        data: series.sleepOnset
      },
      {
        name: t("Wake after sleep onset"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("waso", e),
            mouseOver: (e) => onChartMouseOver("waso", e)
          }
        },
        color: "rgba(139, 0, 0, 1)",
        data: series.timeWakeAfterSleepOnset
      },
      {
        name: t("REM Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("rem", e),
            mouseOver: (e) => onChartMouseOver("rem", e)
          }
        },
        color: "rgba(255, 140, 0, 1)",
        showInNavigator: true,
        data: series.timeInREMSleep
      },
      {
        name: t("Light Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("light", e),
            mouseOver: (e) => onChartMouseOver("light", e)
          }
        },
        color: "rgba(173, 216, 230, 1)",
        showInNavigator: true,
        data: series.timeInLightSleep
      },
      {
        name: t("Deep Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("deep", e),
            mouseOver: (e) => onChartMouseOver("deep", e)
          }
        },
        color: "rgba(0, 0, 139, 1)",
        data: series.timeInDeepSleep
      },
      {
        name: t("Went to bed"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("onset", e),
            mouseOver: (e) => onChartMouseOver("onset", e)
          }
        },
        color: "transparent",
        data: series.relativeSessionStart,
        showInLegend: false
      }
    ],
    tooltip: {
      useHTML: true,
      shared: true,
      headerFormat: "",
      formatter() {
        const { points } = this;
        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);
        let tooltipMarkup = pointsLength
          ? `<span style="font-size:10px;font-weight:bold">${moment
              .utc(timestamp)
              .format("LLLL")}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (points[index].series.name === "Went to bed") {
            // tool tip marckup for Went to bed
          } else {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            const sec = points[index].y;
            const h = Math.trunc(sec / 60 / 60);
            const m = Math.trunc((sec - h * 60 * 60) / 60);
            tooltipMarkup += `${(h > 0 ? `${h}h ` : "") + m}m`;
            tooltipMarkup += "</b><br/>";
          }
        }

        return tooltipMarkup;
      }
    }
  };
};

SleepHistoryStagesChart.propTypes = {
  plotData: PropTypes.any
};
