import { db } from "./db";

export async function populate() {
  await db.recievers.bulkAdd([
    {
      id: "AA1",
      receiver: "Department One",
      mobNumbers: ["43996534"]
    }
  ]);

  await db.notification.bulkAdd([
    {
      id: "NR1",
      user: {
        act_as_root_user: false,
        attributes: {
          user_type: "default"
        },
        display_name: "Test User",
        birth_year: 2021,
        email: "fr@vitalthings.com",
        first_name: "Test User",
        gender: "Male",
        height: "172",
        hid: "d6becf98c3b2898ff0fd46089fa67b17b575be130526fe7f074308d5e0ed1b22",
        id: "5f96dc2dd1d43900172c0fae",
        language_preference: "en-gb",
        last_name: "Admin",
        user_groups: [],
        view_users: [],
        weight: "70"
      },
      name: "Movement Level 1 for Test User",
      enabled: true,
      effective_period: ["0-24"],
      type: "movement",
      notification_type: "user",
      recievers: [],
      rules: {
        sendnotification: [
          {
            minTimePresent: "4",
            avarageMovementInTime: "60",
            threshold: "30",
            operator: ">="
          }
        ],
        sendOkMsg: {
          avarageMovementInTime: "50",
          threshold: "30",
          operator: "<"
        }
      }
    }
  ]);
}
