import { Device } from "interfaces/device.interface";

import * as types from "../actionTypes/devicesActionTypes";
import { DEVICE_ACTIONS } from "../actionTypes/devicesActionTypes";

export default function deviceReducer(
  state: any = {},
  action: types.devicesActionTypes
) {
  switch (action.type) {
    case types.FETCH_DEVICES_ACTION_SUCCESS:
      return {
        ...state,
        devices: action.payload._embedded.devices,
        loading: false,
        currentAction: ""
      };
    case types.CHANGE_USER_ACTION:
      return {
        ...state,
        loading: true
      };

    case types.DELETE_MULTI_DEVICES_ACTION_SUCCESS:
      return {
        ...state,
        currentAction: DEVICE_ACTIONS.deleteSuccess,
        devices: state.devices.filter(
          (device: Device) =>
            action.payload.indexOf(device.serial_number) === -1
        )
      };

    case types.CHANGE_USER_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: DEVICE_ACTIONS.deviceUpdateSuccess,
        devices: state.devices.map((device: Device) =>
          device.serial_number === action.payload.serial_number
            ? { ...action.payload }
            : device
        )
      };
    case types.RESET_DEVICE_ACTION:
      return {
        ...state,
        currentAction: DEVICE_ACTIONS.reset
      };

    case types.UNREGISTER_DEVICE_SUCCESS_ACTION:
      return {
        ...state,
        devices: state.devices.filter(
          (device: Device) => device.serial_number !== action.payload
        )
      };
    default:
      return state;
  }
}
