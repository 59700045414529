import "./i18n/config";
import "react-toastify/dist/ReactToastify.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import React from "react";

import AdminLayout from "./layouts/Admin.tsx";
import AuthLayout from "./layouts/Auth";
import HomeLayout from "./layouts/Home";
import PrivateRoute from "./services/utils/PrivateRoute";
import theme from "./styles/themes/somnofy";

function App(props) {
  return (
    <MuiThemeProvider
      theme={createMuiTheme({
        ...theme,
        palette: {
          ...theme.palette,
          type: props.styleType
        },
        overrides: {
          MuiRadio: {
            root: {
              color: theme.palette.dark
            },
            colorSecondary: {
              "&$checked": {
                color: theme.palette.dark
              }
            }
          },
          MuiCheckbox: {
            root: {
              color: theme.palette.dark,
              padding: "0 15px",
              borderRadius: 0
            }
          }
        }
      })}
    >
      <CssBaseline>
        {/* autoClose={4000} */}
        <ToastContainer
          hideProgressBar
          position="bottom-right"
          autoClose={7000}
          toastClassName="toast-custom-styles"
          style={{
            width: "auto",
            right: 64,
            bottom: 64
          }}
        />
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/home" component={HomeLayout} />
          <PrivateRoute path="/app" component={AdminLayout} />
          <Redirect to="/home" />
        </Switch>
      </CssBaseline>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  styleType: PropTypes.any
};

const mapStateToProps = (state) => ({
  styleType: state.customization.styleType
});

export default withRouter(connect(mapStateToProps)(App));
