import { makeStyles } from "@material-ui/core/styles";
import MuiCard from "@material-ui/core/Card";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  }
}));

function Card(props) {
  const classes = useStyles();
  return <MuiCard className={classes.root} {...props} />;
}

export default Card;
