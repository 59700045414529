import * as types from "../actionTypes/notificationLogActionTypes";

export default function notificationLogReducer(
  state: any = {},
  action: types.notificationLogActionTypes
) {
  switch (action.type) {
    case types.GET_NOTIFICATION_LOG_SUCCESS:
      return {
        ...state,
        notificationsLog: action.payload || [],
        currentAction: "fetch-succcess"
      };
    default:
      return {};
  }
}
