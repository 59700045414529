import PropTypes from "prop-types";
import React from "react";

import Client from "../../../services/apiClient";
import MonitorComponent from "./Monitor";

export class Monitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
      lastUpdated: new Date(),
      refreshTime: 10, // 10 seconds default
      dialogOpen: false,
      dashboardModalUser: null,
      dashboardModalUserName: null,
      pauseRefresh: false,
      tileScale: localStorage.myKey || "60"
    };
    this.ref = React.createRef();

    this.setRefreshTime = this.setRefreshTime.bind(this);
    this.updateSessions = this.updateSessions.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.toggleDashboardModal = this.toggleDashboardModal.bind(this);
    this.setTileScale = this.setTileScale.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.updateTime);
    clearInterval(this.reloadUsers);
  }

  setTileScale(scale) {
    localStorage.myKey = scale;
    this.setState({
      tileScale: scale
    });
  }

  componentDidMount() {
    this.client = new Client(this.props.apiInfo);
    this.client.getUserDevices().then((res) => {
      this.setState({
        userDevices: res
      });
      this.client.getSessionsForUserDevices(res).then((userDeviceSessions) => {
        this.setState({
          userDeviceSessions,
          lastUpdated: new Date()
        });
      });
    });
    this.reloadUsers = setInterval(() => {
      if (!this.state.pauseRefresh) {
        this.updateSessions();
      }
    }, this.state.refreshTime * 1000);
    this.updateTime = setInterval(() => {
      if (!this.state.pauseRefresh) {
        this.setState({ time: new Date() });
      }
    }, 1000 * 10);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState !== this.state;
  }

  setRefreshTime(time) {
    clearInterval(this.reloadUsers);
    this.setState({
      refreshTime: time.target.value
    });
    this.reloadUsers = setInterval(() => {
      if (!this.state.pauseRefresh) {
        this.updateSessions();
      }
    }, time.target.value * 1000);
  }

  toggleDashboardModal(userId, userName) {
    this.setState({ dashboardModalUser: userId });
    this.setState({ dashboardModalUserName: userName });
    this.setState({ pauseRefresh: !!userId });
  }

  toggleDialog() {
    this.setState((prevState) => ({ dialogOpen: !prevState.dialogOpen }));
  }

  updateSessions() {
    const devices = this.client.getDevices();
    const sessions = this.client.getSessionsForUserDevices(
      this.state.userDevices
    );

    Promise.all([devices, sessions]).then((data) => {
      const { userDevices } = this.state;
      userDevices.forEach((userDevice) => {
        const device = data[0].find(
          (d) => d.serial_number === userDevice.device.serial_number
        );
        // Todo: (fr@vitalthings.com)
        if (device) {
          // eslint-disable-next-line no-param-reassign
          userDevice.device = device;
        }
      });

      this.setState({
        userDevices,
        userDeviceSessions: data[1],
        lastUpdated: new Date()
      });
    });
  }

  render() {
    return (
      <MonitorComponent
        user={this.props.user}
        users={this.state.userDeviceSessions}
        time={this.state.time}
        lastUpdated={this.state.lastUpdated}
        updateSessions={() => this.updateSessions()}
        refreshTime={this.state.refreshTime}
        setRefreshTime={(time) => this.setRefreshTime(time)}
        toggleDialog={() => this.toggleDialog()}
        dialogOpen={this.state.dialogOpen}
        dashboardModalUser={this.state.dashboardModalUser}
        dashboardModalUserName={this.state.dashboardModalUserName}
        toggleDashboardModal={this.toggleDashboardModal}
        apiInfo={this.props.apiInfo}
        tileScale={this.state.tileScale}
        setTileScale={(tileScale) => this.setTileScale(tileScale)}
        loginedUser={this.props.loginedUser}
      />
    );
  }
}

Monitor.propTypes = {
  user: PropTypes.any,
  apiInfo: PropTypes.any,
  loginedUser: PropTypes.any
};

export default Monitor;
