import { isEqual, isObject, transform } from "lodash";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: any, base: any, exceptions?: string[]) {
  return transform(object, (result, value, key) => {
    const res: any = result;
    if (!isEqual(value, base[key])) {
      res[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}

export function equalCustomizerForUser(objValue: string, othValue: string) {
  if (objValue === "last_name") {
    //
  }
  return undefined;
}

export const preventClickAction = (event?: any) => {
  if (event) {
    const dataRef = event.target.getAttribute("data-ref");
    if (
      dataRef === "preventParentClick" ||
      event.target.type === "checkbox" ||
      event.target.tagName === "path"
    ) {
      return true;
    }
  }
  return false;
};
