import { makeStyles } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import React from "react";

import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { User } from "interfaces/user.interface";
import { getLocale } from "utils/date.util";

const useStyles = makeStyles((theme: any) => ({
  header: {},
  title: {
    fontWeight: 500,
    fontSize: 20,
    margin: "16px 8px 24px 28px",
    "@media print": {
      margin: "10"
    }
  },
  headerText: {
    margin: "8px 8px 8px 28px",
    fontSize: 14,
    color: theme.palette.primary.grey
  },
  printWrapper: {
    position: "absolute",
    right: 32,
    top: 36,
    width: 32,
    height: 56,
    cursor: "pointer",
    "& span": {
      width: "100%"
    },
    "@media print": {
      display: "none"
    }
  },
  printLabel: {
    fontSize: 14,
    color: theme.palette.primary.dark
  }
}));
function NightReportHeader({
  timeframeStart,
  createdAt,
  loginedUser,
  printRef
}: {
  timeframeStart: string;
  createdAt: string;
  loginedUser: User;
  printRef: any;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        {t("Night")}:{" "}
        {timeframeStart &&
          new Intl.DateTimeFormat(getLocale(), {
            dateStyle: "full"
          }).format(new Date(timeframeStart))}
      </div>
      <div className={classes.headerText}>
        {t("Report generated time")}:{" "}
        {createdAt &&
          new Intl.DateTimeFormat(getLocale(), {
            dateStyle: "full",
            timeStyle: "short"
          }).format(new Date(createdAt))}
      </div>
      <div className={classes.headerText}>
        {t("Report generated by")}: {loginedUser.display_name}
      </div>
      {printRef && (
        <div className={classes.printWrapper} aria-hidden onClick={handlePrint}>
          <span>
            <PrintIcon />
          </span>
          <span className={classes.printLabel}>{t("Print")}</span>
        </div>
      )}
    </div>
  );
}

export default NightReportHeader;
