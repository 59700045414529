import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import {
  createUserGroup,
  deleteGroupAction,
  updateUserGroup
} from "store/actions/groupActions";
import {
  getAvailbleUsers,
  getGroupBaseObject,
  getGroupSaveButtonLabel
} from "utils/groupManagement";
import { toastError } from "utils/toast.util";
import BreadCrumb from "components/BreadCrump/BreadCrump";
import CommonStyles from "theme/CommonStyles";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import InfoBox from "components/InfoBox/InfoBox";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMViewUsersList from "components/SMViewUsersList/SMViewUsersList";
import SaveCancelDeleteButtons from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

interface Props {
  usersEntity: { [key: string]: User };
  userGroupType: string;
  userGroupsEntity: { [key: string]: Group };
  id: string;
  routePath: string;
}

export const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    "& .MuiInput-input": {
      fontSize: 34,
      border: `1px solid ${theme.palette.primary.dark}`,
      padding: 12,
      width: 442,
      height: 36,
      borderRadius: 5
    }
  },
  wrapper: {
    marginBottom: 56
  },
  txtError: {
    "& .MuiInput-input": {
      border: `1px solid ${theme.palette.primary.red}`
    }
  },
  editIcon: {
    float: "left",
    marginLeft: 32,
    width: 25,
    marginTop: 15
  },
  groupName: {
    fontSize: 34,
    float: "left",
    marginBottom: 32
  }
}));

function GroupView({
  usersEntity,
  userGroupType,
  userGroupsEntity,
  id,
  routePath
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const commonCls = CommonStyles();

  const [updatedGroup, setUpdatedGroup] = useState<Group>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  const onGroupChange = (key: string, value: any) => {
    const updatedGroupInfo: Group = {
      ...updatedGroup,
      [key]: value
    } as Group;
    setUpdatedGroup(updatedGroupInfo);
  };

  useEffect(() => {
    if (
      userState.currentAction === USER_ACTIONS.createEmployeeSuccess ||
      userState.currentAction === USER_ACTIONS.createSuccess
    ) {
      if (userState.newUser && userState.newUser.id) {
        const users = updatedGroup?.users || [];
        users.push(userState.newUser.id);
        onGroupChange("users", users);
      }
    }
  }, [userState]);

  useEffect(() => {
    if (groupState.currentAction === GROUP_ACTIONS.createUserGroupSuccess) {
      if (groupState.newItem && groupState.newItem.id) {
        const grps = updatedGroup?.view_user_groups || [];
        grps.push(groupState.newItem.id);
        onGroupChange("view_user_groups", grps);
      }
    }
  }, [groupState]);

  useEffect(() => {
    resetGroup();
  }, [id, userGroupsEntity, userGroupType]);

  const resetGroup = () => {
    const grp = _.cloneDeep(userGroupsEntity[id]);
    if (grp) {
      setUpdatedGroup(_.cloneDeep(updatedGroup || grp));
    } else if (id === "0") {
      setUpdatedGroup(
        updatedGroup || _.cloneDeep(getGroupBaseObject(userGroupType))
      );
    } else {
      const path = `${routePath.replace("/:id", "")}`;
      history.push(path);
    }
  };

  const onCancel = () => {
    resetGroup();
  };

  const saveGroup = () => {
    setErrorMessage("");

    if (!updatedGroup) {
      return;
    }
    if (!updatedGroup.name) {
      setErrorMessage(t("Please Enter Group Name"));
      // show toast
      toastError(t("Please Enter Group Name"));
    } else if (updatedGroup.id === "0") {
      dispatch(createUserGroup(updatedGroup));
    } else {
      dispatch(updateUserGroup(updatedGroup));
    }
  };

  const onDelete = () => {
    if (updatedGroup?.id) {
      dispatch(deleteGroupAction(updatedGroup));
    }
  };

  if (!updatedGroup) {
    return <></>;
  }

  return (
    <div
      className={`${commonCls.tabContentWrapperNoTopPadding} ${commonCls.greyBackground}`}
    >
      {/* <GroupViewBreadCrumb type={userGroupType} /> */}
      <BreadCrumb
        text={
          userGroupType === UserGroupType.device
            ? t("User Groups")
            : t("Employees groups")
        }
      />
      <div>
        <div className={classes.groupName}>{updatedGroup.name} </div>
      </div>

      <InfoBox>
        <DeviceInfoText
          text={
            userGroupType === UserGroupType.viewer
              ? `${t("Employee Group Name")}*`
              : `${t("User Group Name")}*`
          }
          field={
            <div>
              <SMTextField
                placeholder={
                  userGroupType === UserGroupType.viewer
                    ? t("Employee Group Name")
                    : t("User Group Name")
                }
                onSave={(val: string) => {
                  onGroupChange("name", val);
                }}
                value={updatedGroup.name}
                delay={0}
                borderRed={(errorMessage && !updatedGroup.name) || false}
                width={251}
              />
            </div>
          }
        />
      </InfoBox>
      <InfoBox>
        <SMViewUsersList
          label={
            userGroupType === UserGroupType.device
              ? t("Users in this Group")
              : t("Employees in this group")
          }
          lists={getAvailbleUsers(Object.values(usersEntity), userGroupType)}
          onChange={(value: string[], key: string) => {
            onGroupChange("users", value);
          }}
          searchPlaceholder={t("Search")}
          defaultValues={updatedGroup.users}
          userGroupType={userGroupType}
        />
      </InfoBox>

      {userGroupType === UserGroupType.viewer && (
        <InfoBox>
          <SMGroupLists
            label={t("Can View User Groups")}
            defaultValues={updatedGroup.view_user_groups}
            lists={Object.values(userGroupsEntity).filter(
              (grp: Group) => grp.type === UserGroupType.device
            )}
            onChange={(value: string[], key: string) => {
              onGroupChange("view_user_groups", value);
            }}
            searchPlaceholder={t("Search")}
            userGroupType={UserGroupType.device}
          />
        </InfoBox>
      )}

      <SaveCancelDeleteButtons
        txtSave={getGroupSaveButtonLabel({ updatedGroup, userGroupType })}
        txtCancel={t("Cancel")}
        txtDelete={t("Delete this group")}
        onCancel={onCancel}
        onSave={saveGroup}
        onDelete={() => (updatedGroup.id ? onDelete() : null)}
        deleteConfirmText={t(
          "Deleting this group will remove all data associated with this group. This action is non-reversable"
        )}
        deleteConfirmTitle={t("Delete this group")}
        hideDelete={id === "0"}
        disabledSaveButton={_.isEqual(updatedGroup, userGroupsEntity[id])}
      />
    </div>
  );
}
export default GroupView;
