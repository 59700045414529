import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Device } from "interfaces/device.interface";
import {
  INTERFACE_NOTIFICATION_LOG_TYPES,
  INTERFACE_NOTIFICATION_TYPES,
  NOTIFICATION_LOG_TYPES
} from "constants/notificationContstants";
import { NotificationFilterTypes } from "constants/app.enum";
import {
  SMNotification,
  SMNotificationLog
} from "interfaces/notification.interface";
import { User } from "interfaces/user.interface";
import { fetchAllTheUsers } from "store/actions/userActions";
import {
  filterLogsBySearchQuery,
  getNotificationLogsByType,
  logsFilterdedByType,
  logsFilterdedByUser,
  logsFilteredByFiringTime,
  logsSortedByFiringTime,
  sortNotificationLogsByType
} from "utils/notificationUtils";
import { preventClickAction } from "utils/app.util";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import SMPagination from "components/SMPagination/SMPagination";
import SecondaryTabHeader from "components/RouteTabs/SecondaryTabHeader";

import NotificationLogHeader from "./components/NotificationLogHeader";
import NotificationLogListBanner from "./components/NotificationLogListBanner";
import NotificationLogListBody from "./components/NotificationLogListBody";
import notificationLogRoutes from "../notificationLog.routes";

export const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 28,
    position: "relative"
    // paddingTop: 54
  },
  container: {
    background: "#FFF",
    width: "100%",
    float: "left",
    padding: "56px 33px 81px 33px"
    // borderRadius: "8px 8px 0 0",
    // border: `2px solid ${theme.palette.primary.dark}`,
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  addNewGroup: {
    float: "left",
    cursor: "pointer"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 6
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  },
  filterWrapper: {
    width: "100%",
    float: "left"
  },
  radioFilters: {
    float: "right"
  },
  radioFilter: {
    "& > span": {
      fontSize: 16
    }
  },
  floatRight: {
    float: "right"
  }
}));

interface Props {
  type: INTERFACE_NOTIFICATION_TYPES;
  notificationLogEntity: { [key: string]: SMNotificationLog };
  deviceEntity: { [key: string]: Device };
}

function NotificationLogList({
  type,
  notificationLogEntity,
  deviceEntity
}: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const commonCls = CommonStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [query, setQuery] = useState<string>("q");
  const [sortedLogs, setSortedLogs] = useState<SMNotificationLog[]>([]);
  const [activePage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPageLogs, setCurrentPageLogs] = useState<SMNotificationLog[]>();

  const usersState = useSelector((state: any) => {
    return state?.users || {};
  });

  useEffect(() => {
    dispatch(fetchAllTheUsers());
  }, [dispatch]);

  useEffect(() => {
    const filteredLogs = getNotificationLogsByType(
      Object.values(notificationLogEntity),
      type
    );
    setSortedLogs(logsSortedByFiringTime(filteredLogs, "ASC"));
  }, [notificationLogEntity]);

  useEffect(() => {
    if (itemsPerPage >= sortedLogs?.length) {
      setCurrentPage(1);
    }
    if (sortedLogs) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentPageLogs(sortedLogs.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [sortedLogs, activePage, itemsPerPage]);

  const onChange = (q: string) => {
    setQuery(q);
    setCurrentPage(1);
    const filteredRules = filterLogsBySearchQuery(
      getNotificationLogsByType(Object.values(notificationLogEntity), type),
      q
    );
    setSortedLogs(filteredRules);
  };

  const viewNotificationLogDetails = (event: any, item?: SMNotificationLog) => {
    if (!preventClickAction(event)) {
      history.push(`${history.location.pathname}/${item?.id ? item.id : "0"}`);
    }
  };

  const sortLogs = (
    sortType: "user" | "ruleType" | "firingTime",
    order: "ASC" | "DESC"
  ) => {
    setSortedLogs(
      _.cloneDeep(sortNotificationLogsByType(sortedLogs, order, sortType))
    );
  };

  const onUserFilterChange = ({
    users,
    from,
    to,
    notificationTypes
  }: {
    users: User[];
    from: string;
    to: string;
    notificationTypes: { id: string; name: string; value: string }[];
  }) => {
    //
    let logs = getNotificationLogsByType(
      Object.values(notificationLogEntity),
      type
    );

    if (users && users.length) {
      logs = logsFilterdedByUser(logs, users);
    }

    if (notificationTypes && type === NOTIFICATION_LOG_TYPES.USER_LOG) {
      logs = logsFilterdedByType(logs, notificationTypes);
    }

    if (from) {
      logs = logsFilteredByFiringTime(logs, from, ">=");
    }
    if (to) {
      logs = logsFilteredByFiringTime(logs, to, "<=");
    }

    setSortedLogs(logs);
  };

  return (
    <div className={classes.root}>
      {/* <SecondaryTabHeader routes={notificationLogRoutes} />
      <div className={`${commonCls.tabContentWrapperNoTopPadding}`}> */}
      <div className={classes.container}>
        <NotificationLogListBanner
          setItemsPerPage={setItemsPerPage}
          type={type}
          onSearch={onChange}
          total={sortedLogs.length}
          users={usersState.users}
          onUserFilterChange={onUserFilterChange}
        />

        <NotificationLogHeader
          sortLogs={sortLogs}
          isUserLog={type === NOTIFICATION_LOG_TYPES.USER_LOG}
        />

        <NotificationLogListBody
          searchQuery={query}
          logs={currentPageLogs || []}
          logType={type}
          viewNotificationLogDetails={viewNotificationLogDetails}
          deviceEntity={deviceEntity}
        />

        {/* Pagination  */}
        {sortedLogs && sortedLogs?.length > itemsPerPage && (
          <SMPagination
            activePage={activePage}
            itemsPerPage={itemsPerPage}
            itemsLength={sortedLogs?.length}
            handlePageChange={(pageNumber: number) =>
              setCurrentPage(pageNumber)
            }
          />
        )}
      </div>
    </div>
    // </div>
  );
}

export default NotificationLogList;
