import { Theme, makeStyles } from "@material-ui/core";
import React from "react";

import SetAppLanguage from "./components/SetAppLanguage";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    float: "left",
    width: "100%",
    margin: "0 1%",
    padding: 10,
    position: "relative",
    maxWidth: 700,
    boxShadow: "rgb(0 0 0 / 5%) 0px 1px 2px 0px",
    marginTop: 10,
    marginRight: 20,
    marginBottom: 10
  },
  formControl: {}
}));

function AppLocalisation() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SetAppLanguage />
    </div>
  );
}

export default AppLocalisation;
