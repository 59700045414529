import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import ResizeDetector from "react-resize-detector";
import moment from "moment";

import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);
export default class SleepHistoryVitalsChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.plotData !== this.props.plotData;
  }

  render() {
    return (
      <ResizeDetector
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <HighchartsReact
          ref={this.chartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={getOptions(
            this.props,
            this.props?.enableHeartRateMean || ""
          )}
        />
      </ResizeDetector>
    );
  }
}

SleepHistoryVitalsChart.propTypes = {
  plotData: PropTypes.shape({
    series: PropTypes.object,
    threshold: PropTypes.object
  }).isRequired,
  enableHeartRateMean: PropTypes.string
};

const getOptions = (props, enableHeartRateMean) => {
  const { plotData, onChartClick, onChartMouseOver } = props;
  const { series } = plotData;
  const showHeartRate =
    series.nonREMMeanHeartRate.length > 0 && enableHeartRateMean === "true";
  const showExternalHeartRate = series.nonREMMeanExternalHeartRate.length > 0;
  const showExternalSpO2 = series.externalSpO2Mean.length > 0;
  return {
    chart: {
      type: "spline",
      backgroundColor: "transparent"
    },
    title: {
      text: null // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b"
      },
      title: {
        text: "Date"
      },
      ordinal: false
    },
    yAxis: [
      {
        // 0

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 1

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 2

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 3

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 4

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 5

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 6

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 7: Sound

        min: 20,
        labels: {
          enabled: false
        }
      },
      {
        // 8: SpO2

        min: 60,
        labels: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: true
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: t("Resting respiration rate (rpm)"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("rpm", e),
            mouseOver: (e) => onChartMouseOver("rpm", e)
          }
        },
        yAxis: 0,
        color: "blue",
        data: series.nonREMMeanRespirationRate
      },
      {
        name: t("Mean heart rate"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("rhr", e),
            mouseOver: (e) => onChartMouseOver("rhr", e)
          }
        },
        yAxis: 1,
        color: "red",
        data: series.nonREMMeanHeartRate,
        visible: false,
        showInLegend: showHeartRate
      },
      {
        name: t("Mean heart rate external"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("rhrext", e),
            mouseOver: (e) => onChartMouseOver("rhrext", e)
          }
        },
        yAxis: 1,
        color: "purple",
        data: series.nonREMMeanExternalHeartRate,
        visible: false,
        showInLegend: showExternalHeartRate
      },
      {
        name: t("Mean SpO2 external"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("mspo2", e),
            mouseOver: (e) => onChartMouseOver("mspo2", e)
          }
        },
        yAxis: 8,
        color: "blue",
        data: series.externalSpO2Mean,
        visible: false,
        showInLegend: showExternalSpO2
      },
      {
        name: t("Time asleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("asleep", e),
            mouseOver: (e) => onChartMouseOver("asleep", e)
          }
        },
        yAxis: 2,
        color: "black",
        visible: false,
        data: series.timeAsleep
      },
      {
        name: t("Sleep Onset"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("onset", e),
            mouseOver: (e) => onChartMouseOver("onset", e)
          }
        },
        yAxis: 2,
        color: "yellow",
        visible: false,
        data: series.sleepOnset
      },
      {
        name: t("Sleep Efficiency"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("efficiency", e),
            mouseOver: (e) => onChartMouseOver("efficiency", e)
          }
        },
        yAxis: 3,
        color: "green",
        visible: false,
        data: series.sleepEfficiency
      },
      {
        name: t("Sleep Score"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("score", e),
            mouseOver: (e) => onChartMouseOver("score", e)
          }
        },
        yAxis: 3,
        color: "orange",
        visible: false,
        data: series.sleepScore1
      },
      {
        name: t("Times awake"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("timesawake", e),
            mouseOver: (e) => onChartMouseOver("timesawake", e)
          }
        },
        yAxis: 4,
        color: "brown",
        visible: false,
        data: series.numberOfTimesAwakeLong
      },
      {
        name: t("Times not present"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("timesnotpresent", e),
            mouseOver: (e) => onChartMouseOver("timesnotpresent", e)
          }
        },
        yAxis: 4,
        color: "orange",
        visible: false,
        data: series.numberOfTimesNoPresence
      },

      {
        name: t("Time with movement (%)"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("epochswithmovementpct", e),
            mouseOver: (e) => onChartMouseOver("epochswithmovementpct", e)
          }
        },
        yAxis: 5,
        color: "black",
        visible: false,
        data: series.epochsWithMovementPct
      },
      {
        name: t("Light"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("lightambientmean", e),
            mouseOver: (e) => onChartMouseOver("lightambientmean", e)
          }
        },
        yAxis: 6,
        color: "orange",
        visible: false,
        data: series.lightAmbientMean
      },
      {
        name: t("Sound amplitude"),
        cursor: "pointer",
        point: {
          events: {
            click: (e) => onChartClick("soundamplitudemean", e),
            mouseOver: (e) => onChartMouseOver("soundamplitudemean", e)
          }
        },
        yAxis: 7,
        color: "green",
        visible: false,
        data: series.soundAmplitudeMean
      }
    ],
    tooltip: {
      shared: true,
      split: false,
      crosshairs: true,
      formatter() {
        const { points } = this;
        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);
        let tooltipMarkup = pointsLength
          ? `<span style="font-size: 10px;font-weight:bold">${moment
              .utc(timestamp)
              .format("LLLL")}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (points[index].series.name === t("Resting respiration rate"))
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} RPM</b><br/>`;
          else if (
            points[index].series.name.substring(0, 15) === t("Mean heart rate")
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} BPM</b><br/>`;
          else if (
            points[index].series.name === t("Time asleep") ||
            points[index].series.name === t("Sleep Onset")
          ) {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            const sec = points[index].y;
            const h = Math.trunc(sec / 60 / 60);
            const m = Math.trunc((sec - h * 60 * 60) / 60);
            tooltipMarkup += `${(h > 0 ? `${h}h ` : "") + m}m`;
            tooltipMarkup += "</b><br/>";
          } else if (points[index].series.name === t("Light")) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} lux</b><br/>`;
          } else if (points[index].series.name === t("Sound amplitude")) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(2)} dBA</b><br/>`;
          } else
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)}</b><br/>`;
        }

        return tooltipMarkup;
      }
    }
  };
};
