import moment from "moment";

const newHypnogramPlotData = () => ({
  hypnogram: [],
  timestamp: [],
  distanceMean: [],
  respirationRateMean: [],
  respirationRateVarMean: [],
  heartRateMean: [],
  heartRateVar: [],
  externalHeartRateMean: [],
  externalHeartRateVar: [],
  externalSpO2Mean: [],
  movementMean: [],
  signalQualityMean: [],
  lightAmbient: [],
  soundAmplitude: []
});

export const getHypnogramPlotData = (sleepAnalysis) => {
  if (!sleepAnalysis) {
    return null;
  }
  const hypnogramPlotData = newHypnogramPlotData();
  let hasExternalHeartRate = false;
  let hasExternalSpO2 = false;
  let hasHeartRate = false;
  for (let i = 0; i < sleepAnalysis.hypnogram.timestamp.length; i += 1) {
    const timestamp = Number(moment.utc(sleepAnalysis.hypnogram.timestamp[i]));

    hypnogramPlotData.hypnogram.push({
      x: timestamp,
      y: sleepAnalysis.hypnogram.sleep_stage[i],
      ix: i
    });

    hypnogramPlotData.distanceMean.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.distance_mean[i],
      ix: i
    });

    hypnogramPlotData.respirationRateMean.push({
      x: timestamp,
      y:
        sleepAnalysis.epoch_data.respiration_rate_mean[i] > 0
          ? sleepAnalysis.epoch_data.respiration_rate_mean[i]
          : null,
      ix: i
    });

    hypnogramPlotData.respirationRateVarMean.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.respiration_rate_var_mean[i],
      ix: i
    });

    if (sleepAnalysis.epoch_data.heart_rate_mean) {
      hypnogramPlotData.heartRateMean.push({
        x: timestamp,
        y:
          sleepAnalysis.epoch_data.heart_rate_mean[i] > 0
            ? sleepAnalysis.epoch_data.heart_rate_mean[i]
            : null,
        ix: i
      });

      hypnogramPlotData.heartRateVar.push({
        x: timestamp,
        y: sleepAnalysis.epoch_data.heart_rate_var[i],
        ix: i
      });
      if (sleepAnalysis.epoch_data.heart_rate_mean[i] > 0) {
        hasHeartRate = true;
      }
    }

    if (sleepAnalysis.epoch_data.external_heart_rate_mean) {
      hypnogramPlotData.externalHeartRateMean.push({
        x: timestamp,
        y:
          sleepAnalysis.epoch_data.external_heart_rate_mean[i] > 0
            ? sleepAnalysis.epoch_data.external_heart_rate_mean[i]
            : null,
        ix: i
      });

      hypnogramPlotData.externalHeartRateVar.push({
        x: timestamp,
        y: sleepAnalysis.epoch_data.external_heart_rate_var[i],
        ix: i
      });
      if (sleepAnalysis.epoch_data.external_heart_rate_mean[i] > 0) {
        hasExternalHeartRate = true;
      }
    }

    if (sleepAnalysis.epoch_data.external_spo2_mean) {
      hypnogramPlotData.externalSpO2Mean.push({
        x: timestamp,
        y:
          sleepAnalysis.epoch_data.external_spo2_mean[i] > 0
            ? sleepAnalysis.epoch_data.external_spo2_mean[i]
            : null,
        ix: i
      });
      if (sleepAnalysis.epoch_data.external_spo2_mean[i] > 0) {
        hasExternalSpO2 = true;
      }
    }

    hypnogramPlotData.movementMean.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.movement_mean[i],
      ix: i
    });

    hypnogramPlotData.signalQualityMean.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.signal_quality_mean[i],
      ix: i
    });

    hypnogramPlotData.lightAmbient.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.light_ambient[i],
      ix: i
    });

    hypnogramPlotData.soundAmplitude.push({
      x: timestamp,
      y: sleepAnalysis.epoch_data.sound_amplitude[i],
      ix: i
    });
  }
  if (!hasHeartRate) {
    hypnogramPlotData.heartRateMean = [];
    hypnogramPlotData.heartRateVar = [];
  }
  if (!hasExternalHeartRate) {
    hypnogramPlotData.externalHeartRateMean = [];
    hypnogramPlotData.externalHeartRateVar = [];
  }
  if (!hasExternalSpO2) {
    hypnogramPlotData.externalSpO2Mean = [];
  }
  return {
    series: hypnogramPlotData
  };
};
