import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

export const useStyles = makeStyles((theme: any) => ({
  label: {
    float: "left",
    textTransform: "none",
    fontSize: 16,
    color: theme.palette.primary.dark
  }
}));

function SwipeableTemporaryDrawer({
  label,
  component,
  toggle,
  setToggle
}: {
  label: string;
  component: JSX.Element;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classes = useStyles();
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setToggle(open);
    };

  return (
    <div>
      <React.Fragment key="right">
        <Button onClick={toggleDrawer(true)} className={classes.label}>
          {label}
        </Button>
        <SwipeableDrawer
          anchor="right"
          open={toggle}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {component}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export default SwipeableTemporaryDrawer;
