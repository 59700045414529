import { makeStyles } from "@material-ui/core";

import th from "../styles/themes/somnofy";

const CommonStyles = makeStyles((theme: any) => ({
  title: {
    border: "1px solid #00acc1", // @Todo(fr@vitalthings.com): move to theme
    backgroundColor: "#00acc1", // @Todo(fr@vitalthings.com): move to theme
    color: "#FFF"
  },
  header: {
    display: "flex",
    width: "100%",
    color: theme.palette.primary.listHeader,
    // background: "#00acc1", // @Todo(fr@vitalthings.com): move to theme
    // border: "1px solid #00acc1", // @Todo(fr@vitalthings.com): move to theme
    // boxShadow:
    //   "0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)",
    fontWeight: "normal",
    fontSize: 16,
    borderBottom: `2px solid ${theme.palette.secondary.lightGrey}`,
    cursor: "pointer",
    alignItems: "center",
    "&>div > span": {
      float: "left"
    },
    height: "auto"
  },

  colHeader: {
    flex: 1,
    padding: "16px 26px",
    minWidth: 50,
    overflowWrap: "break-word",
    fontSize: 16,
    fontWeight: 400
  },
  colLargeHeader: {
    flex: 3,
    overflowWrap: "break-word",
    padding: "16px 26px",
    minWidth: 50,
    fontSize: 16,
    fontWeight: 400
  },
  col: {
    flex: 1,
    padding: 26,
    minWidth: 50,
    overflowWrap: "break-word",
    fontSize: 16,
    fontWeight: 400,
    position: "relative"
  },
  colLarge: {
    flex: 3,
    overflowWrap: "break-word",
    padding: 26,
    minWidth: 50,
    fontSize: 16,
    fontWeight: 400
  },
  colExtraLarge: {
    flex: 8,
    overflowWrap: "break-word",
    padding: 26,
    minWidth: 50,
    fontSize: 16,
    fontWeight: 400,
    position: "relative"
  },
  col9: {
    flex: 6,
    overflowWrap: "break-word",
    padding: 26,
    minWidth: 50,
    fontSize: 16,
    fontWeight: 400,
    position: "relative"
  },
  flexGrow8: {
    flexGrow: 8,
    overflowWrap: "break-word",
    padding: 26,
    minWidth: 50,
    fontSize: 16,
    fontWeight: 400,
    position: "relative"
  },
  rowContainer: {
    cursor: "pointer",
    float: "left",
    width: "100%"
  },
  row: {
    display: "flex",
    alignItems: "flext-start"
  },
  paginationItem: {
    listStyle: "none",
    float: "left",
    width: 30,
    background: "#F0f0f0",
    padding: 5,
    margin: 2,
    textAlign: "center",
    color: "#00acc1"
  },
  pagination: {
    cursor: "pointer",
    width: "calc(100% - 31px)",
    position: "fixed",
    bottom: 0,
    padding: "5px 0",
    borderTop: "1px solid #CCC",
    background: "#FFF",
    "& > ul": {
      margin: 0
    }
  },
  paginationItemActive: {
    background: "#00acc1"
  },
  paginationActiveLink: {
    color: "#FFF !important"
  },
  paginationLink: {
    color: theme.palette.primary.dark
  },
  sortIcon: {
    display: "inline-block",
    position: "relative"
  },
  searchWrapper: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center"
  },
  largeIcon: {
    width: 60,
    height: 60
  },
  errorToast: {
    color: th.palette.red,
    border: `1px solid ${th.palette.red}`
  },
  listSearchWrapper: {
    width: "100%",
    margin: "10px 0",
    float: "left",
    background: "#FFF",
    padding: "0 5px 10px 5px"
  },
  evenChildBackground: {
    "&:nth-child(even)": {
      background: theme.palette.secondary.grey,
      color: theme.palette.type === "dark" ? "#000" : "inherit"
      // "&.warningBg": {
      //   background: "#e9a297f7 !important" as any
      // }
    },
    "&.warningBg": {
      background: "#e9a297f7"
    }
  },
  italicsStyle: {
    color: "dimgrey",
    fontSize: 13,
    fontStyle: "italic"
  },
  tabContentWrapper: {
    padding: "56px 33px 56px 33px",
    width: "100%",
    float: "left"
  },
  tabContentWrapperNoTopPadding: {
    padding: "0 33px 56px 33px",
    width: "100%",
    float: "left"
  },
  floatLeft: {
    float: "left"
  },
  greyBackground: {
    background: theme.palette.secondary.grey
  },
  textList: {
    fontSize: 16,
    marginBottom: 16
  },
  fullWidth: {
    width: "100%"
  },
  txtCenter: {
    textAlign: "center"
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  colorPrimary: {
    color: theme.palette.primary.dark
  },
  floatNone: {
    float: "none !important" as any
  },
  floatRight: {
    float: "right !important" as any
  },
  toolTipCls: {
    background: theme.palette.primary.lightBlue,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.6)"
  },
  errorIcon: {
    width: 20,
    height: 20,
    color: "#B00020",
    position: "absolute",
    left: "-3px",
    top: "25px"
  },
  txtRight: {
    textAlign: "right !important" as any
  },
  checkboxlistWraper: {
    padding: "26px 0 26px 18px",
    "& > .MuiFormControlLabel-root": {
      marginRight: 0,
      marginLeft: 0,
      "& > .MuiButtonBase-root": {
        padding: 0
      }
    }
  },
  actionIcon: {
    padding: "0 10px"
  },
  actionBtnWrapper: {
    margin: "32px 28px"
  },
  actionBtn: {
    margin: "0 7px",
    float: "left"
  },
  alignFlexEnd: {
    justifyContent: "flex-end"
  },

  autoSuggestTextfield: {
    border: `1px solid ${theme.palette.primary.input}`,
    minHeight: 48,
    margin: 0,
    borderRadius: 5,
    boxSizing: "border-box",
    paddingLeft: 12
  },
  autoSuggestInputRoot: {
    padding: 0,
    paddingLeft: 4,
    margin: 0,
    "& input": {
      minHeight: 48,
      padding: "0 !important"
    }
  },
  popupIndicator: {
    width: 12,
    height: 7,
    marginRight: 20,
    "& .MuiIconButton-root": {
      margin: 0,
      padding: 0,
      fontSize: 12
    }
  },
  clearIndicator: {
    display: "none"
  },
  margin10: {
    margin: 10
  },
  marginBottom10: {
    marginBottom: 10
  },
  actionButtonWrapper: {
    width: 81,
    textAlign: "center",
    padding: 16
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  lightBlue: {
    background: `${theme.palette.secondary.lightBlue} !important`
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  },
  width100: {
    width: "100%"
  },
  cursorPointer: {
    cursor: "pointer"
  }
}));
export default CommonStyles;
