import { FormControl, Input, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const useStyles = makeStyles((theme: any) => ({
  formControl: {
    width: ({ borderRed, width }: { borderRed: boolean; width?: string }) =>
      width || "100%",
    float: "left",
    margin: 0, //
    outline: "none"
  },
  input: {
    border: ({ borderRed, width }: { borderRed: boolean; width?: string }) =>
      borderRed
        ? `1px solid ${theme.palette.primary.red}`
        : `1px solid ${theme.palette.primary.input}`,
    borderRadius: 5,
    "& button.MuiIconButton-root": {
      color: theme.palette.primary.dark
    },
    "& > input": {
      padding: 16,
      color: theme.palette.primary.input
    },
    "&:focus": {
      outline: "none"
    }
  },
  txtField: {}
}));

function SMTextFieldWithIcon({
  onChange,
  placeholder,
  borderRed = false,
  endAdornment,
  value,
  width
}: {
  onChange: (value: string) => void;
  placeholder?: string;
  borderRed?: boolean;
  endAdornment: JSX.Element;
  value: string;
  width?: string;
}): JSX.Element {
  const [txt, setText] = useState<string>(value);
  const classes = useStyles({ borderRed, width });

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Input
        disableUnderline
        type="text"
        value={txt}
        autoComplete="off"
        onChange={handleChange}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{
          autoComplete: "off",
          classes: {
            input: classes.txtField
          }
        }}
        endAdornment={endAdornment}
      />
    </FormControl>
  );
}

export default SMTextFieldWithIcon;
