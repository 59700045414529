import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const SomnofyVideoSrc =
  "https://somnofy.ams3.cdn.digitaloceanspaces.com/Film/TheSomnofy_1280x720.mp4";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  video: {
    height: "100vh",
    objectFit: "cover"
  }
}));

const LandingComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <video className={classes.video} autoPlay muted loop>
        <source src={SomnofyVideoSrc} />
      </video>
    </div>
  );
};

export default LandingComponent;
