/* getErrorText
 * This fucntion will return the error messages based on the error code
 * @param: Error code
 * @return: String  error message.
 */
export const getErrorText = (code) => {
  let statusText = "An error occured, please try again later";
  if (code === 401 || code === 400) {
    statusText = "Invalid username or password, please try again";
  } else if (code === 403) {
    statusText = "You don't have access to this service";
  } else if (code === 500) {
    statusText = "An error occured, please try again later";
  } else if (code === 503) {
    statusText = "Service unavailable";
  } else {
    statusText = "Invalid username or password, please try again";
  }
  return statusText;
};
