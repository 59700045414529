import { Checkbox, FormControlLabel } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useState } from "react";
import _ from "lodash";

import { Device } from "interfaces/device.interface";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { getDeviceUser } from "utils/deviceManagement";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import Highlighted from "components/Highlighted/HighLighted";
import TextRowWithViewMore from "components/TextRowWithViewMore/TextRowWithViewMore";
import UserListActionButtons from "components/UserListActionButtons/UserListActionButtons";

function UserListRowModeView({
  user,
  commonCls,
  history,
  filterType,
  searchQuery,
  devicesEntityWithUserID,
  groupEntity,
  selectedItems,
  setSelectedItems,
  setEditUser,
  editUser,
  onDelete,
  viewUserDetails,
  isAdmin
}: {
  commonCls: any;
  history: any;
  filterType: string;
  searchQuery: string;
  user: User;
  devicesEntityWithUserID: { [key: string]: Device };
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setEditUser: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  editUser: { [key: string]: boolean };
  onDelete: (user: User) => void;
  viewUserDetails: (event: any, userObj: User) => void;
  isAdmin: boolean;
}) {
  return (
    <div
      className={`${commonCls.row} ${commonCls.evenChildBackground}`}
      onClick={(event: any) => viewUserDetails(event, user)}
      aria-hidden
    >
      {isAdmin ? (
        <BulkActionCheckBox
          wrapperClassName={commonCls.checkboxlistWraper}
          id={user.id}
          selected={selectedItems}
          setSelected={setSelectedItems}
        />
      ) : (
        <></>
      )}
      <div className={`${commonCls.col}  ${commonCls.colorPrimary}`}>
        {filterType !== "name" && user.display_name}
        {filterType === "name" && (
          <Highlighted text={user.display_name} highlight={searchQuery} />
        )}
      </div>
      <div className={commonCls.col}>
        {getDeviceUser(devicesEntityWithUserID, user).map((device: Device) => {
          return (
            <div key={device.serial_number} className={commonCls.textList}>
              {device.name}
              <span
                className={commonCls.italicsStyle}
              >{` (${device.serial_number})`}</span>
            </div>
          );
        })}
      </div>
      <div className={commonCls.col}>
        {user.user_groups && (
          <TextRowWithViewMore
            lists={user.user_groups.map((id: string) => groupEntity[id]?.name)}
            commonCls={commonCls}
          />
        )}
      </div>
      <div className={commonCls.col}>{user.gender}</div>

      <div className={`${commonCls.col} ${commonCls.txtCenter}`}>
        {user?.birth_year}
      </div>
      {isAdmin ? (
        <UserListActionButtons
          classes={commonCls}
          onEditClick={() => {
            const updated = _.mapValues(editUser, () => false);
            setEditUser({ ...updated, [user.id]: true });
          }}
          onDeleteClick={() => onDelete(user)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default UserListRowModeView;
