import { makeStyles } from "@material-ui/core/styles";

import { center, column, flex } from "../../../../styles/utils";
import { dangerColor } from "../../../../assets/jss/material-dashboard-pro-react";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
  },
  paper: {
    padding: 20,
    minWidth: "364px"
  },
  form: {
    display: flex,
    flexDirection: column
  },
  textField: {
    marginTop: 32
  },
  buttonContainer: {
    display: flex,
    flexDirection: column,
    justifyContent: center,
    margin: "36px 12px 0px"
  },
  loginButton: {
    backgroundColor: "dodgerblue",
    color: "white",
    height: "10vh",
    width: "30vw"
  },
  registerButton: {
    backgroundColor: "unset !important",
    transition: "transform 0.2s",
    "& :hover": {
      transition: "transform 0.2s",
      transform: "scale(1.05)"
    }
  },
  modal: {
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper
  },
  error: {
    height: "20px",
    marginTop: "10px",
    color: dangerColor[0],
    textAlign: "center"
  }
}));
