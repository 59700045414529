import React from "react";

import { Device } from "interfaces/device.interface";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { isAdminUser } from "utils/userManagement";

import UserListRowModeEdit from "./UserListRowModeEdit";
import UserListRowModeView from "./UserListRowModeView";

function UserListRow({
  user,
  commonCls,
  history,
  filterType,
  searchQuery,
  devicesEntityWithUserID,
  groupEntity,
  selectedItems,
  setSelectedItems,
  onSaveAction,
  t,
  editUser,
  setEditUser,
  onDelete,
  viewUserDetails,
  loginedUser
}: {
  commonCls: any;
  history: any;
  filterType: string;
  searchQuery: string;
  user: User;
  devicesEntityWithUserID: { [key: string]: Device };
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  t: (key: string) => string;
  onSaveAction: (updatedUser: User, currentUserGroupsIds: string[]) => void;
  editUser: { [key: string]: boolean };
  setEditUser: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  onDelete: (user: User) => void;
  viewUserDetails: (event: any, userObj: User) => void;
  loginedUser: User;
}) {
  return (
    <>
      {editUser[user.id] && isAdminUser(loginedUser) ? (
        <UserListRowModeEdit
          user={user}
          commonCls={commonCls}
          history={history}
          filterType={filterType}
          searchQuery={searchQuery ?? ""}
          devicesEntityWithUserID={devicesEntityWithUserID}
          groupEntity={groupEntity}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditUser={setEditUser}
          editUser={editUser}
          t={t}
          onSaveAction={onSaveAction}
          loginedUser={loginedUser}
        />
      ) : (
        <UserListRowModeView
          user={user}
          commonCls={commonCls}
          history={history}
          filterType={filterType}
          searchQuery={searchQuery ?? ""}
          devicesEntityWithUserID={devicesEntityWithUserID}
          groupEntity={groupEntity}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditUser={setEditUser}
          editUser={editUser}
          onDelete={onDelete}
          viewUserDetails={viewUserDetails}
          isAdmin={isAdminUser(loginedUser)}
        />
      )}
    </>
  );
}
export default UserListRow;
