import PropTypes from "prop-types";
import React from "react";

const SectionContent = (props) => {
  return <div>{props.children}</div>;
};

SectionContent.propTypes = {
  children: PropTypes.any
};
export default SectionContent;
