import moment from "moment";

export const getSleepStatisticsPlotData = (session) => {
  if (!session) {
    return null;
  }

  const sleepReport = session._embedded.sleep_analysis.report;
  const timeAtStart = new Date(
    Number(moment.utc(sleepReport.time_at_intended_sleep))
  );
  const timeAtSleep = new Date(Number(moment.utc(sleepReport.time_at_sleep)));
  const timeAtWakeup = new Date(Number(moment.utc(sleepReport.time_at_wakeup)));

  let sec = sleepReport.time_asleep;
  let h = Math.trunc(sec / 60 / 60);
  let m = Math.trunc((sec - h * 60 * 60) / 60);
  const timeAsleepString = `${(h > 0 ? `${h}h ` : "") + m}m`;

  sec = sleepReport.sleep_onset;
  h = Math.trunc(sec / 60 / 60);
  m = Math.trunc((sec - h * 60 * 60) / 60);
  const sleepOnsetString = `${(h > 0 ? `${h}h ` : "") + m}m`;

  return {
    series: {
      timeAtStart,
      timeAtSleep,
      timeAtWakeup,
      timeAsleepString,
      sleepOnsetString,
      sleepEfficiency: sleepReport.sleep_efficiency,
      timesAwakeAway: [
        sleepReport.number_of_times_awake_long.toString(),
        sleepReport.number_of_times_no_presence
      ].join("/"),
      nonREMMeanHeartRate: sleepReport.non_rem_mean_heartrate,
      nonREMMeanExternalHeartRate: sleepReport.non_rem_mean_external_heartrate,
      nonREMMeanRespirationRate: sleepReport.non_rem_mean_rpm,
      externalSpO2Mean: sleepReport.external_spo2_mean
    }
  };
};
