import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { difference } from "utils/app.util";
import {
  fetchAllTheUsers,
  resetUserPreloaders
} from "store/actions/userActions";
import {
  generateAutoFillEmail,
  generateBirthYearOptions,
  isAdminUser
} from "utils/userManagement";
import { toastError, toastSucess } from "utils/toast.util";
import AutoSuggestSelect from "components/AutoSuggestSelect/AutoSuggestSelect";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";
import EmailAndPasswordField from "@modules/settings/employees/employeeView/components/EmailAndPasswordField";
import InfoBox from "components/InfoBox/InfoBox";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMTextFieldWithIcon from "components/SMTextFieldWithIcon/SMTextFieldWithIcon";
import SaveCancelDeleteButton from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: "56px 32px 0 32px",
    background: theme.palette.secondary.grey,
    width: "100%",
    float: "left"
  },
  title: {
    fontSize: 34,
    marginBottom: 14
  },
  radioGroup: {
    flexDirection: "row"
  },
  btnWrapper: {
    float: "left",
    marginTop: 20,
    marginBottom: 53,
    width: "100%",
    display: "flext",
    alignItems: "center"
  },
  actionBtn: {
    float: "left",
    marginRight: 32
  },
  deleteBtn: {
    float: "right",
    marginRight: 64,
    fontSize: 16,
    color: theme.palette.primary.delete,
    fontWeight: 500
  },
  hwWrapper: {
    width: 254
  },
  endAdornmentStyle: {
    paddingRight: 18
  }
}));

interface Props {
  user: User;
  loginedUser: User;
  groupEntity: { [key: string]: Group };
  onSave: (user: User) => void;
  onDelete: (user: User) => void;
  onBack: () => void;
  onChangePassword: (user: User, password: string) => void;
}

function UserInformation({
  user,
  loginedUser,
  groupEntity,
  onSave,
  onDelete,
  onBack,
  onChangePassword
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updatedUserObj, setUpdatedUserObj] = useState<User>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [passwords, setPasswords] = useState<{ new: string; confirm: string }>({
    new: "",
    confirm: ""
  });
  const [customSettings, setCustomSettings] = useState<any>({});

  const dispatch = useDispatch();
  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  useEffect(() => {
    fetch("/custom_settings.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((settings: any) => {
        setCustomSettings(settings.custom_settings);
      });
  }, []);

  useEffect(() => {
    if (groupState.currentAction === GROUP_ACTIONS.createUserGroupSuccess) {
      if (updatedUserObj && groupState.newItem && groupState.newItem.id) {
        const grps = updatedUserObj.user_groups || [];
        grps.push(groupState.newItem.id);
        onChange(grps, "user_groups");
      }
    }
  }, [groupState]);

  useEffect(() => {
    setUpdatedUserObj(_.cloneDeep(user));
  }, [user]);

  useEffect(() => {
    if (userState.currentAction === USER_ACTIONS.createSuccess) {
      toastSucess(t("User added successfully"));
      resetUserObject();
      dispatch(resetUserPreloaders());
      dispatch(fetchAllTheUsers());
      onBack();
    }

    if (userState.currentAction === USER_ACTIONS.updateSuccess) {
      toastSucess(t("User updated successfully"));
      dispatch(resetUserPreloaders());
      onBack();
    }
  }, [userState.currentAction]);

  const onChange = (value: any, key: string) => {
    if (!updatedUserObj) {
      return;
    }
    const updatedUser: User = {
      ...updatedUserObj,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;
    setUpdatedUserObj(updatedUser);
  };

  const resetUserObject = () => {
    setUpdatedUserObj(_.cloneDeep(user));
  };

  const validateAndSave = () => {
    setErrorMessage("");
    if (!updatedUserObj) {
      return;
    }
    if (!updatedUserObj.first_name) {
      setErrorMessage(t("User ID is missing. Please enter User ID"));
      // show toast
      toastError(t("User ID is missing. Please enter User ID"));
    } else {
      onSave({ ...updatedUserObj, password: passwords.new });
    }
  };

  const handleUserIDBlur = () => {
    if (
      customSettings &&
      !!customSettings[loginedUser.email]?.administration?.users
        ?.enable_email &&
      updatedUserObj
    ) {
      onChange(generateAutoFillEmail(updatedUserObj, loginedUser), "email");
    }
  };

  if (!updatedUserObj) {
    return <></>;
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{updatedUserObj.first_name}</div>
      <InfoBox>
        <DeviceInfoText
          text={`${t("User Id")}*`}
          field={
            <div>
              <SMTextField
                placeholder={t("User ID")}
                onSave={(val: string) => {
                  onChange(val, "first_name");
                }}
                value={updatedUserObj.first_name}
                delay={0}
                borderRed={
                  (errorMessage && !updatedUserObj.first_name) || false
                }
                handleBlur={handleUserIDBlur}
              />
            </div>
          }
        />

        <EmailAndPasswordField
          user={updatedUserObj}
          errorMessage={errorMessage}
          onChange={onChange}
          onChangePassword={onChangePassword}
          setErrorMessage={setErrorMessage}
          setPasswords={setPasswords}
          loginedUser={loginedUser}
          enable_email={
            customSettings &&
            !!customSettings[loginedUser.email]?.administration?.users
              ?.enable_email
          }
          enable_password={
            customSettings &&
            !!customSettings[loginedUser.email]?.administration?.users
              ?.enable_password
          }
          autofill
        />
      </InfoBox>

      <InfoBox>
        <DeviceInfoText
          text={`${t("Sex")}*`}
          field={
            <div>
              <RadioGroup
                value={updatedUserObj.gender ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value as string, "gender")
                }
                classes={{ root: classes.radioGroup }}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label={t("Male")}
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label={t("Female")}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label={t("Other")}
                />
              </RadioGroup>
            </div>
          }
        />

        <DeviceInfoText
          text={`${t("Birth Year")}*`}
          field={
            <div>
              <AutoSuggestSelect
                defaultValue={`${updatedUserObj.birth_year}`}
                options={generateBirthYearOptions()}
                onSelect={(value: string) => onChange(value, "birth_year")}
              />
            </div>
          }
        />
      </InfoBox>

      <InfoBox>
        <DeviceInfoText
          text={`${t("Height")}`}
          field={
            <div className={classes.hwWrapper}>
              <SMTextFieldWithIcon
                placeholder={t("Example 165 cm")}
                onChange={(val: string) => {
                  onChange(val, "height");
                }}
                value={updatedUserObj.height ?? ""}
                endAdornment={
                  <div className={classes.endAdornmentStyle}>{t("cm")}</div>
                }
              />
            </div>
          }
        />

        <DeviceInfoText
          text={`${t("Weight")}`}
          field={
            <div className={classes.hwWrapper}>
              <SMTextFieldWithIcon
                placeholder={t("Example 78 kg")}
                onChange={(val: string) => {
                  onChange(val, "weight");
                }}
                value={updatedUserObj.weight ?? ""}
                endAdornment={
                  <div className={classes.endAdornmentStyle}>kg</div>
                }
              />
            </div>
          }
        />
      </InfoBox>
      <InfoBox>
        <SMGroupLists
          label={t("Belongs to User Groups")}
          defaultValues={updatedUserObj.user_groups || []}
          lists={Object.values(groupEntity).filter(
            (group: Group) => group.type === UserGroupType.device
          )}
          onChange={onChange}
          searchPlaceholder={t("Search")}
          userGroupType={UserGroupType.device}
        />
      </InfoBox>

      <SaveCancelDeleteButton
        txtSave={updatedUserObj.id ? t("Save") : t("Add User")}
        txtCancel={t("Cancel")}
        txtDelete={t("Delete this user")}
        onCancel={resetUserObject}
        onSave={() => validateAndSave()}
        onDelete={() => (updatedUserObj.id ? onDelete(updatedUserObj) : null)}
        deleteConfirmTitle={t("Delete this user")}
        deleteConfirmText={t(
          "Deleting this user will remove all data associated with this user. This action is non-reversable"
        )}
        hideDelete={!isAdminUser(loginedUser) || !updatedUserObj.id}
        disabledSaveButton={_.isEqual(
          _.omit(user, ["display_name"]),
          _.omit(updatedUserObj, ["display_name"])
        )}
      />
    </div>
  );
}
export default UserInformation;
