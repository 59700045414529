import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { AppRouteProps } from "interfaces/router.interface";
import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { Loading } from "components";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import {
  getAllUserGroups,
  updateCurrentAction
} from "store/actions/groupActions";
import { normalizedGroupsEntity } from "utils/groupManagement";
import { toastSucess } from "utils/toast.util";
import CommonStyles from "theme/CommonStyles";
import SomnofySwitchRoute from "components/SomnofySwitchRoute/SomnofySwitchRoute";

import GroupList from "./components/GroupList";
import GroupView from "./components/GroupView";
import userGroupsRoutes, { groupsRouteNames } from "./userGroups.routes";

/**
 * @name GroupsManagement
 * @description Group management component
 * @returns GroupsManagement componet
 */
function GroupsManagement() {
  const history = useHistory();
  const { t } = useTranslation();
  const common = CommonStyles();

  const dispatch = useDispatch();
  const [userGroups, setUserGroups] = useState<Group[]>([]);
  const [userGroupsEntity, setUserGroupsEntity] = useState<{
    [key: string]: Group;
  }>({});

  const [usersEntity, setUsersEntity] = useState<{ [id: string]: User }>({});

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const groupsState = useSelector((state: any) => {
    return state?.userGroupsReducer || {};
  });

  const users: User[] = useSelector((state: any) => {
    return state?.users?.users || [];
  });

  const { id } = useParams<{ id: any }>();

  useEffect(() => {
    if (users?.length) {
      const entity: { [key: string]: User } = {};
      for (let i = 0; i < users.length; i += 1) {
        entity[users[i].id] = users[i];
      }
      setUsersEntity(entity);
    } else {
      setUsersEntity({});
    }
  }, [users.length]);

  useEffect(() => {
    if (groupsState.groups && groupsState.groups?.length) {
      setUserGroups(groupsState.groups);
      setUserGroupsEntity(normalizedGroupsEntity(groupsState.groups));
    }
    if (
      groupsState.currentAction === GROUP_ACTIONS.createEmployeeGroupSuccess ||
      groupsState.currentAction === GROUP_ACTIONS.createUserGroupSuccess
    ) {
      toastSucess(t("Group created successfully"));
      dispatch(updateCurrentAction(GROUP_ACTIONS.reset));
      history.goBack();
    }

    if (groupsState.currentAction === GROUP_ACTIONS.updateSuccess) {
      toastSucess(t("Group updated successfully"));
      dispatch(updateCurrentAction(GROUP_ACTIONS.reset));
      const allowedUrls = ["employees", "deviceUsers"];
      const currentPathName = history.location.pathname.split("/").reverse();
      if (
        !(
          _.isArray(currentPathName) &&
          currentPathName.length > 0 &&
          allowedUrls.includes(currentPathName[0])
        )
      ) {
        history.goBack();
      }
    }

    if (groupsState.currentAction === GROUP_ACTIONS.deleteSuccess) {
      toastSucess(t("Group deleted successfully"));
      dispatch(updateCurrentAction(GROUP_ACTIONS.reset));
    }
  }, [groupsState]);

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, []);

  if (!loginedUser) {
    return <div />;
  }

  const getRender = (props: any, currentRoute: AppRouteProps): JSX.Element => {
    if (!userGroups || !loginedUser || !userGroupsEntity) {
      return <Loading />;
    }

    if (
      currentRoute.name === groupsRouteNames.employees ||
      currentRoute.name === groupsRouteNames.deviceUsers
    ) {
      return (
        <GroupList
          loginedUser={loginedUser}
          userGroupType={
            currentRoute.name === groupsRouteNames.employees
              ? UserGroupType.viewer
              : UserGroupType.device
          }
          userGroupsEntity={userGroupsEntity}
          usersEntity={usersEntity}
          routePath={currentRoute.path}
        />
      );
    }
    if (
      currentRoute.name === groupsRouteNames.employeesDetails ||
      currentRoute.name === groupsRouteNames.deviceUsersGroupDetails
    ) {
      return (
        <GroupView
          usersEntity={usersEntity}
          id={props.match.params.id}
          userGroupsEntity={userGroupsEntity}
          userGroupType={
            currentRoute.name === groupsRouteNames.employeesDetails
              ? UserGroupType.viewer
              : UserGroupType.device
          }
          routePath={currentRoute.path}
        />
      );
    }
    return <Loading />;
  };

  return (
    <SomnofySwitchRoute
      routes={userGroupsRoutes}
      renderFunction={getRender}
      wrapperClass={`${common.greyBackground} ${common.floatLeft} ${common.fullWidth}`}
    />
  );
}

export default GroupsManagement;
