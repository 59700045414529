import Awake2Icon from "../assets/icons/Awake2.svg";
import NotPresentIcon from "../assets/icons/Notpresent.svg";
import OutOfBedIcon from "../assets/icons/OutOfBed.svg";
import Sleeping2Icon from "../assets/icons/Sleeping2.svg";
import i18n from "../i18n/config";

const t = i18n.t.bind(i18n);

export const getBackgroundColor = (status) => {
  switch (status) {
    case "awake":
      return "#3399FF";
    case "asleep":
      return "#737373"; // "#3B3B3B"
    case "away":
      return "#D8B566";
    case "disconnected":
      return "#CCCCCC";
    default:
      return "#C6C6C6";
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case "awake":
      return t("Awake in bed");
    case "asleep":
      return t("asleep");
    case "away":
      return t("Not in bed");
    case "disconnected":
      return t("In Active");
    default:
      return t("No data");
  }
};

export const getStatusIcon = (status) => {
  switch (status) {
    case "awake":
      return Awake2Icon;
    case "asleep":
      return Sleeping2Icon;
    case "away":
      return OutOfBedIcon;
    default:
      return NotPresentIcon;
  }
};

export const getTileScaleCopy = (tileScale) => {
  let tileScaleCopy;
  if (tileScale > 120 && tileScale <= 150) {
    tileScaleCopy = 150;
  } else if (tileScale > 90 && tileScale <= 120) {
    tileScaleCopy = 120;
  } else if (tileScale > 60 && tileScale <= 90) {
    tileScaleCopy = 90;
  } else if (tileScale > 38 && tileScale <= 60) {
    tileScaleCopy = 60;
  } else if (tileScale > 26 && tileScale <= 38) {
    tileScaleCopy = 38;
  } else if (tileScale > 19 && tileScale <= 26) {
    tileScaleCopy = 26;
  } else if (tileScale > 14 && tileScale <= 19) {
    tileScaleCopy = 19;
  } else if (tileScale > 10 && tileScale <= 14) {
    tileScaleCopy = 14;
  } else if (tileScale > 6 && tileScale <= 10) {
    tileScaleCopy = 10;
  } else if (tileScale > 3 && tileScale <= 6) {
    tileScaleCopy = 6;
  } else if (tileScale > 2 && tileScale <= 3) {
    tileScaleCopy = 3;
  } else if (tileScale > 1 && tileScale <= 2) {
    tileScaleCopy = 2;
  } else if (tileScale > 0 && tileScale <= 1) {
    tileScaleCopy = 1;
  }
  return tileScaleCopy;
};
