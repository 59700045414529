import * as PropTypes from "prop-types";
import React from "react";

import SleepHistoryVitalsComponent from "./SleepHistoryVitals";

function SleepHistoryVitals(props) {
  return props.options && props.options.plotData ? (
    <SleepHistoryVitalsComponent {...props} />
  ) : null;
}

SleepHistoryVitals.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    onChartMouseOver: PropTypes.func,
    onChartClick: PropTypes.func,
    plotData: PropTypes.shape({
      series: PropTypes.object,
      threshold: PropTypes.object
    }).isRequired
  }),
  enableHeartRateMean: PropTypes.string
};

export default SleepHistoryVitals;
