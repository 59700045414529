import axios from "axios";

const URL = "/v1/sessions";
const getSleepAnalysis = (sessionId, userId) => {
  const query = {
    embed: [
      "sleep_analysis.hypnogram",
      "sleep_analysis.meta",
      "sleep_analysis.epoch_data"
    ]
  };
  if (userId) {
    query.user_id = userId;
  }
  return axios
    .get([URL, sessionId].join("/"), { params: query })
    .then((res) => res.data._embedded.sleep_analysis);
};

export default { getSleepAnalysis };
