import { toast } from "react-toastify";
import axios from "axios";

import { API_SERVICES } from "constants/constants";
import { User } from "interfaces/user.interface";
import {
  handleError,
  handleResponse,
  showErrorToastForUser
} from "services/responseHandler";

export const userServices = {
  changeUserPassword,
  updateUserInformation,
  addNewUser,
  deleteUser,
  fetchAllUsers
};

async function changeUserPassword(
  newPassword: string,
  subUser: boolean,
  user: User
) {
  const url = API_SERVICES.changePassword.replace("$$", user.id);

  const headers: any = {
    // current_password: currentPass
  };

  if (subUser) {
    headers.params = JSON.stringify({ fields: ["clients"] });
  }

  return axios({
    method: "put",
    url,
    data: { password: newPassword },
    headers,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function updateUserInformation(user: User) {
  const url = API_SERVICES.updateUser.replace("$$", user.id);
  const display_name = `${user.first_name} ${user.last_name}`;
  const payload = {
    first_name: user.first_name,
    last_name: user.last_name,
    display_name,
    attributes: user.attributes,
    email: user.email,
    gender: user.gender,
    height: user.height,
    weight: user.weight,
    birth_year: user.birth_year,
    language_preference: user.language_preference,
    view_users: user.view_users,
    user_groups: user.user_groups,
    act_as_root_user: user.act_as_root_user
  };

  return axios({
    method: "put",
    url,
    data: payload,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}

async function addNewUser(user: User) {
  const url = API_SERVICES.addNewUser;
  const display_name = `${user.first_name} ${user.last_name}`;
  const payload = {
    password: user.password,
    first_name: user.first_name,
    last_name: user.last_name,
    display_name,
    attributes: user.attributes,
    email: user.email,
    gender: user.gender,
    height: user.height,
    weight: user.weight,
    birth_year: user.birth_year,
    language_preference: user.language_preference,
    view_users: user.view_users,
    user_groups: user.user_groups,
    act_as_root_user: user.act_as_root_user
  };

  return axios({
    method: "post",
    url,
    data: payload,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}

async function deleteUser(user: User) {
  const url = API_SERVICES.deleteNewUser.replace("$$", user.id);
  return axios({
    method: "delete",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}

async function fetchAllUsers() {
  const url = API_SERVICES.fetchAllUsers;
  return axios({
    method: "get",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
