import { ClassNameMap } from "@material-ui/styles";
import React from "react";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";

import GroupListRowEdit from "./GroupListRowEdit";
import GroupListRowView from "./GroupListRowView";

interface Props {
  searchQuery: string | undefined;
  commonCls: any;
  group: Group;
  userGroupType: string;
  classes: ClassNameMap<
    "actionButtons" | "groupUsersItem" | "undoTimerBtn" | "undoTimerTime"
  >;
  onEdit: (group: Group) => void;
  deviceUsers?: User[];
  history: any;
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setEditGroup: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editGroup: { [key: string]: boolean };
  onDelete: (grp: Group) => void;
  viewGroupDetails: (event: any, group: Group) => void;
  t: any;
  onSaveAction: (group: Group) => void;
  usersEntity: { [key: string]: User };
}

function GroupListRow({
  searchQuery,
  commonCls,
  classes,
  group,
  deviceUsers,
  groupEntity,
  userGroupType,
  history,
  onEdit,
  selectedItems,
  setSelectedItems,
  setEditGroup,
  editGroup,
  onDelete,
  viewGroupDetails,
  onSaveAction,
  usersEntity,
  t
}: Props) {
  return (
    <>
      {editGroup[group.id] ? (
        <GroupListRowEdit
          searchQuery={searchQuery}
          commonCls={commonCls}
          group={group}
          deviceUsers={deviceUsers}
          groupEntity={groupEntity}
          userGroupType={userGroupType}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditGroup={setEditGroup}
          editGroup={editGroup}
          onDelete={onDelete}
          t={t}
          onSaveAction={onSaveAction}
          usersEntity={usersEntity}
        />
      ) : (
        <GroupListRowView
          searchQuery={searchQuery}
          commonCls={commonCls}
          group={group}
          deviceUsers={deviceUsers}
          groupEntity={groupEntity}
          userGroupType={userGroupType}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setEditGroup={setEditGroup}
          editGroup={editGroup}
          onDelete={onDelete}
          viewGroupDetails={viewGroupDetails}
        />
      )}
    </>
  );
}

export default GroupListRow;
