import PropTypes from "prop-types";
import React from "react";

const Section = (props) => {
  return <div>{props.children}</div>;
};

Section.propTypes = {
  children: PropTypes.any
};

export default Section;
