export const API_URL = "https://app.api.somnofy.com";
export const API_SERVICES = {
  getAlldevices: "/v1/devices",
  getdevicesSettings: "/v1/devices/$$/settings/user",
  changeUser: "/v1/devices/$$",
  updateDevicesSettings: "/v1/devices/$$/settings/user",
  changePassword: "/v1/users/$$",
  updateUser: "/v1/users/$$",
  addNewUser: "/v1/users",
  deleteNewUser: "/v1/users/$$",
  getAllUserGroups: "/v1/user-groups",
  addNewUserGroup: "/v1/user-groups",
  updateUserGroup: "/v1/user-groups/$$",
  deleteUserGroup: "/v1/user-groups/$$",
  fetchAllUsers:
    "/v1/users?fields=settings&fields=clients&fields=view_users&limit=999&offset=0",
  fetchNightReport: "/beta/reports?report_type=night_report_v1", // &user_ids=5a4a6000edcdb7ccd3a9a65d&user_ids=59482ced6f8b4b8d905c1018,
  deleteDevice: "/v1/devices/$$",
  getAllNotification: "/v1/notification"
};

export const deviceListItemsPerPage = 25;
export const usersListItemsPerPage = 15;
