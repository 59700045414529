import PropTypes from "prop-types";
import React from "react";

import HypnogramChart from "./HypnogramChart";

const HypnogramComponent = (props) => {
  const { plotData } = props.options;
  const { enableHeartRateMean } = props;
  return (
    <div>
      <HypnogramChart
        plotData={plotData}
        enableHeartRateMean={enableHeartRateMean}
      />
    </div>
  );
};

HypnogramComponent.propTypes = {
  options: PropTypes.any,
  enableHeartRateMean: PropTypes.string
};

export default HypnogramComponent;
