import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

import {
  NightReportItem,
  NightReportItemMetric,
  NightReportItemStatus
} from "interfaces/nightReport.interface";
import { User } from "interfaces/user.interface";
import { getLocale } from "utils/date.util";
import {
  getNightReportValueDifference,
  getRoundedNightReportValue
} from "utils/report.util";
import InfoBox from "components/InfoBox/InfoBox";

import NightReportListRowNoPresence from "./NightReportListRowNoPresence";
import i18n from "../../../../i18n/config";

const styles = createStyles({
  root: {},
  title: {
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 10,
    margin: "4px 8px 8px 8px",
    float: "left"
  },
  titleWrapper: {
    marginBottom: 30,
    width: "100%",
    float: "left"
  },
  headerText: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.87)",
    clear: "both",
    float: "left",
    margin: "0 8px",
    fontWeight: 300
  },
  printWrapper: {
    position: "absolute",
    right: 32,
    top: 36,
    width: 32,
    height: 56,
    cursor: "pointer",
    "& span": {
      width: "100%"
    },
    "@media print": {
      display: "none"
    }
  },
  colHeader: {
    flex: 1,
    padding: 5,
    minWidth: 50,
    overflowWrap: "break-word",
    fontSize: 10,
    fontWeight: 400
  },
  col: {
    flex: 1,
    padding: "9px 8px 9px 8px",
    minWidth: 50,
    overflowWrap: "break-word",
    fontSize: 10,
    fontWeight: 400,
    position: "relative",
    borderTop: 0
  },
  rowContainer: {
    cursor: "pointer",
    float: "left",
    width: "100%"
  },
  row: {
    display: "flex",
    alignItems: "top"
  },
  // evenChildBackground: {
  //   "&:nth-child(even)": {
  //     background: "#EDEDED",
  //     color: "#000"
  //   }
  // },
  evenChildBackground: {
    "&:nth-child(even)": {
      background: "#EDEDED",
      color: "#000",
      "&.warningBg": {
        background: "#e9a297f7 !important" as any
      }
    },
    "&.warningBg": {
      background: "#FFCCCB"
    }
  },
  header: {
    display: "flex",
    width: "100%",
    fontWeight: "normal",
    color: "#212121",
    cursor: "pointer",
    paddingBottom: 7,
    alignItems: "flex-start",
    borderBottom: "1px solid #212121",
    "&>div > span": {
      float: "left"
    },
    height: "auto",
    marginBottom: 8
  },
  errorIcon: {
    width: 16,
    height: 16,
    color: "#B00020",
    position: "absolute",
    left: "-14px",
    top: "8px"
  },
  alertIcon: {
    width: 16,
    height: 16,
    color: "#B00020",
    position: "absolute",
    left: "-14px",
    top: "8px"
  },
  floatLeft: {
    float: "left"
  },
  col9: {
    flex: 8,
    overflowWrap: "break-word",
    padding: "9px 8px 9px 8px",
    minWidth: 50,
    fontSize: 10,
    position: "relative"
  },
  infoText: {
    lineHeight: "16px"
  },
  headerRight: {
    fontSize: 14,
    color: "#000",
    fontWeight: "bold",
    float: "right"
  },
  legends: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.87)",
    float: "left",
    marginTop: 8
  },
  legendsIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    color: "#B00020",
    float: "left",
    "& > span": {}
  },
  legendsTitle: {
    fontWeight: 500,
    clear: "both",
    marginBottom: 8
  },
  desc: {
    clear: "both",
    lineHeight: "17px"
  },
  legendItems: {
    clear: "both",
    margin: 0,
    "& > li": {
      margin: "4px 0"
    }
  },
  baseLineHeights: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.87)",
    float: "right",
    lineHeight: "16px",
    textAlign: "right",
    width: 232,
    marginTop: 32
  },
  pageNumber: {
    "&:after": {
      // content: 'counter(page) " of " counter(pages) +1 ' as any,
      // content: 'counter(page)' as any,
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.87)",
      float: "right",
      marginTop: 8,
      counterIncrement: "page"
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  items?: NightReportItem[];
  timeframeStart?: string;
  loginedUser: User;
  createdAt?: string;
  classes: any;
  t: any;
  ref: any;
}

type ClassNames = { classes: { [className in keyof typeof styles]: string } };

const getTimeAsleep = (seconds: number): string => {
  if (!seconds) {
    return "";
  }
  const HH = Math.floor(seconds / 3600);
  const MM = Math.floor(seconds / 60) % 60;
  return `${HH}h ${MM}m`;
};

const hasAlert = (item: NightReportItem) => {
  if (
    item.movement?.deviation_alert ||
    item.respiration_rate?.deviation_alert ||
    item.time_asleep?.deviation_alert ||
    item.sleep_score?.deviation_alert ||
    item.out_of_bed?.deviation_alert
  ) {
    return true;
  }
  return false;
};
class NightReportToPrint extends React.PureComponent<Props & ClassNames, any> {
  render() {
    const { items, timeframeStart, createdAt, loginedUser, classes, t } =
      this.props;
    return (
      <div className={classes.root}>
        {/* <LandscapeOrientation /> */}
        <InfoBox padding={16}>
          {timeframeStart && createdAt ? (
            <div className={classes.titleWrapper}>
              <div className={classes.floatLeft}>
                <div className={classes.title}>
                  {t("Night")}:{" "}
                  {timeframeStart &&
                    new Intl.DateTimeFormat(getLocale(), {
                      dateStyle: "full"
                    }).format(new Date(timeframeStart))}
                </div>
                <div className={classes.headerText}>
                  {t("Report generated time")}:{" "}
                  {createdAt &&
                    new Intl.DateTimeFormat(getLocale(), {
                      dateStyle: "full",
                      timeStyle: "short"
                    }).format(new Date(createdAt))}
                </div>
                <div className={classes.headerText}>
                  {t("Report generated by")}: {loginedUser.display_name}
                </div>
              </div>
              <div className={classes.headerRight}>{t("Night Report")}</div>
              <div className={classes.pageNumber} />
            </div>
          ) : (
            ""
          )}
          <header className={classes.header} aria-hidden>
            <div className={classes.colHeader}>
              <span> {t("Device")} </span>
            </div>
            <div className={classes.colHeader}>
              <span>{t("User Id")}</span>
            </div>
            <div className={classes.colHeader}>
              <span> {t("Respiration Rate (rpm)")} </span>
            </div>
            <div className={classes.colHeader}>
              <span> {t("Movement")}</span>
            </div>

            <div className={classes.colHeader}>
              <span> {t("Total Sleep Time")} </span>
            </div>
            <div className={classes.colHeader}>
              <span style={{ float: "right" }}> {t("Sleep Score")} </span>
            </div>

            <div className={classes.colHeader}>
              <span style={{ float: "right" }}>
                {" "}
                {`${t("Out of Bed")} (${t("no.of times")})`}{" "}
              </span>
            </div>
            <div className={classes.colHeader} style={{ textAlign: "center" }}>
              <span style={{ float: "none" }}>{t("Session in Progress")}</span>
            </div>
            <div className={classes.colHeader}>
              <span>
                {t("Baseline Nights")}
                {" (*)"}
              </span>
            </div>
          </header>

          <div className={classes.rowContainer}>
            {items &&
              items.map((item: NightReportItem, index) => {
                if (item.status !== NightReportItemStatus.NO_PRESENCE) {
                  return (
                    <div
                      className={`${classes.row} ${
                        classes.evenChildBackground
                      } ${hasAlert(item) ? "warningBg" : ""}`}
                      aria-hidden
                      key={item.user.id}
                    >
                      <div className={classes.col}>{item?.device?.name}</div>
                      <div className={classes.col}>
                        {item?.user?.display_name}
                      </div>
                      <div className={classes.col}>
                        <TableData
                          item={item.respiration_rate}
                          pct
                          t={t}
                          classes={classes}
                          round={0}
                        />
                      </div>
                      <div className={classes.col}>
                        <TableData
                          item={item.movement}
                          pct
                          t={t}
                          classes={classes}
                          round={0}
                        />
                      </div>
                      <div className={classes.col}>
                        {item.time_asleep?.deviation_alert ? (
                          <ErrorOutlineIcon className={classes.alertIcon} />
                        ) : (
                          ""
                        )}
                        {getTimeAsleep(item?.time_asleep?.value) || t("N/A")}
                        {item?.time_asleep?.deviation_pct
                          ? ` (${
                              item?.time_asleep?.deviation_pct > 0 ? "+" : ""
                            }${Math.round(+item?.time_asleep?.deviation_pct)}%)`
                          : ""}
                      </div>
                      <div
                        className={classes.col}
                        style={{ textAlign: "right" }}
                      >
                        <TableData
                          item={item.sleep_score}
                          pct={false}
                          t={t}
                          classes={classes}
                          round={0}
                          baseline
                          valueRound
                        />
                      </div>
                      <div
                        className={classes.col}
                        style={{ textAlign: "right" }}
                      >
                        <TableData
                          item={item.out_of_bed}
                          pct={false}
                          t={t}
                          classes={classes}
                          round={1}
                          baseline
                        />
                      </div>
                      <div
                        className={classes.col}
                        style={{ textAlign: "center" }}
                      >
                        {item.selected_session_is_in_progress
                          ? t("Yes")
                          : t("No")}
                      </div>
                      <div
                        className={classes.col}
                        style={{ textAlign: "center" }}
                      >
                        {item.baseline_night_count
                          ? item.baseline_night_count
                          : t("N/A")}
                      </div>
                    </div>
                  );
                }
                return (
                  <NightReportListRowNoPresence
                    item={item}
                    classes={classes}
                    key={`${item.user.id}_${item?.device?.name}`}
                    t={t}
                    customClases={classes}
                    message={
                      item.status === NightReportItemStatus.NO_PRESENCE
                        ? t("User not present or data not available")
                        : t("No sleep data available")
                    }
                  />
                );
              })}
          </div>

          <div className={classes.legends}>
            <div className={classes.legendsTitle}>{t("Legends")}</div>
            <div className={classes.desc}>
              <ErrorOutlineIcon className={classes.legendsIcon} />
              <span>
                {t(
                  "Flagged for attention if there is a difference compared to the baseline value, as follows"
                )}
                :
              </span>
            </div>
            <ul className={classes.legendItems}>
              <li>
                {t("Respiration Rate: difference > 15 % (below or above)")}
              </li>
              <li>{t("Movement: difference > 30% (below or above)")}</li>
              <li>
                {t("Total Sleep Time: difference > 30% (below or above)")}
              </li>
            </ul>
          </div>
          <div className={classes.baseLineHeights}>
            {t(
              "(*) Baseline Nights: the number of nights used to calculate the baseline value, which is the average measurement for the past 21 days"
            )}
          </div>
        </InfoBox>
      </div>
    );
  }
}

function TableData({
  item,
  pct,
  t,
  classes,
  round = -1,
  baseline = false,
  valueRound = false
}: {
  item?: NightReportItemMetric;
  pct: boolean;
  t: any;
  classes: any;
  round?: number;
  baseline?: boolean;
  valueRound?: boolean;
}) {
  if (!item) {
    return t("N/A");
  }
  return (
    <>
      {item?.deviation_alert ? (
        <div>
          {" "}
          <ErrorOutlineIcon className={classes.alertIcon} />{" "}
        </div>
      ) : (
        ""
      )}
      {getRoundedNightReportValue(item?.value, valueRound)}
      {getNightReportValueDifference({ item, baseline, round, pct })}
    </>
  );
}
export default withStyles(styles)(NightReportToPrint);
