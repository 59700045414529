import PropTypes from "prop-types";
import React from "react";

import SleepScoreGauge from "./SleepScoreGauge";

const SleepScoreComponent = (props) => {
  const { options } = props;
  const { plotData } = options;
  return <SleepScoreGauge plotData={plotData} />;
};

SleepScoreComponent.propTypes = {
  options: PropTypes.any
};

export default SleepScoreComponent;
