import axios from "axios";

import { Group } from "interfaces/group.interface";
import { showErrorToastForUser } from "services/responseHandler";
import { userGroupServices } from "services/userGroups/userGroupServices";

import * as types from "../actionTypes/groupsActionTypes";
import { GROUP_ACTIONS } from "../actionTypes/groupsActionTypes";
import { fetchAllTheUsers } from "./userActions";

/**
 * Redux action type to udpate the current status
 *
 * @param none
 * @return fetch device action type.
 */
export const updateCurrentAction = (
  data: string
): types.userGroupsCurrentActionType => {
  return {
    type: types.UPDATE_USER_GROUPS_CURRENT_ACTION,
    payload: data
  };
};

/**
 * Redux action type for to to fetch all the groups
 *
 * @param data groups
 * @return payload- array of groups
 */
export const getAllGroupsActionSuccess = (
  data: Group[]
): types.fetchUserGroupsSuccessActionType => {
  return {
    type: types.FETCH_USER_GROUPS_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type for to to fetch all the groups
 *
 * @param data groups
 * @return payload- array of groups
 */
export const createGroupsActionSuccess = (
  data: Group
): types.addNewUserGroupSuccessActionType => {
  return {
    type: types.ADD_NEW_USER_GROUP_ACTION_TYPE,
    payload: data
  };
};

/**
 * Redux action type for to edit user group action success
 *
 * @param none
 * @return updated user group.
 */
export const editGroupActionSuccess = (
  data: Group
): types.editUserGroupSuccessActionType => {
  return {
    type: types.EDIT_USER_GROUPS_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action type for to delete user group
 *
 * @param none
 * @return updated user group.
 */
export const deleteGroupActionSuccess = (
  data: Group
): types.deleteUserGroupSuccessActionType => {
  return {
    type: types.DELETE_USER_GROUPS_ACTION_SUCCESS,
    payload: data
  };
};

export const deleteMultiGroupsActionSuccess = (
  data: string[]
): types.deleteMultipleGroupsSuccessAction => {
  return {
    type: types.DELETE_MULTI_GROUPS_ACTION_SUCCESS,
    payload: data
  };
};

/**
 * Redux action to reset status
 *
 * @param none
 */
export function resetCurrentAction() {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.reset));
  };
}

/**
 * Redux action to get all the devices
 *
 * @param none
 * @return devices array sorted based on the last-activity
 */
export function getAllUserGroups() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.fetching));
    userGroupServices.fetchUserGroups().then((data: any) => {
      data._embedded.user_groups.sort((a: Group, b: Group) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });

      dispatch(
        getAllGroupsActionSuccess(data._embedded.user_groups as Group[])
      );
    });
  };
}

export function createUserGroup(data: Group) {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.create));
    userGroupServices.addNewUserGroup(data).then((reponse: Group) => {
      dispatch(createGroupsActionSuccess(reponse));
    });
  };
}

export function updateUserGroup(group: Group) {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.updating));
    userGroupServices.updateUserGroup(group).then((data: Group) => {
      dispatch(fetchAllTheUsers());
      dispatch(editGroupActionSuccess(data));
    });
  };
}

/**
 * Redux action to delete user
 *
 * @param user: User object
 * @return updated user data with id
 */
export const deleteGroupAction = (group: Group) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.deleting));
    userGroupServices.deleteUserGroup(group).then((data: any) => {
      dispatch(fetchAllTheUsers());
      dispatch(deleteGroupActionSuccess(group));
    });
  };
};

export function multiGroupUpdate(groups: Group[]) {
  return async function (dispatch: (arg0: any) => void) {
    dispatch(updateCurrentAction(GROUP_ACTIONS.multiGroupUpdate));
    for (let i = 0; i < groups.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await userGroupServices
        .updateUserGroup(groups[i])
        .then((res: any) => {
          return res;
        })
        .catch((error: any) => {
          showErrorToastForUser(error);
          return error;
        });
    }
    dispatch(fetchAllTheUsers());

    // dispatch user group to sync with the current state
    dispatch(getAllUserGroups());
    // multi group update
    dispatch(updateCurrentAction(GROUP_ACTIONS.multiGroupUpdateSuccess));
  };
}

/**
 * Redux action to reset pre-loaders
 *
 * @return update user action preloader
 */
export const deleteMultipleGroups = (groups: Group[]) => {
  const processed = [];
  const success: string[] = [];
  const failed: string[] = [];

  const dispatchSuccess = (dispatch: any) => {
    if (processed.length >= groups.length && success.length > 0) {
      dispatch(fetchAllTheUsers());
      dispatch(deleteMultiGroupsActionSuccess(success));
    }
  };

  return async function (dispatch: (arg0: any) => void) {
    for (let i = 0; i < groups.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await userGroupServices
        .deleteUserGroup(groups[i])
        .then((data: any) => {
          processed.push(groups[i].id);
          success.push(groups[i].id);
          dispatchSuccess(dispatch);
        })
        .catch((error: any) => {
          processed.push(groups[i].id);
          failed.push(groups[i].id);
          dispatchSuccess(dispatch);
        });
    }
  };
};
