import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { AppRouteProps } from "interfaces/router.interface";
import { USER_ACTIONS } from "store/actionTypes/usersActionTypes";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import {
  fetchAllTheUsers,
  resetUserPreloaders
} from "store/actions/userActions";
import { getAllUserGroups } from "store/actions/groupActions";
import { toastSucess } from "utils/toast.util";
import Loading from "components/Loading/Loading";
import SomnofySwitchRoute from "components/SomnofySwitchRoute/SomnofySwitchRoute";

import EmployeeList from "./employeesList/EmployeeList";
import EmployeeView from "./employeeView/EmployeeView";
import employeeRoutes, { employeeRouteNames } from "./employees.routes";

/**
 * @name DevicesList
 * @description employees management base form
 * @returns Employees Management
 */
function EmployeesManagement() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [usersEntity, setUsersEntity] = useState<{ [key: string]: User }>({});
  const [employees, setEmployees] = useState<User[]>([]);
  const [deviceUsers, setDeviceUsers] = useState<User[]>([]);

  useEffect(() => {
    dispatch(fetchAllTheUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, [dispatch]);

  const usersState = useSelector((state: any) => {
    return state?.users;
  });

  useEffect(() => {
    if (usersState.currentAction === USER_ACTIONS.deleteSuccess) {
      toastSucess(t("Employee deleted successfully"));
      dispatch(resetUserPreloaders());
    }

    if (usersState.users) {
      const empls = usersState.users.filter((user: User) => {
        return (
          user.attributes?.user_type === UserAttributesTypes.viewer ||
          !user.parent_id
        );
      });

      const dvcUsers = usersState.users.filter((user: User) => {
        return user.attributes?.user_type !== UserAttributesTypes.viewer;
      });

      // set device users
      setDeviceUsers(dvcUsers);

      // set employees
      setEmployees(empls);

      if (usersState.users?.length) {
        const normalizedUsers: any = {};
        for (let i = 0; i < usersState.users.length; i += 1) {
          normalizedUsers[usersState.users[i].id] = usersState.users[i];
        }
        setUsersEntity(normalizedUsers);
      }
    } else {
      setUsersEntity({});
    }
  }, [usersState]);

  // user object will be undefined after << onDelete >>, redirect to employee list page after delete

  const getRender = (props: any, currentRoute: AppRouteProps) => {
    switch (currentRoute.name) {
      // Employees list page
      case employeeRouteNames.employeesList:
        if (usersState) {
          return (
            <EmployeeList users={employees} routePath={currentRoute.path} />
          );
        }
        return <Loading />;

      // Employee view page
      case employeeRouteNames.viewEmployee:
        return usersEntity ? (
          <EmployeeView
            routePath={currentRoute.path}
            id={props.match.params.id}
            usersEntity={usersEntity}
            deviceUsers={deviceUsers}
          />
        ) : (
          <Loading />
        );

      // default case
      default:
        return <Loading />;
    }
  };

  // Returns the location to any component.
  return (
    <div>
      <SomnofySwitchRoute routes={employeeRoutes} renderFunction={getRender} />
    </div>
  );
}
export default EmployeesManagement;
