import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { GROUP_ACTIONS } from "store/actionTypes/groupsActionTypes";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import {
  createUserGroup,
  getAllUserGroups,
  updateCurrentAction
} from "store/actions/groupActions";
import {
  getAvailbleUsers,
  getGroupBaseObject,
  getGroupSaveButtonLabel,
  normalizedGroupsEntity
} from "utils/groupManagement";
import { toastError, toastSucess } from "utils/toast.util";
import SMGroupLists from "components/SMGroupListWithState/SMGroupLists";
import SMTextField from "components/SMTextField/SMTextField";
import SMViewUsersList from "components/SMViewUsersList/SMViewUsersList";
import SaveCancelDeleteButton from "components/SaveCancelDeleteButton/SaveCancelDeleteButton";

export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "32px 37px 32px 32px",
    width: 551,
    color: theme.palette.primary.listHeader
  },
  content: {},
  title: {
    fontSize: 24,
    marginBottom: 24,
    float: "left",
    lineHeight: "24px"
  },
  titleWrapper: {
    width: "100%",
    float: "left"
  },
  close: {
    float: "right",
    cursor: "pointer"
  },
  formFieldWrapper: {
    marginTop: 32,
    width: "100%",
    float: "left"
  },
  label: {
    fontSize: 16,
    width: "100%",
    float: "left"
  },
  radioGroup: {
    flexDirection: "row",
    float: "left"
  },
  radio: {
    marginRight: 34
  },
  formElem: {
    float: "left",
    width: 377
  },
  footerWrapper: {
    marginTop: 24,
    float: "left"
  },
  sectionWrapper: {
    marginTop: 64
  }
}));

function AddGroupDrawer({
  onclose,
  userGroupType
}: {
  onclose: React.Dispatch<React.SetStateAction<boolean>>;
  userGroupType: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [group, setGroup] = useState<Group>(
    _.cloneDeep(getGroupBaseObject(userGroupType))
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});
  const [usersEntity, setUsersEntity] = useState<{ [id: string]: User }>({});

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  const userState = useSelector((state: any) => {
    return state?.users || {};
  });

  useEffect(() => {
    dispatch(getAllUserGroups());
  }, [dispatch]);

  // create users entity
  useEffect(() => {
    if (userState.users?.length) {
      const entity: { [key: string]: User } = {};
      for (let i = 0; i < userState.users.length; i += 1) {
        entity[userState.users[i].id] = userState.users[i];
      }
      setUsersEntity(entity);
    } else {
      setUsersEntity({});
    }
  }, [userState]);

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }

    if (
      groupState.currentAction === GROUP_ACTIONS.createUserGroupSuccess ||
      groupState.currentAction === GROUP_ACTIONS.createEmployeeGroupSuccess
    ) {
      toastSucess(t("Group created successfully"));
      dispatch(updateCurrentAction(GROUP_ACTIONS.reset));
      resetGroup();
    }
  }, [groupState]);

  const resetGroup = () => {
    setGroup(_.cloneDeep(getGroupBaseObject(userGroupType)));
    onclose(false);
  };

  const saveGroup = () => {
    setErrorMessage("");

    if (!group.name) {
      setErrorMessage(t("Please Enter Group Name"));

      // show toast
      toastError(t("Please Enter Group Name"));
    } else {
      dispatch(createUserGroup(group));
    }
  };

  const onGroupChange = (key: string, value: any) => {
    const updatedGroupInfo: Group = {
      ...group,
      [key]: value
    } as Group;
    setGroup(updatedGroupInfo);
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          {userGroupType === UserGroupType.device
            ? t("Add user group")
            : t("Add Employee Group")}
        </div>
        <div className={classes.close} onClick={() => resetGroup()} aria-hidden>
          <CloseIcon />
        </div>
      </div>
      <div className={`${classes.formFieldWrapper}`}>
        <div className={classes.label}>
          {userGroupType === UserGroupType.viewer
            ? t("Employee Group Name")
            : t("User Group Name")}
        </div>
        <SMTextField
          placeholder={
            userGroupType === UserGroupType.viewer
              ? t("Employee Group Name")
              : t("User Group Name")
          }
          onSave={(val: string) => {
            onGroupChange("name", val);
          }}
          value={group.name}
          delay={0}
          borderRed={(errorMessage && !group.name) || false}
          width={343}
        />
      </div>
      <div className={`${classes.formFieldWrapper} ${classes.sectionWrapper}`}>
        <div className={classes.label}>
          {userGroupType === UserGroupType.device
            ? t("Users in this Group")
            : t("Employees in this group")}
        </div>
        <SMViewUsersList
          label={
            userGroupType === UserGroupType.device
              ? t("Users in this Group")
              : t("Employees in this group")
          }
          lists={getAvailbleUsers(Object.values(usersEntity), userGroupType)}
          onChange={(value: string[], key: string) => {
            onGroupChange("users", value);
          }}
          searchPlaceholder={t("Search")}
          defaultValues={group.users}
          userGroupType={userGroupType}
          showItems={6}
          hideDrawer
          hideLabel
          inline
        />
      </div>

      {userGroupType === UserGroupType.viewer && (
        <div className={`${classes.formFieldWrapper}`}>
          <div className={classes.label}>{t("Can View User Groups")}</div>
          <SMGroupLists
            label={t("Can View User Groups")}
            defaultValues={group.view_user_groups}
            lists={Object.values(groupEntity).filter(
              (grp: Group) => grp.type === UserGroupType.device
            )}
            onChange={(value: string[], key: string) => {
              onGroupChange("view_user_groups", value);
            }}
            searchPlaceholder={t("Search")}
            userGroupType={userGroupType}
            showItems={6}
            hideDrawer
            inline
            hideLabel
          />
        </div>
      )}

      <div className={classes.footerWrapper}>
        <SaveCancelDeleteButton
          txtSave={getGroupSaveButtonLabel({
            updatedGroup: group,
            userGroupType
          })}
          txtCancel={t("Cancel")}
          txtDelete=""
          onCancel={resetGroup}
          onSave={saveGroup}
          onDelete={() => {}}
          hideDelete
        />
      </div>
    </div>
  );
}
export default AddGroupDrawer;
