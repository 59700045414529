import { Checkbox, FormControlLabel } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserAttributesTypes } from "constants/userContstants";
import { getUserAccess, getUserType } from "utils/userManagement";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import Highlighted from "components/Highlighted/HighLighted";
import TextRowWithViewMore from "components/TextRowWithViewMore/TextRowWithViewMore";
import UserListActionButtons from "components/UserListActionButtons/UserListActionButtons";

interface Props {
  searchQuery: string | undefined;
  commonCls: any;
  user: User;
  t: any;
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editEmployee: { [key: string]: boolean };
  setEditEmployee: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  viewUserDetails: (event: any, user: User) => void;
  onDelete: (user: User) => void;
  isAdmin: boolean;
}

function EmployeeListRowView({
  searchQuery,
  commonCls,
  user,
  groupEntity,
  t,
  selectedItems,
  setSelectedItems,
  editEmployee,
  setEditEmployee,
  onDelete,
  viewUserDetails,
  isAdmin
}: Props) {
  return (
    <div
      className={`${commonCls.row} ${commonCls.evenChildBackground}`}
      key={user.id}
      onClick={(event: any) => viewUserDetails(event, user)}
      aria-hidden
    >
      {isAdmin ? (
        <BulkActionCheckBox
          wrapperClassName={commonCls.checkboxlistWraper}
          id={user.id}
          selected={selectedItems}
          setSelected={setSelectedItems}
        />
      ) : (
        <></>
      )}
      <div className={`${commonCls.col} ${commonCls.colorPrimary}`}>
        <Highlighted text={user.display_name} highlight={searchQuery} />
      </div>
      <div className={commonCls.col}>{user.email}</div>
      <div className={commonCls.col}>
        {user.user_groups && (
          <TextRowWithViewMore
            lists={user.user_groups.map((id: string) => groupEntity[id]?.name)}
            commonCls={commonCls}
          />
        )}
      </div>
      <div className={commonCls.col} style={{ textAlign: "center" }}>
        {getUserType(user) === UserAttributesTypes.default ? t("Yes") : t("No")}
      </div>
      <div className={commonCls.col}>
        {getUserAccess(user).map((acs: string) => (
          <div key={acs} className={commonCls.textList}>
            {acs}
          </div>
        ))}
      </div>

      {isAdmin ? (
        <UserListActionButtons
          classes={commonCls}
          onEditClick={() => {
            const updated = _.mapValues(editEmployee, () => false);
            setEditEmployee({ ...updated, [user.id]: true });
          }}
          onDeleteClick={() => onDelete(user)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default EmployeeListRowView;
