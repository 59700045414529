import React from "react";

import InformationTileComponent from "./InformationTile";

class InformationTile extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  render() {
    return <InformationTileComponent {...this.props} />;
  }
}

export default InformationTile;
