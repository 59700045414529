import axios from "axios";
import moment from "moment";

const URL = "/v1/sessions";
const defaultQuery = {
  embed: "sleep_analysis.report",
  limit: 500,
  time_in_bed: '{"$gte":12600}', // 12600
  from: moment().subtract(3, "month").toISOString(),
  sort: "desc"
};

const sessionsParams = {
  user_id: "*", // This gives you a maximum of one session (the latest one) for each of your users (including the authenticated user).
  from: moment()
    .subtract(2 * 24, "hours")
    .format("YYYY-MM-DDTHH:mm:ss"), // Define how far back we want to look for a session
  embed: ["sleep_analysis.epoch_data", "sleep_analysis.hypnogram"],
  epoch_count: 240,
  sort: "desc",
  limit: 100
};

const getSessions = (q = {}) => {
  const query = { ...defaultQuery, ...q };
  const info = { params: query };
  return axios
    .get(URL, info)
    .then((res) => res.data._embedded.sessions.reverse());
};

const getLatestSessionsForUser = () => {
  const info = { params: sessionsParams };
  return axios.get(URL, info).then((res) => res.data._embedded.sessions);
};

export default {
  getSessions,
  getLatestSessionsForUser
};
