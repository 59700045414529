import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SMButton from "components/SMButton/SMButton";

import SMDeleteDialog from "./DeleteDialog";

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: "56px 32px 0 32px",
    background: theme.palette.secondary.grey,
    width: "100%",
    float: "left"
  },
  title: {
    fontSize: 34,
    marginBottom: 14
  },
  radioGroup: {
    flexDirection: "row"
  },
  btnWrapper: {
    float: "left",
    marginTop: 20,
    marginBottom: 53,
    width: "100%",
    display: "flext",
    alignItems: "center"
  },
  actionBtn: {
    float: "left",
    marginRight: 24
  },
  deleteBtn: {
    float: "right",
    marginRight: 64
  }
}));

function SaveCancelDeleteButton({
  txtSave,
  onCancel,
  txtDelete,
  txtCancel,
  onSave,
  onDelete,
  deleteConfirmTitle,
  deleteConfirmText,
  hideDelete = false,
  disabledSaveButton = false
}: {
  txtSave: string;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void | null;
  txtDelete: string;
  txtCancel: string;
  deleteConfirmText?: string;
  deleteConfirmTitle?: string;
  hideDelete?: boolean;
  disabledSaveButton?: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classes.btnWrapper}>
      <div className={classes.actionBtn}>
        <SMButton
          text={txtSave}
          onClick={onSave}
          disabled={disabledSaveButton}
        />
      </div>
      <div className={classes.actionBtn}>
        {/* <SMButton outlineBtn text={txtCancel} onClick={onCancel} /> */}
      </div>
      {!hideDelete && (
        <div className={classes.deleteBtn} aria-hidden>
          <SMDeleteDialog
            buttonLabel={txtDelete}
            onDelete={onDelete}
            deleteConfirmTitle={deleteConfirmTitle}
            deleteConfirmText={deleteConfirmText}
          />
        </div>
      )}
    </div>
  );
}

export default SaveCancelDeleteButton;
