import { DATA_ACTION_TYPES } from "constants/data.action.types";
import { SMSReceiver } from "interfaces/notification.interface";

import * as types from "../actionTypes/notificationRecieverActionTypes";

export default function SMSRecieversReducer(
  state: any = {},
  action: types.notificationRecieverActionTypes
) {
  switch (action.type) {
    case types.GET_NOTIFICATION_RECIEVER_SUCCESS:
      return {
        ...state,
        recievers: action.payload || [],
        currentAction: DATA_ACTION_TYPES.fetchSuccess
      };

    case types.ADD_NOTIFICATION_RECIEVER_SUCCESS:
      return {
        ...state,
        recievers: [...state.recievers, action.payload],
        currentAction: DATA_ACTION_TYPES.createSuccess
      };
    case types.RESET_NOTIFICATION_RECIEVER_PRELOADERS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.reset
      };

    case types.UPDATE_NOTIFICATION_RECIEVER_SUCCESS:
      return {
        currentAction: DATA_ACTION_TYPES.updateSuccess,
        recievers: state.recievers.map((reciever: SMSReceiver) =>
          reciever.id === action.payload.id ? { ...action.payload } : reciever
        )
      };

    case types.DELETE_NOTIFICATION_RECIEVER_SUCCESS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.deleteSuccess,
        recievers: state.recievers.filter(
          (rule: SMSReceiver) => rule.id !== action.payload.id
        )
      };

    case types.BULK_DELETE_NOTIFICATION_RECIEVER_SUCCESS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.bulkDeleteSuccess,
        recievers: state.recievers.filter(
          (reciever: SMSReceiver) => action.payload.indexOf(reciever.id) === -1
        )
      };
    default:
      return state;
  }
}
