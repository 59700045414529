import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { DATA_ACTION_TYPES } from "constants/data.action.types";
import { SMSReceiver } from "interfaces/notification.interface";
import {
  SMSReceiverBaseObject,
  allNotificationChecked,
  showSMSRecieverToastMessage,
  sortSMSReceiver,
  sortSMSReceiversBySearchQuery,
  toggleAllSMSReceiversChecked
} from "utils/notificationUtils";
import {
  addSMSReciever,
  bulkDeleteSMSRecievers,
  deleteSMSReciever,
  getAllSMSRecievers,
  resetSMSRecieverPreloaderAction,
  updateSMSReciever
} from "store/actions/notificationRecieversActions";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import SMPagination from "components/SMPagination/SMPagination";

import NotificationReceiversBanner from "./components/NotificationReceiversBanner";
import NotificationReceiversBody from "./components/NotificationReceiversBody";
import NotificationReceiversHeader from "./components/NotificationReceiversHeader";

function NotificationReceivers() {
  const commonCls = CommonStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activePage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentSMSReceivers, setCurrentSMSReceivers] = useState<SMSReceiver[]>(
    []
  );

  const [sortedReceivers, setSortedReceivers] = useState<SMSReceiver[]>([]);
  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [curSortField, setSortField] = useState<string>("name");
  const [searchQuery, setSearchQuery] = useState<string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteReceiver, setDeleteReceiver] = useState<SMSReceiver | null>(
    null
  );
  const [editSMSReceiver, setEditSMSReceiver] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const smsRecieversState = useSelector((state: any) => {
    return state?.smsRecievers || {};
  });

  useEffect(() => {
    const smsreceivers: SMSReceiver[] = smsRecieversState.recievers;
    setSortedReceivers(smsreceivers);
    if (
      smsRecieversState.currentAction === DATA_ACTION_TYPES.createSuccess ||
      smsRecieversState.currentAction === DATA_ACTION_TYPES.updateSuccess ||
      smsRecieversState.currentAction === DATA_ACTION_TYPES.deleteSuccess ||
      smsRecieversState.currentAction === DATA_ACTION_TYPES.bulkDeleteSuccess
    ) {
      showSMSRecieverToastMessage(smsRecieversState.currentAction);
      dispatch(resetSMSRecieverPreloaderAction());
    }
  }, [smsRecieversState]);

  useEffect(() => {
    dispatch(getAllSMSRecievers());
  }, []);

  useEffect(() => {
    if (itemsPerPage >= sortedReceivers?.length) {
      setCurrentPage(1);
    }
    if (sortedReceivers) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentSMSReceivers(
        sortedReceivers.slice(indexOfFirstItem, indexOfLastItem)
      );
    }
  }, [sortedReceivers, activePage, itemsPerPage]);

  useEffect(() => {
    if (deleteReceiver) {
      setOpenDeleteDialog(true);
    }
  }, [deleteReceiver]);

  const onSearch = (q: string) => {
    setSearchQuery(q);
    setCurrentPage(1);
    const filteredReceivers = sortSMSReceiversBySearchQuery(
      smsRecieversState.recievers,
      q
    );
    setSortedReceivers(filteredReceivers);
    resetAddReciever();
  };

  const addNewNotificationReciever = () => {
    const inProgress = editSMSReceiver["0"];
    if (!inProgress) {
      const updated = _.mapValues(editSMSReceiver, () => false);
      const objCopy = _.cloneDeep(SMSReceiverBaseObject());
      const recievers = [objCopy, ...sortedReceivers];
      setSortedReceivers(recievers);
      setEditSMSReceiver({ ...updated, [objCopy.id]: true });
    }
  };

  const onToggleSelectAll = (checked: boolean) => {
    if (currentSMSReceivers) {
      setSelectedItems({
        ...selectedItems,
        ...toggleAllSMSReceiversChecked(currentSMSReceivers, checked)
      });
    }
  };

  const sortList = (sortType: string): void => {
    let order = "ASC";
    if (curSortField === sortType) {
      order = curSortOrder === "ASC" ? "DESC" : "ASC";
    }
    setSortField(sortType);
    setSortOrder(order as "ASC" | "DESC");
    setSortedReceivers(sortSMSReceiver(sortedReceivers, order, sortType));
  };

  const resetAddReciever = () => {
    setEditSMSReceiver({ ...editSMSReceiver, [`0`]: false });
  };

  const onDelete = (item: SMSReceiver | null) => {
    if (item && item.id === "0") {
      const arr = sortedReceivers.filter(
        (elem: SMSReceiver) => elem.id !== "0"
      );
      setSortedReceivers(arr);
      resetAddReciever();
    } else if (item && item.id) {
      setDeleteReceiver(null);
      dispatch(deleteSMSReciever(item));
    }
  };

  const onSave = (item: SMSReceiver) => {
    if (item.id === "0") {
      dispatch(addSMSReciever(item));
    } else {
      dispatch(updateSMSReciever(item));
    }
    setEditSMSReceiver({ ...editSMSReceiver, [item.id]: false });
  };

  const bulkDeleteAction = () => {
    const recieversToDelete: SMSReceiver[] = sortedReceivers.filter(
      (item: SMSReceiver) => {
        return selectedItems[item.id];
      }
    );
    dispatch(bulkDeleteSMSRecievers(recieversToDelete));
  };

  const ifBulkDelete = () => {
    const selected = sortedReceivers?.filter((group: SMSReceiver) => {
      return selectedItems[group.id];
    });
    return selected?.length > 0;
  };

  return (
    <div className={commonCls.tabContentWrapper}>
      <NotificationReceiversBanner
        onSearch={onSearch}
        setItemsPerPage={setItemsPerPage}
        addNew={addNewNotificationReciever}
        selectedItems={selectedItems}
        sortedReceivers={sortedReceivers}
        bulkDelete={bulkDeleteAction}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        deleteReceiver={deleteReceiver}
        setDeleteReceiver={setDeleteReceiver}
      />
      <NotificationReceiversHeader
        curSortOrder={curSortOrder}
        curSortField={curSortField}
        allSelected={allNotificationChecked(
          currentSMSReceivers || [],
          selectedItems
        )}
        onToggleSelectAll={onToggleSelectAll}
        onClick={sortList}
      />
      <NotificationReceiversBody
        currentSMSReceivers={currentSMSReceivers}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        searchQuery={searchQuery || ""}
        setDeleteReceiver={setDeleteReceiver}
        editSMSReceiver={editSMSReceiver}
        setEditSMSReceiver={setEditSMSReceiver}
        onDelete={onDelete}
        onSave={onSave}
      />

      {/* Pagination  */}
      {sortedReceivers && sortedReceivers?.length > itemsPerPage && (
        <SMPagination
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          itemsLength={sortedReceivers?.length}
          handlePageChange={(pageNumber: number) => setCurrentPage(pageNumber)}
        />
      )}

      {/* Delete dialog box */}
      <DialogBox
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={() =>
          ifBulkDelete() ? bulkDeleteAction() : onDelete(deleteReceiver)
        }
        title={t("Delete SMSReceiver(s)")}
        dialogDesc={t(
          "Deleting this SMSReceiver will remove all data associated with this SMSReceiver. This action is non-reversable"
        )}
        confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
        confirmPlaceHolder={t("Key in confirmation text here")}
        confirmText={t("I AM SURE")}
        buttonOk={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </div>
  );
}
export default NotificationReceivers;
