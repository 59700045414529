import axios from "axios";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";

import { API_SERVICES } from "../../constants/constants";
import { getToken } from "../../utils/localStorage";
import {
  handleError,
  handleResponse,
  showErrorToastForUser
} from "../responseHandler";

export const userGroupServices = {
  fetchUserGroups,
  updateUserGroup,
  addNewUserGroup,
  deleteUserGroup
};

const token = getToken();

// Function to load channels
async function fetchUserGroups() {
  const url = API_SERVICES.getAllUserGroups;
  return axios({
    method: "get",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function updateUserGroup(group: Group) {
  const url = API_SERVICES.updateUserGroup.replace("$$", group.id);
  return axios({
    method: "put",
    url,
    data: group,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}

async function addNewUserGroup(data: any) {
  const url = API_SERVICES.addNewUserGroup;
  return axios({
    method: "post",
    url,
    data,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}

async function deleteUserGroup(group: Group) {
  const url = API_SERVICES.deleteUserGroup.replace("$$", group.id);
  return axios({
    method: "delete",
    url,
    timeout: 4000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorToastForUser(error);
      return handleError(error);
    });
}
