export const getSleepScorePlotData = (session) => {
  if (!session) {
    return null;
  }
  const sleepReport = session._embedded.sleep_analysis.report;

  return {
    series: {
      sleepScore: sleepReport.sleep_score_1
    }
  };
};
