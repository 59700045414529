import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { AppRouteProps } from "interfaces/router.interface";

const useStyles = makeStyles((theme: any) => ({
  tabWrapper: {
    float: "left",
    paddingLeft: 41,
    "&.Mui-selected": {
      //  borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    // marginTop: 54,
    position: "absolute",
    top: 0
  },
  tabs: {
    float: "left",
    cursor: "pointer",
    padding: "16px 24px",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 16,
    border: 0,
    marginLeft: 24,
    color: "#FFF",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: theme.palette.primary.dark
  },
  active: {
    boxShadow: "none",
    background: "#FFF",
    color: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.dark}`,
    borderBottom: 0
  }
}));

function SecondaryTabHeader({ routes }: { routes: AppRouteProps[] }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const changeRoute = (path: string) => {
    history.push(path);
  };

  return (
    <div className={classes.tabWrapper}>
      {routes.map((route: AppRouteProps) => {
        if (route.exact && !route.redirectPath) {
          return (
            <div
              key={route.name}
              className={`${classes.tabs} ${
                location.pathname.includes(route.path) ? classes.active : ""
              }`}
              onClick={(e: any) => changeRoute(route.path)}
              aria-hidden
            >
              {t(route.name)}
            </div>
          );
        }
        return "";
      })}
    </div>
  );
}

export default SecondaryTabHeader;
