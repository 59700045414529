import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import ResizeDetector from "react-resize-detector";

import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);
export default class SleepScoreGauge extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    return (
      <ResizeDetector
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <HighchartsReact
          ref={this.chartRef}
          highcharts={Highcharts}
          constructorType="chart"
          options={getPlotOptions(this.props.plotData)}
        />
      </ResizeDetector>
    );
  }
}

const getPlotOptions = ({ series }) => {
  return {
    chart: {
      type: "solidgauge",
      margin: [0, 0, 0, 0],
      backgroundColor: "transparent"
    },
    title: null,
    pane: {
      center: ["50%", "70%"],
      size: "90%",
      startAngle: -120,
      endAngle: 120,
      background: {
        backgroundColor:
          (HighchartsReact.theme && HighchartsReact.theme.backgroundColor) ||
          "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc"
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },

    // the value axis

    yAxis: {
      stops: [
        [0.1, "#000000"], // black
        [0.7, "#DF5353"], // red
        [0.8, "#DDDF0D"], // yellow
        [0.9, "#55BF3B"] // green
      ],
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        text: null,
        y: -70
      },
      labels: {
        y: 15
      },
      min: 0,
      max: 100
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 20,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    series: [
      {
        name: "SleepScore",
        data: [series.sleepScore],
        dataLabels: {
          format: `<div style="text-align:center"><span style="font-size:25px;color:
                        ${
                          (HighchartsReact.theme &&
                            HighchartsReact.theme.contrastColor) ||
                          "black"
                        }
                        ">{y}</span><br/>
                        <span style="font-size:12px;color:silver">${t(
                          "Sleep Score"
                        )}</span></div>`
        }
      }
    ]
  };
};

SleepScoreGauge.propTypes = {
  plotData: PropTypes.any
};
