import { TextField, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { SMNotification } from "interfaces/notification.interface";

export const useStyles = makeStyles((theme) => ({
  subtitleEditIcon: {
    float: "right",
    marginRight: 10
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: 14,
    float: "left",
    width: "100%"
  },
  seriallNumber: {
    color: "#949391"
  },
  textFieldroot: {}
}));

function CustomEffectivePeriod({
  onChange,
  notification
}: {
  onChange: (key: string, value: string[]) => void;
  notification: SMNotification;
}) {
  const classes = useStyles();
  const [customEffectivePeriod, setCustomEffectivePeriod] = useState<string[]>(
    notification.effective_period || []
  );

  const customEffectivePeriodChange = (index: number, value: string) => {
    const customPeriod = [...customEffectivePeriod];
    customPeriod[index] = value;
    setCustomEffectivePeriod(customPeriod);
    onChange("effective_period", customPeriod);
  };

  return (
    <div style={{ float: "left", width: 200 }}>
      <div>
        <div style={{ width: "45%", float: "left" }}>
          <TextField
            type="time"
            classes={{
              root: classes.textFieldroot
            }}
            value={customEffectivePeriod[0] ?? ""}
            InputLabelProps={{
              shrink: true
            }}
            onChange={(e) => {
              customEffectivePeriodChange(0, e.target.value);
            }}
            inputProps={{
              step: 300 // 5 min
            }}
            key="from"
          />
        </div>
        <div style={{ width: "45%", float: "right" }}>
          <TextField
            type="time"
            InputLabelProps={{
              shrink: true
            }}
            value={customEffectivePeriod[1] ?? ""}
            onChange={(e) => {
              customEffectivePeriodChange(1, e.target.value);
            }}
            inputProps={{
              step: 300 // 5 min
            }}
            key="to"
          />
        </div>
      </div>
    </div>
  );
}

export default CustomEffectivePeriod;
