import { DATA_ACTION_TYPES } from "constants/data.action.types";
import { SMNotification } from "interfaces/notification.interface";

import * as types from "../actionTypes/notificationActionTypes";

export default function notificationReducer(
  state: any = {},
  action: types.notificationActionTypes
) {
  switch (action.type) {
    case types.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload || [],
        currentAction: DATA_ACTION_TYPES.fetchSuccess
      };

    case types.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
        currentAction: DATA_ACTION_TYPES.createSuccess
      };
    case types.RESET_NOTIFICATION_PRELOADERS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.reset
      };

    case types.UPDATE_NOTIFICATION_SUCCESS:
      return {
        currentAction: DATA_ACTION_TYPES.updateSuccess,
        notifications: state.notifications.map((rule: SMNotification) =>
          rule.id === action.payload.id ? { ...action.payload } : rule
        )
      };

    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.deleteSuccess,
        notifications: state.notifications.filter(
          (rule: SMNotification) => rule.id !== action.payload.id
        )
      };

    case types.BULK_DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        currentAction: DATA_ACTION_TYPES.bulkDeleteSuccess,
        notifications: state.notifications.filter(
          (rule: SMNotification) => action.payload.indexOf(rule.id) === -1
        )
      };

    default:
      return state;
  }
}
