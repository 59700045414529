import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { SMSReceiver } from "interfaces/notification.interface";
import SMButton from "components/SMButton/SMButton";
import SMTextField from "components/SMTextField/SMTextField";

interface Props {
  commonCls: any;
  smsReceiver: SMSReceiver;
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  t: any;
  onCancelEdit: (item: SMSReceiver) => void;
  onSave: (item: SMSReceiver) => void;
  errorMessage: string;
}

function NotificationReceiversItemEdit({
  commonCls,
  selectedItems,
  setSelectedItems,
  smsReceiver,
  onCancelEdit,
  onSave,
  errorMessage,
  t
}: Props) {
  const [updatedObj, setUpdatedObj] = useState<SMSReceiver>(
    _.cloneDeep(smsReceiver)
  );

  const [mobileNumbers, setMobileNumbers] = useState<string>(
    smsReceiver?.mobNumbers?.join(",") || ""
  );

  useEffect(() => {
    const numbers = mobileNumbers?.split(",");
    if (numbers) {
      const filteredNumbers = numbers.filter((numb: string) => {
        return numb !== "" && numb !== null;
      });
      onChange(filteredNumbers, "mobNumbers");
    }
  }, [mobileNumbers]);

  const onChange = (value: any, key: string) => {
    const updatedItem: SMSReceiver = {
      ...updatedObj,
      [key]: value
    };
    setUpdatedObj(updatedItem);
  };

  return (
    <div
      className={`${commonCls.row} ${commonCls.evenChildBackground}`}
      key={smsReceiver.id}
      aria-hidden
    >
      <div
        className={`${commonCls.checkboxlistWraper}`}
        data-ref="preventParentClick"
      >
        <FormControlLabel
          value="select"
          control={<Checkbox color="default" />}
          label=""
          labelPlacement="end"
          checked={!!selectedItems[updatedObj.id]}
          onChange={(e, value) => {
            setSelectedItems({ ...selectedItems, [smsReceiver.id]: value });
          }}
        />
      </div>
      <div className={`${commonCls.col} ${commonCls.colorPrimary}`}>
        <SMTextField
          placeholder={t("New Department")}
          onSave={(val: string) => onChange(val, "receiver")}
          value={updatedObj.receiver}
          delay={0}
          width="100%"
          borderRed={(errorMessage && !updatedObj.receiver) || false}
        />
      </div>

      <div className={`${commonCls.col} ${commonCls.colorPrimary}`}>
        <SMTextField
          placeholder={t("e.g. 44 55 66 77")}
          onSave={(val: string) => setMobileNumbers(val)}
          value={mobileNumbers}
          delay={0}
          width="100%"
        />
      </div>

      <div
        className={`${commonCls.col} ${commonCls.txtCenter}`}
        data-ref="preventParentClick"
      >
        <div className={commonCls.actionBtn}>
          <SMButton
            text={t("Save")}
            onClick={() => {
              onSave(updatedObj);
            }}
          />
        </div>
        <div className={commonCls.actionBtn}>
          <SMButton
            outlineBtn
            text={t("Cancel")}
            onClick={() => onCancelEdit(smsReceiver)}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationReceiversItemEdit;
