import moment from "moment";

const options = () => ({
  timeAsleep: {
    getValue: (sleepReport) => sleepReport.time_asleep
  },
  sleepOnset: {
    getValue: (sleepReport) => sleepReport.sleep_onset
  },
  sleepEfficiency: {
    getValue: (sleepReport) => sleepReport.sleep_efficiency
  },
  sleepScore1: {
    getValue: (sleepReport) => sleepReport.sleep_score_1
  },
  numberOfTimesAwakeLong: {
    getValue: (sleepReport) => sleepReport.number_of_times_awake_long
  },
  numberOfTimesNoPresence: {
    getValue: (sleepReport) => sleepReport.number_of_times_no_presence
  },
  nonREMMeanRespirationRate: {
    getValue: (sleepReport) => sleepReport.non_rem_mean_rpm
  },
  nonREMMeanHeartRate: {
    getValue: (sleepReport) => sleepReport.non_rem_mean_heartrate
  },
  nonREMMeanExternalHeartRate: {
    getValue: (sleepReport) => sleepReport.non_rem_mean_external_heartrate
  },
  externalSpO2Mean: {
    getValue: (sleepReport) => sleepReport.external_spo2_mean
  },
  epochsWithMovementPct: {
    getValue: (sleepReport) => sleepReport.epochs_with_movement_pct
  },
  lightAmbientMean: {
    getValue: (sleepReport) => sleepReport.light_ambient_mean
  },
  soundAmplitudeMean: {
    getValue: (sleepReport) => sleepReport.sound_amplitude_mean
  }
});

const emptyPlotData = () => {
  return Object.keys(options()).reduce((acc, currVal) => {
    return { ...acc, [currVal]: [] };
  }, {});
};

export const getSleepHistoryVitalsPlotData = (sessions) => {
  if (!sessions) {
    return null;
  }
  const newPlotSleepData = emptyPlotData();
  sessions.sort((a, b) => {
    const dateA = new Date(a?.start_time)?.toDateString();
    const dateB = new Date(b?.start_time)?.toDateString();
    return new Date(dateA) - new Date(dateB);
  });
  sessions.forEach((session, i) => {
    const sleepReport = session._embedded?.sleep_analysis?.report;
    if (sleepReport) {
      const timestamp = Number(moment.utc(sleepReport.session_start));
      Object.keys(options()).map((key) =>
        newPlotSleepData[key].push({
          x: timestamp,
          y: options()[key].getValue(sleepReport),
          ix: i
        })
      );
    }
  });

  let hasHeartRate = false;
  for (let i = 0; i < newPlotSleepData.nonREMMeanHeartRate.length; i += 1) {
    if (newPlotSleepData.nonREMMeanHeartRate[i].y != null) {
      hasHeartRate = true;
      break;
    }
  }
  if (!hasHeartRate) newPlotSleepData.nonREMMeanHeartRate = [];

  let hasExternalHeartRate = false;
  for (
    let i = 0;
    i < newPlotSleepData.nonREMMeanExternalHeartRate.length;
    i += 1
  ) {
    if (newPlotSleepData.nonREMMeanExternalHeartRate[i].y != null) {
      hasExternalHeartRate = true;
      break;
    }
  }
  if (!hasExternalHeartRate) newPlotSleepData.nonREMMeanExternalHeartRate = [];

  let hasExternalSpO2 = false;
  for (let i = 0; i < newPlotSleepData.externalSpO2Mean.length; i += 1) {
    if (newPlotSleepData.externalSpO2Mean[i].y != null) {
      hasExternalSpO2 = true;
      break;
    }
  }
  if (!hasExternalSpO2) newPlotSleepData.externalSpO2Mean = [];

  return {
    series: newPlotSleepData
  };
};
