import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
// @material-ui/core components
// core components
import { initReactI18next, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";

import { authenticate } from "../store/actions/auth";
import { setLanguage } from "../utils/localStorage";
import Router from "../services/utils/Router";
import pagesStyle from "../assets/jss/material-dashboard-pro-react/layouts/authStyle";

const SomnofyVideoSrc =
  "https://somnofy.ams3.cdn.digitaloceanspaces.com/Film/TEST-DENNE.mp4";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.routerRef = React.createRef();

    this.wrapper = React.createRef();

    this.changeLanguage = this.changeLanguage.bind(this);
    this.checkActiveLanguage = this.checkActiveLanguage.bind(this);
  }

  changeLanguage(lang) {
    const { i18n } = this.props;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }

  checkActiveLanguage(lang) {
    const { i18n, classes } = this.props;
    return i18n.language === lang ? classes.activeLang : classes.language;
  }

  render() {
    const { classes, ...rest } = this.props;
    const router = this.routerRef.current;

    return (
      <div>
        <div className={classes.wrapper} ref={this.wrapper}>
          <div className={classes.fullPage}>
              {/* <div className={classes.langWrapper}>
                <span
                  className={this.checkActiveLanguage("en")}
                  onClick={() => this.changeLanguage("en")}
                  aria-hidden="true"
                >
                  EN
                </span>
                <span className={classes.langDivider} />
                <span
                  className={this.checkActiveLanguage("nb")}
                  aria-hidden="true"
                  onClick={() => this.changeLanguage("nb")}
                >
                  NO
                </span>
              </div>  */}
            <Router layout="/auth" ref={this.routerRef} />
          </div>
        </div>
      </div>
    );
  }
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: !_.isEmpty(state.auth.user)
  };
};
export default withRouter(
  connect(mapStateToProps, { authenticate })(
    withStyles(pagesStyle)(withTranslation()(Auth))
  )
);
