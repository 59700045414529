import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";

import { AppRouteProps } from "interfaces/router.interface";
import { User } from "interfaces/user.interface";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    paddingTop: 0,
    background: theme.palette.white,
    width: "100%",
    float: "left"
  },
  tabWrapper: {
    background: theme.palette.secondary.grey,
    float: "left",
    width: "100%",
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    "&.Mui-selected": {}
  },
  tabs: {
    float: "left",
    cursor: "pointer",
    margin: "0 25px",
    padding: "26px 0",
    textAlign: "center",
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.primary.grey,
    "&:hover": {
      transition: "all .5s ease",
      color: theme.palette.dark
    }
  },
  active: {
    "& > span": {
      borderBottom: `2px solid ${theme.palette.dark}`
    },
    color: theme.palette.dark
  },
  tabContentWrapper: {
    padding: "0",
    width: "100%",
    float: "left",
    height: "100%"
  }
}));

function RouteTabs({
  routes,
  routeLabel,
  canView,
  hideTabs = false,
  isAdmin = true
}: {
  routes: AppRouteProps[];
  routeLabel: { [key: string]: string };
  canView?: (route: AppRouteProps) => boolean;
  hideTabs?: boolean;
  isAdmin?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const changeRoute = (path: string) => {
    history.push(path);
  };

  return (
    <div className={classes.root}>
      {!hideTabs && (
        <div className={classes.tabWrapper}>
          {routes.map((route: AppRouteProps) => {
            if (route.component && (canView ? canView(route) : true)) {
              return (
                <div
                  key={route.name}
                  className={`${classes.tabs} ${
                    location.pathname.includes(route.path) ? classes.active : ""
                  }`}
                  onClick={(e: any) => changeRoute(route.path)}
                  aria-hidden
                >
                  <span>{t(route.name)}</span>
                </div>
              );
            }
            return "";
          })}
        </div>
      )}

      <div className={classes.tabContentWrapper}>
        <Switch>
          {routes.map((route: AppRouteProps) => (
            <Route
              exact={route.exact}
              key={route.name}
              path={route.path}
              render={() => {
                const Component = route.component;
                return Component ? (
                  <Component />
                ) : (
                  <Redirect
                    to={
                      (!isAdmin
                        ? route.redirectPathIfNotAdmin
                        : route.redirectPath) || "/"
                    }
                  />
                );
              }}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}
export default RouteTabs;
