import { SMNotification } from "interfaces/notification.interface";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const RESET_NOTIFICATION_PRELOADERS = "RESET_NOTIFICATION_PRELOADERS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const BULK_DELETE_NOTIFICATION_SUCCESS =
  "BULK_DELETE_NOTIFICATION_SUCCESS";

export interface getNotificationAction {
  type: typeof GET_NOTIFICATION;
}
export interface getNotificationSuccessAction {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: SMNotification[];
}
export interface addNotificationSuccess {
  type: typeof ADD_NOTIFICATION_SUCCESS;
  payload: SMNotification;
}

export interface updateNotificationSuccess {
  type: typeof UPDATE_NOTIFICATION_SUCCESS;
  payload: SMNotification;
}

export interface deleteNotificationSuccess {
  type: typeof DELETE_NOTIFICATION_SUCCESS;
  payload: SMNotification;
}

export interface bulkDeleteNotificationSuccess {
  type: typeof BULK_DELETE_NOTIFICATION_SUCCESS;
  payload: string[];
}

export interface resetNotificationPreloaders {
  type: typeof RESET_NOTIFICATION_PRELOADERS;
}

export type notificationActionTypes =
  | getNotificationAction
  | getNotificationSuccessAction
  | addNotificationSuccess
  | resetNotificationPreloaders
  | updateNotificationSuccess
  | deleteNotificationSuccess
  | bulkDeleteNotificationSuccess;
