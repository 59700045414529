import React from "react";

import {
  NightReportItem,
  NightReportItemMetric
} from "interfaces/nightReport.interface";
import {
  getNightReportValueDifference,
  getRoundedNightReportValue
} from "utils/report.util";

import ErrorIcon from "./NightReportErrorIcon";

function NightReportListRow({
  item,
  classes,
  t,
  openDashBoardPopup
}: {
  item: NightReportItem;
  classes: any;
  t: any;
  openDashBoardPopup?: (item: NightReportItem) => void;
}) {
  const getTimeAsleep = (seconds: number): string => {
    if (!seconds) {
      return "";
    }
    const HH = Math.floor(seconds / 3600);
    const MM = Math.floor(seconds / 60) % 60;
    return `${HH}h ${MM}m`;
  };

  const hasAlert = () => {
    if (item.respiration_rate?.deviation_alert) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`${classes.row} ${classes.evenChildBackground} ${
        hasAlert() ? "warningBg" : ""
      } }`}
      aria-hidden
      onClick={() => (openDashBoardPopup ? openDashBoardPopup(item) : "")}
    >
      <div className={classes.col}>{item?.device?.name}</div>
      <div className={classes.col}>{item?.user?.display_name}</div>
      <div className={classes.col}>
        <TableData item={item.respiration_rate} pct t={t} round={0} />
      </div>
      <div className={classes.col}>
        <TableData item={item.movement} pct t={t} round={0} />
      </div>
      <div className={classes.col}>
        {item.time_asleep?.deviation_alert ? <ErrorIcon /> : ""}
        {getTimeAsleep(item?.time_asleep?.value) || t("N/A")}
        {item?.time_asleep?.deviation_pct
          ? ` (${item?.time_asleep?.deviation_pct > 0 ? "+" : ""}${Math.round(
              +item?.time_asleep?.deviation_pct
            )}%)`
          : ""}
      </div>
      <div className={`${classes.col} ${classes.txtRight}`}>
        <TableData
          item={item.sleep_score}
          pct={false}
          baseline
          t={t}
          round={0}
          valueRound
        />
      </div>
      <div className={`${classes.col} ${classes.txtRight}`}>
        <TableData
          item={item.out_of_bed}
          pct={false}
          baseline
          t={t}
          round={1}
        />
      </div>
      <div className={`${classes.col} ${classes.txtCenter} `}>
        {item.selected_session_is_in_progress ? t("Yes") : t("No")}
      </div>
      <div className={`${classes.col} ${classes.txtCenter} `}>
        {item.baseline_night_count ? item.baseline_night_count : t("N/A")}
      </div>
    </div>
  );
}

function TableData({
  item,
  pct,
  t,
  round = -1,
  baseline = false,
  valueRound = false
}: {
  item?: NightReportItemMetric;
  pct: boolean;
  t: any;
  round?: number;
  baseline?: boolean;
  valueRound?: boolean;
}) {
  if (!item) {
    return t("N/A");
  }

  return (
    <>
      {item?.deviation_alert ? <ErrorIcon /> : ""}
      {getRoundedNightReportValue(item?.value, valueRound)}
      {getNightReportValueDifference({ item, baseline, round, pct })}
    </>
  );
}

export default NightReportListRow;
