import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Device } from "interfaces/device.interface";
import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { filterUserGroup } from "utils/groupManagement";
import { generateBirthYearOptions } from "utils/userManagement";
import { getDeviceUser } from "utils/deviceManagement";
import { toastError } from "utils/toast.util";
import AutoSuggestSelect from "components/AutoSuggestSelect/AutoSuggestSelect";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import SMButton from "components/SMButton/SMButton";
import SMCustomSelect from "components/SMCustomSelect/SMCustomSelect";
import SMTextField from "components/SMTextField/SMTextField";
import UserListSaveCancelButtons from "components/UserListActionButtons/UserListSaveCancelButtons";

function UserListRowModeEdit({
  user,
  commonCls,
  history,
  filterType,
  searchQuery,
  devicesEntityWithUserID,
  groupEntity,
  selectedItems,
  setSelectedItems,
  onSaveAction,
  setEditUser,
  editUser,
  t,
  loginedUser
}: {
  commonCls: any;
  history: any;
  filterType: string;
  searchQuery: string;
  user: User;
  devicesEntityWithUserID: { [key: string]: Device };
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  t: (key: string) => string;
  onSaveAction: (updatedUser: User, currentUserGroupsIds: string[]) => void;
  setEditUser: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  editUser: { [key: string]: boolean };
  loginedUser: User;
}) {
  const [updatedUserObj, setUpdatedUserObj] = useState<User>(_.cloneDeep(user));
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onChange = (value: any, key: string) => {
    const updatedUser: User = {
      ...user,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;
    setUpdatedUserObj(updatedUser);
  };

  const getDefaultGroupValues = () => {
    return updatedUserObj.user_groups?.map((id: string) => groupEntity[id]);
  };

  const saveUser = () => {
    setErrorMessage("");
    if (!user.first_name) {
      setErrorMessage(t("User ID is missing. Please enter User ID"));
      // show toast
      toastError(t("User ID is missing. Please enter User ID"));
    } else {
      onSaveAction(updatedUserObj, user.user_groups || []);
    }
  };

  return (
    <div className={`${commonCls.evenChildBackground} ${commonCls.lightBlue}`}>
      <div className={`${commonCls.row}`}>
        <BulkActionCheckBox
          wrapperClassName={commonCls.checkboxlistWraper}
          id={user.id}
          selected={selectedItems}
          setSelected={setSelectedItems}
        />
        <div className={`${commonCls.col}`}>
          <SMTextField
            placeholder={t("User ID")}
            onSave={(val: string) => {
              onChange(val, "first_name");
            }}
            value={updatedUserObj.first_name}
            delay={0}
            width="100%"
            borderRed={(errorMessage && !updatedUserObj.first_name) || false}
            autoFocus
          />
        </div>
        <div className={commonCls.col}>
          {getDeviceUser(devicesEntityWithUserID, updatedUserObj).map(
            (device: Device) => {
              return (
                <div key={device.serial_number} className={commonCls.textList}>
                  {device.name}
                  <span
                    className={commonCls.italicsStyle}
                  >{` (${device.serial_number})`}</span>
                </div>
              );
            }
          )}
        </div>
        <div className={commonCls.col}>
          <SMCustomSelect
            optionsList={
              filterUserGroup({
                type: UserGroupType.device,
                userGroups: Object.values(groupEntity)
              }) as any[]
            }
            selectedList={getDefaultGroupValues() as any[]}
            appendText={t("groups selected")}
            onChange={(options: any[]) => {
              const userGroups = options.map((grp: Group) => grp.id);
              onChange(userGroups, "user_groups");
            }}
            labels={{
              txtShowMore: t("Show All"),
              txtShowLess: t("Show Less"),
              txtSelectClearToggle: t("Select All / Clear All"),

              txtSelected: t("Selected"),
              txtNotSelected: t("Not Selected")
            }}
          />
        </div>
        <div className={commonCls.col}>
          <RadioGroup
            value={updatedUserObj.gender}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value as string, "gender")
            }
          >
            <FormControlLabel
              value="Male"
              control={<Radio />}
              label={t("Male")}
            />
            <FormControlLabel
              value="Female"
              control={<Radio />}
              label={t("Female")}
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label={t("Other")}
            />
          </RadioGroup>
        </div>
        <div className={commonCls.col}>
          <AutoSuggestSelect
            defaultValue={`${updatedUserObj.birth_year}`}
            options={generateBirthYearOptions()}
            onSelect={(value: string) => onChange(value, "birth_year")}
            width="100%"
          />
        </div>
        <div className={commonCls.col} />
      </div>
      <UserListSaveCancelButtons
        onCancel={() => {
          setEditUser({ ...editUser, [updatedUserObj.id]: false });
        }}
        onSave={saveUser}
        txtCancel={t("Cancel")}
        txtSave={t("Save")}
        classes={commonCls}
      />
    </div>
  );
}
export default UserListRowModeEdit;
