import * as PropTypes from "prop-types";
import React from "react";

import SessionDetailsComponent from "./SessionDetails";

function SessionDetails(props) {
  return props.options ? <SessionDetailsComponent {...props} /> : null;
}
SessionDetails.propTypes = {
  options: PropTypes.shape({
    sleepScoreOptions: PropTypes.object,
    sleepStagesOptions: PropTypes.object,
    sleepStatisticsOptions: PropTypes.object,
    title: PropTypes.string
  }).isRequired
};

export default SessionDetails;
