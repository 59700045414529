import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { AppRouteProps } from "interfaces/router.interface";
import { Device } from "interfaces/device.interface";
import { Loading } from "components";
import { NOTIFICATION_TYPES } from "constants/notificationContstants";
import { SMNotificationLog } from "interfaces/notification.interface";
import { getAllDevices } from "store/actions/devicesActions";
import { getAllNotificationsLog } from "store/actions/notificationsLogActions";
import CommonStyles from "theme/CommonStyles";
import SomnofySwitchRoute from "components/SomnofySwitchRoute/SomnofySwitchRoute";

import NotificationLogDetails from "./notificationLogListView/components/NotificationLogDetails";
import NotificationLogList from "./notificationLogListView/NotificationLogList";
import notificationLogRoutes, {
  notificationLogRouteNames
} from "./notificationLog.routes";

/**
 * @name NotificationLog Management
 * @description NotificationLog management component
 * @returns NotificationLog component
 */
function NotificationLogContainer() {
  const common = CommonStyles();
  const dispatch = useDispatch();

  const [notificationLogEntity, setNotificationLogEntity] = useState<{
    [key: string]: SMNotificationLog;
  }>({});

  const notitificationsLogs: SMNotificationLog[] = useSelector((state: any) => {
    return state?.notificationLogReducer.notificationsLog || [];
  });

  const [deviceEntity, setDeviceEntity] = useState<{ [key: string]: Device }>();

  /**
   * React device from state.
   *
   * @return users
   */
  const devices: Device[] = useSelector((state: any) => {
    return state?.deviceReducer.devices || [];
  });

  /**
   * React hook to fetch program info data
   *
   * @param {dispatch} dispatch user object
   * @return dispatch action.
   */
  useEffect(() => {
    dispatch(getAllDevices());
  }, []);

  /**
   * Create normalized devices
   *
   * @param {devices} devices object
   * @return {deviceEntity} create a normalized devices object
   */
  useEffect(() => {
    if (devices.length) {
      const normalizedDevice: any = {};
      for (let i = 0; i < devices.length; i += 1) {
        normalizedDevice[devices[i].serial_number] = devices[i];
      }
      // device entity
      setDeviceEntity(normalizedDevice);
    }
  }, [devices]);
  useEffect(() => {
    if (notitificationsLogs.length) {
      const normalizedNotificationsLog: any = {};
      for (let i = 0; i < notitificationsLogs.length; i += 1) {
        normalizedNotificationsLog[notitificationsLogs[i].id] =
          notitificationsLogs[i];
      }
      // notification entity
      setNotificationLogEntity(normalizedNotificationsLog);
    }
  }, [notitificationsLogs]);

  useEffect(() => {
    dispatch(getAllNotificationsLog());
  }, []);

  const getRender = (props: any, currentRoute: AppRouteProps): JSX.Element => {
    if (
      currentRoute.name === notificationLogRouteNames.usersLog ||
      currentRoute.name === notificationLogRouteNames.devicesLog
    ) {
      const notificationType =
        currentRoute.name === notificationLogRouteNames.usersLog
          ? NOTIFICATION_TYPES.USER_RULES
          : NOTIFICATION_TYPES.DEVICE_RULES;
      return (
        <NotificationLogList
          type={notificationType}
          notificationLogEntity={notificationLogEntity}
          deviceEntity={deviceEntity || {}}
        />
      );
    }
    if (
      currentRoute.name === notificationLogRouteNames.devicesLogDetails ||
      currentRoute.name === notificationLogRouteNames.usersLogDetails
    ) {
      const notificationType =
        currentRoute.name === notificationLogRouteNames.usersLogDetails
          ? NOTIFICATION_TYPES.USER_RULES
          : NOTIFICATION_TYPES.DEVICE_RULES;
      return (
        <NotificationLogDetails
          routePath={props?.match?.url}
          type={notificationType}
          notificationLogEntity={notificationLogEntity}
          id={props.match.params.id}
          deviceEntity={deviceEntity || {}}
        />
      );
    }
    return <Loading />;
  };

  return (
    <SomnofySwitchRoute
      routes={notificationLogRoutes}
      renderFunction={getRender}
      wrapperClass={`${common.greyBackground} ${common.floatLeft} ${common.width100}`}
    />
  );
}

export default NotificationLogContainer;
