import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { NavLink, useHistory } from "react-router-dom";
import React from "react";

import { AppChildMenuProps, AppMenuProps } from "interfaces/router.interface";
import { ReactComponent as Logo } from "assets/icons/somnofy-logo.svg";

interface Props {
  routes: AppMenuProps[];
  toggleMenu: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  header: {
    paddingTop: 32,
    paddingLeft: 32,
    float: "left",
    display: "block",
    width: "100%"
  },
  logo: {
    marginTop: 32
  },
  drawerPaper: {
    background: theme.palette.primary.dark,
    width: 300,
    zIndex: 700
  },
  root: {
    marginTop: 88,
    float: "left",
    width: "100%"
  },
  itemIcon: {
    color: "rgba(255, 255, 255, 0.74)",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "16px",
    textAlign: "center",
    verticalAlign: "middle"
  },
  menu: {
    padding: "18px 32px 18px 32px",
    "&:hover": {
      background: "#4B92A1",
      transition: "all 300ms linear"
    }
  },
  menuText: {
    color: "rgba(255, 255, 255, 0.74)",
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 500,
    lineHeight: "16px",
    "&.MuiTypography-body1": {
      color: "rgba(255, 255, 255, 0.74)",
      fontSize: 16,
      textTransform: "uppercase",
      fontWeight: 500,
      lineHeight: "16px"
    }
  },
  menuTextRoot: {
    margin: 0
  },
  itemIconRoot: {
    minWidth: "auto"
  },
  activeRoute: {
    background: theme.palette.primary.darkGreen,
    "&:hover": {
      background: theme.palette.primary.darkGreen,
      transition: "all 300ms linear"
    }
  },
  nested: {},
  activeChilRoute: {
    background: "#4B92A1",
    transition: "all 300ms linear"
  },
  arrow: {
    color: "rgba(255, 255, 255, 0.74)",
    position: "absolute",
    right: 32
  },
  navLink: {
    textDecoration: "none",
    "&,&:hover,&:focus": {
      color: "inherit"
    }
  },
  childNavLink: {}
}));

/*
    Sidebar
*/
function Sidebar({ routes, toggleMenu }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});

  const handleClick = (route: AppMenuProps) => {
    let updatedState = { ...open };
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].views && routes[i].views?.length) {
        updatedState[routes[i].name] = false;
      }
    }
    updatedState = { ...updatedState, [route.name]: !open[route.name] };
    setOpen(updatedState);
  };
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={toggleMenu}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Brand classes={classes} />
      <div>
        <List component="nav" className={classes.root}>
          {routes.map((route: AppMenuProps) => {
            return (
              <SMNavLink
                classes={classes}
                route={route}
                key={route.slug}
                location={history.location}
                open={open}
                handleClick={handleClick}
              />
            );
          })}
        </List>
      </div>
    </Drawer>
  );
}

const Brand = ({ classes }: { classes: any }) => {
  return (
    <header className={classes.header}>
      <NavLink to="/" className={classes.logo}>
        <Logo />
      </NavLink>
    </header>
  );
};

/*
    Single Navigation menu
*/
const SMNavLink = ({
  classes,
  route,
  location,
  open,
  handleClick
}: {
  classes: any;
  route: AppMenuProps;
  location: any;
  open: { [key: string]: boolean };
  handleClick: (route: AppMenuProps) => void;
}) => {
  const { views } = route;
  const collapseChild = (e: any) => {
    if (views?.length) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <NavLink
        to={route.layout + route.path}
        className={classes.navLink}
        onClick={collapseChild}
        key={route.name}
      >
        <ListItem
          button
          className={`${classes.menu} ${
            location.pathname.indexOf(route.path) > -1
              ? classes.activeRoute
              : ""
          }`}
          onClick={() => handleClick(route)}
        >
          <ListItemIcon className={classes.itemIconRoot}>
            {route.icon ? <route.icon className={classes.itemIcon} /> : ""}
          </ListItemIcon>
          <ListItemText
            primary={route.name}
            classes={{ primary: classes.menuText, root: classes.menuTextRoot }}
          />
          {views && views.length ? (
            <ExpandCollapseIcon
              views={views}
              open={open}
              route={route}
              classes={classes}
            />
          ) : (
            ""
          )}
        </ListItem>
      </NavLink>

      {/* Child navigation  */}
      {views && views.length ? (
        <ChildNavLink
          views={views}
          open={open}
          classes={classes}
          route={route}
          location={location}
        />
      ) : (
        ""
      )}
    </div>
  );
};

/*
    Child Navigation menu
*/
const ChildNavLink = ({
  views,
  open,
  classes,
  route,
  location
}: {
  views: AppChildMenuProps[];
  open: { [key: string]: boolean };
  classes: any;
  route: AppMenuProps;
  location: any;
}) => {
  return (
    <Collapse in={open[route.name]} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {" "}
        {views.map((childRoute: AppChildMenuProps) => {
          return (
            <NavLink
              to={childRoute.layout + childRoute.path}
              className={`${classes.navLink} ${classes.childNavLink}`}
              key={childRoute.path}
            >
              <ListItem
                button
                className={`${classes.nested} ${
                  location.pathname.indexOf(childRoute.path) > -1
                    ? classes.activeChilRoute
                    : ""
                }`}
              >
                <ListItemText
                  primary={childRoute.name}
                  className={classes.menuText}
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    </Collapse>
  );
};

/*
    Expand Collapse icon
*/
const ExpandCollapseIcon = ({
  views,
  open,
  route,
  classes
}: {
  views: AppChildMenuProps[];
  open: { [key: string]: boolean };
  route: AppMenuProps;
  classes: any;
}) => {
  return (
    <>
      {views && views.length ? (
        <>
          {" "}
          {open[route.name] ? (
            <ExpandLess className={classes.arrow} />
          ) : (
            <ExpandMore className={classes.arrow} />
          )}{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default Sidebar;
