import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import { UserGroupType } from "constants/userGroupConstants";

export const useStyles = makeStyles((theme: any) => ({
  iconSpan: {
    "& > svg": {
      transform: "rotate(272deg)"
    }
  },
  wrapper: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    marginBottom: 27,
    float: "left",
    width: "100%",
    textTransform: "capitalize"
  },
  breadcrump: {
    float: "left",
    cursor: "pointer"
  }
}));

function BreadCrumb({ text }: { text: string }) {
  const classes = useStyles();
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.wrapper}>
      <div aria-hidden onClick={() => goBack()} className={classes.breadcrump}>
        <div>
          <span>{text}</span>
          <span className={classes.iconSpan}>
            <DownArrowOutline />
          </span>
        </div>
      </div>
    </div>
  );
}
export default BreadCrumb;
