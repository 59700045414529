import * as types from "../actionTypes/reportActionTypes";

export default function reportReducer(
  state: any = {},
  action: types.nightReportActionTypes
) {
  switch (action.type) {
    case types.FETCH_NIGHT_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload || { items: [] },
        currentAction: "success"
      };
    default:
      return state;
  }
}
