import React, { useEffect, useRef, useState } from "react";

import {
  NightReportItem,
  NightReportItemStatus
} from "interfaces/nightReport.interface";
import CommonStyles from "theme/CommonStyles";
import SMPagination from "components/SMPagination/SMPagination";

import NightReportListRow from "./NightReportListRow";
import NightReportListRowNoPresence from "./NightReportListRowNoPresence";
import useStyles from "../nightReport.style";

function NightReportView({
  nightReportItems,
  t,
  itemsPerPage,
  openDashBoardPopup
}: {
  nightReportItems: NightReportItem[];
  t: any;
  itemsPerPage: number;
  openDashBoardPopup?: (item: NightReportItem) => void;
}) {
  const classes = useStyles();
  const commonCls = CommonStyles();

  const [currentReportItems, setCurrentReportItems] =
    useState<NightReportItem[]>();
  const [activePage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    setItems();
  }, [...nightReportItems]);

  useEffect(() => {
    setItems();
  }, [activePage, itemsPerPage]);

  // useCustomArrayEffect(() => {
  //     setItems();
  // }, [nightReportItems], (a, b) => arrayEqual(a[0], b[0]));

  const setItems = () => {
    if (nightReportItems) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentReportItems(
        nightReportItems.slice(indexOfFirstItem, indexOfLastItem)
      );
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div
        className={commonCls.rowContainer}
        style={{ borderBottom: "1px solid #212121" }}
      >
        {currentReportItems &&
          currentReportItems.map((item: NightReportItem, index) => {
            if (
              item.status !== NightReportItemStatus.NO_PRESENCE &&
              item.status !== NightReportItemStatus.NO_SLEEP
            ) {
              return (
                <NightReportListRow
                  item={item}
                  classes={commonCls}
                  key={`${item.user.id}`}
                  t={t}
                  openDashBoardPopup={openDashBoardPopup}
                />
              );
            }
            return (
              <NightReportListRowNoPresence
                item={item}
                classes={commonCls}
                key={`${item.user.id}`}
                t={t}
                customClases={classes}
                message={
                  item.status === NightReportItemStatus.NO_PRESENCE
                    ? t("User not present or data not available")
                    : t("No sleep data available")
                }
              />
            );
          })}
      </div>

      {nightReportItems && nightReportItems?.length > itemsPerPage ? (
        <SMPagination
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          itemsLength={nightReportItems?.length}
          handlePageChange={handlePageChange}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default NightReportView;
