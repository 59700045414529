import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import PropTypes from "prop-types";
import React from "react";

const styles = () => {
  return {
    root: {}
  };
};

const SleepStatisticsComponent = (props) => {
  const { options, elements } = props;
  const { rows } = options;
  const thirdRowItemsLength =
    elements && elements.length > 6 ? elements.slice(6).length : 3;
  return (
    <Grid container spacing={3} direction="row">
      {elements.slice(0, 6).map((el, i) => {
        // Todo (fr@vitalthings.com)
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={i} item xs={parseInt(12 / (rows || 3), 10)}>
            <el.component {...el.args} />
          </Grid>
        );
      })}

      {elements.slice(6).map((el, i) => {
        return (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            item
            xs={thirdRowItemsLength === 2 ? 6 : 4}
          >
            <el.component {...el.args} />
          </Grid>
        );
      })}
    </Grid>
  );
};

SleepStatisticsComponent.propTypes = {
  options: PropTypes.any,
  elements: PropTypes.any
};

export default withStyles(styles)(SleepStatisticsComponent);
