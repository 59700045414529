import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import axios from "axios";
import qs from "query-string";

import { API_URL } from "constants/constants";
import { AppMenuProps } from "interfaces/router.interface";
import { User } from "interfaces/user.interface";
import { fetchAllTheUsers } from "store/actions/userActions";
import { getToken } from "utils/localStorage";
import { logOut } from "store/actions/auth";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";

import {
  containerFluid,
  drawerWidth,
  transition
} from "../assets/jss/material-dashboard-pro-react";
import Router, { populateWithUsers } from "../services/utils/Router";
import rawRoutes from "../services/utils/routes";

let token;
if ((token = getToken())) {
  axios.defaults.headers.common.Authorization = `Basic ${token}`;
}
axios.defaults.responseType = "json";
axios.defaults.baseURL = API_URL;
axios.defaults.paramsSerializer = (params) => qs.stringify(params);

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100%",
    background: "#FFF",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: ({ toggleMenu }: { toggleMenu: boolean }) =>
      toggleMenu ? `calc(100% - ${drawerWidth}px)` : "100%",
    overflow: "auto",
    position: "relative",

    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",

    float: "left",
    minWidth: 980,
    marginLeft: ({ toggleMenu }: { toggleMenu: boolean }) =>
      toggleMenu ? `${drawerWidth}px` : "0"
  },
  content: {
    minHeight: "calc(100vh - 123px)"
  },
  container: {
    ...containerFluid,
    position: "relative"
  },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${0}px)`
    }
  }
}));

function SMDashboard() {
  const [toggleMenu, setToggleMenu] = React.useState<boolean>(true);
  const classes = useStyles({ toggleMenu });
  const dispatch = useDispatch();
  const history = useHistory();

  const mainPanelRef = useRef<any>(null);
  const routerRef: React.RefObject<any> = useRef<any>(null);
  const [routes, setRoutes] = React.useState<AppMenuProps[]>([]);

  const usersState = useSelector((state: any) => {
    return state?.users;
  });

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  useEffect(() => {
    dispatch(fetchAllTheUsers());
  }, [dispatch]);

  const logout = () => {
    logOut();
    history.push("/");
  };

  useEffect(() => {
    if (usersState?.users?.length) {
      const adminRoutes = rawRoutes.filter((route) => route.layout === "/app");
      const populatedRoutes = populateWithUsers(
        [...adminRoutes],
        usersState.users
      );
      setRoutes(populatedRoutes);
    }
  }, [usersState, loginedUser]);

  const filterRoutes = (appRoutes: AppMenuProps[]) => {
    const isAdmin = loginedUser
      ? loginedUser.attributes?.user_type !== "viewer"
      : false;
    const privileges = loginedUser?.attributes?.privileges || [
      "view_monitor",
      "view_dashbaord"
    ];
    const restrictedPages = ["notification", "administration"];
    if (appRoutes && appRoutes.length) {
      if (!isAdmin) {
        let filteredRoute = appRoutes;

        filteredRoute = filteredRoute.filter((item: AppMenuProps) => {
          return restrictedPages.indexOf(item.slug) === -1;
        });

        if (Array.isArray(privileges)) {
          if (privileges.indexOf("view_monitor") === -1) {
            filteredRoute = filteredRoute.filter((item: AppMenuProps) => {
              return item.slug !== "monitor";
            });
          }

          if (privileges.indexOf("view_dashbaord") === -1) {
            filteredRoute = filteredRoute.filter((item: AppMenuProps) => {
              return item.slug !== "dashboard" && item.slug !== "reporting";
            });
          }
        }
        return filteredRoute;
      }
      return appRoutes;
    }
    return [];
  };

  const funcToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar routes={filterRoutes(routes)} toggleMenu={toggleMenu} />
      <div className={classes.mainPanel} ref={mainPanelRef}>
        <AdminNavbar
          onLogout={logout}
          loginedUser={loginedUser}
          funcToggleMenu={funcToggleMenu}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Router layout="/app" ref={routerRef} />
          </div>
          <div style={{ clear: "both" }}> </div>
        </div>
      </div>
    </div>
  );
}

export default SMDashboard;
