import axios from "axios";

import { API_SERVICES } from "../../constants/constants";
import { handleError, handleResponse } from "../responseHandler";

export const reportServices = {
  fetchNightReport
};

// Function to fetch night report
async function fetchNightReport() {
  const url = API_SERVICES.fetchNightReport;
  return axios({
    method: "get",
    url,
    timeout: 30000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
