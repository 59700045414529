import { withStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import ResizeDetector from "react-resize-detector";

import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);
class SleepStagesChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    return (
      <ResizeDetector
        handleWidth
        onResize={() =>
          this.chartRef.current
            ? this.chartRef.current.chart.reflow()
            : undefined
        }
      >
        <HighchartsReact
          ref={this.chartRef}
          highcharts={Highcharts}
          constructorType="chart"
          options={getPlotOptions(this.props)}
        />
      </ResizeDetector>
    );
  }
}

const getPlotOptions = ({ plotData, theme }) => {
  const colorType = theme.palette.type;
  const { series } = plotData;
  return {
    chart: {
      backgroundColor: "transparent",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    },
    legend: {
      itemStyle: { color: theme.palette.chart.legend.selected[colorType] },
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType]
      },
      itemHoverStyle: { color: theme.palette.chart.legend.hover[colorType] }
    },
    title: { text: null },
    credits: { enabled: false },
    tooltip: { pointFormat: "<b>{point.percentage:.1f}%</b>" },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.1f}%</b>",
          distance: -40,
          style: {
            color:
              (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black",
            fontSize: 18
          }
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: "Sleep Stages",
        colorByPoint: true,
        data: [
          {
            name: t("Wake"),
            color: "rgba(139, 0, 0, 1)",
            y: series.timeAwakeAfterSleepOnset
          },
          {
            name: t("REM"),
            color: "rgba(255, 140, 0, 1)",
            y: series.timeInREMSleep
          },
          {
            name: t("Light"),
            color: "rgba(173, 216, 230, 1)",
            y: series.timeInLightSleep
          },
          {
            name: t("Deep"),
            color: "rgba(0, 0, 139, 1)",
            y: series.timeInDeepSleep
          },
          {
            name: t("No Presence"),
            color: "grey",
            visible: false,
            y: series.timeInNoPresence
          }
        ]
      }
    ]
  };
};

export default withStyles({}, { withTheme: true })(SleepStagesChart);
