import { blue, grey, indigo, orange, red } from "@material-ui/core/colors";

const theme = {
  palette: {
    type: "light",
    primary: {
      main: "#448AFF",
      dark: "#457E89",
      grey: "rgba(0, 0, 0, 0.6)", // in use,
      listHeader: "#212121", // in use - rgba(0, 0, 0, 0.87)
      lightGreen: "#CFDDE0", // in use
      text: "#414141",
      input: "#666666", // in use,,
      red: "#B00020", // in use,,
      black: "#666666", // in use,
      darkGreen: "#395A5F", // in use,
      green: "#4B92A1",
      lightBlue: "#E3F6F3"
    },
    secondary: {
      main: "#1EB980",
      grey: "#EDEDED", // currently in use,
      lightGrey: "#DFE0E0",
      lightBlue: "#E5F5FA" // currently in use,
    },
    blue: "#05ACC1",
    black: "#000000",
    white: "#FFF",
    red: "#A50520", // in use
    dark: "#457E89", // in use
    error: red,
    chart: {
      legend: {
        selected: {
          light: grey["900"],
          dark: grey["100"]
        },
        unselected: {
          light: grey["400"],
          dark: grey["600"]
        },
        hover: {
          light: grey["400"],
          dark: grey["600"]
        }
      },
      sleepHistory: {
        deep: {
          light: "",
          dark: ""
        },
        light: {
          light: "",
          dark: ""
        },
        rem: {
          light: "",
          dark: ""
        },
        awakeAfterSleepOnset: {
          light: "",
          dark: ""
        },
        sleepOnset: {
          light: "",
          dark: ""
        }
      },
      sleepStages: {
        wake: {
          light: red["900"],
          dark: red["900"]
        },
        rem: {
          light: orange["700"],
          dark: orange["700"]
        },
        light: {
          light: blue["100"],
          dark: blue["100"]
        },
        deep: {
          light: indigo["700"],
          dark: indigo["700"]
        },
        noPresence: {
          light: grey["400"],
          dark: grey["400"]
        }
      }
    }
  }
};

export default theme;
