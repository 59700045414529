import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";

import SMTextField from "components/SMTextField/SMTextField";

export const useStyles = makeStyles((theme: any) => ({
  dialog: {
    padding: "8px",
    "& .MuiPaper-root": {
      padding: 8
    }
  },
  dialogLabel: {
    fontSize: 16,
    color: theme.palette.primary.red,
    fontWeight: 500,
    textDecoration: "none",
    textTransform: "none"
  },
  actionButtons: {
    color: theme.palette.primary.dark,
    textTransform: "uppercase"
  },
  dialogTitle: {
    paddingBottom: 0,
    "& h2": {
      fontSize: 20,
      color: theme.palette.primary.red,
      fontWeight: 500
    }
  },
  dialogContent: {
    paddingTop: 0,
    fontSize: 16,
    color: theme.palette.primary.listHeader,
    marginBottom: 32,
    paddingBottom: 0
  },
  dialogContentText: {
    margin: "16px 0",
    float: "left",
    "& span": {
      color: theme.palette.primary.red
    }
  }
}));

function DialogBox({
  open,
  setOpen,
  dialogDesc,
  title,
  onDelete,
  confirmPlaceHolder,
  confirmTextDesc,
  confirmText,
  buttonOk,
  buttonCancel
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  dialogDesc: string;
  onDelete: () => void;
  confirmPlaceHolder: string;
  confirmTextDesc: string;
  confirmText: string;
  buttonOk: string;
  buttonCancel: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirm, setConfirm] = useState<string>("");
  const handleClose = () => {
    setOpen(false);
    setConfirm("");
  };

  const handleDelete = () => {
    handleClose();
    if (confirm === t("I AM SURE")) {
      onDelete();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          {dialogDesc}
        </DialogContentText>
        <DialogContentText
          className={classes.dialogContentText}
          dangerouslySetInnerHTML={{
            __html: confirmTextDesc
          }}
        />
        <SMTextField
          placeholder={confirmPlaceHolder}
          onSave={(val: string) => setConfirm(val)}
          value={confirm}
          delay={0}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.actionButtons}>
          {buttonCancel}
        </Button>
        <Button
          onClick={() => (confirm === confirmText ? handleDelete() : null)}
          className={classes.actionButtons}
          style={{
            opacity: confirm === confirmText ? 1 : 0.5,
            cursor: confirm === confirmText ? "pointer" : "not-allowed"
          }}
        >
          {buttonOk}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DialogBox;
