import { Autocomplete } from "@material-ui/lab";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";

import { Group } from "interfaces/group.interface";
import { User } from "interfaces/user.interface";
import { UserGroupType } from "constants/userGroupConstants";
import { UserPrivileges } from "constants/userContstants";
import { filterUserGroup } from "utils/groupManagement";
import { privillagesCheckBoxChecked } from "utils/userManagement";
import { toastError } from "utils/toast.util";
import BulkActionCheckBox from "components/BulkActionCheckBox/BulkActionCheckBox";
import SMButton from "components/SMButton/SMButton";
import SMCustomSelect from "components/SMCustomSelect/SMCustomSelect";
import SMTextField from "components/SMTextField/SMTextField";

interface Props {
  commonCls: any;
  user: User;
  t: any;
  groupEntity: { [key: string]: Group };
  selectedItems: { [key: string]: boolean };
  setSelectedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  editEmployee: { [key: string]: boolean };
  setEditEmployee: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  onSaveAction: (updatedEmp: User, currentUserGroupsIds: string[]) => void;
}

function EmployeeListRowEdit({
  commonCls,
  user,
  groupEntity,
  t,
  selectedItems,
  setSelectedItems,
  editEmployee,
  setEditEmployee,
  onSaveAction
}: Props) {
  const [updatedEmpObj, setUpdatedEmpObj] = useState<User>(_.cloneDeep(user));
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onChange = (value: any, key: string) => {
    const updatedUser: User = {
      ...user,
      [key]: value
    };
    updatedUser.display_name = `${updatedUser.first_name} ${updatedUser.last_name}`;
    setUpdatedEmpObj(updatedUser);
  };

  const getDefaultGroupValues = () => {
    return (
      updatedEmpObj.user_groups?.map((id: string) => groupEntity[id]) || []
    );
  };

  const saveUser = () => {
    setErrorMessage("");
    if (!user.first_name) {
      setErrorMessage(t("User ID is missing. Please enter User ID"));
      // show toast
      toastError(t("User ID is missing. Please enter User ID"));
    } else {
      onSaveAction(updatedEmpObj, user.user_groups || []);
    }
  };
  return (
    <div className={`${commonCls.lightBlue}`}>
      <div className={`${commonCls.row}`} aria-hidden>
        <BulkActionCheckBox
          wrapperClassName={commonCls.checkboxlistWraper}
          id={user.id}
          selected={selectedItems}
          setSelected={setSelectedItems}
        />
        <div className={`${commonCls.col}`}>
          <div className={`${commonCls.marginBottom10}`}>
            <SMTextField
              placeholder={t("e.g. Kari")}
              onSave={(val: string) => {
                onChange(val, "first_name");
              }}
              value={updatedEmpObj.first_name}
              delay={0}
              width="100%"
              borderRed={(errorMessage && !updatedEmpObj.first_name) || false}
            />
          </div>
          <div>
            <SMTextField
              placeholder={t("Nordmann")}
              onSave={(val: string) => {
                onChange(val, "last_name");
              }}
              value={updatedEmpObj.last_name}
              delay={0}
              width="100%"
              borderRed={(errorMessage && !updatedEmpObj.last_name) || false}
            />
          </div>
        </div>

        <div className={`${commonCls.col}`}>
          <SMTextField
            onSave={(val: string) => {
              onChange(val, "email");
            }}
            value={user.email}
            delay={0}
            borderRed={(errorMessage && !user.email) || false}
            width="100%"
            placeholder={t("e.g. Kari.Nordmann@norge.kommune.no")}
          />
        </div>
        <div className={`${commonCls.col}`}>
          <SMCustomSelect
            optionsList={
              filterUserGroup({
                type: UserGroupType.viewer,
                userGroups: Object.values(groupEntity)
              }) as any[]
            }
            selectedList={getDefaultGroupValues() as any[]}
            appendText="groups selected"
            onChange={(options: any[]) => {
              const userGroups = options.map((grp: Group) => grp.id);
              onChange(userGroups, "user_groups");
            }}
            labels={{
              txtShowMore: t("Show All"),
              txtShowLess: t("Show Less"),
              txtSelectClearToggle: t("Select All / Clear All"),
              txtSelected: t("Selected"),
              txtNotSelected: t("Not Selected")
            }}
          />
        </div>
        <div className={`${commonCls.col} ${commonCls.txtCenter}`}>
          <RadioGroup
            style={{ display: "block" }}
            value={updatedEmpObj.act_as_root_user ? "Yes" : "No"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.value === "Yes", "act_as_root_user")
            }
            classes={{ root: "" }}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label={t("Yes")}
            />
            <FormControlLabel value="No" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </div>

        <div className={`${commonCls.col}`}>
          <div>
            <FormControlLabel
              value="view_monitor"
              control={<Checkbox color="default" />}
              label={t("Monitor")}
              labelPlacement="end"
              checked={updatedEmpObj.attributes?.privileges?.includes(
                UserPrivileges.view_monitor
              )}
              onChange={(event, value) => {
                const attributes = privillagesCheckBoxChecked(
                  updatedEmpObj,
                  value,
                  UserPrivileges.view_monitor
                );
                onChange(attributes, "attributes");
              }}
            />
            <div style={{ marginTop: 16 }}>
              <FormControlLabel
                value="view_dashboard"
                control={<Checkbox color="default" />}
                label={t("Sleep records")}
                labelPlacement="end"
                checked={updatedEmpObj.attributes?.privileges?.includes(
                  UserPrivileges.view_dashbaord
                )}
                onChange={(event, value) => {
                  const attributes = privillagesCheckBoxChecked(
                    updatedEmpObj,
                    value,
                    UserPrivileges.view_dashbaord
                  );
                  onChange(attributes, "attributes");
                }}
              />
            </div>
          </div>
        </div>
        <div className={`${commonCls.col}`} />
      </div>

      <div className={`${commonCls.row} ${commonCls.alignFlexEnd}`}>
        <div className={commonCls.actionBtnWrapper}>
          <div className={commonCls.actionBtn}>
            <SMButton text={t("Save")} onClick={saveUser} />
          </div>
          <div className={commonCls.actionBtn}>
            <SMButton
              outlineBtn
              text={t("Cancel")}
              onClick={() =>
                setEditEmployee({ ...editEmployee, [updatedEmpObj.id]: false })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmployeeListRowEdit;
