import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Group } from "interfaces/group.interface";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { SortTypes } from "constants/app.enum";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { User } from "interfaces/user.interface";
import {
  allChecked,
  isAdminUser,
  sortUsers,
  sortUsersBySearchQuery,
  toggleAllUsersChecked,
  viewUserDetails
} from "utils/userManagement";
import {
  deleteMultipleUsers,
  deleteUserAction,
  upadateUserInformation
} from "store/actions/userActions";
import {
  getGroupsToUpdate,
  normalizedGroupsEntity
} from "utils/groupManagement";
import BulkActions from "components/BulkActions/BulkActions";
import CommonStyles from "theme/CommonStyles";
import DialogBox from "components/SaveCancelDeleteButton/DialogBox";
import PerPageListItems from "components/PerPageListItems/PerPageListItems";
import SMButton from "components/SMButton/SMButton";
import SMPagination from "components/SMPagination/SMPagination";
import SearchTextField from "components/SearchTextField/SearchTextField";

import EmployeeRow from "./components/EmployeeRow";
import EmployeesListHeader from "./components/EmployeesListHeader";

interface UsersListInterface {
  routePath: string;
  users: User[];
}

export const useStyles = makeStyles((theme) => ({
  addNewUser: {
    float: "left",
    cursor: "pointer",
    marginBottom: 6.5
  },
  perPageWrapper: {
    float: "right"
  },
  searchWrapper: {
    width: "100%",
    float: "left",
    marginTop: 25
  },
  bulkActionWrapper: {
    width: "100%",
    float: "left",
    marginTop: 9,
    marginBottom: 2
  }
}));

/**
 * @name EmployeeList
 * @description employee list component
 * @returns EmployeeList componet
 */
function EmployeeList({ routePath, users }: UsersListInterface): JSX.Element {
  const commonCls = CommonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { t } = useTranslation();

  const [activePage, setCurrentPage] = useState(1);
  const [sortedUsers, setSortedUsers] = useState<User[]>([]);
  const [currentUsers, setCurrentUsers] = useState<User[]>();

  const [curSortField, setSortField] = useState<number>(3);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [curSortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const [groupEntity, setGroupEntity] = useState<{ [key: string]: Group }>({});

  const [editEmployee, setEditEmployee] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [deleteEmployee, setDeleteEmployee] = useState<User>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const loginedUser: User = useSelector((state: any) => {
    return (state?.auth.user as User) || {};
  });

  // bulik actions
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const groupState = useSelector((state: any) => {
    return state.userGroupsReducer;
  });

  useEffect(() => {
    if (groupState && groupState.groups && groupState.groups.length) {
      setGroupEntity(normalizedGroupsEntity(groupState.groups));
    }
  }, [groupState]);

  useEffect(() => {
    if (users && users.length) {
      const emp = [...users];
      emp.sort((a: User, b: User) =>
        a.display_name > b.display_name ? 1 : -1
      );
      setSortedUsers(emp);
    }
  }, [users]);

  useEffect(() => {
    if (sortedUsers) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentUsers(sortedUsers.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [sortedUsers, activePage, itemsPerPage]);

  const sortList = (sortType: SortTypes): void => {
    if (!Number.isNaN(sortType)) {
      let order = "ASC";
      if (curSortField === sortType) {
        order = curSortOrder === "ASC" ? "DESC" : "ASC";
      }
      setSortField(sortType);
      setSortOrder(order as "ASC" | "DESC");
      setSortedUsers(sortUsers(sortedUsers, order, sortType));
    }
  };

  const onChange = (q: string, selectedType: string = "name") => {
    setSearchQuery(q);
    setCurrentPage(1);
    const filteredUsers = sortUsersBySearchQuery({
      q,
      type: "name",
      users
    });

    setCurrentPage(1);
    setSortedUsers(filteredUsers);
  };

  const addNewUser = () => {
    const path = `${routePath}/0`;
    history.push(path);
  };

  const handleClick = (key: string) => {
    if (key === "remove_selected") {
      setOpenDeleteDialog(true);
    }
  };

  const onToggleSelectAll = (checked: boolean) => {
    if (currentUsers) {
      setSelectedItems({
        ...selectedItems,
        ...toggleAllUsersChecked(currentUsers, checked)
      });
    }
  };

  const onSingleDelete = () => {
    if (deleteEmployee) {
      dispatch(deleteUserAction({ user: deleteEmployee }));
      setDeleteEmployee(undefined);
    }
  };

  const bulkDelete = () => {
    setCurrentPage(1);
    const usersToDelete: User[] = users.filter((user: User) => {
      return selectedItems[user.id];
    });
    dispatch(deleteMultipleUsers(usersToDelete));
    setSelectedItems({});
  };

  const onSaveAction = (updatedUser: User, currentUserGroupsIds: string[]) => {
    setEditEmployee({ ...editEmployee, [updatedUser.id]: false });
    const groups: Group[] = getGroupsToUpdate({
      user: updatedUser,
      groupEntity,
      currentUserGroupsIds
    });
    dispatch(upadateUserInformation({ user: updatedUser, groups }));
  };

  const ifBulkDelete = () => {
    const selected = users?.filter((user: User) => {
      return selectedItems[user.id];
    });
    return selected?.length > 0;
  };

  return (
    <div className={commonCls.tabContentWrapper}>
      <div className={classes.perPageWrapper}>
        <PerPageListItems
          type={USER_SETTINGS_CONSTANTS.employees_per_page}
          label={t("Per page")}
          onChange={setItemsPerPage}
          total={sortedUsers?.length}
        />
      </div>
      {loginedUser && isAdminUser(loginedUser) && (
        <div className={classes.addNewUser}>
          <SMButton
            icon={<Plus />}
            text={t("Add Employee")}
            onClick={addNewUser}
          />
        </div>
      )}

      <div className={classes.searchWrapper}>
        <SearchTextField
          onChange={onChange}
          width="320px"
          placeholder={t("e.g. Kari Nordmann")}
        />
      </div>

      {isAdminUser(loginedUser) ? (
        <div className={classes.bulkActionWrapper}>
          <BulkActions
            selectedItems={selectedItems}
            actions={[{ key: "remove_selected", label: t("Delete Selected") }]}
            handleClick={handleClick}
          />
        </div>
      ) : (
        <></>
      )}
      <EmployeesListHeader
        curSortOrder={curSortOrder}
        curSortField={curSortField}
        onClick={sortList}
        loginedUser={loginedUser}
        onToggleSelectAll={onToggleSelectAll}
        allSelected={allChecked(currentUsers || [], selectedItems)}
        isAdmin={isAdminUser(loginedUser)}
      />

      {/* Users List  */}
      <div className={commonCls.rowContainer}>
        {currentUsers &&
          currentUsers.map((user: User, index) => {
            return (
              <EmployeeRow
                key={user.id}
                commonCls={commonCls}
                searchQuery={searchQuery}
                loginedUser={loginedUser}
                user={user}
                groupEntity={groupEntity}
                t={t}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setEditEmployee={setEditEmployee}
                editEmployee={editEmployee}
                onSaveAction={onSaveAction}
                viewUserDetails={(event: any, userObj: User) =>
                  viewUserDetails(event, userObj, history)
                }
                onDelete={(deleteMe: User) => {
                  setDeleteEmployee(deleteMe);
                  setOpenDeleteDialog(true);
                }}
              />
            );
          })}
      </div>

      {/* Pagination  */}
      {sortedUsers && sortedUsers?.length > itemsPerPage && (
        <SMPagination
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          itemsLength={sortedUsers?.length}
          handlePageChange={(pageNumber: number) => setCurrentPage(pageNumber)}
        />
      )}

      {/* Delete dialog box */}
      <DialogBox
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={() => (ifBulkDelete() ? bulkDelete() : onSingleDelete())}
        title={t("Delete users")}
        dialogDesc={t(
          "Deleting this employee will remove all data associated with this employee. This action is non-reversable"
        )}
        confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
        confirmPlaceHolder={t("Key in confirmation text here")}
        confirmText={t("I AM SURE")}
        buttonOk={t("Delete")}
        buttonCancel={t("Cancel")}
      />
    </div>
  );
}
export default EmployeeList;
