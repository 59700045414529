import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import {
  Device,
  DeviceSettings,
  disabledTrackingTypes
} from "interfaces/device.interface";
import { OnOffSwitch } from "components/OnOffSwitch";
import {
  getDisabledSleepTracking,
  isDisabledSleepTracking
} from "utils/deviceManagement";
import DeviceInfoText from "components/DeviceInfoText/DeviceInfoText";

export const useStyles = makeStyles((theme) => ({
  subtitleEditIcon: {
    float: "right",
    marginRight: 10
  },
  subtitle: {
    fontWeight: "bold",
    marginTop: 14,
    float: "left",
    width: "100%"
  },
  seriallNumber: {
    color: "#949391"
  },
  textFieldroot: {}
}));

interface Props {
  device: Device;
  deviceSettings: DeviceSettings;
  onChange: (
    name: string,
    event: React.ChangeEvent<HTMLInputElement> | null,
    value: any
  ) => void;
  t: (param: string) => string;
  showLabel?: boolean;
  inline?: boolean;
}

function DisableSleepTrackingComponent({
  deviceSettings,
  onChange,
  device,
  t,
  showLabel = true,
  inline = true
}: Props) {
  const classes = useStyles();
  const [disabledTracking, setDisabledTracking] = useState<string[]>([]);
  const [isDisabledTrackingOn, setIsDisabledTrackingOn] = useState<boolean>();

  useEffect(() => {
    setDisabledTracking(getDisabledSleepTracking(deviceSettings));
    setIsDisabledTrackingOn(isDisabledSleepTracking(deviceSettings));
  }, [deviceSettings]);

  const onDisableTracking = (
    type: disabledTrackingTypes,
    pos: 0 | 1 | null,
    value: string | null
  ) => {
    let controlSchedule = [...(deviceSettings?.controlSchedule || [])];
    if (type === "enable" && value !== null) {
      let from: string;
      let to;

      // calculate from
      if (pos === 0) {
        from = value;
      } else {
        from = disabledTracking.length > 0 ? disabledTracking[0] : "00";
      }

      // calculate to
      if (pos === 1) {
        to = value;
      } else {
        to = disabledTracking.length > 0 ? disabledTracking[1] : "00";
      }

      const disabledTrackProps = {
        enabled: true,
        enableSession: false,
        timespan: [`${from}`, `${to}`]
      };

      if (controlSchedule.length) {
        controlSchedule[0] = disabledTrackProps;
      } else {
        controlSchedule.push(disabledTrackProps);
      }
    } else {
      controlSchedule = [
        {
          enabled: type === "enable",
          enableSession: false,
          timespan: controlSchedule[0].timespan || []
        }
      ];
    }
    onChange("controlSchedule", null, controlSchedule);
  };

  return (
    <div>
      <DeviceInfoText
        text={`${showLabel ? t("Disable Sleep Tracking") : ""}`}
        field={
          <div>
            <div style={{ float: "left" }}>
              <OnOffSwitch
                checked={isDisabledTrackingOn ?? false}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  value: any
                ) => {
                  setIsDisabledTrackingOn(value);
                  onDisableTracking(value ? "enable" : "disable", null, null);
                }}
                name="disable sleep tracking"
              />
            </div>

            <div style={{ float: "left", width: "100%" }}>
              {isDisabledTrackingOn && (
                <div>
                  <div
                    style={{ width: inline ? "45%" : "100%", float: "left" }}
                  >
                    <TextField
                      type="time"
                      classes={{
                        root: classes.textFieldroot
                      }}
                      value={disabledTracking[0] ?? ""}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        onDisableTracking("enable", 0, e.target.value);
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      key="from"
                    />
                  </div>
                  <div
                    style={{ width: inline ? "45%" : "100%", float: "right" }}
                  >
                    <TextField
                      type="time"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={disabledTracking[1] ?? ""}
                      onChange={(e) => {
                        onDisableTracking("enable", 1, e.target.value);
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      key="to"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}

export default DisableSleepTrackingComponent;
