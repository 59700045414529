export const DATA_ACTION_TYPES = {
  deleting: "deleting",
  deleteSuccess: "deleteSuccess",
  bulkDeleteSuccess: "bulkDeleteSuccess",
  fetchSuccess: "fetch-success",
  updateSuccess: "update-success",
  create: "create-rule",
  createSuccess: "create-rule-success",
  reset: ""
};
